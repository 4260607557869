import axios from 'axios'

class BannersService {
  getBanners = () =>
    axios({
      url: 'banner',
      method: 'GET',
    })

  setBanners = (data) =>
    axios({
      url: 'banner',
      method: 'POST',
      data,
    })

  updateBanner = ({ bannerId, data }) =>
    axios.put(`banner/${bannerId}`, {
      validFrom: data.validFrom ? data.validFrom : null,
      validTo: data.validTo ? data.validTo : null,
      oldPrice: data.oldPrice ? data.oldPrice : null,
    })

  toggleBannerVisibility = (id) =>
    axios({
      url: `banner/disable/${id}`,
      method: 'PUT',
    })
}

export default new BannersService()
