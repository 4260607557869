import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { yupResolver } from '@hookform/resolvers/yup'
import { FormProvider, useForm } from 'react-hook-form'

import { signIn } from 'redux/actions/auth'

import { signInSchema } from 'utils/schemas'
import { getCorrectErrorMessages } from 'utils/errors'

import AuthLayout from 'layouts/AuthLayout'
import HookedField from 'components/HookedField'
import ErrorList from 'components/ErrorList'
import Button from 'components/Button'

const SignIn = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const { signInErrors } = useSelector((state) => state.auth)

  const methods = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    shouldFocusError: true,
    resolver: yupResolver(signInSchema),
  })

  const signInHandler = ({ email, password }) =>
    dispatch(signIn({ signInData: { email, password }, navigate }))

  return (
    <AuthLayout title={t('auth.sign in')}>
      <div className="signIn">
        <div className="signIn-form">
          <FormProvider {...methods}>
            <form autoComplete="off" onSubmit={methods.handleSubmit(signInHandler)}>
              <HookedField
                label={t('fields.email')}
                name="email"
                defaultValue=""
                fieldType="textField"
                type="email"
                placeholder={t('fields.email')}
                isRequired={true}
              />
              <HookedField
                label={t('fields.password')}
                name="password"
                defaultValue=""
                fieldType="passwordField"
                type="password"
                placeholder={t('fields.password')}
                isRequired={true}
              />
              <Button
                className="signIn-btn"
                size="large"
                type="primary"
                htmlType="submit"
                disabled={methods.formState.isSubmitted && !methods.formState.isValid}
              >
                {t('auth.sign in')}
              </Button>
              <ErrorList errors={getCorrectErrorMessages(signInErrors)} isOnRequest />
            </form>
          </FormProvider>
        </div>
      </div>
    </AuthLayout>
  )
}

export default SignIn
