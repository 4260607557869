import { createElement } from 'react'
import { Outlet } from 'react-router-dom'
import withAuth from 'hocs/withAuth'

const Main = ({ layout }) => {
  return (
    <>
      {createElement(layout, {
        children: <Outlet />,
      })}
    </>
  )
}

export default withAuth(Main)
