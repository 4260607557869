import {
  GET_BANNERS_REQUEST,
  SET_BANNERS_REQUEST,
  TOGGLE_BANNER_VISIBILITY_REQUEST,
  GET_BANNER_REQUEST,
  UPDATE_BANNER_REQUEST,
  CREATE_BANNER_REQUEST,
  DELETE_BANNER_REQUEST,
} from '../types/banners'

export function getBanners(payload) {
  return {
    type: GET_BANNERS_REQUEST,
    payload,
  }
}

export function setBanners(payload) {
  return {
    type: SET_BANNERS_REQUEST,
    payload,
  }
}

export function toggleBannerVisibility(payload) {
  return {
    type: TOGGLE_BANNER_VISIBILITY_REQUEST,
    payload,
  }
}

export function getBanner(payload) {
  return {
    type: GET_BANNER_REQUEST,
    payload,
  }
}

export function updateBanner(payload) {
  return {
    type: UPDATE_BANNER_REQUEST,
    payload,
  }
}

export function createBanner(payload) {
  return {
    type: CREATE_BANNER_REQUEST,
    payload,
  }
}

export function deleteBanner(payload) {
  return {
    type: DELETE_BANNER_REQUEST,
    payload,
  }
}
