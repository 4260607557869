import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { createUsersNotification } from 'redux/actions/usersNotifications'
import { useNavigate } from 'react-router-dom'
import Page from 'components/Page'
import UserNotificationForm from 'components/forms/UserNotificationForm'

const UsersNotificationsAdd = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { isChangeLoading } = useSelector((state) => state.usersNotifications)

  const submitHandler = (data) => {
    const avatarFile = data.avatar || null
    const preparedData = {
      body: data.body,
      landingPage: data.landingPage,
      title: data.title,
    }

    if (data.landingPage === 'LINK') {
      preparedData.url = data.url
    } else {
      preparedData.product = data.product
    }

    dispatch(
      createUsersNotification({
        data: preparedData,
        avatarFile,
        navigate,
      }),
    )
  }

  return (
    <Page pageTitle={t('usersNotifications.new notification')} withCancelBtn={true}>
      <UserNotificationForm submitHandler={submitHandler} loading={isChangeLoading} />
    </Page>
  )
}

export default UsersNotificationsAdd
