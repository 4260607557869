import moment from 'moment'
import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { getCustomers } from 'redux/actions/customers'
import SortableColumnTitle from 'components/SortableColumnTitle'
import PageTable from 'components/PageTable'
import Icon from 'components/Icon'
import { default as CustomButton } from 'components/Button'
import { DATE_FORMAT, ICONS } from 'utils/constants'
import ExportCustomersModal from '../../../components/ExportCustomersModal'

const Customers = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [isExportModalVisible, setIsExportModalVisible] = useState(false)
  const {
    items,
    pagination: paginationState,
    isGetLoading,
  } = useSelector((state) => state.customers)
  const [isNeedLoadData, setIsNeedLoadData] = useState(false)
  const [currentPage, setCurrentPage] = useState(1)
  const [pageSize, setPageSize] = useState(20)
  const [sortType, setSortType] = useState(null)
  const [sortOrder, setSortOrder] = useState(null)
  const [search, setSearch] = useState('')

  const refreshCustomers = useCallback(() => {
    dispatch(getCustomers({ currentPage, pageSize, sortType, sortOrder, search }))
    setIsNeedLoadData(false)
  }, [dispatch, currentPage, pageSize, sortType, sortOrder, search])

  useEffect(() => {
    if (currentPage !== 1) setCurrentPage(1)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search])

  useEffect(() => {
    setIsNeedLoadData(true)
  }, [pageSize, currentPage, sortType, sortOrder, search])

  useEffect(() => {
    isNeedLoadData && refreshCustomers()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isNeedLoadData])

  const propertiesKeyMap = {
    user_lastorder: 'LAST_ORDER',
    users_fullName: 'FULL_NAME',
    users_phoneNumber: 'PHONE_NUMBER',
    users_email: 'EMAIL',
    user_totalOrders: 'TOTAL_ORDERS',
    user_spent: 'SPENT',
    user_avgrating: 'AVG_RATING',
    user_balance: 'WALLET_BALANCE',
  }

  const adaptSortOrder = (antTableOrder) => {
    if (antTableOrder === 'ascend') return 'ASC'
    if (antTableOrder === 'descend') return 'DESC'
    return null
  }

  const columns = [
    {
      title: '№',
      dataIndex: 'users_id',
      key: 'users_id',
      width: '35px',
      align: 'center',
    },
    {
      title: (props) => (
        <SortableColumnTitle {...props} title={t('fields.last order')} id="user_lastorder" />
      ),
      dataIndex: 'lastorder',
      key: 'user_lastorder',
      sorter: true,
      align: 'center',
      render: (date) => (date ? moment(date).format(DATE_FORMAT) : '—'),
    },
    {
      title: (props) => (
        <SortableColumnTitle {...props} title={t('fields.name en')} id="users_fullName" />
      ),
      dataIndex: 'users_fullName',
      key: 'users_fullName',
      sorter: true,
      render: (users_fullName) => decodeURI(users_fullName).trim(),
    },
    {
      title: (props) => (
        <SortableColumnTitle {...props} title={t('fields.mobile')} id="users_phoneNumber" />
      ),
      dataIndex: 'users_phoneNumber',
      key: 'users_phoneNumber',
      sorter: true,
    },
    {
      title: (props) => (
        <SortableColumnTitle {...props} title={t('fields.email')} id="users_email" />
      ),
      dataIndex: 'users_email',
      key: 'users_email',
      sorter: true,
    },
    {
      title: (props) => (
        <SortableColumnTitle {...props} title={t('fields.total orders')} id="user_totalOrders" />
      ),
      dataIndex: 'totalorders',
      key: 'user_totalOrders',
      align: 'center',
      sorter: true,
    },
    {
      title: (props) => (
        <SortableColumnTitle {...props} title={t('fields.spent')} id="user_spent" />
      ),
      dataIndex: 'spent',
      key: 'user_spent',
      align: 'center',
      render: (spent) => (spent ? parseFloat(spent).toFixed(2) : '—'),
      sorter: true,
    },
    {
      title: (props) => (
        <SortableColumnTitle {...props} title={t('fields.inWallet')} id="user_balance" />
      ),
      dataIndex: 'walletBalance',
      key: 'user_balance',
      align: 'center',
      render: (balance) => {
        return (
          <span>
            {balance
              ? t('wallet.currency', {
                  amount: parseFloat(balance).toFixed(2),
                })
              : '—'}
          </span>
        )
      },
      sorter: true,
    },
    {
      title: (props) => (
        <SortableColumnTitle {...props} title={t('ratings.average rating')} id="user_avgrating" />
      ),
      dataIndex: 'avgrating',
      key: 'user_avgrating',
      align: 'center',
      render: (rating) => (rating ? rating : '0'),
      sorter: true,
    },
  ]

  const tableChangeHandler = (pagination, filter, sorter) => {
    setCurrentPage(pagination.current)
    setPageSize(pagination.pageSize)
    setSortType(propertiesKeyMap[sorter.columnKey] || null)
    setSortOrder(adaptSortOrder(sorter.order))
  }

  return (
    <>
      <PageTable
        pageTitle={t('customers.title')}
        baseData={items}
        columns={columns}
        action={
          <>
            <CustomButton type="primary" onClick={() => setIsExportModalVisible(true)}>
              <Icon icon={ICONS.UPLOAD} size={24} color={'#ffffff'} />
              <span className="btn-text">{t('app.export')}</span>
            </CustomButton>
          </>
        }
        onRow={(record) => ({
          onClick: () => navigate(`/main/customers/view/${record.users_id}`),
        })}
        loading={isGetLoading}
        pagination={{
          current: currentPage,
          pageSize: pageSize,
          total: paginationState.total,
          showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
          showSizeChanger: true,
        }}
        sortDirections={['ascend', 'descend', 'ascend']}
        onChange={tableChangeHandler}
        searchHandler={(value) => setSearch(value.trim())}
        rowKey="users_id"
      />
      <ExportCustomersModal
        isVisible={isExportModalVisible}
        handleCancel={() => setIsExportModalVisible(false)}
        fileDownloadedHandler={() => setIsExportModalVisible(false)}
      />
    </>
  )
}

export default Customers
