import { useCallback, useMemo, useContext, useEffect } from 'react'
import { useQuery } from '@tanstack/react-query'
import ClientContext from 'providers/ClientProvider'
import { getCurrentAdminUser } from '../api/admin-users-managment'

const useClient = () => {
  const { client, setClient } = useContext(ClientContext)

  const { data: clientData, isFetched } = useQuery({
    queryKey: ['current-admin-user'],
    queryFn: getCurrentAdminUser,
  })

  const isClientCan = useCallback(
    (permission) => {
      if (client.data) {
        return Boolean(
          client.data.role.permissions.filter(
            (onePermission) => onePermission.permission === permission,
          ).length > 0,
        )
      }

      return false
    },
    [client.data],
  )

  const isSuperAdmin = useMemo(() => {
    if (client.data) {
      return Boolean(client.data.role.id === 1)
    }

    return false
  }, [client.data])

  useEffect(() => {
    setClient({ ...client, isLoaded: isFetched, data: clientData, isAuthorized: !!clientData })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientData, isFetched])

  return { client, isClientCan, isSuperAdmin }
}

export default useClient
