export const GET_COUPONS_REQUEST = 'GET_COUPONS_REQUEST'
export const GET_COUPONS = 'GET_COUPONS'
export const GET_COUPONS_REQUEST_PENDING = 'GET_COUPONS_REQUEST_PENDING'
export const GET_COUPONS_REQUEST_SUCCESS = 'GET_COUPONS_REQUEST_SUCCESS'
export const GET_COUPONS_REQUEST_ERROR = 'GET_COUPONS_REQUEST_ERROR'

export const CREATE_COUPON_REQUEST = 'CREATE_COUPON_REQUEST'
export const CREATE_COUPON = 'CREATE_COUPON'
export const CREATE_COUPON_REQUEST_SUCCESS = 'CREATE_COUPON_REQUEST_SUCCESS'
export const CREATE_COUPON_REQUEST_ERROR = 'CREATE_COUPON_REQUEST_ERROR'

export const GET_COUPON_REQUEST = 'GET_COUPON_REQUEST'
export const GET_COUPON = 'GET_COUPON'
export const GET_COUPON_REQUEST_PENDING = 'GET_COUPON_REQUEST_PENDING'
export const GET_COUPON_REQUEST_SUCCESS = 'GET_COUPON_REQUEST_SUCCESS'
export const GET_COUPON_REQUEST_ERROR = 'GET_COUPON_REQUEST_ERROR'

export const DELETE_COUPON_REQUEST = 'DELETE_COUPON_REQUEST'
export const DELETE_COUPON = 'DELETE_COUPON'
export const DELETE_COUPON_REQUEST_PENDING = 'DELETE_COUPON_REQUEST_PENDING'
export const DELETE_COUPON_REQUEST_SUCCESS = 'DELETE_COUPON_REQUEST_SUCCESS'
export const DELETE_COUPON_REQUEST_ERROR = 'DELETE_COUPON_REQUEST_ERROR'
