export const GET_RATING_REQUEST = 'GET_RATING_REQUEST'
export const GET_RATING = 'GET_RATING'
export const GET_RATING_REQUEST_PENDING = 'GET_RATING_REQUEST_PENDING'
export const GET_RATING_REQUEST_SUCCESS = 'GET_RATING_REQUEST_SUCCESS'
export const GET_RATING_REQUEST_ERROR = 'GET_RATING_REQUEST_ERROR'

export const GET_RATING_MESSAGE_REQUEST = 'GET_RATING_MESSAGE_REQUEST'
export const GET_RATING_MESSAGE = 'GET_RATING_MESSAGE'
export const GET_RATING_MESSAGE_REQUEST_PENDING = 'GET_RATING_MESSAGE_REQUEST_PENDING'
export const GET_RATING_MESSAGE_REQUEST_SUCCESS = 'GET_RATING_MESSAGE_REQUEST_SUCCESS'
export const GET_RATING_MESSAGE_REQUEST_ERROR = 'GET_RATING_MESSAGE_REQUEST_ERROR'

export const UPDATE_RATING_MESSAGE_REQUEST = 'UPDATE_RATING_MESSAGE_REQUEST'
export const UPDATE_RATING_MESSAGE = 'UPDATE_RATING_MESSAGE'
export const UPDATE_RATING_MESSAGE_REQUEST_PENDING = 'UPDATE_RATING_MESSAGE_REQUEST_PENDING'
export const UPDATE_RATING_MESSAGE_REQUEST_SUCCESS = 'UPDATE_RATING_MESSAGE_REQUEST_SUCCESS'
export const UPDATE_RATING_MESSAGE_REQUEST_ERROR = 'UPDATE_RATING_MESSAGE_REQUEST_ERROR'

export const DELETE_RATING_MESSAGE_REQUEST = 'DELETE_RATING_MESSAGE_REQUEST'
export const DELETE_RATING_MESSAGE = 'DELETE_RATING_MESSAGE'
export const DELETE_RATING_MESSAGE_REQUEST_PENDING = 'DELETE_RATING_MESSAGE_REQUEST_PENDING'
export const DELETE_RATING_MESSAGE_REQUEST_SUCCESS = 'DELETE_RATING_MESSAGE_REQUEST_SUCCESS'
export const DELETE_RATING_MESSAGE_REQUEST_ERROR = 'DELETE_RATING_MESSAGE_REQUEST_ERROR'
