import {
  GET_RATING_MESSAGE_REQUEST,
  GET_RATING_REQUEST,
  UPDATE_RATING_MESSAGE_REQUEST,
} from '../types/rating'

export function getRatings(payload) {
  return {
    type: GET_RATING_REQUEST,
    payload,
  }
}

export function getRatingsMessage(payload) {
  return {
    type: GET_RATING_MESSAGE_REQUEST,
    payload,
  }
}
export function updateRatingsMessage(payload) {
  return {
    type: UPDATE_RATING_MESSAGE_REQUEST,
    payload,
  }
}
