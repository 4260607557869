import { createStore, applyMiddleware, combineReducers, compose } from 'redux'
import createSagaMiddleware from 'redux-saga'
import rootReducer from './redux/reducers/rootReducer'
import rootSaga from './redux/saga/rootSaga'

const isNotProduction = process.env.NODE_ENV !== 'production'
const saga = createSagaMiddleware()
let middlewares = applyMiddleware(saga)

if (isNotProduction && window.__REDUX_DEVTOOLS_EXTENSION__) {
  middlewares = compose(middlewares, window.__REDUX_DEVTOOLS_EXTENSION__())
}

const initialState = {}

const reducer = combineReducers({
  ...rootReducer,
})

export const store = createStore(reducer, initialState, middlewares)

saga.run(rootSaga)
