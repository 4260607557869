import React from 'react'

const ConfirmModal = ({ question, acceptHandler, declineHandler }) => {
  return (
    <div className="confirmModal">
      <h4 className="confirmModal-question">{question}</h4>
      <div className="modal-actions">
        <button className="btn btn--normal" onClick={declineHandler}>
          No
        </button>
        <button className="btn btn--normal btn--primary" onClick={acceptHandler}>
          Yes
        </button>
      </div>
    </div>
  )
}

export default ConfirmModal
