import { call, put, all, fork, takeEvery, select } from 'redux-saga/effects'
import { showLoading, hideLoading } from 'react-redux-loading-bar'
import CategoriesService from '../middlewares/categories'
import { notify } from 'reapop'
import i18n from 'i18next'

import {
  GET_CATEGORIES_REQUEST,
  GET_CATEGORIES,
  GET_CATEGORY_REQUEST,
  GET_CATEGORY,
  UPDATE_CATEGORY_REQUEST,
  UPDATE_CATEGORY,
  GET_CATEGORIES_REQUEST_PENDING,
  GET_CATEGORIES_REQUEST_SUCCESS,
  GET_CATEGORIES_REQUEST_ERROR,
  CHANGE_CATEGORIES_REQUEST_PENDING,
  CHANGE_CATEGORIES_REQUEST_SUCCESS,
  CHANGE_CATEGORIES_REQUEST_ERROR,
  GET_SUP_CATEGORIES_REQUEST,
} from '../types/categories'

function* getCategoriesWatcher() {
  yield takeEvery(GET_CATEGORIES_REQUEST, getCategoriesWorker)
}

function* getSupCategoriesWatcher() {
  yield takeEvery(GET_SUP_CATEGORIES_REQUEST, getSupCategoriesWorker)
}

function* getCategoryWatcher() {
  yield takeEvery(GET_CATEGORY_REQUEST, getCategoryWorker)
}

function* updateCategoryWatcher() {
  yield takeEvery(UPDATE_CATEGORY_REQUEST, updateCategoryWorker)
}

function* getSupCategoriesWorker() {
  try {
    yield put(showLoading())
    yield put({ type: GET_CATEGORIES_REQUEST_PENDING })
    yield call(CategoriesService.getSupCategories)
    const { current, pageSize } = yield select((state) => state.categories.pagination)
    yield put({
      type: GET_CATEGORIES_REQUEST,
      payload: {
        page: current,
        size: pageSize,
      },
    })
    yield put({ type: GET_CATEGORIES_REQUEST_SUCCESS })
    yield put(hideLoading())
  } catch (error) {
    yield put({ type: GET_CATEGORIES_REQUEST_ERROR, payload: error.response.data.messages })
    yield put(hideLoading())
  }
}

function* getCategoriesWorker({ payload: { page, size } }) {
  try {
    yield put(showLoading())
    yield put({ type: GET_CATEGORIES_REQUEST_PENDING })
    const response = yield call(CategoriesService.getCategories, page, size)
    const { result, total } = response.data.data
    yield put({
      type: GET_CATEGORIES,
      payload: {
        items: result,
        pagination: {
          current: page,
          pageSize: size,
          total: total,
        },
      },
    })
    yield put({ type: GET_CATEGORIES_REQUEST_SUCCESS })
    yield put(hideLoading())
  } catch (error) {
    yield put({ type: GET_CATEGORIES_REQUEST_ERROR, payload: error.response.data.messages })
    yield put(hideLoading())
  }
}

function* getCategoryWorker({ payload: { id } }) {
  try {
    yield put(showLoading())
    yield put({ type: GET_CATEGORIES_REQUEST_PENDING })
    const response = yield call(CategoriesService.getCategoryById, id)
    const category = response.data.data
    yield put({ type: GET_CATEGORY, payload: category })
    yield put({ type: GET_CATEGORIES_REQUEST_SUCCESS })
    yield put(hideLoading())
  } catch (error) {
    yield put({ type: GET_CATEGORIES_REQUEST_ERROR, payload: error.response.data.messages })
    yield put(hideLoading())
  }
}

function* updateCategoryWorker({ payload: { id, data, navigate } }) {
  try {
    yield put(showLoading())
    yield put({ type: CHANGE_CATEGORIES_REQUEST_PENDING })
    const response = yield call(CategoriesService.updateCategory, id, data)
    const category = response.data.data
    yield put({ type: UPDATE_CATEGORY, payload: category })
    yield put({ type: CHANGE_CATEGORIES_REQUEST_SUCCESS })
    yield put(
      notify({
        title: i18n.t('notifs.updated'),
        status: 'success',
        dismissAfter: 4000,
        allowHTML: true,
      }),
    )

    navigate('/main/categories')
    yield put(hideLoading())
  } catch (error) {
    yield put({ type: CHANGE_CATEGORIES_REQUEST_ERROR, payload: error.response.data.messages })
    yield put(hideLoading())
  }
}

export default function* categories() {
  yield all([
    fork(getCategoriesWatcher),
    fork(getCategoryWatcher),
    fork(updateCategoryWatcher),
    fork(getSupCategoriesWatcher),
  ])
}
