import { useTranslation } from 'react-i18next'
import { Modal } from 'antd'
import BannerForm from 'components/forms/BannerForm'
import styles from './BannerFormModal.module.scss'

const BannerFormModal = ({ isVisible, onCancel, banner, submitCallback }) => {
  const { t } = useTranslation()
  const handleCancel = () => onCancel()

  return (
    <Modal
      className={styles.modal}
      open={isVisible}
      onCancel={handleCancel}
      footer={null}
      centered={true}
      width={550}
    >
      <h3 className={styles.modal__title}>
        {banner ? t('banners.update offer') : t('banners.new offer')}
      </h3>
      <div className={styles.modal__body}>
        <BannerForm banner={banner} submitCallback={submitCallback} />
      </div>
    </Modal>
  )
}

export default BannerFormModal
