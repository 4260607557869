import { useTranslation } from 'react-i18next'

import Page from '../../Page'
import { Col, Row, Skeleton } from 'antd'
import styles from './CashbackFormSkeleton.module.scss'

const CashbackFormSkeleton = () => {
  const { t } = useTranslation()

  return (
    <Page pageTitle={t('cashback.view.title')}>
      <Row className={styles.cashbackRow}>
        <Col span={12}>
          <Skeleton.Input active className={styles.cashbackInput} />
        </Col>
      </Row>
      <Row className={styles.cashbackRow}>
        <Col span={12}>
          <Skeleton.Input active className={styles.cashbackInput} />
        </Col>
      </Row>
      <Row className={styles.cashbackRow}>
        <Col span={12}>
          <Skeleton.Input active className={styles.cashbackInput} />
        </Col>
      </Row>
      <Row className={styles.cashbackRow}>
        <Col span={12}>
          <Skeleton.Input active className={styles.cashbackInput} />
        </Col>
      </Row>
      <Row gutter={[10, 0]} className={styles.cashbackRow}>
        <Col span={6}>
          <Skeleton.Input active className={styles.cashbackInput} />
        </Col>
        <Col span={6}>
          <Skeleton.Input active className={styles.cashbackInput} />
        </Col>
      </Row>
      <Row className={styles.cashbackRow}>
        <Col span={12}>
          <Skeleton.Input active className={styles.cashbackInput} />
        </Col>
      </Row>
      <Row className={styles.cashbackRow}>
        <Col span={12}>
          <Skeleton.Input active className={styles.cashbackInput} />
        </Col>
      </Row>
    </Page>
  )
}

export default CashbackFormSkeleton
