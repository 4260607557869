import { useEffect, useState } from 'react'

const useEnvironment = () => {
  const [environment, setEnvironment] = useState(null)
  const [isDev, setIsDev] = useState(null)
  const [isProd, setIsProd] = useState(null)

  useEffect(() => {
    if (process.env.NODE_ENV === 'development' || document.location.origin.includes('dev-admin')) {
      setEnvironment('DEV')
      setIsDev(true)
      setIsProd(false)
    } else {
      setEnvironment('PROD')
      setIsDev(false)
      setIsProd(true)
    }
  }, [])

  return { environment, isDev, isProd }
}

export default useEnvironment
