import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { getCategory, updateCategory } from 'redux/actions/categories'
import Page from 'components/Page'
import CategoryForm from 'components/forms/CategoryForm'
import { useMutation } from '@tanstack/react-query'
import { updateFile } from '../../../api/files'
import { message } from 'antd'
import { notify } from 'reapop'

const CategoriesView = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { id } = useParams()
  const { item: category, isGetLoading, isChangeLoading } = useSelector((state) => state.categories)

  const updateCategoryImageMutation = useMutation(updateFile, {
    onSuccess: async () => {
      message.success('Category image successfully updated')
    },
    onError: (err) => {
      if (err.response?.data?.messages.length) {
        dispatch(
          notify({ title: 'Category image upload failed', status: 'error', dismissAfter: 4000 }),
        )
      } else {
        return message.error('Something went wrong')
      }
    },
  })

  useEffect(() => {
    dispatch(getCategory({ id }))
  }, [id])

  const submitHandler = async (id, { name, arabianName, isVisible, categoryImage }) => {
    if (categoryImage) {
      await updateCategoryImageMutation.mutateAsync({
        id: categoryImage.id,
        file: categoryImage.file,
      })
    }

    dispatch(
      updateCategory({
        id,
        data: {
          name,
          arabianName,
          isVisible,
        },
        navigate,
      }),
    )
  }

  return (
    <Page pageTitle={category.name} isGetLoading={isGetLoading} isChangeLoading={isChangeLoading}>
      <CategoryForm
        defaultValues={category}
        submitHandler={(data) => submitHandler(id, data)}
        loading={isChangeLoading}
      />
    </Page>
  )
}

export default CategoriesView
