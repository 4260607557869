import { Col, Row, Skeleton, Space } from 'antd'
import { useTranslation } from 'react-i18next'

const PromocodeFormSkeleton = () => {
  const { t } = useTranslation()

  return (
    <div className="promocode-form promocode-form--skeleton">
      <div className="form-title">{t('promocodes.general info')}</div>
      <Skeleton active />
      <div className={'promocode-form__divider'}></div>
      <Space direction={'vertical'} size={40} style={{ width: '100%' }}>
        <Skeleton active />
        <Row gutter={20}>
          <Col span={12}>
            <Skeleton.Input active size={'large'} block={true} />
          </Col>
        </Row>
      </Space>
    </div>
  )
}

export default PromocodeFormSkeleton
