import axios from 'axios'

class DashboardService {
  getGeneralStats = ({ startDate, finishDate }) =>
    axios.get('dashboard/statistic/general', {
      params: {
        startDate,
        finishDate,
      },
    })

  getOrdersStats = ({ startDate, finishDate }) =>
    axios.get('dashboard/statistic/orders', {
      params: {
        startDate,
        finishDate,
      },
    })

  getOrdersAverageStats = ({ startDate, finishDate }) =>
    axios.get('dashboard/statistic/orders/average', {
      params: {
        startDate,
        finishDate,
      },
    })

  getOrdersAverageRevenueStats = ({ startDate, finishDate }) =>
    axios.get('dashboard/statistic/orders/average/revenue', {
      params: {
        startDate,
        finishDate,
      },
    })

  getOrdersMoreOneStats = ({ startDate, finishDate }) =>
    axios.get('dashboard/statistic/orders/more-one', {
      params: {
        startDate,
        finishDate,
      },
    })

  getOrdersTopPositionsStats = ({ startDate, finishDate, responseType }) =>
    axios.get('dashboard/statistic/tops-positions', {
      params: {
        startDate,
        finishDate,
        responseType,
      },
    })

  getOrdersPerAreaStats = ({ startDate, finishDate, average }) =>
    axios.get('dashboard/statistic/orders/per-area', {
      params: {
        startDate,
        finishDate,
        average: average !== null ? !!average : null,
      },
    })

  getOrdersConversionStats = ({ startDate, finishDate }) =>
    axios.get('dashboard/statistic/orders/conversion', {
      params: {
        startDate,
        finishDate,
      },
    })

  getOrdersPaymentStats = ({ startDate, finishDate }) =>
    axios.get('dashboard/statistic/orders/payment', {
      params: {
        startDate,
        finishDate,
      },
    })

  getRepeatedOrdersStats = ({ startDate, finishDate, numberOfRepeatedOrders }) =>
    axios.get('dashboard/statistic/orders/repeated', {
      params: {
        startDate,
        finishDate,
        numberOfRepeatedOrders,
      },
    })

  getMosquesStats = ({ startDate, finishDate, mosqueId, sortType, sortOrder }) =>
    axios.get('dashboard/statistic/mosques', {
      params: {
        startDate,
        finishDate,
        mosqueId: mosqueId ? mosqueId : null,
        sortType: sortType ? sortType : null,
        sortOrder: sortOrder ? sortOrder : null,
      },
    })

  getPromoCodesStats = ({ startDate, finishDate, responseType }) =>
    axios.get('dashboard/statistic/promocodes', {
      params: {
        startDate,
        finishDate,
        responseType,
      },
    })
}

export default new DashboardService()
