export const GET_BANNERS_REQUEST = 'GET_BANNERS_REQUEST'
export const GET_BANNERS = 'GET_BANNERS'

export const SET_BANNERS_REQUEST = 'SET_BANNERS_REQUEST'
export const TOGGLE_BANNER_VISIBILITY_REQUEST = 'TOGGLE_BANNER_VISIBILITY_REQUEST'

export const GET_BANNER_REQUEST = 'GET_BANNER_REQUEST'
export const GET_BANNER = 'GET_BANNER'

export const UPDATE_BANNER_REQUEST = 'UPDATE_BANNER_REQUEST'
export const UPDATE_BANNER = 'UPDATE_BANNER'

export const CREATE_BANNER_REQUEST = 'CREATE_BANNER_REQUEST'
export const CREATE_BANNER = 'CREATE_BANNER'

export const DELETE_BANNER_REQUEST = 'DELETE_BANNER_REQUEST'
export const DELETE_BANNER = 'DELETE_BANNER'

export const GET_BANNERS_REQUEST_PENDING = 'GET_BANNERS_REQUEST_PENDING'
export const GET_BANNERS_REQUEST_SUCCESS = 'GET_BANNERS_REQUEST_SUCCESS'
export const GET_BANNERS_REQUEST_ERROR = 'GET_BANNERS_REQUEST_ERROR'

export const CHANGE_BANNERS_REQUEST_PENDING = 'CHANGE_BANNERS_REQUEST_PENDING'
export const CHANGE_BANNERS_REQUEST_SUCCESS = 'CHANGE_BANNERS_REQUEST_SUCCESS'
export const CHANGE_BANNERS_REQUEST_ERROR = 'CHANGE_BANNERS_REQUEST_ERROR'
