export const GET_PROMOTIONS_REQUEST = 'GET_PROMOTIONS_REQUEST'
export const GET_PROMOTIONS = 'GET_PROMOTIONS'

export const GET_SUP_PROMOTIONS_REQUEST = 'GET_SUP_PROMOTIONS_REQUEST'
export const GET_SUP_PROMOTIONS = 'GET_SUP_PROMOTIONS'

export const GET_PROMOTION_REQUEST = 'GET_PROMOTION_REQUEST'
export const GET_PROMOTION = 'GET_PROMOTION'

export const UPDATE_PROMOTION_REQUEST = 'UPDATE_PROMOTION_REQUEST'
export const UPDATE_PROMOTION = 'UPDATE_PROMOTION'

export const CREATE_PROMOTION_REQUEST = 'CREATE_PROMOTION_REQUEST'
export const CREATE_PROMOTION = 'CREATE_PROMOTION'

export const DELETE_PROMOTION_REQUEST = 'DELETE_PROMOTION_REQUEST'
export const DELETE_PROMOTION = 'DELETE_PROMOTION'

export const GET_PROMOTIONS_REQUEST_PENDING = 'GET_PROMOTIONS_REQUEST_PENDING'
export const GET_PROMOTIONS_REQUEST_SUCCESS = 'GET_PROMOTIONS_REQUEST_SUCCESS'
export const GET_PROMOTIONS_REQUEST_ERROR = 'GET_PROMOTIONS_REQUEST_ERROR'

export const CHANGE_PROMOTIONS_REQUEST_PENDING = 'CHANGE_PROMOTIONS_REQUEST_PENDING'
export const CHANGE_PROMOTIONS_REQUEST_SUCCESS = 'CHANGE_PROMOTIONS_REQUEST_SUCCESS'
export const CHANGE_PROMOTIONS_REQUEST_ERROR = 'CHANGE_PROMOTIONS_REQUEST_ERROR'
