export const GET_USERS_NOTIFICATIONS_REQUEST = 'GET_USERS_NOTIFICATIONS_REQUEST'
export const GET_USERS_NOTIFICATIONS = 'GET_USERS_NOTIFICATIONS'

export const GET_USERS_NOTIFICATION_REQUEST = 'GET_USERS_NOTIFICATION_REQUEST'
export const GET_USERS_NOTIFICATION = 'GET_USERS_NOTIFICATION'

export const UPDATE_USERS_NOTIFICATION_REQUEST = 'UPDATE_USERS_NOTIFICATION_REQUEST'
export const UPDATE_USERS_NOTIFICATION = 'UPDATE_USERS_NOTIFICATION'

export const CREATE_USERS_NOTIFICATION_REQUEST = 'CREATE_USERS_NOTIFICATION_REQUEST'
export const CREATE_USERS_NOTIFICATION = 'CREATE_USERS_NOTIFICATION'
export const CREATE_USERS_NOTIFICATIONS_REQUEST_ERROR = 'CREATE_USERS_NOTIFICATIONS_REQUEST_ERROR'

export const DELETE_USERS_NOTIFICATION_REQUEST = 'DELETE_USERS_NOTIFICATION_REQUEST'
export const DELETE_USERS_NOTIFICATION = 'DELETE_USERS_NOTIFICATION'
export const DELETE_USERS_NOTIFICATIONS_REQUEST_ERROR = 'DELETE_USERS_NOTIFICATIONS_REQUEST_ERROR'

export const GET_USERS_NOTIFICATIONS_REQUEST_PENDING = 'GET_USERS_NOTIFICATIONS_REQUEST_PENDING'
export const GET_USERS_NOTIFICATIONS_REQUEST_SUCCESS = 'GET_USERS_NOTIFICATIONS_REQUEST_SUCCESS'
export const GET_USERS_NOTIFICATIONS_REQUEST_ERROR = 'GET_USERS_NOTIFICATIONS_REQUEST_ERROR'

export const CHANGE_USERS_NOTIFICATIONS_REQUEST_PENDING =
  'CHANGE_USERS_NOTIFICATIONS_REQUEST_PENDING'
export const CHANGE_USERS_NOTIFICATIONS_REQUEST_SUCCESS =
  'CHANGE_USERS_NOTIFICATIONS_REQUEST_SUCCESS'
export const CHANGE_USERS_NOTIFICATIONS_REQUEST_ERROR = 'CHANGE_USERS_NOTIFICATIONS_REQUEST_ERROR'

export const SEND_USERS_NOTIFICATION_REQUEST = 'SEND_USERS_NOTIFICATIONS_REQUEST'
export const SEND_USERS_NOTIFICATION = 'SEND_USERS_NOTIFICATIONS'

export const GET_NOTIFICATION_PRODUCTS_REQUEST = 'GET_NOTIFICATION_PRODUCTS_REQUEST'
export const GET_NOTIFICATION_PRODUCTS = 'GET_NOTIFICATION_PRODUCTS'

export const GET_NOTIFICATION_PRODUCTS_REQUEST_PENDING = 'GET_NOTIFICATION_PRODUCTS_REQUEST_PENDING'
export const GET_NOTIFICATION_PRODUCTS_REQUEST_SUCCESS = 'GET_NOTIFICATION_PRODUCTS_REQUEST_SUCCESS'
export const GET_NOTIFICATION_PRODUCTS_REQUEST_ERROR = 'GET_NOTIFICATION_PRODUCTS_REQUEST_ERROR'

export const GET_NOTIFICATION_AREAS_REQUEST = 'GET_NOTIFICATION_AREAS_REQUEST'
export const GET_NOTIFICATION_AREAS = 'GET_NOTIFICATION_AREAS'

export const GET_NOTIFICATION_AREAS_PENDING = 'GET_NOTIFICATION_AREAS_PENDING'
export const GET_NOTIFICATION_AREAS_SUCCESS = 'GET_NOTIFICATION_AREAS_SUCCESS'
export const GET_NOTIFICATION_AREAS_ERROR = 'GET_NOTIFICATION_AREAS_ERROR'
