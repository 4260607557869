import classNames from 'classnames'
import { Spin } from 'antd'
import './PreloaderBlock.scss'

/**
 *
 * @param height
 * @param minHeight
 * @param size 'small' | 'normal' | 'large'
 * @param asOverlay boolean
 * @returns {JSX.Element}
 * @constructor
 */

const PreloaderBlock = ({ height, minHeight, size, asOverlay }) => {
  const classes = classNames({
    preloaderBlock: true,
    [`preloaderBlock--overlay`]: !!asOverlay,
    [`preloaderBlock--${size ? size : 'normal'}`]: true,
  })

  const styles = {
    height: height,
    minHeight: minHeight,
  }

  return (
    <div className={classes} style={styles}>
      <Spin />
    </div>
  )
}

export default PreloaderBlock
