import { Table } from 'antd'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { useCallback, useEffect, useState, useRef } from 'react'
import Tabs from './Tabs'
import SearchBar from './SearchBar'

const PageTable = ({
  pageTitle,
  action,
  baseData,
  columns,
  components,
  onRow,
  tabs,
  loading,
  pagination,
  onChange,
  searchHandler,
  rowKey,
  isDraggable,
  sortDirections = ['ascend', 'descend'],
}) => {
  const [searchValue, setSearchValue] = useState('')
  const handleSearch = useCallback(() => {
    searchHandler?.(searchValue)
  }, [searchHandler, searchValue])

  useEffect(() => {
    const searchTimeout = setTimeout(() => handleSearch(), 1000)
    return () => {
      clearTimeout(searchTimeout)
    }
  }, [searchValue])

  const pageRef = useRef()

  const scrollContentToTop = () => {
    const tableWrapper = pageRef.current.querySelector('.ant-table')
    tableWrapper && tableWrapper.scrollTo({ top: 0, behavior: 'smooth' })
  }

  const table = (
    <Table
      columns={columns}
      // Right now we refuse from search bar, but we can get back to it, all comment below because of it.
      // dataSource={filteredData === null ? baseData : filteredData}
      dataSource={baseData}
      rowKey={(record) => (rowKey ? record[rowKey] : record.id)}
      components={components}
      onRow={onRow}
      pagination={pagination}
      loading={loading}
      sortDirections={sortDirections}
      onChange={(paginate, sort, filter) => {
        scrollContentToTop()
        onChange?.(paginate, sort, filter)
      }}
    />
  )

  return (
    <div className="page" ref={pageRef}>
      <div className="page-header">
        <h3 className="page-title">{pageTitle}</h3>
        <div className="page-header-actions">
          {/*Right now we refuse from search bar, but we can get back to it, all comment below because of it.*/}
          {searchHandler && (
            <SearchBar value={searchValue} onChange={(search) => setSearchValue(search)} />
          )}
          {action ? action : null}
        </div>
      </div>
      <Tabs tabs={tabs} />
      {isDraggable ? <DndProvider backend={HTML5Backend}>{table}</DndProvider> : table}
    </div>
  )
}

export default PageTable
