// import axios from "axios";
// It is simulation of real request
import { surveyData } from '../../utils/data'

class SurveysService {
  getSurveys = () => {
    return new Promise((resolve, reject) => {
      setTimeout(() => resolve(surveyData), 500)
    })
  }

  getSurveyById = (id) => {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        const survey = surveyData.find((survey) => survey.id === id)
        resolve(survey)
      }, 500)
    })
  }
}

export default new SurveysService()
