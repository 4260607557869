import { useTranslation } from 'react-i18next'
import Page from '../../../components/Page'
import CashbackForm from '../../../components/forms/CashbackForm/CashbackForm'

const CashbackAdd = () => {
  const { t } = useTranslation()

  return (
    <Page pageTitle={t('cashback.add.title')}>
      <CashbackForm />
    </Page>
  )
}

export default CashbackAdd
