import React from 'react'
import Icon from './Icon'
import { ICONS } from '../utils/constants'

const SettingsButton = ({ settingHandler }) => {
  return (
    <button className="playButton settingsButton" onClick={settingHandler}>
      <Icon icon={ICONS.SURVEY} />
    </button>
  )
}

export default SettingsButton
