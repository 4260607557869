import classNames from 'classnames'
import { useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from '@tanstack/react-query'
import { useChartsDates } from './hooks/useChartsDates'
import useNodeSize from './hooks/useNodeSize'
import { Chart, registerables } from 'chart.js'
import { Bar } from 'react-chartjs-2'
import PreloaderBlock from 'components/PreloaderBlock/PreloaderBlock'
import DashboardPeriodChanger from 'components/Dashboard/PeriodChanger/DashboardPeriodChanger'
import DashboardPeriodOffsetChanger from 'components/Dashboard/PeriodOffsetChanger/DashboardPeriodOffsetChanger'
import DashboardService from 'redux/middlewares/dashboard'
import { DASHBOARD_PERIODS } from 'utils/constants'
import baseStyles from './styles/base.module.scss'
import chartStyles from './styles/ordersValuesPerAreaChart.module.scss'

Chart.register(...registerables)

const OrdersValuesPerAreaChart = ({ globalStartDate, globalFinishDate }) => {
  const { t } = useTranslation()
  const targetYLinesCount = 7
  const analyticsBlockWidth = 0
  const chartRef = useRef()
  const chartSize = useNodeSize(chartRef)
  const [reRenderFlag, setReRenderFlag] = useState(false)
  const [datePeriod, setDatePeriod] = useState(DASHBOARD_PERIODS.CUSTOM_DATE)
  const [periodOffset, setPeriodOffset] = useState(0)
  const { startDate, finishDate } = useChartsDates({
    globalStartDate: globalStartDate,
    globalFinishDate: globalFinishDate,
    period: datePeriod,
    offset: periodOffset,
  })

  // Load orders average count per area
  const { data: ordersStatsPerArea, isFetching: isOrdersStatsPerAreaFetching } = useQuery(
    ['dashboard-orders-average-per-area', startDate, finishDate],
    async () => {
      const response = await DashboardService.getOrdersPerAreaStats({
        startDate: startDate.toISOString(),
        finishDate: finishDate.toISOString(),
        average: true,
      })

      return response.data.data
    },
    {
      staleTime: 1000 * 60 * 10, // 10 minute
      refetchOnMount: 'always',
    },
  )

  // Generate labels from areas in stats
  const labels = useMemo(() => {
    const labels = []

    if (ordersStatsPerArea) {
      ordersStatsPerArea.map((areaData) => {
        labels.push(areaData.area_name)

        return areaData
      })
    }

    return labels
  }, [ordersStatsPerArea])

  // Generate values from areas in stats
  const values = useMemo(() => {
    const values = []

    if (ordersStatsPerArea) {
      ordersStatsPerArea.map((areaData) => {
        values.push(areaData.revenue_per_area)

        return areaData
      })
    }

    return values
  }, [ordersStatsPerArea])

  // Get max value in data stack
  const maxValue = useMemo(() => {
    let maxValue = 0

    values.forEach((oneValue) => {
      maxValue = oneValue > maxValue ? oneValue : maxValue
    })

    return maxValue
  }, [values])

  // Get target chart width
  const targetCanvasWidth = useMemo(() => {
    const needSize = labels.length * 70

    if (chartSize.width !== undefined && chartSize.width > needSize + analyticsBlockWidth) {
      return chartSize.width - analyticsBlockWidth
    }

    return needSize
  }, [labels, chartSize.width])

  // Trigger rerender if target chart width was changed
  useEffect(() => {
    setReRenderFlag(true)

    const reRenderTimeout = window.setTimeout(() => {
      setReRenderFlag(false)
    }, 50)

    return () => {
      window.clearTimeout(reRenderTimeout)
    }
  }, [targetCanvasWidth])

  // Calculate step size depends on max value in data stack
  const stepSize = useMemo(
    () => (maxValue >= targetYLinesCount ? Math.ceil(maxValue / targetYLinesCount) : 1),
    [maxValue, targetYLinesCount],
  )

  return (
    <div className={baseStyles.chart} ref={chartRef}>
      <div className={baseStyles.chart__header}>
        <div className={baseStyles.chart__headerTop}>
          <p className={baseStyles.chart__headerTitle}>{t('dashboard.orders revenue per area')}</p>
          <DashboardPeriodChanger
            periods={[
              DASHBOARD_PERIODS.DAY,
              DASHBOARD_PERIODS.WEEK,
              DASHBOARD_PERIODS.MONTH,
              DASHBOARD_PERIODS.YEAR,
              DASHBOARD_PERIODS.CUSTOM_DATE,
            ]}
            defaultPeriod={datePeriod}
            onChange={(period) => setDatePeriod(period)}
          />
        </div>
      </div>
      <div className={classNames([baseStyles.chart__body, baseStyles.chart__body_xScrollable])}>
        {!isOrdersStatsPerAreaFetching && targetCanvasWidth && !reRenderFlag ? (
          <>
            {labels.length > 0 ? (
              <Bar
                data={{
                  labels,
                  datasets: [
                    {
                      label: t('dashboard.orders revenue per area'),
                      data: values,
                      borderColor: '#e07317',
                      backgroundColor: '#e07317',
                    },
                  ],
                }}
                width={targetCanvasWidth}
                height={chartSize.width && targetCanvasWidth <= chartSize.width ? 320 : 308} // Additional space for scrollbar
                options={{
                  indexAxis: 'x',
                  elements: {
                    bar: {
                      borderWidth: 0,
                    },
                  },
                  responsive: false,
                  maintainAspectRatio: true,
                  plugins: {
                    legend: {
                      display: false,
                    },
                    title: {
                      display: false,
                    },
                    tooltip: {
                      displayColors: false,
                      callbacks: {
                        label(tooltipItem) {
                          return `${t('dashboard.orders revenue')}: ${
                            tooltipItem.formattedValue
                          } KD`
                        },
                      },
                    },
                  },
                  scales: {
                    y: {
                      beginAtZero: true,
                      suggestedMax: stepSize * targetYLinesCount,
                      ticks: {
                        stepSize: stepSize,
                        callback: (tickValue) => `${tickValue} KD`,
                      },
                    },
                  },
                }}
              />
            ) : (
              <div className={chartStyles.ordersValuesPerAreaChart__noData}>
                <span className={chartStyles.ordersValuesPerAreaChart__noDataText}>
                  {t('dashboard.no orders')}
                </span>
              </div>
            )}
          </>
        ) : (
          <PreloaderBlock minHeight={320} />
        )}
      </div>
      <div className={baseStyles.chart__footer}>
        <div className={baseStyles.chart__offsetChangerWrap}>
          <DashboardPeriodOffsetChanger
            startDate={globalStartDate}
            finishDate={globalFinishDate}
            period={datePeriod}
            disabled={datePeriod === DASHBOARD_PERIODS.CUSTOM_DATE || isOrdersStatsPerAreaFetching}
            changeOffsetCallback={(newOffset) => {
              setPeriodOffset(newOffset)
            }}
          />
        </div>
      </div>
    </div>
  )
}

export default OrdersValuesPerAreaChart
