import i18n from 'i18next'
import classNames from 'classnames'
import { useState } from 'react'
import { useQuery } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'
import { Select } from 'antd'
import PreloaderBlock from 'components/PreloaderBlock/PreloaderBlock'
import { parseThousandsGroups } from 'helpers/numbers'
import DashboardService from 'redux/middlewares/dashboard'
import { topsTypes, topsTypesLabels } from './constants/topsTypes'
import baseStyles from './styles/base.module.scss'
import selectStyles from './styles/headerSelect.module.scss'
import tableStyle from './styles/topTable.module.scss'

const TopOffers = ({ globalStartDate, globalFinishDate }) => {
  const { t } = useTranslation()
  const [responseType, setResponseType] = useState(topsTypes.COUNT)

  // Load tops data
  const { data: topsPositions, isFetching: isTopsPositionsFetching } = useQuery(
    ['dashboard-tops-positions', globalStartDate, globalFinishDate, responseType],
    async () => {
      const response = await DashboardService.getOrdersTopPositionsStats({
        startDate: globalStartDate.toISOString(),
        finishDate: globalFinishDate.toISOString(),
        responseType,
      })

      return response.data.data
    },
    {
      staleTime: 1000 * 60 * 10, // 10 minute
      refetchOnMount: 'always',
    },
  )

  return (
    <div className={baseStyles.chart}>
      <div className={baseStyles.chart__header}>
        <div className={baseStyles.chart__headerTop}>
          <p className={baseStyles.chart__headerTitle}>{t('dashboard.top offers')}</p>
          <div className={'field-wrapper'}>
            <Select
              className={selectStyles.headerSelect}
              options={[
                { value: topsTypes.COUNT, label: t(topsTypesLabels.COUNT) },
                { value: topsTypes.REVENUE, label: t(topsTypesLabels.REVENUE) },
              ]}
              value={responseType}
              onChange={(topsType) => setResponseType(topsType)}
            />
          </div>
        </div>
      </div>
      <div className={baseStyles.chart__body}>
        {!isTopsPositionsFetching && topsPositions?.areas ? (
          <div
            className={classNames([tableStyle.table, tableStyle.table_stickyHead])}
            style={{ maxHeight: 289 }}
          >
            <table>
              <thead>
                <tr>
                  <th>#</th>
                  <th>SKU</th>
                  <th>{t(topsTypesLabels[responseType])}</th>
                </tr>
              </thead>
              <tbody>
                {topsPositions?.offers.map((offerStat, index) => {
                  return (
                    <tr key={offerStat.banners_id}>
                      <td>{index + 1}</td>
                      <td>
                        {i18n.language === 'ar'
                          ? offerStat.product_arabianTitle
                          : offerStat.product_title}
                      </td>
                      {responseType === topsTypes.COUNT && <td>{offerStat.count}</td>}
                      {responseType === topsTypes.REVENUE && (
                        <td>{parseThousandsGroups(offerStat.revenue)}</td>
                      )}
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
        ) : (
          <PreloaderBlock minHeight={248} />
        )}
      </div>
    </div>
  )
}

export default TopOffers
