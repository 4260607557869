import {
  GET_ORDERS_REQUEST,
  GET_ORDER_REQUEST,
  GET_ORDER,
  UPDATE_ORDER_REQUEST,
  CREATE_ORDER_REQUEST,
  DELETE_ORDER_REQUEST,
  CHANGE_PAGINATION,
  CHANGE_ORDERS_SEARCH,
  CHANGE_ORDERS_SORT_TYPE,
  CHANGE_ORDERS_SORT_ORDER,
  CHANGE_ORDER_IS_SAP_SYNCED_STATUS,
} from '../types/orders'

export function getOrders(payload) {
  return {
    type: GET_ORDERS_REQUEST,
    payload,
  }
}

export function getOrder(payload) {
  return {
    type: GET_ORDER_REQUEST,
    payload,
  }
}

export function setOrder(payload) {
  return {
    type: GET_ORDER,
    payload,
  }
}

export function updateOrder(payload) {
  return {
    type: UPDATE_ORDER_REQUEST,
    payload,
  }
}

export function createOrder(payload) {
  return {
    type: CREATE_ORDER_REQUEST,
    payload,
  }
}

export function deleteOrder(payload) {
  return {
    type: DELETE_ORDER_REQUEST,
    payload,
  }
}

export function setOrdersSearch(payload) {
  return {
    type: CHANGE_ORDERS_SEARCH,
    payload,
  }
}

export function setPagination(payload) {
  return {
    type: CHANGE_PAGINATION,
    payload,
  }
}

export function setOrdersSortType(payload) {
  return {
    type: CHANGE_ORDERS_SORT_TYPE,
    payload,
  }
}

export function setOrdersSortOrder(payload) {
  return {
    type: CHANGE_ORDERS_SORT_ORDER,
    payload,
  }
}

export function setOrderIsSapSyncedStatus(payload) {
  return {
    type: CHANGE_ORDER_IS_SAP_SYNCED_STATUS,
    payload,
  }
}
