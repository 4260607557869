import { useEffect } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import HookedField from '../../../HookedField'
import Button from '../../../Button'
import { useTranslation } from 'react-i18next'
import { yupResolver } from '@hookform/resolvers/yup'
import { userNotificationSettingSchema } from '../../../../utils/schemas'
import {
  getNotificationAreas,
  getNotificationProducts,
} from '../../../../redux/actions/usersNotifications'

const FormModal = ({ submitHandler, notificationId }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  let { areas, products } = useSelector((state) => state.usersNotifications)
  const methods = useForm({
    mode: 'onSubmit',
    shouldFocusError: true,
    resolver: yupResolver(userNotificationSettingSchema),
  })

  const fieldsOptions = [
    { label: 'Orders number', value: 'ORDERS_NUMBER' },
    { label: 'Orders value', value: 'ORDERS_VALUE' },
    { label: 'Last day', value: 'LAST_DAY' },
    { label: 'Area', value: 'AREA' },
    { label: 'Items in card', value: 'ITEMS_IN_CART' },
    { label: 'Specific items', value: 'SPECIFIC_ITEMS' },
  ]
  const typesOptions = [
    { label: 'Equal', value: 'EQUAL' },
    { label: 'Greater or equal', value: 'GREATER_OR_EQUAL' },
    { label: 'Less or equal', value: 'LESS_OR_EQUAL' },
  ]

  const fetchArea = () => {
    dispatch(getNotificationAreas())
  }
  const fetchProducts = (pageType) => {
    dispatch(getNotificationProducts({ pageType }))
  }

  const fieldsType = methods.watch('field', 'ORDERS_NUMBER')
  const type = methods.watch('type', 'EQUAL')

  useEffect(() => {
    switch (fieldsType) {
      case 'AREA':
        fetchArea()
        break
      case 'SPECIFIC_ITEMS':
        fetchProducts('SKU_PAGE')
        break
      default:
        areas = []
    }
  }, [fieldsType])

  useEffect(() => {
    switch (type) {
      case 'EQUAL':
        fetchArea()
        break
      case 'GREATER_OR_EQUAL':
        break
      default:
        return null
    }
  }, [type])

  return (
    <div className="formModal">
      <FormProvider {...methods}>
        <form className="form" autoComplete="off" onSubmit={methods.handleSubmit(submitHandler)}>
          <fieldset className="form-fieldset">
            <div className="form-grid">
              <HookedField
                label={t('fields.id')}
                name="id"
                disabled={true}
                defaultValue={notificationId}
                placeholder="id"
                fieldType="textField"
                type="text"
              />
              <HookedField
                type="select"
                name="field"
                defaultValue=""
                placeholder={t('fields.select page')}
                fieldType="selectField"
                label={t('fields.select page')}
                options={fieldsOptions}
              />
              <HookedField
                disabled={fieldsType === 'AREA' || fieldsType === 'ITEMS_IN_CART'}
                type="select"
                name="type"
                defaultValue="EQUAL"
                placeholder={t('fields.select page')}
                fieldType="selectField"
                label={t('fields.select page')}
                options={typesOptions}
              />
              <HookedField
                label={t('fields.number')}
                // disabled={fieldsType === 'ITEMS_IN_CART'}
                name="number"
                defaultValue="1"
                placeholder=""
                fieldType={
                  fieldsType === 'AREA' || fieldsType === 'SPECIFIC_ITEMS'
                    ? 'selectField'
                    : 'textField'
                }
                type={fieldsType === 'AREA' || fieldsType === 'SPECIFIC_ITEMS' ? 'select' : 'text'}
                options={fieldsType === 'AREA' ? areas : products}
              />
            </div>
          </fieldset>
          <div className="form-grid">
            <Button className="save-btn" size="large" type="primary" htmlType="submit">
              {t('app.send')}
            </Button>
          </div>
        </form>
      </FormProvider>
    </div>
  )
}

export default FormModal
