import { call, put, all, fork, takeEvery } from 'redux-saga/effects'

import { showLoading, hideLoading } from 'react-redux-loading-bar'

import PaymentService from '../middlewares/payment'

import {
  GET_PAYMENT_REQUEST,
  GET_PAYMENT_REQUEST_SUCCESS,
  GET_PAYMENT_REQUEST_PENDING,
  GET_PAYMENT_REQUEST_ERROR,
} from '../types/payment'
import { notify } from 'reapop'

function* getPaymentWatcher() {
  yield takeEvery(GET_PAYMENT_REQUEST, getPaymentWorker)
}

function* getPaymentWorker({ payload: { referenceNumber } }) {
  try {
    yield put(showLoading())
    yield put({ type: GET_PAYMENT_REQUEST_PENDING })
    const response = yield call(PaymentService.getPayment, referenceNumber)
    const { isSuccess, order, payment } = response.data.data
    yield put({
      type: GET_PAYMENT_REQUEST_SUCCESS,
      payload: {
        isSuccess: isSuccess,
        order: order,
        payment: payment,
      },
    })
    yield put(hideLoading())
  } catch (error) {
    yield put(
      notify({
        title: error.message,
        status: 'error',
        dismissAfter: 4000,
        allowHTML: true,
      }),
    )
    yield put({ type: GET_PAYMENT_REQUEST_ERROR })
    yield put(hideLoading())
  }
}

export default function* payment() {
  yield all([fork(getPaymentWatcher)])
}
