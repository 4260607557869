import axios from 'axios'

class CategoriesService {
  getCategories = (page, size) =>
    axios({
      url: 'categories/sap',
      method: 'GET',
      params: {
        page,
        take: size,
      },
    })

  getSupCategories = () =>
    axios({
      url: 'categories/sap/pull',
      method: 'GET',
    })

  getCategoryById = (id) =>
    axios({
      url: `categories/sap/${id}`,
      method: 'GET',
    })

  updateCategory = (id, data) =>
    axios({
      url: `categories/sap/${id}`,
      method: 'PUT',
      data,
    })
}

export default new CategoriesService()
