import { useTranslation } from 'react-i18next'

import { FormProvider, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'

import { categoriesSchema } from 'utils/schemas'
import { ICONS } from 'utils/constants'

import HookedField from 'components/HookedField'
import Button from '../Button'
import Icon from '../Icon'
import CategoryImageUploader from '../UserImageUploader'

const CategoryForm = ({ defaultValues = {}, submitHandler, loading }) => {
  const { t } = useTranslation()

  const methods = useForm({
    mode: 'onSubmit',
    shouldFocusError: true,
    resolver: yupResolver(categoriesSchema),
  })

  return (
    <div className="productForm">
      <FormProvider {...methods}>
        <form className="form" autoComplete="off" onSubmit={methods.handleSubmit(submitHandler)}>
          <fieldset className="form-fieldset">
            <div className="form-block">
              <div className="form-imgWrapper">
                <CategoryImageUploader
                  methods={methods}
                  formInputName={'categoryImage'}
                  image={defaultValues?.image}
                />
              </div>
              <div className="form-meta">
                <div className="form-title">{t('fields.visibility')}</div>
                <div className="form-caption">{t('products.caption')}</div>
                <div className="form-visibility">
                  <Icon className="form-visibility-icon" icon={ICONS.EYE} size={24} />
                  <span className="form-visibility-text">{t('products.switch')}</span>
                  <HookedField
                    className="form-visibility-switch"
                    name="isVisible"
                    defaultValue={defaultValues?.isVisible}
                    fieldType="customField"
                    type="switch"
                  />
                </div>
              </div>
            </div>
          </fieldset>
          <fieldset className="form-fieldset">
            <div className="form-title">{t('orders.general info')}</div>
            <div className="form-grid">
              {defaultValues?.id && (
                <HookedField
                  label={t('fields.id')}
                  name="id"
                  disabled={true}
                  defaultValue={defaultValues.id}
                  placeholder="id"
                  fieldType="textField"
                  type="text"
                />
              )}
              <HookedField
                label={t('fields.products in category')}
                name="products"
                defaultValue={defaultValues?.totalProducts || '-'}
                placeholder={t('fields.products in category')}
                fieldType="textField"
                type="text"
                disabled={true}
              />
              <HookedField
                label={t('fields.name en')}
                name="name"
                defaultValue={defaultValues?.name || ''}
                placeholder={t('fields.name en')}
                fieldType="textField"
                type="text"
                isRequired={true}
              />
              <HookedField
                label={t('fields.name ar')}
                name="arabianName"
                defaultValue={defaultValues?.arabianName || ''}
                dir="rtl"
                placeholder={t('fields.name ar')}
                fieldType="textField"
                type="text"
                isRequired={true}
              />
            </div>
          </fieldset>
          <div className="form-grid">
            <Button
              className="save-btn"
              size="large"
              type="primary"
              htmlType="submit"
              disabled={loading || !methods.formState.isDirty}
            >
              {t('app.save')}
            </Button>
          </div>
        </form>
      </FormProvider>
    </div>
  )
}

export default CategoryForm
