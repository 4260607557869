import moment from 'moment'
import { useCallback, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Tooltip } from 'antd'
import { getPromotions, getSupPromotions, updatePromotion } from 'redux/actions/promotions'
import { sortByAlphabet, sortByDate } from 'utils/sorters'
import { DATE_FORMAT, ICONS, PERMISSIONS } from 'utils/constants'
import useClient from 'hooks/useClient'
import SortableColumnTitle from 'components/SortableColumnTitle'
import PageTable from 'components/PageTable'
import Icon from 'components/Icon'
import Button from 'components/Button'

const Promotions = () => {
  const { t } = useTranslation()
  const { isClientCan } = useClient()
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const isClientCanUpdatePromotions = isClientCan(PERMISSIONS.UPDATE_PROMOTIONS)

  const { items, pagination, isGetLoading } = useSelector((state) => state.promotions)

  const refreshPromotions = (page, size) => {
    dispatch(
      getPromotions({
        page,
        size,
      }),
    )
  }

  const refreshSupPromotions = () => {
    dispatch(getSupPromotions())
  }

  const changePromotionVisibility = useCallback(
    ({ id, isVisible }) => {
      dispatch(
        updatePromotion({
          id,
          data: { isVisible },
          navigate,
        }),
      )
    },
    [dispatch, navigate],
  )

  useEffect(() => {
    if (!items.length) {
      refreshPromotions(pagination.current, pagination.pageSize)
    }
  }, [items.length])

  const columns = useMemo(() => {
    const promotionsColumns = []

    promotionsColumns.push({
      title: '№',
      dataIndex: 'id',
      key: 'id',
      align: 'center',
      sorter: (a, b) => a.id - b.id,
      render: (text, record, index) => index + 1,
    })
    promotionsColumns.push({
      title: (props) => <SortableColumnTitle {...props} title={t('fields.name en')} id="nameEn" />,
      dataIndex: 'promotionName',
      key: 'promotionName',
      sorter: (a, b) => sortByAlphabet(a.promotionName, b.promotionName),
    })
    promotionsColumns.push({
      title: (props) => <SortableColumnTitle {...props} title={t('fields.name ar')} id="nameAr" />,
      render: (text) => <p dir="rtl">{text}</p>,
      dataIndex: 'promotionArabicName',
      key: 'promotionArabicName',
      sorter: (a, b) => sortByAlphabet(a.promotionArabicName, b.promotionArabicName),
    })
    promotionsColumns.push({
      title: (props) => (
        <SortableColumnTitle {...props} title={t('fields.type')} id="promotionType" />
      ),
      dataIndex: 'promotionType',
      key: 'promotionType',
      sorter: (a, b) => sortByAlphabet(a.promotionType, b.promotionType),
    })
    promotionsColumns.push({
      title: (props) => <SortableColumnTitle {...props} title="PromotionCode" id="promotionCode" />,
      dataIndex: 'sapId',
      key: 'sapId',
      sorter: (a, b) => sortByAlphabet(a.sapId, b.sapId),
    })
    promotionsColumns.push({
      title: (props) => <SortableColumnTitle {...props} title={t('fields.date')} id="date" />,
      dataIndex: 'createdAt',
      key: 'createdAt',
      sorter: (a, b) => sortByDate(a.createdAt, b.createdAt),
      render: (promotionReleasedDate) =>
        promotionReleasedDate && moment(promotionReleasedDate).format(DATE_FORMAT),
      defaultSortOrder: 'descend',
    })
    if (isClientCanUpdatePromotions) {
      promotionsColumns.push({
        title: t('fields.visibility'),
        dataIndex: 'isVisible',
        key: 'isVisible',
        render: (_, record) => {
          return (
            <div className="table-actions">
              <Tooltip
                placement="topRight"
                title={record.isVisible ? t('app.hide') : t('app.show')}
              >
                <button
                  className="action-btn action-icon--visibility"
                  onClick={(event) => {
                    event.stopPropagation()
                    changePromotionVisibility({
                      id: record.id,
                      isVisible: !record.isVisible,
                    })
                  }}
                >
                  <Icon
                    className="action-icon"
                    icon={record.isVisible ? ICONS.VISIBLE : ICONS.INVISIBLE}
                    size={20}
                  />
                </button>
              </Tooltip>
            </div>
          )
        },
      })
    }
    return promotionsColumns
  }, [isClientCanUpdatePromotions, changePromotionVisibility, t])

  const tableChangeHandler = (pagination) => {
    refreshPromotions(pagination.current, pagination.pageSize)
  }

  return (
    <PageTable
      pageTitle={t('promotions.title')}
      // dont need in this scope
      // action={
      //     <Button type="primary" onClick={() => navigate(`/main/promotions/add`)}>
      //         <Icon icon={ICONS.PLUS} className="btn-icon-suffix"/>
      //         <span className="btn-text">
      //             {t('promotions.add button')}
      //         </span>
      //     </Button>
      // }
      action={
        isClientCanUpdatePromotions ? (
          <Button type="primary" onClick={refreshSupPromotions}>
            <span className="btn-text">{t('promotions.pull')}</span>
          </Button>
        ) : null
      }
      baseData={items}
      columns={columns}
      onRow={
        isClientCanUpdatePromotions
          ? (record) => {
              return {
                onClick: () => navigate(`/main/promotions/view/${record.id}`),
              }
            }
          : null
      }
      loading={isGetLoading}
      pagination={{
        ...pagination,
        showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
        showSizeChanger: pagination.total >= 10,
      }}
      onChange={tableChangeHandler}
    />
  )
}

export default Promotions
