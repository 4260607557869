import axios from 'axios'

class AuthService {
  signIn = (signInData) =>
    axios({
      url: 'auth/admin/signin',
      method: 'POST',
      data: signInData,
    })

  signOut = () => {
    return axios.post('/logs')
  }
}

export default new AuthService()
