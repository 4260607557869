import axios from 'axios'

class RatingService {
  getRatings = (pageSize, currentPage, sortType, sortOrder) =>
    axios({
      url: 'rating',
      method: 'GET',
      params: {
        page: currentPage,
        take: pageSize,
        sortType,
        sortOrder,
      },
    })

  getRatingMassage = () =>
    axios({
      url: 'rating/message',
      method: 'GET',
    })

  createRatingMassage = (data) =>
    axios({
      url: `/rating/message`,
      method: 'POST',
      data,
    })

  updateRatingMassage = (data) =>
    axios({
      url: `/rating/message`,
      method: 'PUT',
      data,
    })

  updateProduct = (data) =>
    axios({
      url: `/rating/message`,
      method: 'DELETE',
      data,
    })
}

export default new RatingService()
