export const GET_PRODUCTS_REQUEST = 'GET_PRODUCTS_REQUEST'
export const GET_PRODUCTS = 'GET_PRODUCTS'

export const GET_SUP_PRODUCTS_REQUEST = 'GET_SUP_PRODUCTS_REQUEST'
export const GET_SUP_PRODUCTS = 'GET_PRODUCTS'

export const GET_PRODUCT_REQUEST = 'GET_PRODUCT_REQUEST'
export const GET_PRODUCT = 'GET_PRODUCT'

export const UPDATE_PRODUCT_REQUEST = 'UPDATE_PRODUCT_REQUEST'
export const UPDATE_PRODUCT = 'UPDATE_PRODUCT'

export const CREATE_PRODUCT_REQUEST = 'CREATE_PRODUCT_REQUEST'
export const CREATE_PRODUCT = 'CREATE_PRODUCT'

export const DELETE_PRODUCT_REQUEST = 'DELETE_PRODUCT_REQUEST'
export const DELETE_PRODUCT = 'DELETE_PRODUCT'

export const GET_PRODUCTS_REQUEST_PENDING = 'GET_PRODUCTS_REQUEST_PENDING'
export const GET_PRODUCTS_REQUEST_SUCCESS = 'GET_PRODUCTS_REQUEST_SUCCESS'
export const GET_PRODUCTS_REQUEST_ERROR = 'GET_PRODUCTS_REQUEST_ERROR'

export const CHANGE_PRODUCTS_REQUEST_PENDING = 'CHANGE_PRODUCTS_REQUEST_PENDING'
export const CHANGE_PRODUCTS_REQUEST_SUCCESS = 'CHANGE_PRODUCTS_REQUEST_SUCCESS'
export const CHANGE_PRODUCTS_REQUEST_ERROR = 'CHANGE_PRODUCTS_REQUEST_ERROR'
