import React from 'react'

import { useTranslation } from 'react-i18next'

import { ICONS } from '../utils/constants'

import Icon from './Icon'

const SearchBar = ({ value, onChange, className }) => {
  const { t } = useTranslation()

  const classes = ['searchBar', className]

  return (
    <div className={classes.join(' ')}>
      <label htmlFor="search" className="searchBar-label">
        <Icon className="searchBar-icon" icon={ICONS.SEARCH} color="#F5AD0A" />
      </label>
      <input
        className="searchBar-input"
        id="search"
        type="text"
        name="search"
        placeholder={t('fields.search')}
        value={value}
        onChange={(e) => onChange(e.target.value)}
      />
    </div>
  )
}

export default SearchBar
