import classNames from 'classnames'
import PreloaderBlock from 'components/PreloaderBlock/PreloaderBlock'
import { Card, Row } from 'antd'
// import { Switch } from 'antd'
// import { useState } from 'react'

const PermissionCard = ({
  title,
  loading,
  className,
  children,
  checked,
  handleChecked,
  ...rest
}) => {
  const classes = classNames({
    [``]: true,
    [`${className}`]: !!className,
  })

  return (
    <Card className={classes} title={title} {...rest}>
      {loading && <PreloaderBlock asOverlay={true} size={'small'} />}
      <Row>{children}</Row>
    </Card>
  )
}

export default PermissionCard
