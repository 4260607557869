import { useEffect, useState } from 'react'
import {
  getCustomPeriodDates,
  getAmPeriodDates,
  getPmPeriodDates,
  getDayPeriodDates,
  getMonthPeriodDates,
  getWeekPeriodDates,
  getYearPeriodDates,
} from '../helpers/datesRangeGenerators'
import { DASHBOARD_PERIODS } from 'utils/constants'

export const useChartsDates = ({ globalStartDate, globalFinishDate, period, offset = 0 }) => {
  const [startDate, setStartDate] = useState(globalStartDate.clone())
  const [finishDate, setFinishDate] = useState(globalFinishDate.clone())
  const [prevStartDate, setPrevStartDate] = useState(
    globalStartDate.clone().subtract(globalFinishDate.diff(globalStartDate)).startOf('day'),
  )
  const [prevFinishDate, setPrevFinishDate] = useState(
    globalStartDate.clone().subtract(1, 'millisecond').endOf('day'),
  )
  const [prevYearStartDate, setPrevYearStartDate] = useState(
    globalStartDate.clone().subtract(1, 'year').startOf('day'),
  )
  const [prevYearFinishDate, setPrevYearFinishDate] = useState(
    globalFinishDate.clone().subtract(1, 'year').endOf('day'),
  )

  // Calc dates
  useEffect(() => {
    let generatedDates

    switch (period) {
      case DASHBOARD_PERIODS.CUSTOM_DATE:
        generatedDates = getCustomPeriodDates(globalStartDate, globalFinishDate)
        break
      case DASHBOARD_PERIODS.YEAR:
        generatedDates = getYearPeriodDates(offset)
        break
      case DASHBOARD_PERIODS.MONTH:
        generatedDates = getMonthPeriodDates(offset)
        break
      case DASHBOARD_PERIODS.WEEK:
        generatedDates = getWeekPeriodDates(offset)
        break
      case DASHBOARD_PERIODS.DAY:
        generatedDates = getDayPeriodDates(offset)
        break
      case DASHBOARD_PERIODS.AM:
        generatedDates = getAmPeriodDates(offset)
        break
      case DASHBOARD_PERIODS.PM:
        generatedDates = getPmPeriodDates(offset)
        break
      default:
        generatedDates = getDayPeriodDates(offset)
        break
    }

    setStartDate(generatedDates.startDate)
    setFinishDate(generatedDates.finishDate)
    setPrevStartDate(generatedDates.prevStartDate)
    setPrevFinishDate(generatedDates.prevFinishDate)
    setPrevYearStartDate(generatedDates.prevYearStartDate)
    setPrevYearFinishDate(generatedDates.prevYearFinishDate)
  }, [globalStartDate, globalFinishDate, period, offset])

  return {
    startDate,
    finishDate,
    prevStartDate,
    prevFinishDate,
    prevYearStartDate,
    prevYearFinishDate,
  }
}
