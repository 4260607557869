import moment from 'moment'

export const getCustomPeriodDates = (initialStartDate, initialFinishDate) => {
  const startDate = initialStartDate.clone()
  const finishDate = initialFinishDate.clone()
  const prevStartDate = initialStartDate
    .clone()
    .subtract(initialFinishDate.diff(initialStartDate))
    .startOf('day')
  const prevFinishDate = initialStartDate.clone().subtract(1, 'millisecond').endOf('day')
  const prevYearStartDate = initialStartDate.clone().subtract(1, 'year').startOf('day')
  const prevYearFinishDate = initialFinishDate.clone().subtract(1, 'year').endOf('day')

  return {
    startDate,
    finishDate,
    prevStartDate,
    prevFinishDate,
    prevYearStartDate,
    prevYearFinishDate,
  }
}

export const getYearPeriodDates = (offset) => {
  const today = moment()
  const startDate = today.clone().subtract(11, 'month').subtract(offset, 'year').startOf('month')
  const finishDate = today
    .clone()
    .subtract(offset, 'year')
    .endOf(offset !== 0 ? 'month' : 'millisecond')
  const prevStartDate = today
    .clone()
    .subtract(11, 'month')
    .subtract(offset + 1, 'year')
    .startOf('month')
  const prevFinishDate = today
    .clone()
    .subtract(offset + 1, 'year')
    .endOf(offset !== 0 ? 'month' : 'millisecond')
  const prevYearStartDate = startDate.clone().subtract(1, 'year')
  const prevYearFinishDate = finishDate.clone().subtract(1, 'year')

  return {
    startDate,
    finishDate,
    prevStartDate,
    prevFinishDate,
    prevYearStartDate,
    prevYearFinishDate,
  }
}

export const getMonthPeriodDates = (offset) => {
  const today = moment()
  const startDate = today
    .clone()
    .subtract(1, 'month')
    .subtract(offset, 'month')
    .add(1, 'day')
    .startOf('day')
  const finishDate = today.clone().subtract(offset, 'month').endOf('day')
  const prevStartDate = today
    .clone()
    .subtract(1, 'month')
    .subtract(offset + 1, 'month')
    .add(1, 'day')
    .startOf('day')
  const prevFinishDate = today
    .clone()
    .subtract(offset + 1, 'month')
    .endOf('day')
  const prevYearStartDate = startDate.clone().subtract(1, 'year')
  const prevYearFinishDate = finishDate.clone().subtract(1, 'year')

  return {
    startDate,
    finishDate,
    prevStartDate,
    prevFinishDate,
    prevYearStartDate,
    prevYearFinishDate,
  }
}

export const getWeekPeriodDates = (offset) => {
  const today = moment()
  const startDate = today
    .clone()
    .subtract(1, 'week')
    .subtract(offset, 'week')
    .add(1, 'day')
    .startOf('day')
  const finishDate = today.clone().subtract(offset, 'week').endOf('day')
  const prevStartDate = today
    .clone()
    .subtract(1, 'week')
    .subtract(offset + 1, 'week')
    .add(1, 'day')
    .startOf('day')
  const prevFinishDate = today
    .clone()
    .subtract(offset + 1, 'week')
    .endOf('day')
  const prevYearStartDate = startDate.clone().subtract(1, 'year')
  const prevYearFinishDate = finishDate.clone().subtract(1, 'year')

  return {
    startDate,
    finishDate,
    prevStartDate,
    prevFinishDate,
    prevYearStartDate,
    prevYearFinishDate,
  }
}

export const getDayPeriodDates = (offset) => {
  const today = moment()
  const startDate = today.clone().subtract(offset, 'day').startOf('day')
  const finishDate = today.clone().subtract(offset, 'day').endOf('day')
  const prevStartDate = today
    .clone()
    .subtract(offset + 1, 'day')
    .startOf('day')
  const prevFinishDate = today
    .clone()
    .subtract(offset + 1, 'day')
    .endOf('day')
  const prevYearStartDate = startDate.clone().subtract(1, 'year')
  const prevYearFinishDate = finishDate.clone().subtract(1, 'year')

  return {
    startDate,
    finishDate,
    prevStartDate,
    prevFinishDate,
    prevYearStartDate,
    prevYearFinishDate,
  }
}

export const getAmPeriodDates = (offset) => {
  const today = moment()
  const startDate = today.clone().startOf('day').subtract(offset, 'day')
  const finishDate = today
    .clone()
    .startOf('day')
    .add(12, 'hour')
    .subtract(offset, 'day')
    .subtract(1, 'millisecond')
  const prevStartDate = startDate.clone().subtract(1, 'day')
  const prevFinishDate = finishDate.clone().subtract(1, 'day')
  const prevYearStartDate = startDate.clone().subtract(1, 'year')
  const prevYearFinishDate = finishDate.clone().subtract(1, 'year')

  return {
    startDate,
    finishDate,
    prevStartDate,
    prevFinishDate,
    prevYearStartDate,
    prevYearFinishDate,
  }
}

export const getPmPeriodDates = (offset) => {
  const today = moment()
  const startDate = today.clone().startOf('day').add(12, 'hour').subtract(offset, 'day')
  const finishDate = today.clone().endOf('day').subtract(offset, 'day').subtract(1, 'millisecond')
  const prevStartDate = startDate.clone().subtract(1, 'day')
  const prevFinishDate = finishDate.clone().subtract(1, 'day')
  const prevYearStartDate = startDate.clone().subtract(1, 'year')
  const prevYearFinishDate = finishDate.clone().subtract(1, 'year')

  return {
    startDate,
    finishDate,
    prevStartDate,
    prevFinishDate,
    prevYearStartDate,
    prevYearFinishDate,
  }
}
