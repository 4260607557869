import { GET_MOSQUES_REQUEST, GET_SUP_MOSQUES_REQUEST } from '../types/mosques'

export function getMosques(payload) {
  return {
    type: GET_MOSQUES_REQUEST,
    payload,
  }
}

export function getSupMosques(payload) {
  return {
    type: GET_SUP_MOSQUES_REQUEST,
    payload,
  }
}
