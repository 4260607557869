import React from 'react'

import './AlertModal.scss'

const AlertModal = ({ title, closeHandler }) => (
  <div className="alertModal">
    <h4 className="alertModal-title">{title}</h4>
    <div className="modal-actions">
      <button onClick={closeHandler}>Ok</button>
    </div>
  </div>
)

export default AlertModal
