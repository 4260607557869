import moment from 'moment'
import { notify } from 'reapop'
import classNames from 'classnames'
import { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { useDispatch } from 'react-redux'
import useClient from 'hooks/useClient'
import update from 'immutability-helper'

import { Popconfirm, Tooltip } from 'antd'
import PageTable from 'components/PageTable'
import Button from 'components/Button'
import Icon from 'components/Icon'
import DraggableBodyRow from 'components/DraggableBodyRow'
import FlyerFormModal from 'components/Modal/modals/FlyerFormModal/FlyerFormModal'
import { parseThousandsGroups } from 'helpers/numbers'
import { deleteFlyer, getFlyers, updateFlyer } from 'api/flyers'
import { DATE_TIME_FORMAT, ICONS, PERMISSIONS } from 'utils/constants'

import cls from './flyers.module.scss'

const Flyers = () => {
  const { t } = useTranslation()
  const { isClientCan } = useClient()
  const queryClient = useQueryClient()
  const dispatch = useDispatch()
  const flyersListQueryKey = useMemo(() => ['flyers-list'], [])
  const [activeFlyer, setActiveFlyer] = useState(null)
  const [isFlyerModalVisible, setIsFlyerModalVisible] = useState(false)
  const [_, setDataChangeFlag] = useState(false) // Rerender trigger

  // permissions for flyers
  const isClientCanCreateFliers = isClientCan(PERMISSIONS.CREATE_FLIERS)
  const isClientCanDeleteFliers = isClientCan(PERMISSIONS.DELETE_FLIERS)
  const isClientCanUpdateFliers = isClientCan(PERMISSIONS.UPDATE_FLIERS)

  const { data: flyersData, isFetching: isFlyersFetching } = useQuery({
    queryKey: flyersListQueryKey,
    queryFn: getFlyers,
  })

  const onMutationError = useCallback(() => {
    setDataChangeFlag(false)

    dispatch(
      notify({
        title: t('notifs.error title'),
        status: 'error',
        dismissAfter: 4000,
      }),
    )
  }, [dispatch, t])

  const updateFlyerMutation = useMutation(updateFlyer, {
    onError: () => onMutationError(),
  })

  const deleteFlyerMutation = useMutation(deleteFlyer, {
    onSuccess: () => {
      dispatch(
        notify({
          title: t('flyers.messages.deleted'),
          status: 'success',
          dismissAfter: 2000,
        }),
      )
    },
    onError: () => onMutationError(),
  })

  const isTableLoading = useMemo(() => {
    return Boolean(
      isFlyersFetching || updateFlyerMutation.isLoading || deleteFlyerMutation.isLoading,
    )
  }, [isFlyersFetching, updateFlyerMutation, deleteFlyerMutation])

  const components = {
    body: {
      row: DraggableBodyRow,
    },
  }

  const moveRow = useCallback(
    (dragIndex, hoverIndex) => {
      const movedRecord = flyersData?.result[dragIndex]
      const dragRow = flyersData?.result[dragIndex]

      const newData = update(flyersData?.result, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, dragRow],
        ],
      })

      // Update query state
      queryClient.setQueryData(flyersListQueryKey, (prevFlyersData) => {
        if (prevFlyersData?.result?.length) {
          return { ...prevFlyersData, result: newData }
        }

        return prevFlyersData
      })

      // Make query
      updateFlyerMutation
        .mutateAsync({
          id: movedRecord.id,
          index: hoverIndex + 1, // In DB first index is 1
        })
        .then(() => {
          dispatch(
            notify({
              title: t('flyers.messages.order changed'),
              status: 'success',
              dismissAfter: 3000,
            }),
          )
        })
    },
    [flyersData?.result, flyersListQueryKey, updateFlyerMutation, queryClient, dispatch, t],
  )

  const toggleVisibility = useCallback(
    async (flyer) => {
      // Prevent multiple clicks
      if (updateFlyerMutation.isLoading && updateFlyerMutation?.variables?.id === flyer.id) {
        return false
      }

      await setDataChangeFlag(true)

      // Make query
      updateFlyerMutation
        .mutateAsync({
          id: flyer.id,
          isVisible: !flyer.isVisible,
        })
        .then((updatedFlyer) => {
          if (updatedFlyer.isVisible) {
            dispatch(
              notify({
                title: t('flyers.messages.visibility enabled'),
                status: 'success',
                dismissAfter: 2000,
              }),
            )
          } else {
            dispatch(
              notify({
                title: t('flyers.messages.visibility disabled'),
                status: 'none',
                dismissAfter: 2000,
              }),
            )
          }
        })

      // Update query state
      await queryClient.setQueryData(flyersListQueryKey, (prevFlyersData) => {
        if (prevFlyersData?.result?.length) {
          const newFlyersRecords = prevFlyersData.result.map((oneFlyer) => {
            oneFlyer.isVisible = oneFlyer.id === flyer.id ? !oneFlyer.isVisible : oneFlyer.isVisible
            return oneFlyer
          })

          return { ...prevFlyersData, result: newFlyersRecords }
        }

        return prevFlyersData
      })

      await setDataChangeFlag(false)
    },
    [queryClient, updateFlyerMutation, flyersListQueryKey, dispatch, t],
  )

  const deleteFlyerHandler = useCallback(
    async (flyerId) => {
      // Prevent multiple clicks
      if (deleteFlyerMutation.isLoading && deleteFlyerMutation?.variables?.id === flyerId) {
        return false
      }

      await setDataChangeFlag(true)

      await deleteFlyerMutation.mutateAsync(flyerId)

      // Update query state
      await queryClient.setQueryData(flyersListQueryKey, (prevFlyersData) => {
        if (prevFlyersData?.result?.length) {
          const newFlyersRecords = prevFlyersData.result.filter((oneFlyer) => {
            return oneFlyer.id !== flyerId
          })

          return { ...prevFlyersData, result: newFlyersRecords }
        }

        return prevFlyersData
      })

      setDataChangeFlag(false)
    },
    [queryClient, deleteFlyerMutation, flyersListQueryKey],
  )

  const submitCallback = useCallback(() => {
    setActiveFlyer(null)
    setIsFlyerModalVisible(false)

    dispatch(
      notify({
        title: t('flyers.messages.updated'),
        status: 'success',
        dismissAfter: 2000,
      }),
    )

    void queryClient.invalidateQueries(flyersListQueryKey)
  }, [flyersListQueryKey, setDataChangeFlag])

  const columns = useMemo(() => {
    const columns = []

    columns.push({
      title: '№',
      dataIndex: 'id',
      key: 'id',
      width: 50,
      render: (_, record, index) => index + 1,
    })

    columns.push({
      title: t('fields.product'),
      dataIndex: 'productId',
      key: 'productId',
      width: 246,
      render: (_, record) => record?.product?.title || '',
    })

    columns.push({
      title: t('fields.sap id'),
      dataIndex: 'sapId',
      key: 'sapId',
      width: 80,
      render: (_, record) => record?.product?.sapId || '',
    })

    columns.push({
      title: t('fields.valid from'),
      dataIndex: 'validFrom',
      key: 'validFrom',
      width: 129,
      render: (validFrom) =>
        validFrom ? (
          moment(validFrom).format(DATE_TIME_FORMAT)
        ) : (
          <span className={cls.noDateString}>-</span>
        ),
    })

    columns.push({
      title: t('fields.valid to'),
      dataIndex: 'validTo',
      key: 'validTo',
      width: 129,
      render: (validTo) =>
        validTo ? (
          moment(validTo).format(DATE_TIME_FORMAT)
        ) : (
          <span className={cls.noDateString}>-</span>
        ),
    })

    columns.push({
      title: t('fields.status'),
      dataIndex: 'status',
      key: 'status',
      width: 94,
      render: (_, record) => {
        const now = moment()

        if (record.isVisible) {
          if (record.validFrom && record.validTo) {
            if (now < moment(record.validFrom)) {
              return <span className={cls.statusString}>🟡 {t('flyers.status awaiting')}</span>
            } else if (now > moment(record.validTo)) {
              return <span className={cls.statusString}>🟡 {t('flyers.status expired')}</span>
            }
          }

          return <span className={cls.statusString}>🟢 {t('flyers.status active')}</span>
        }

        return <span className={cls.statusString}>🔴 {t('flyers.status inactive')}</span>
      },
    })

    columns.push({
      title: t('fields.old price'),
      dataIndex: 'oldPrice',
      key: 'oldPrice',
      width: 75,
      render: (oldPrice) => {
        return oldPrice ? `${parseThousandsGroups(oldPrice)} KD` : '-'
      },
    })

    if (isClientCanUpdateFliers || isClientCanDeleteFliers) {
      columns.push({
        title: t('fields.actions'),
        align: 'center',
        width: 70,
        render: (_, record) => {
          return (
            <div className={cls.actionsRow}>
              {isClientCanUpdateFliers && (
                <Tooltip
                  placement="topRight"
                  title={record.isVisible ? t('app.hide') : t('app.show')}
                >
                  <Icon
                    className={cls.actionIcon}
                    icon={record.isVisible ? ICONS.VISIBLE : ICONS.INVISIBLE}
                    size={20}
                    onClick={(event) => {
                      event.stopPropagation()
                      void toggleVisibility(record)
                    }}
                  />
                </Tooltip>
              )}

              {isClientCanDeleteFliers && (
                <Popconfirm
                  title={t('notifs.are you sure question')}
                  placement="topRight"
                  onConfirm={(event) => {
                    event.stopPropagation()
                    void deleteFlyerHandler(record.id)
                  }}
                  onCancel={(e) => e.stopPropagation()}
                  okText={t('app.delete')}
                  okType="danger"
                  cancelText={t('app.no')}
                >
                  <Icon
                    className={classNames([cls.actionIcon, cls.actionIcon_delete])}
                    icon={ICONS.TRASH}
                    size={20}
                    onClick={(e) => e.stopPropagation()}
                  />
                </Popconfirm>
              )}
            </div>
          )
        },
      })
    }

    return columns
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [flyersData?.result, t, toggleVisibility, flyersListQueryKey])

  return (
    <>
      <PageTable
        pageTitle={t('flyers.title')}
        action={
          isClientCanCreateFliers ? (
            <Button
              type="primary"
              onClick={() => {
                setActiveFlyer(null)
                setIsFlyerModalVisible(true)
              }}
            >
              <Icon icon={ICONS.PLUS} className="btn-icon-suffix" />
              <span className="btn-text">{t('flyers.add button')}</span>
            </Button>
          ) : null
        }
        baseData={flyersData?.result || []}
        columns={columns}
        components={components}
        onRow={
          isClientCanUpdateFliers
            ? (record, index) => ({
                index,
                moveRow,
                onClick: () => {
                  setActiveFlyer(record)
                  setIsFlyerModalVisible(true)
                },
              })
            : null
        }
        pagination={false}
        loading={isTableLoading}
        isDraggable={true}
      />
      <FlyerFormModal
        flyer={activeFlyer}
        isVisible={isFlyerModalVisible}
        onCancel={() => setIsFlyerModalVisible(false)}
        submitCallback={submitCallback}
      />
    </>
  )
}

export default Flyers
