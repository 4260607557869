import { Navigate } from 'react-router-dom'

import {
  SignIn,
  SignUp,
  NotFound,
  Dashboard,
  ProductsView,
  Products,
  Customers,
  // Notifications,
  // NotificationsView,
  NotificationsAdd,
  ProductsAdd,
  Banners,
  Flyers,
  Categories,
  CategoriesView,
  CategoriesAdd,
  CustomersView,
  CustomersAdd,
  Orders,
  OrdersView,
  OrdersAdd,
  Promotions,
  PromotionsView,
  PromotionsAdd,
  // Surveys,
  // SurveysAdd,
  // SurveysView,
  UsersManagement,
  UsersManagementView,
  UsersManagementAdd,
  UsersNotifications,
  UsersNotificationsView,
  UsersNotificationsAdd,
  Profile,
  ComingSoon,
  Payment,
  // Upsales,
  UpsalesView,
  CouponsAdd,
  CouponsView,
  Coupons,
  Promocodes,
  PromocodesAdd,
  PromocodesView,
  Rating,
  RatingView,
  Mosques,
  Users,
  AdminUser,
  Roles,
  Campaigns,
  CampaignsAdd,
  CampaignView,
  Cashback,
  CashbackAdd,
  CashbackView,
} from 'pages'
import Logs from '../pages/admin-team-management/Logs/Logs'
import ResetPassword from '../pages/auth/ResetPassword'

export const mainPages = [
  { path: '/main/mosques', element: <Mosques /> },
  { path: '/main/users-notifications/add', element: <UsersNotificationsAdd /> },
  { path: '/main/users-notifications/view/:id', element: <UsersNotificationsView /> },
  { path: '/main/users-notifications/:slug', element: <UsersNotifications /> },
  { path: '/main/users-notifications/', element: <UsersNotifications /> },
  { path: '/main/users-management/add', element: <UsersManagementAdd /> },
  { path: '/main/users-management/view/:id', element: <UsersManagementView /> },
  { path: '/main/users-management', element: <UsersManagement /> },
  { path: '/main/promotions/add', element: <PromotionsAdd /> },
  { path: '/main/promotions/view/:id', element: <PromotionsView /> },
  { path: '/main/promotions', element: <Promotions /> },
  { path: '/main/orders/add', element: <OrdersAdd /> },
  { path: '/main/orders/view/:id', element: <OrdersView /> },
  { path: '/main/orders', element: <Orders /> },
  { path: '/main/customers/add', element: <CustomersAdd /> },
  { path: '/main/customers/view/:id', element: <CustomersView /> },
  { path: '/main/customers', element: <Customers /> },
  { path: '/main/categories/add', element: <CategoriesAdd /> },
  { path: '/main/categories/view/:id', element: <CategoriesView /> },
  { path: '/main/categories', element: <Categories /> },
  { path: '/main/banners', element: <Banners /> },
  { path: '/main/flyers', element: <Flyers /> },
  { path: '/main/products/add', element: <ProductsAdd /> },
  { path: '/main/products/view/:id', element: <ProductsView /> },
  { path: '/main/products', element: <Products /> },
  { path: '/main/users', element: <Users /> },
  { path: '/main/roles', element: <Roles /> },
  { path: '/main/create-user', element: <AdminUser /> },
  { path: '/main/edit-user/:id', element: <AdminUser /> },
  { path: '/main/coupons/add', element: <CouponsAdd /> },
  { path: '/main/coupons/view/:id', element: <CouponsView /> },
  { path: '/main/coupons', element: <Coupons /> },
  { path: '/main/promocodes', element: <Promocodes /> },
  { path: '/main/rating', element: <Rating /> },
  { path: '/main/rating/massage', element: <RatingView /> },
  { path: '/main/notifications/add', element: <NotificationsAdd /> },
  { path: '/main/profile', element: <Profile /> },
  { path: '/main/coming', element: <ComingSoon /> },
  { path: '/main', element: <Customers /> },
  { path: '/main/logs', element: <Logs /> },
  { path: '/main/campaigns', element: <Campaigns /> },
  { path: '/main/cashback', element: <Cashback /> },

  // not in this scope of work
  // { path: '/main/surveys/add', element: <SurveysAdd/> },
  // { path: '/main/surveys/view/:id', element: <SurveysView/> },
  // { path: '/main/surveys', element: <Surveys/> },
  // { path: '/main/notifications/view/:id', element: <NotificationsView/> },
  // { path: '/main/notifications/:slug', element: <Notifications/> },
  // { path: '/main/notifications/', element: <Notifications/> },
]

export const mainScrolledPages = [
  { path: '/main/upselling', element: <UpsalesView /> },
  { path: '/main/dashboard', element: <Dashboard /> },
  { path: '/main/promocodes/add', element: <PromocodesAdd /> },
  { path: '/main/promocodes/view/:id', element: <PromocodesView /> },
  { path: '/main/campaigns/add', element: <CampaignsAdd /> },
  { path: '/main/campaigns/:id', element: <CampaignView /> },
  { path: '/main/cashback/add', element: <CashbackAdd /> },
  { path: '/main/cashback/:id', element: <CashbackView /> },
]

export const publicPages = [
  { path: '/sign-in', element: <SignIn /> },
  { path: '/sign-up', element: <SignUp /> },
  { path: '/page-404', element: <NotFound /> },
  { path: '/', element: <Navigate to="/main" replace /> },
  { path: '/payment', element: <Payment /> },
  { path: '/reset-password', element: <ResetPassword /> },
  { path: '*', element: <NotFound /> },
]
