import { useTranslation } from 'react-i18next'
import { roundPrice } from 'utils/functions'
import { PROMOCODE_TYPES } from 'utils/constants'

const OrderSummary = ({
  subTotalAmount,
  totalAmount,
  freeItemsSum,
  promocodeDiscountSum,
  deliveryCost,
  orderPromocode,
  orderWalletDiscount,
}) => {
  const { t } = useTranslation()

  return (
    <div className="orderSummary">
      <div className="orderSummary-info">
        <div className="orderSummary-item">
          <div className="orderSummary-title">{t('orders.amount')}</div>
          <div className="orderSummary-value">
            {roundPrice(totalAmount)}
            &nbsp;
            {t('app.currency')}
          </div>
        </div>
        <div className="orderSummary-item">
          <div className="orderSummary-title">{t('orders.discount')}</div>
          <div className="orderSummary-value">
            {freeItemsSum ? roundPrice(freeItemsSum) : 0}
            &nbsp;
            {t('app.currency')}
          </div>
        </div>
        <div className="orderSummary-item">
          <div className="orderSummary-title">{t('orders.walletDiscount')}</div>
          <div className="orderSummary-value">
            {orderWalletDiscount ? roundPrice(orderWalletDiscount) : 0}
            &nbsp;
            {t('app.currency')}
          </div>
        </div>
        <div className="orderSummary-item">
          <div className="orderSummary-title">
            {t('orders.promocode')}
            &nbsp;
            {orderPromocode?.discountType === PROMOCODE_TYPES.PERCENT.KEY && (
              <span style={{ color: '#808898' }}>({orderPromocode.discountQuantity}%)</span>
            )}
          </div>
          <div className="orderSummary-value">
            {roundPrice(promocodeDiscountSum)}
            &nbsp;
            {t('app.currency')}
          </div>
        </div>
        <div className="orderSummary-item">
          <div className="orderSummary-title">{t('orders.delivery price')}</div>
          <div className="orderSummary-value">
            {roundPrice(deliveryCost)}
            &nbsp;
            {t('app.currency')}
          </div>
        </div>
      </div>
      <div className="orderSummary-main">
        <div className="orderSummary-item">
          <div className="orderSummary-title">{t('orders.total')}</div>
          <div className="orderSummary-value">
            {roundPrice(subTotalAmount)}
            &nbsp;
            {t('app.currency')}
          </div>
        </div>
      </div>
    </div>
  )
}

export default OrderSummary
