import { useTranslation } from 'react-i18next'
import { ICONS } from 'utils/constants'

import { roundPrice } from '../utils/functions'
import Icon from './Icon'

const ProductItem = ({ data }) => {
  const { t } = useTranslation()

  return (
    <div className="productItem">
      <div className="productItem-image">
        {data?.product?.image?.src ? (
          <img src={data.product.image.src} alt={data.product.title} />
        ) : (
          <Icon icon={ICONS.IMAGE} size={32} color="#808898" />
        )}
      </div>
      <div className="productItem-info">
        {data?.product?.title ? (
          <div className="productItem-title">{data.product.title}</div>
        ) : null}
        {data?.product?.category?.name ? (
          <div className="productItem-category">{data.product.category.name}</div>
        ) : null}
      </div>
      <div className="productItem-meta">
        {data?.product?.currentPrice ? (
          <div className="productItem-price">
            {roundPrice(data.product.currentPrice)} {t('app.currency')}
          </div>
        ) : null}
        {data?.quantity ? (
          <div className="productItem-quantity">
            {t('app.quantity')}: {data.quantity}
          </div>
        ) : null}
      </div>
    </div>
  )
}

export default ProductItem
