import { SHOW_MODAL, HIDE_MODAL, PREV_MODAL } from '../types/modal'

export function showModal(payload) {
  return {
    type: SHOW_MODAL,
    payload,
  }
}

export function hideModal(payload) {
  return {
    type: HIDE_MODAL,
    payload,
  }
}

export function prevModal(payload) {
  return {
    type: PREV_MODAL,
    payload,
  }
}
