import classNames from 'classnames'
import { useState } from 'react'
import { DASHBOARD_PERIODS, DASHBOARD_PERIODS_LABELS } from 'utils/constants'
import PropTypes from 'prop-types'
import styles from './style.module.scss'

const DashboardPeriodChanger = ({ periods, defaultPeriod, onChange }) => {
  const [activePeriod, setActivePeriod] = useState(defaultPeriod || DASHBOARD_PERIODS.CUSTOM_DATE)

  return (
    <div className={styles.changer}>
      {periods.map((period) => {
        return (
          <div
            key={period}
            className={classNames({
              [`${styles.changer__item}`]: true,
              [`${styles.changer__item_active}`]: period === activePeriod,
            })}
            onClick={() => {
              if (!!onChange && period !== activePeriod) {
                onChange(period)
              }

              setActivePeriod(period)
            }}
          >
            {DASHBOARD_PERIODS_LABELS[period]}
          </div>
        )
      })}
    </div>
  )
}

DashboardPeriodChanger.propTypes = {
  periods: PropTypes.arrayOf(PropTypes.oneOf(Object.values(DASHBOARD_PERIODS))),
  defaultPeriod: PropTypes.oneOf(Object.values(DASHBOARD_PERIODS)),
}

DashboardPeriodChanger.defaultProps = {
  defaultPeriod: DASHBOARD_PERIODS.CUSTOM_DATE,
}

export default DashboardPeriodChanger
