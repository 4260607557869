import Icon from './Icon'
import { ICONS } from 'utils/constants'

const SortableColumnTitle = ({ sortColumn, sortOrder, title, id }) => {
  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      {title}
      <Icon
        icon={sortColumn?.key === id && sortOrder === 'descend' ? ICONS.DESCEND : ICONS.ASCEND}
        color={sortColumn?.key === id && sortOrder ? 'rgb(24, 144, 255)' : '#808898'}
        style={{ marginLeft: '8px' }}
      />
    </div>
  )
}

export default SortableColumnTitle
