import axios from 'axios'

export const getPromocodes = async (
  pageSize,
  currentPage,
  sortType,
  sortOrder,
  search,
  filterByStatus,
) => {
  const response = await axios.get('promocode', {
    params: {
      take: pageSize,
      page: currentPage,
      sortType,
      sortOrder,
      search: search ? search : undefined,
      filterByStatus,
    },
  })

  return response.data.data
}

export const getPromocodeById = async (id) => {
  const response = await axios.get(`promocode/${id}`)
  return response.data.data
}

export const addPromocode = async (data) => await axios.post('promocode', data)

export const updatePromocode = async ({ id, promocode }) => {
  const response = await axios.put(`promocode/${id}`, { ...promocode })
  return response.data.data
}

export const deletePromocode = async (id) => await axios.delete(`promocode/${id}`)
