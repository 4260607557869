import axios from 'axios'

export const getLogList = async (
  page,
  take,
  sortType,
  sortOrder,
  filterByAdminId,
  filterByCatalog,
  startDate,
  finishDate,
) => {
  const response = await axios.get(`logs/list`, {
    params: {
      page,
      take,
      filterByAdminId,
      filterByCatalog,
      startDate,
      finishDate,
      asc: sortType && sortOrder === 'ascend' ? sortType : null,
      desc: sortType && sortOrder === 'descend' ? sortType : null,
    },
  })

  return response.data.data
}
