import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getProduct, updateProduct } from 'redux/actions/products'
import Page from 'components/Page'
import ProductForm from 'components/forms/ProductForm'
import { useNavigate, useParams } from 'react-router-dom'
import { useMutation } from '@tanstack/react-query'
import { updateFile } from '../../../api/files'
import { message } from 'antd'
import { notify } from 'reapop'

const ProductsView = () => {
  const dispatch = useDispatch()
  const { item: product, isGetLoading, isChangeLoading } = useSelector((state) => state.products)
  const { id } = useParams()
  const navigate = useNavigate()

  const updateProductImageMutation = useMutation(updateFile, {
    onSuccess: async () => {
      message.success('Product image successfully updated')
    },
    onError: (err) => {
      if (err.response?.data?.messages.length) {
        dispatch(
          notify({ title: 'Product image upload failed', status: 'error', dismissAfter: 4000 }),
        )
      } else {
        return message.error('Something went wrong')
      }
    },
  })

  useEffect(() => {
    dispatch(getProduct({ id }))
  }, [dispatch, id])

  const submitHandler = async (
    id,
    {
      title,
      arabianTitle,
      shortName,
      shortNameArabian,
      packName,
      packNameArabian,
      about,
      aboutArabian,
      isVisible,
      oldPrice,
      volume,
      productImage,
    },
  ) => {
    if (productImage) {
      await updateProductImageMutation.mutateAsync({
        id: productImage.id,
        file: productImage.file,
      })
    }
    dispatch(
      updateProduct({
        id,
        data: {
          title,
          arabianTitle,
          shortName,
          shortNameArabian,
          packName,
          packNameArabian,
          about,
          aboutArabian,
          isVisible,
          oldPrice,
          volume,
        },
        navigate,
      }),
    )
  }

  return (
    <Page pageTitle={product.title} isGetLoading={isGetLoading} isChangeLoading={isChangeLoading}>
      <ProductForm
        defaultValues={product}
        submitHandler={(data) => submitHandler(id, data)}
        loading={isChangeLoading}
      />
    </Page>
  )
}

export default ProductsView
