import { useCallback, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { notify } from 'reapop'
import { useTranslation } from 'react-i18next'
import { useQuery, useQueryClient, useMutation } from '@tanstack/react-query'
import { useDispatch } from 'react-redux'
import update from 'immutability-helper'

import useClient from 'hooks/useClient'

import { sortByAlphabet } from 'utils/sorters'
import { PERMISSIONS } from 'utils/constants'

import { fetchCategoryList, pullCategoriesFromSap, updateCategory } from '../../../api/categories'

import SortableColumnTitle from 'components/SortableColumnTitle'
import PageTable from 'components/PageTable'
import Button from 'components/Button'
import DraggableBodyRow from '../../../components/DraggableBodyRow'

const QUERY_KEY = 'category-list'

const Categories = () => {
  const [currentPage, setCurrentPage] = useState(1)
  const [pageSize, setPageSize] = useState(10)
  const [_, setActiveCategory] = useState(null)
  const { t } = useTranslation()
  const { isClientCan } = useClient()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const queryClient = useQueryClient()

  const isClientCanUpdateCategories = isClientCan(PERMISSIONS.UPDATE_PRODUCT_CATEGORIES)
  const { data: categories, isFetching } = useQuery({
    queryKey: [QUERY_KEY],
    queryFn: async () => await fetchCategoryList({ currentPage, pageSize }),
  })

  const onMutationError = useCallback(() => {
    dispatch(
      notify({
        title: t('notifs.error title'),
        status: 'error',
        dismissAfter: 4000,
      }),
    )
  }, [dispatch, t])

  const updateCategoryMutation = useMutation(updateCategory, {
    onError: () => onMutationError(),
  })

  const handleSapCategoriesPull = async () => {
    await pullCategoriesFromSap()
    await queryClient.invalidateQueries([QUERY_KEY])
  }

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      align: 'center',
      width: '5%',
    },
    {
      title: (props) => <SortableColumnTitle {...props} title={t('fields.name en')} id="name" />,
      dataIndex: 'name',
      key: 'name',
      sorter: (a, b) => sortByAlphabet(a.name, b.name),
    },
    {
      title: (props) => (
        <SortableColumnTitle {...props} title={t('fields.name ar')} id="arabianName" />
      ),
      render: (text) => <p dir="rtl">{text}</p>,
      dataIndex: 'arabianName',
      key: 'arabianName',
      align: 'right',
      sorter: (a, b) => sortByAlphabet(a.arabianName, b.arabianName),
    },
    {
      title: t('fields.visibility'),
      dataIndex: 'isVisible',
      key: 'isVisible',
      width: '15%',
      render: (isVisible) => (isVisible ? t('app.active') : t('app.disable')),
    },
  ]

  const components = {
    body: {
      row: DraggableBodyRow,
    },
  }

  const moveRow = useCallback(
    (dragIndex, hoverIndex) => {
      const movedRecord = categories?.result[dragIndex]
      const dragRow = categories?.result[dragIndex]
      const newData = update(categories?.result, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, dragRow],
        ],
      })

      queryClient.setQueryData([QUERY_KEY], (prevCategoriesData) => {
        if (prevCategoriesData?.result?.length) {
          return { ...prevCategoriesData, result: newData }
        }

        return prevCategoriesData
      })

      updateCategoryMutation
        .mutateAsync({
          id: movedRecord.id,
          displayIndex: hoverIndex,
        })
        .then(() => {
          dispatch(
            notify({
              title: t('categories.messages.orderChanged'),
              status: 'success',
              dismissAfter: 3000,
            }),
          )
        })
    },
    [categories?.result, updateCategoryMutation, queryClient, dispatch, t],
  )

  const tableChangeHandler = (pagination) => {
    setCurrentPage(pageSize === pagination.pageSize ? pagination.current : 1)
    setPageSize(pagination.pageSize)
  }

  const isTableIsLoading = useMemo(() => {
    return Boolean(isFetching || updateCategoryMutation.isLoading)
  }, [isFetching, updateCategoryMutation])

  return (
    <PageTable
      pageTitle={t('categories.title')}
      action={
        isClientCanUpdateCategories ? (
          <Button type="primary" onClick={handleSapCategoriesPull}>
            <span className="btn-text">{t('promotions.pull')}</span>
          </Button>
        ) : null
      }
      baseData={categories?.result || []}
      columns={columns}
      components={components}
      onRow={
        isClientCanUpdateCategories
          ? (record, index) => ({
              index,
              moveRow,
              onClick: () => {
                setActiveCategory(record)
                navigate(`/main/categories/view/${record.id}`)
              },
            })
          : null
      }
      isDraggable={true}
      loading={isTableIsLoading}
      pagination={{
        pageSize,
        current: currentPage,
        total: categories?.total,
        showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
        showSizeChanger: isFetching ? true : categories?.total >= 10,
      }}
      onChange={tableChangeHandler}
    />
  )
}

export default Categories
