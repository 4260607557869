import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useQuery } from '@tanstack/react-query'
import useClient from 'hooks/useClient'
import usePrevious from 'hooks/usePrevious'
import { getSupProducts } from 'redux/actions/products'
import { useNavigate } from 'react-router-dom'
import ProductsService from 'redux/middlewares/products'
import CategoriesService from 'redux/middlewares/categories'
import Button from 'components/Button'
import SortableColumnTitle from 'components/SortableColumnTitle'
import PageTable from 'components/PageTable'
import { PERMISSIONS } from 'utils/constants'

const Products = () => {
  const { t } = useTranslation()
  const { isClientCan } = useClient()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [pageSize, setPageSize] = useState(20)
  const [currentPage, setCurrentPage] = useState(1)
  const [sortType, setSortType] = useState(null)
  const [sortOrder, setSortOrder] = useState(null)
  const [filterByCategoryId, setFilterByCategoryId] = useState(null)
  const [search, setSearch] = useState('')
  const prevSearch = usePrevious(search)

  const isClientCanUpdateProducts = isClientCan(PERMISSIONS.UPDATE_PRODUCTS)

  const {
    data: productsData,
    isFetching: isProductsDataFetching,
    refetch: refetchProductsData,
  } = useQuery(
    ['productsTable', currentPage, pageSize, sortType, sortOrder, filterByCategoryId],
    async () => {
      const response = await ProductsService.getProducts(
        currentPage,
        pageSize,
        sortType,
        sortOrder,
        filterByCategoryId,
        search,
      )
      return response.data.data
    },
  )

  // Refetch products when search query was changed
  useEffect(() => {
    if (prevSearch !== undefined && prevSearch !== search) {
      if (currentPage !== 1) {
        setCurrentPage(1)
      } else {
        refetchProductsData()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, prevSearch, currentPage])

  const { data: allCategories } = useQuery(['allProductsCategories'], async () => {
    const response = await CategoriesService.getCategories()
    return response.data.data
  })

  const refreshSupProducts = () => dispatch(getSupProducts())

  const categoriesFilerOptions = useMemo(() => {
    const options = []

    if (allCategories?.result) {
      allCategories.result.map((oneCategory) => {
        options.push({ text: oneCategory.name, value: oneCategory.id })
        return oneCategory
      })
    }

    return options
  }, [allCategories])

  const propertiesKeyMap = {
    products_col_Title: 'title',
    products_col_arabianTitle: 'arabianTitle',
    products_col_categoryName: 'categoryName',
    products_col_shortName: 'shortName',
    products_col_priceForOne: 'currentPrice',
  }

  const tableChangeHandler = (pagination, filter, sorter) => {
    setCurrentPage(pageSize === pagination.pageSize ? pagination.current : 1)
    setPageSize(pagination.pageSize)
    setSortType(propertiesKeyMap[sorter.columnKey] || null)
    setSortOrder(sorter.order)
    setFilterByCategoryId(
      filter?.products_col_categoryName ? filter.products_col_categoryName[0] : null,
    )
  }

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      align: 'center',
      width: 57,
    },
    {
      title: (props) => (
        <SortableColumnTitle {...props} title={t('fields.name en')} id="products_col_Title" />
      ),
      dataIndex: 'title',
      key: 'products_col_Title',
      width: 257,
      sorter: true,
    },
    {
      title: (props) => (
        <SortableColumnTitle
          {...props}
          title={t('fields.name ar')}
          id="products_col_arabianTitle"
        />
      ),
      render: (text) => <p dir="rtl">{text}</p>,
      dataIndex: 'arabianTitle',
      key: 'products_col_arabianTitle',
      width: 221,
      sorter: true,
      align: 'right',
    },
    {
      title: t('fields.product category'),
      dataIndex: ['category', 'name'],
      key: 'products_col_categoryName',
      width: 160,
      filters: categoriesFilerOptions,
      filterMultiple: false,
    },
    {
      title: t('fields.sap id'),
      dataIndex: 'sapId',
      key: 'sapId',
      width: 84,
    },
    {
      title: (props) => (
        <SortableColumnTitle {...props} title={t('fields.pack')} id="products_col_shortName" />
      ),
      dataIndex: 'shortName',
      key: 'products_col_shortName',
      width: 129,
      sorter: true,
    },
    {
      title: (props) => (
        <SortableColumnTitle
          {...props}
          title={t('fields.price for 1')}
          id="products_col_priceForOne"
        />
      ),
      dataIndex: 'currentPrice',
      key: 'products_col_priceForOne',
      width: 115,
      sorter: true,
    },
    {
      title: t('fields.visibility'),
      dataIndex: 'isVisible',
      key: 'isVisible',
      width: 114,
      render: (isVisible) => (isVisible ? t('app.active') : t('app.disable')),
    },
  ]

  return (
    <PageTable
      pageTitle={t('products.title')}
      action={
        isClientCanUpdateProducts ? (
          <Button type="primary" onClick={refreshSupProducts}>
            <span className="btn-text">{t('promotions.pull')}</span>
          </Button>
        ) : null
      }
      baseData={productsData?.result || []}
      columns={columns}
      onRow={
        isClientCanUpdateProducts
          ? (record) => ({
              onClick: () => navigate(`/main/products/view/${record.id}`),
            })
          : null
      }
      loading={isProductsDataFetching}
      pagination={{
        pageSize,
        current: currentPage,
        total: productsData?.total,
        showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
        showSizeChanger: productsData?.total >= 10 || true,
      }}
      onChange={tableChangeHandler}
      searchHandler={(value) => setSearch(value.trim())}
    />
  )
}

export default Products
