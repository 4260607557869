import { all, fork } from 'redux-saga/effects'
import auth from './auth'
import customers from './customers'
import banners from './banners'
import categories from './categories'
import notifications from './notifications'
import orders from './orders'
import products from './products'
import promotions from './promotions'
import surveys from './surveys'
import users from './users'
import usersNotifications from './usersNotifications'
import payment from './payment'
import upselling from './upselling'
import coupons from './coupons'
import ratings from './rating'
import mosques from './mosques'

function* rootSaga() {
  yield all([
    fork(auth),
    fork(customers),
    fork(banners),
    fork(categories),
    fork(notifications),
    fork(orders),
    fork(products),
    fork(promotions),
    fork(surveys),
    fork(users),
    fork(usersNotifications),
    fork(payment),
    fork(upselling),
    fork(coupons),
    fork(ratings),
    fork(mosques),
  ])
}

export default rootSaga
