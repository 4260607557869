import {
  GET_PRODUCTS_REQUEST,
  GET_PRODUCT_REQUEST,
  UPDATE_PRODUCT_REQUEST,
  CREATE_PRODUCT_REQUEST,
  DELETE_PRODUCT_REQUEST,
  GET_SUP_PRODUCTS_REQUEST,
} from '../types/products'

export function getProducts(payload) {
  return {
    type: GET_PRODUCTS_REQUEST,
    payload,
  }
}

export function getSupProducts(payload) {
  return {
    type: GET_SUP_PRODUCTS_REQUEST,
    payload,
  }
}

export function getProduct(payload) {
  return {
    type: GET_PRODUCT_REQUEST,
    payload,
  }
}

export function updateProduct(payload) {
  return {
    type: UPDATE_PRODUCT_REQUEST,
    payload,
  }
}

export function createProduct(payload) {
  return {
    type: CREATE_PRODUCT_REQUEST,
    payload,
  }
}

export function deleteProduct(payload) {
  return {
    type: DELETE_PRODUCT_REQUEST,
    payload,
  }
}
