import { call, put, all, fork, takeEvery } from 'redux-saga/effects'

import { showLoading, hideLoading } from 'react-redux-loading-bar'

import SurveysService from '../middlewares/surveys'

import {
  GET_SURVEYS_REQUEST,
  GET_SURVEYS,
  GET_SURVEY_REQUEST,
  GET_SURVEY,
  GET_SURVEYS_REQUEST_PENDING,
  GET_SURVEYS_REQUEST_SUCCESS,
  GET_SURVEYS_REQUEST_ERROR,
} from '../types/surveys'

function* getSurveysWatcher() {
  yield takeEvery(GET_SURVEYS_REQUEST, getSurveysWorker)
}

function* getSurveyWatcher() {
  yield takeEvery(GET_SURVEY_REQUEST, getSurveyWorker)
}

function* getSurveysWorker() {
  try {
    yield put(showLoading())
    yield put({ type: GET_SURVEYS_REQUEST_PENDING })
    const data = yield call(SurveysService.getSurveys)
    yield put({ type: GET_SURVEYS, payload: data })
    yield put({ type: GET_SURVEYS_REQUEST_SUCCESS })
    yield put(hideLoading())
  } catch (error) {
    yield put({ type: GET_SURVEYS_REQUEST_ERROR, payload: error.response.data.messages })
    yield put(hideLoading())
  }
}

function* getSurveyWorker({ payload: { id } }) {
  try {
    yield put(showLoading())
    yield put({ type: GET_SURVEYS_REQUEST_PENDING })
    const response = yield call(SurveysService.getSurveyById, id)
    yield put({ type: GET_SURVEY, payload: response })
    yield put({ type: GET_SURVEYS_REQUEST_SUCCESS })
    yield put(hideLoading())
  } catch (error) {
    yield put({ type: GET_SURVEYS_REQUEST_ERROR, payload: error.response.data.messages })
    yield put(hideLoading())
  }
}

export default function* Surveys() {
  yield all([fork(getSurveysWatcher), fork(getSurveyWatcher)])
}
