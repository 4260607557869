import axios from 'axios'

class MosquesService {
  getMosques = (page, size, search = null) =>
    axios({
      url: 'mosque/admin',
      method: 'GET',
      params: {
        skip: page,
        take: size,
        search: search ? search : null,
      },
    })

  getSupMosques = () =>
    axios({
      url: 'mosque/pull',
      method: 'GET',
    })
}
export default new MosquesService()
