import classNames from 'classnames'
import { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { parseThousandsGroups } from 'helpers/numbers'
import styles from './styles/analyticsBlock.module.scss'

const OrdersAverageAnalytics = ({
  currentStartDate,
  currentFinishDate,
  currentStats,
  prevPeriodStartDate,
  prevPeriodFinishDate,
  prevPeriodStats,
  prevYearStartDate,
  prevYearFinishDate,
  prevYearStats,
}) => {
  const { t } = useTranslation()
  const currentDateFormat =
    currentFinishDate.diff(currentStartDate) <= 43199999 ? 'D MMM HH:mm' : 'D MMM, YYYY'
  const prevPeriodDateFormat =
    prevPeriodFinishDate.diff(prevPeriodStartDate) <= 43199999 ? 'D MMM HH:mm' : 'D MMM, YYYY'
  const prevYearDateFormat =
    prevYearFinishDate.diff(prevYearStartDate) <= 43199999 ? 'D MMM YYYY HH:mm' : 'D MMM, YYYY'

  // Sum total function
  const sumCount = useCallback((stats) => {
    let totalSum = 0
    let totalOrders = 0

    if (stats?.length) {
      stats.map((statItem) => {
        totalSum += parseFloat(statItem.total_avg_order_per_day) * parseInt(statItem.orders_count)
        totalOrders += parseInt(statItem.orders_count)
        return statItem
      })
    }

    return totalOrders !== 0 ? totalSum / totalOrders : 0
  }, [])

  // Get percentage value function
  const calcDiffPercentage = useCallback((sum1, sum2) => {
    let percentage = 0

    if (sum2 !== 0) {
      percentage = Math.round((sum1 / sum2 - 1) * 100)
    } else if (sum1 > 0) {
      percentage = 100
    }

    return percentage
  }, [])

  // Generate percentage react node function
  const constructPercentageNode = useCallback((percentage) => {
    if (percentage === 0) {
      return null
    }

    const classes = classNames({
      [`${styles.text}`]: true,
      [`${styles.text_fat}`]: true,
      [`${styles.text_red}`]: percentage < 0,
      [`${styles.text_green}`]: percentage > 0,
    })

    return (
      <span className={classes}>
        {percentage > 0 && '+'}
        {percentage}%
      </span>
    )
  }, [])

  // Save current period average sum
  const currentAverageSum = useMemo(() => sumCount(currentStats), [sumCount, currentStats])

  // Save previous period average sum
  const prevPeriodAverageSum = useMemo(() => sumCount(prevPeriodStats), [sumCount, prevPeriodStats])

  // Save previous year average sum
  const prevYearAverageSum = useMemo(() => sumCount(prevYearStats), [sumCount, prevYearStats])

  // Save previous period diff percentage value
  const prevPeriodDiffPercentage = useMemo(
    () => calcDiffPercentage(currentAverageSum, prevPeriodAverageSum),
    [calcDiffPercentage, currentAverageSum, prevPeriodAverageSum],
  )

  // Save previous year diff percentage value
  const prevYearDiffPercentage = useMemo(
    () => calcDiffPercentage(currentAverageSum, prevYearAverageSum),
    [calcDiffPercentage, currentAverageSum, prevYearAverageSum],
  )

  return (
    <div className={styles.block}>
      <div className={styles.block__inner}>
        <div className={styles.block__sector}>
          <div className={classNames([styles.block__sectorRow])}>
            <span
              className={classNames([
                styles.text,
                styles.text_large,
                styles.text_gray,
                styles.text_fat,
              ])}
            >
              {currentStartDate.format(currentDateFormat)} -{' '}
              {currentFinishDate.format(currentDateFormat)}
            </span>
          </div>
          <div className={classNames([styles.block__sectorRow])}>
            <span className={classNames([styles.text, styles.text_larger, styles.text_fat])}>
              {`${parseThousandsGroups(currentAverageSum)} KD`}
            </span>
          </div>
        </div>
        <div className={styles.block__sector}>
          <div
            className={classNames([styles.block__sectorRow, styles.block__sectorRow_spaceBetween])}
          >
            <span
              className={classNames([
                styles.text,
                styles.text_gray,
                styles.text_upper,
                styles.text_fat,
              ])}
            >
              {t('dashboard.previous period')}
            </span>
            <span className={classNames([styles.text, styles.text_gray, styles.text_fat])}>
              {prevPeriodStartDate.format(prevPeriodDateFormat)} -{' '}
              {prevPeriodFinishDate.format(prevPeriodDateFormat)}
            </span>
          </div>
          <div className={classNames([styles.block__sectorRow])}>
            <span
              className={classNames([
                styles.text,
                styles.text_large,
                styles.text_fat,
                styles.text_gray,
              ])}
            >
              {`${parseThousandsGroups(prevPeriodAverageSum)} KD`}
            </span>
            {constructPercentageNode(prevPeriodDiffPercentage)}
          </div>
        </div>
        <div className={styles.block__sector}>
          <div
            className={classNames([styles.block__sectorRow, styles.block__sectorRow_spaceBetween])}
          >
            <span
              className={classNames([
                styles.text,
                styles.text_gray,
                styles.text_upper,
                styles.text_fat,
              ])}
            >
              {t('dashboard.previous year')}
            </span>
            <span className={classNames([styles.text, styles.text_gray, styles.text_fat])}>
              {prevYearStartDate.format(prevYearDateFormat)} -{' '}
              {prevYearFinishDate.format(prevYearDateFormat)}
            </span>
          </div>
          <div className={classNames([styles.block__sectorRow])}>
            <span
              className={classNames([
                styles.text,
                styles.text_large,
                styles.text_fat,
                styles.text_gray,
              ])}
            >
              {`${parseThousandsGroups(prevYearAverageSum)} KD`}
            </span>
            {constructPercentageNode(prevYearDiffPercentage)}
          </div>
        </div>
      </div>
    </div>
  )
}

export default OrdersAverageAnalytics
