import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import Button from '../../components/Button'

const NotFound = () => {
  const navigate = useNavigate()
  const { t } = useTranslation()

  return (
    <div className="notFound">
      <div className="serviceText">
        <h3>{t('notFound.title')}</h3>
        <p>{t('notFound.text')}</p>
        <Button className="notFound-btn" onClick={() => navigate('/main')}>
          {t('notFound.btn')}
        </Button>
      </div>
    </div>
  )
}

export default NotFound
