import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getPromotion, deletePromotion, updatePromotion } from '../../../redux/actions/promotions'
import Page from '../../../components/Page'
import PromotionForm from '../../../components/forms/PromotionsForm'
import { useNavigate, useParams } from 'react-router-dom'

const PromotionsView = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { id } = useParams()

  useEffect(() => {
    dispatch(getPromotion({ id }))
  }, [id])

  const {
    item: promotion,
    isGetLoading,
    isChangeLoading,
  } = useSelector((state) => state.promotions)

  //delete button (don't need in this scope)
  // const duplicateHandler = (id) => {
  //     console.log("duplicate", id);
  // }
  //
  // const deleteHandler = (id) => {
  //     dispatch(deletePromotion({
  //         id,
  //         navigate
  //     }))
  // }

  const submitHandler = ({ id, isVisible }) => {
    dispatch(
      updatePromotion({
        id,
        data: { isVisible },
        navigate,
      }),
    )
  }
  return (
    <Page
      pageTitle={promotion.sapId}
      // delete button (don't need in this scope)
      // duplicateHandler={duplicateHandler}
      // deleteHandler={deleteHandler}
      isGetLoading={isGetLoading}
      isChangeLoading={isChangeLoading}
    >
      <PromotionForm
        defaultValues={promotion}
        submitHandler={submitHandler}
        loading={isChangeLoading}
      />
    </Page>
  )
}

export default PromotionsView
