import * as yup from 'yup'
import moment from 'moment'
import { notify } from 'reapop'
import { useCallback, useMemo } from 'react'
import { useMutation, useQuery } from '@tanstack/react-query'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useForm, FormProvider } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup/dist/yup'
import { Col, Modal, Row } from 'antd'
import PreloaderBlock from 'components/PreloaderBlock/PreloaderBlock'
import Button from 'components/Button'
import HookedField from 'components/HookedField'
import CustomersService from 'redux/middlewares/customers'
import UsersNotificationsService from 'redux/middlewares/usersNotifications'
import { DATE_FORMAT } from 'utils/constants'

const todayEnd = moment().endOf('day')

const ExportCustomersModal = ({ isVisible, handleCancel = null, fileDownloadedHandler = null }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  // Setup hooked form
  const methods = useForm({
    mode: 'onSubmit',
    shouldFocusError: true,
    defaultValues: {
      dates: [moment().startOf('month'), moment().endOf('day')],
      addDeliveryAddress: true,
      addArea: true,
      sortType: 'CREATED_AT',
      sortOrder: 'DESC',
      filterByAreaId: null,
    },
    resolver: yupResolver(
      yup.object().shape({
        dates: yup.array().of(yup.date()).required().nullable(),
        addDeliveryAddress: yup.boolean(),
        addArea: yup.boolean(),
        sortType: yup.string(),
        sortOrder: yup.string(),
        filterByAreaId: yup.number().nullable(),
      }),
    ),
  })

  // Form values watchers
  const datesCond = methods.watch('dates')

  // Load all delivery areas
  const { data: allAreas, isFetching: isAllAreasFetching } = useQuery(
    ['all-areas-list'],
    async () => {
      const response = await UsersNotificationsService.getNotificationDeliveryAreas()
      return response.data.data
    },
    {
      staleTime: 1000 * 60 * 60, // One hour save in memory
    },
  )

  // Generate delivery areas select options
  const areasOptions = useMemo(() => {
    let selectOptions = []

    if (!isAllAreasFetching && allAreas?.result) {
      allAreas.result.map((oneArea) => {
        selectOptions.push({
          label: oneArea.name,
          value: oneArea.id,
        })

        return oneArea
      })
    }

    return selectOptions
  }, [allAreas, isAllAreasFetching])

  // Setup export customers data mutation
  const exportCustomersMutation = useMutation(CustomersService.exportUsers, {
    onSuccess: (response) => {
      handleExportResponse(response)
      fileDownloadedHandler && fileDownloadedHandler()
    },
    onError: () => {
      dispatch(notify({ title: t('notifs.error title'), status: 'error', dismissAfter: 4000 }))
    },
  })

  // Callback after file data was received
  const handleExportResponse = useCallback(
    (exportData) => {
      // Push the file to client downloads
      let data = new Blob([exportData.data], { type: exportData.data.type })
      let objectURL = window.URL.createObjectURL(data)
      let tempLink = document.createElement('a')
      tempLink.href = objectURL
      tempLink.setAttribute(
        'download',
        `users_data_from_${datesCond[0].format(DATE_FORMAT)}_to_${datesCond[1].format(
          DATE_FORMAT,
        )}.xls`,
      )
      tempLink.click()
    },
    [datesCond],
  )

  // Form submit handler
  const submitHandler = useCallback(
    (formData) => {
      exportCustomersMutation.mutate({
        startDate: moment(formData.dates[0]).startOf('day').toISOString(),
        finishDate: moment(formData.dates[1]).endOf('day').toISOString(),
        addArea: formData.addArea,
        addDeliveryAddress: formData.addDeliveryAddress,
        sortOrder: formData.sortOrder,
        sortType: formData.sortType,
        filterByAreaId: formData.filterByAreaId,
      })
    },
    [exportCustomersMutation],
  )

  return (
    <Modal
      open={isVisible}
      title={t('customers.export customers')}
      width={350}
      onCancel={handleCancel}
      footer={false}
    >
      {exportCustomersMutation.isLoading && <PreloaderBlock asOverlay={true} />}
      <FormProvider {...methods}>
        <form className="form" autoComplete="off" onSubmit={methods.handleSubmit(submitHandler)}>
          <Row gutter={[15, 10]}>
            <Col span={24}>
              <HookedField
                type="dateRange"
                fieldType="dateRange"
                name="dates"
                label={t('fields.dates range')}
                placeholder={t('fields.select dates range')}
                allowClear={false}
                disabledDate={(current) => current > todayEnd}
              />
            </Col>
            <Col span={24}>
              <HookedField
                type="select"
                name="sortType"
                label={t('fields.sort type')}
                placeholder={t('fields.select sort type')}
                fieldType="selectField"
                allowClear={false}
                options={[
                  { label: 'Created at', value: 'CREATED_AT' },
                  { label: 'User ID', value: 'USER_ID' },
                  { label: 'SAP ID', value: 'SAP_ID' },
                ]}
              />
            </Col>
            <Col span={24}>
              <HookedField
                type="select"
                name="sortOrder"
                label={t('fields.sort order')}
                placeholder={t('fields.sort order')}
                fieldType="selectField"
                allowClear={false}
                options={[
                  { label: 'DESC', value: 'DESC' },
                  { label: 'ASC', value: 'ASC' },
                ]}
              />
            </Col>
            <Col span={24}>
              <HookedField
                type="select"
                name="filterByAreaId"
                label={t('fields.filter by area id')}
                placeholder={t('fields.select area')}
                fieldType="selectField"
                allowClear={true}
                showSearch={true}
                filterOption={(input, option) =>
                  option?.label ? option.label.toLowerCase().includes(input.toLowerCase()) : false
                }
                options={areasOptions}
                loading={isAllAreasFetching}
              />
            </Col>
            <Col span={13}>
              <HookedField
                type="checkbox"
                fieldType="checkbox"
                name="addDeliveryAddress"
                placeholder={t('fields.add delivery address')}
              />
            </Col>
            <Col span={11}>
              <HookedField
                type="checkbox"
                fieldType="checkbox"
                name="addArea"
                placeholder={t('fields.add area')}
              />
            </Col>
            <Col span={24} style={{ textAlign: 'center' }}>
              <Button className="save-btn" type="primary" htmlType="submit">
                {t('app.export')}
              </Button>
            </Col>
          </Row>
        </form>
      </FormProvider>
    </Modal>
  )
}

export default ExportCustomersModal
