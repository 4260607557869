export const GET_NOTIFICATIONS_REQUEST = 'GET_NOTIFICATIONS_REQUEST'
export const GET_NOTIFICATIONS = 'GET_NOTIFICATIONS'

export const GET_NOTIFICATION_REQUEST = 'GET_NOTIFICATION_REQUEST'
export const GET_NOTIFICATION = 'GET_NOTIFICATION'

export const UPDATE_NOTIFICATION_REQUEST = 'UPDATE_NOTIFICATION_REQUEST'
export const UPDATE_NOTIFICATION = 'UPDATE_NOTIFICATION'

export const CREATE_NOTIFICATION_REQUEST = 'CREATE_NOTIFICATION_REQUEST'
export const CREATE_NOTIFICATION = 'CREATE_NOTIFICATION'

export const DELETE_NOTIFICATION_REQUEST = 'DELETE_NOTIFICATION_REQUEST'
export const DELETE_NOTIFICATION = 'DELETE_NOTIFICATION'

export const GET_NOTIFICATIONS_REQUEST_PENDING = 'GET_NOTIFICATIONS_REQUEST_PENDING'
export const GET_NOTIFICATIONS_REQUEST_SUCCESS = 'GET_NOTIFICATIONS_REQUEST_SUCCESS'
export const GET_NOTIFICATIONS_REQUEST_ERROR = 'GET_NOTIFICATIONS_REQUEST_ERROR'

export const CHANGE_NOTIFICATIONS_REQUEST_PENDING = 'CHANGE_NOTIFICATIONS_REQUEST_PENDING'
export const CHANGE_NOTIFICATIONS_REQUEST_SUCCESS = 'CHANGE_NOTIFICATIONS_REQUEST_SUCCESS'
export const CHANGE_NOTIFICATIONS_REQUEST_ERROR = 'CHANGE_NOTIFICATIONS_REQUEST_ERROR'
