import {
  GET_CATEGORIES_REQUEST,
  GET_CATEGORY_REQUEST,
  UPDATE_CATEGORY_REQUEST,
  CREATE_CATEGORY_REQUEST,
  DELETE_CATEGORY_REQUEST,
  GET_SUP_CATEGORIES_REQUEST,
} from '../types/categories'

export function getCategories(payload) {
  return {
    type: GET_CATEGORIES_REQUEST,
    payload,
  }
}

export function getSupCategories(payload) {
  return {
    type: GET_SUP_CATEGORIES_REQUEST,
    payload,
  }
}

export function getCategory(payload) {
  return {
    type: GET_CATEGORY_REQUEST,
    payload,
  }
}

export function updateCategory(payload) {
  return {
    type: UPDATE_CATEGORY_REQUEST,
    payload,
  }
}

export function createCategory(payload) {
  return {
    type: CREATE_CATEGORY_REQUEST,
    payload,
  }
}

export function deleteCategory(payload) {
  return {
    type: DELETE_CATEGORY_REQUEST,
    payload,
  }
}
