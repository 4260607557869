import { useTranslation } from 'react-i18next'
import { Col, Row, Skeleton } from 'antd'

import Page from '../../Page'
import styles from './CampaignFormSkeleton.module.scss'

const CampaignFormSkeleton = () => {
  const { t } = useTranslation()

  return (
    <Page pageTitle={t('campaigns.view.title')}>
      <div>
        <div className="form-title">{t('campaigns.form.title')}</div>
        <Row gutter={[20, 36]} style={{ marginTop: '40px' }}>
          <Col span={24} lg={{ span: 12 }}>
            <Skeleton.Input active className={styles.campaignInput} />
          </Col>
          <Col span={24} lg={{ span: 12 }}>
            <Skeleton.Input active className={styles.campaignInput} />
          </Col>
          <Col span={24} lg={{ span: 12 }}>
            <Skeleton.Input active className={styles.campaignInput} />
          </Col>
          <Col span={24} lg={{ span: 12 }}>
            <Skeleton.Input active className={styles.campaignInput} />
          </Col>
          <Col span={24} lg={{ span: 12 }}></Col>
          <Col span={24} lg={{ span: 12 }}>
            <Skeleton.Input active className={styles.campaignInput} />
          </Col>
        </Row>
        <Row gutter={[20, 5]} className={styles.actions}>
          <Col span={24} lg={{ span: 12 }}>
            <Skeleton.Input active className={styles.campaignInput} />
          </Col>
        </Row>
      </div>
    </Page>
  )
}

export default CampaignFormSkeleton
