import { useTranslation } from 'react-i18next'

import { FormProvider, useForm } from 'react-hook-form'

import HookedField from 'components/HookedField'
import Button from '../Button'
import Icon from '../Icon'
import { ICONS } from 'utils/constants'
import { Switch } from 'antd'

import AvatarUploader from '../UserImageUploader'

const PromotionForm = ({ defaultValues = {}, submitHandler, loading }) => {
  const { t } = useTranslation()

  const methods = useForm({
    mode: 'onSubmit',
    shouldFocusError: true,
  })

  const renderPromotionType = () => {
    switch (defaultValues.promotionType) {
      case 'Free Items':
        return (
          <>
            <HookedField
              label={t('fields.purchase multiple')}
              name="minPurchaseQuantity"
              defaultValue={defaultValues?.minPurchaseQuantity || ''}
              placeholder={t('fields.purchase multiple')}
              fieldType="textField"
              type="text"
              disabled={true}
            />
            <HookedField
              label={t('fields.free quantity')}
              name="freeQuantity"
              defaultValue={defaultValues.getItems ? defaultValues?.getItems[0]?.getItemQty : ''}
              placeholder={t('fields.free quantity')}
              fieldType="textField"
              type="text"
              disabled={true}
            />
          </>
        )
      case 'Discount Amount':
        return (
          <>
            <HookedField
              label={t('fields.purchase multiple')}
              name="minPurchaseQuantity"
              defaultValue={defaultValues?.minPurchaseQuantity || ''}
              placeholder={t('fields.purchase multiple')}
              fieldType="textField"
              type="text"
              disabled={true}
            />
            <HookedField
              label={t('fields.discount amount')}
              name="freeQuantity"
              defaultValue={
                defaultValues.getItems ? defaultValues?.applicableDiscountOnTotalBill : ''
              }
              placeholder={t('fields.discount amount')}
              fieldType="textField"
              type="text"
              disabled={true}
            />
          </>
        )
      case 'Discount Percentage':
        return (
          <>
            <HookedField
              label={t('fields.purchase multiple')}
              name="minPurchaseQuantity"
              defaultValue={defaultValues?.minPurchaseQuantity || ''}
              placeholder={t('fields.purchase multiple')}
              fieldType="textField"
              type="text"
              disabled={true}
            />
            <HookedField
              label={t('fields.discount %')}
              name="percentDiscount"
              defaultValue={
                defaultValues.getItems
                  ? `${defaultValues?.getItems[0].getItemDiscountPercentage} %`
                  : ''
              }
              placeholder={t('fields.discount %')}
              fieldType="textField"
              type="text"
              disabled={true}
            />
          </>
        )
      default:
        return null
    }
  }

  return (
    <div className="customer-form">
      <FormProvider {...methods}>
        <form className="form" autoComplete="off" onSubmit={methods.handleSubmit(submitHandler)}>
          <fieldset className="form-fieldset">
            <div className="form-block">
              <div className="form-imgWrapper">
                <div className="avatarUploader-imgWrapper">
                  {defaultValues?.product?.image?.src ? (
                    <img
                      src={defaultValues?.product?.image?.src}
                      alt={defaultValues?.product.title}
                    />
                  ) : (
                    <Icon icon={ICONS.IMAGE} size={64} color="#808898" />
                  )}
                </div>
              </div>
              <div className="form-meta">
                <div className="form-title">{t('fields.visibility')}</div>
                <div className="form-caption">{t('products.caption')}</div>
                <div className="form-visibility">
                  <Icon className="form-visibility-icon" icon={ICONS.EYE} size={24} />
                  <span className="form-visibility-text">{t('products.switch')}</span>
                  <HookedField
                    className="form-visibility-switch"
                    name="isVisible"
                    defaultValue={defaultValues?.isVisible}
                    fieldType="customField"
                    type="switch"
                  />
                </div>
              </div>
            </div>
          </fieldset>
          <fieldset className="form-fieldset">
            <div className="form-title">{t('orders.general info')}</div>
            <div className="inputs form-grid">
              {defaultValues.id && (
                <HookedField
                  label={t('fields.promotionID')}
                  name="id"
                  disabled={true}
                  defaultValue={defaultValues.id}
                  placeholder="id"
                  fieldType="textField"
                  type="text"
                />
              )}
              {defaultValues.sapId && (
                <HookedField
                  label={t('fields.promotionID')}
                  name="sapId"
                  disabled={true}
                  defaultValue={defaultValues.sapId}
                  placeholder="id"
                  fieldType="textField"
                  type="text"
                />
              )}
              <HookedField
                label={t('fields.purchase SKU')}
                name="purchaseSKU"
                defaultValue={defaultValues?.validFor || ''}
                placeholder={t('fields.purchase SKU')}
                fieldType="textField"
                type="text"
                disabled={true}
              />
              <HookedField
                label={t('fields.promotion title en')}
                name="nameEn"
                defaultValue={defaultValues?.promotionName || ''}
                placeholder={t('fields.promotion title en')}
                fieldType="textField"
                type="text"
                disabled={true}
              />
              <HookedField
                label={t('fields.promotion title ar')}
                name="nameAr"
                dir="rtl"
                defaultValue={defaultValues?.promotionArabicName || ''}
                placeholder={t('fields.promotion title ar')}
                fieldType="textField"
                type="text"
                disabled={true}
              />
              <HookedField
                label={t('fields.original price')}
                name="originalPrice"
                defaultValue={defaultValues?.originalPrice || ''}
                placeholder={t('fields.original price')}
                fieldType="textField"
                type="text"
                disabled={true}
              />
              <HookedField
                label={t('fields.promotion price')}
                name="promotionPrice"
                defaultValue={defaultValues.price || ''}
                placeholder={t('fields.promotion price')}
                fieldType="textField"
                type="text"
                disabled={true}
              />
            </div>
          </fieldset>
          <fieldset className="form-fieldset">
            <div className="form-title">{`${t('promotions.promotion type')} - ${
              defaultValues.promotionType
            }`}</div>
            <div className="inputs form-grid">{renderPromotionType()}</div>
          </fieldset>
          <div className="button-grid">
            <Button
              className="save-btn"
              size="large"
              type="primary"
              htmlType="submit"
              disabled={
                loading ||
                !methods.formState.isDirty ||
                (methods.formState.isSubmitted && !methods.formState.isValid)
              }
            >
              {t('app.save')}
            </Button>
          </div>
        </form>
      </FormProvider>
    </div>
  )
}

export default PromotionForm
