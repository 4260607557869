import { useTranslation } from 'react-i18next'
import { FormProvider, useForm } from 'react-hook-form'
import { useEffect } from 'react'
import HookedField from 'components/HookedField'
import Button from '../Button'
import { Space } from 'antd'

const RatingMessageForm = ({ defaultValues = {}, submitHandler, loading }) => {
  const { t } = useTranslation()

  const methods = useForm({
    mode: 'onSubmit',
    shouldFocusError: true,
    // resolver: yupResolver(userNotificationSchema),
  })

  useEffect(() => {
    methods.setValue('messId', defaultValues?.id || null)
    methods.setValue('isActive', defaultValues?.isActive || false)
    methods.setValue('title', defaultValues?.title || '')
    methods.setValue('subtitle', defaultValues?.subtitle || '')
  }, [defaultValues])

  return (
    <div className="userNotificationForm">
      <FormProvider {...methods}>
        <form className="form" autoComplete="off" onSubmit={methods.handleSubmit(submitHandler)}>
          <HookedField
            label="id"
            name="messId"
            defaultValue={defaultValues?.id || ''}
            placeholder="id"
            fieldType="hiddenField"
            type="hidden"
            isRequired={true}
          />
          <fieldset className="form-fieldset">
            <div className="form-grid">
              <HookedField
                label={t('ratings.rating visible')}
                name="isActive"
                defaultValue={defaultValues?.isActive || false}
                placeholder={t('ratings.rating visible')}
                fieldType="checkbox"
                type="checkbox"
                isRequired={true}
              />
            </div>
            <div className="form-grid">
              <Space direction={'vertical'} size={24}>
                <HookedField
                  label={t('ratings.rating title')}
                  name="title"
                  defaultValue={defaultValues.title || ''}
                  placeholder={t('ratings.rating title')}
                  fieldType="textField"
                  type="text"
                  isRequired={true}
                />
                <Button
                  className="save-btn"
                  size="large"
                  type="primary"
                  htmlType="submit"
                  disabled={loading || !methods.formState.isDirty}
                >
                  {t('app.save')}
                </Button>
              </Space>
              <HookedField
                label={t('ratings.rating subtitle')}
                name="subtitle"
                defaultValue={defaultValues.subtitle || ''}
                placeholder={t('ratings.rating subtitle')}
                fieldType="textField"
                type="textarea"
                isRequired={true}
              />
            </div>
          </fieldset>
          <div className="form-grid"></div>
        </form>
      </FormProvider>
    </div>
  )
}

export default RatingMessageForm
