import { call, put, all, fork, takeEvery } from 'redux-saga/effects'

import { showLoading, hideLoading } from 'react-redux-loading-bar'

import AuthService from '../middlewares/auth'

import { notify } from 'reapop'
import i18n from 'i18next'

import { removeFromLocalStorage, setToLocalStorage } from '../../utils/functions'

import {
  SIGN_IN_REQUEST,
  SIGN_IN_SUCCESS,
  SIGN_IN_ERROR,
  SIGN_OUT_REQUEST,
  SIGN_OUT_SUCCESS,
  SIGN_OUT_ERROR,
} from '../types/auth'

import { HIDE_MODAL } from '../types/modal'

function* signInWatcher() {
  yield takeEvery(SIGN_IN_REQUEST, signInWorker)
}

function* signOutWatcher() {
  yield takeEvery(SIGN_OUT_REQUEST, signOutWorker)
}

function* signInWorker({ payload: { signInData, navigate } }) {
  try {
    yield put(showLoading())
    const response = yield call(AuthService.signIn, signInData)
    setToLocalStorage('access_token', response.data.data.accessToken)
    setToLocalStorage('refresh_token', response.data.data.refreshToken)
    yield put({ type: SIGN_IN_SUCCESS })
    yield put(
      notify({
        title: i18n.t('notifs.success'),
        status: 'success',
        dismissAfter: 4000,
        allowHTML: true,
      }),
    )
    navigate('/main')
    yield put(hideLoading())
  } catch (error) {
    yield put({ type: SIGN_IN_ERROR, payload: error.response.data.messages })
    yield put(hideLoading())
  }
}

function* signOutWorker({ payload: { navigate } }) {
  try {
    yield put(showLoading())

    if (localStorage.getItem('access_token')) {
      yield call(AuthService.signOut)
    }

    removeFromLocalStorage('access_token')
    yield put({ type: SIGN_OUT_SUCCESS })
    yield put({ type: HIDE_MODAL })
    navigate('/sign-in')
    yield put(hideLoading())
  } catch (error) {
    yield put({ type: HIDE_MODAL })
    yield put({ type: SIGN_OUT_ERROR, payload: error })
    yield put(hideLoading())
  }
}

export default function* auth() {
  yield all([fork(signInWatcher), fork(signOutWatcher)])
}
