import { useCallback, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Col, message, Row } from 'antd'
import { FormProvider, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { useMutation } from '@tanstack/react-query'

import HookedField from '../../HookedField'

import { editWalletSchema } from '../../../utils/schemas'
import { editWalletAmount } from '../../../api/wallet-transactions'
import { notify } from 'reapop'
import { useDispatch } from 'react-redux'

const EditWalletModalForm = ({ userId, walletAmount, onClose, onSubmit }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const editWalletBalanceMutation = useMutation(editWalletAmount, {
    onSuccess: async () => {
      message.success('Wallet amount was successfully updated')
    },
    onError: (err) => {
      if (err.response?.data?.messages.length) {
        const errorMessage = err.response.data.messages[0]
        dispatch(
          notify({ title: t(`wallet.error.${errorMessage}`), status: 'error', dismissAfter: 4000 }),
        )
      } else {
        return message.error('Something went wrong')
      }
    },
  })

  const defaultFormValues = useMemo(() => {
    return {
      walletAmount: walletAmount,
    }
  }, [walletAmount])

  const submitHandler = useCallback(
    async (formData) => {
      try {
        await editWalletBalanceMutation.mutateAsync({
          userId: userId,
          data: { transactionAmount: formData.walletAmount },
        })
        onClose()
        onSubmit()
      } catch (error) {
        console.log(error)
      }
    },
    [editWalletBalanceMutation, onClose, onSubmit, userId],
  )

  const methods = useForm({
    mode: 'onSubmit',
    shouldFocusError: true,
    resolver: yupResolver(editWalletSchema),
    defaultValues: defaultFormValues,
  })

  useEffect(() => {
    methods.reset(defaultFormValues)
  }, [defaultFormValues, methods])

  return (
    <div style={{ width: '100%' }}>
      <FormProvider {...methods}>
        <form
          className="form"
          autoComplete="off"
          onSubmit={methods.handleSubmit(submitHandler)}
          defaultValue={methods.defaultValues}
        >
          <Row gutter={[24, 24]}>
            <Col span={24}>
              <HookedField
                label={t('Amount')}
                name="walletAmount"
                placeholder={t('e.g.: 34 KD')}
                fieldType="textField"
                type="textField"
                isRequired={true}
              />
            </Col>
          </Row>

          <Row gutter={[24, 24]}>
            <Col span={24}>
              <Button
                className="btn btn--normal btn--primary"
                type="primary"
                size="large"
                htmlType="submit"
                style={{ width: '100%' }}
                loading={editWalletBalanceMutation.isLoading}
              >
                {t('Save')}
              </Button>
            </Col>
          </Row>
        </form>
      </FormProvider>
    </div>
  )
}

export default EditWalletModalForm
