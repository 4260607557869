import moment from 'moment'
import classNames from 'classnames'
import { notify } from 'reapop'
import { useCallback, useEffect, useMemo } from 'react'
import { useMutation, useQuery } from '@tanstack/react-query'
import { useForm, FormProvider } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { yupResolver } from '@hookform/resolvers/yup'
import { Col, Row, Spin } from 'antd'
import HookedField from 'components/HookedField'
import FlyerImageUploader from 'components/FlyerImageUploader'
import Button from 'components/Button'
import Icon from 'components/Icon'
import { getProducts } from 'api/products'
import { createFlyer, updateFlyer } from 'api/flyers'
import { updateFile } from 'api/files'
import { flyerSchema } from 'utils/schemas'
import { ICONS } from 'utils/constants'

import cls from './FlyerForm.module.scss'

const FlyerForm = ({ flyer, submitCallback }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const { data: productsData, isLoading: isProductSelectLoading } = useQuery({
    queryKey: ['allProducts'],
    queryFn: async () => await getProducts(),
    cacheTime: 1000 * 60 * 60, // 2 hour
    staleTime: 1000 * 60 * 60, // 1 hour
  })

  const methods = useForm({
    mode: 'onSubmit',
    shouldFocusError: true,
    resolver: yupResolver(flyerSchema),
  })

  const categoryIdCond = methods.watch('categoryId')
  const productIdCond = methods.watch('productId')

  const categoriesSelectOptions = useMemo(() => {
    const options = []
    const addedIds = []

    if (productsData && Array.isArray(productsData.result)) {
      productsData.result.map((oneProduct) => {
        // Take only visible products with visible category
        if (oneProduct.isVisible && oneProduct.category?.isVisible) {
          if (addedIds.indexOf(oneProduct.category.id) === -1) {
            options.push({
              label: oneProduct.category.name,
              value: oneProduct.category.id,
            })
            addedIds.push(oneProduct.category.id)
          }
        }

        return oneProduct
      })
    }

    return options
  }, [productsData])

  const productsSelectOptions = useMemo(() => {
    const options = []

    if (productsData && Array.isArray(productsData.result)) {
      productsData.result.map((oneProduct) => {
        // Take only visible products with visible category
        if (oneProduct.isVisible && oneProduct.category?.isVisible) {
          if (oneProduct.category.id === categoryIdCond) {
            options.push({
              label: oneProduct.title,
              value: oneProduct.id,
            })
          }
        }

        return oneProduct
      })
    }

    return options
  }, [productsData, categoryIdCond])

  const submitErrorHandler = useCallback(() => {
    dispatch(
      notify({
        title: t('notifs.error title'),
        status: 'error',
        dismissAfter: 4000,
      }),
    )
  }, [dispatch, t])

  const updateFlyerMutation = useMutation(updateFlyer, {
    onError: () => submitErrorHandler(),
  })

  const createFlyerMutation = useMutation(createFlyer, {
    onError: () => submitErrorHandler(),
  })

  const updateCustomImageMutation = useMutation(updateFile, {
    onError: () => submitErrorHandler(),
  })

  const isFormLoading = useMemo(() => {
    return Boolean(
      createFlyerMutation.isLoading ||
        updateFlyerMutation.isLoading ||
        updateCustomImageMutation.isLoading,
    )
  }, [createFlyerMutation, updateFlyerMutation, updateCustomImageMutation])

  const submitHandler = useCallback(
    async (formData) => {
      // Check is flyer image exist in form data
      if (!formData.customImage) {
        dispatch(
          notify({
            title: t('flyers.messages.no image error'),
            status: 'error',
            dismissAfter: 4000,
          }),
        )
        return false
      }

      let freshFlyer

      const baseFlyerData = {
        oldPrice: formData.oldPrice,
        isVisible: formData.isVisible,
        productId: formData.productId,
        validFrom: formData?.validFrom ? moment(formData.validFrom).toISOString() : null,
        validTo: formData?.validTo ? moment(formData.validTo).toISOString() : null,
      }

      if (flyer) {
        freshFlyer = await updateFlyerMutation.mutateAsync({ ...baseFlyerData, id: flyer.id })
      } else {
        freshFlyer = await createFlyerMutation.mutateAsync(baseFlyerData)
      }

      // Create/update custom image
      if (
        freshFlyer?.logo?.id &&
        formData.customImage &&
        typeof formData.customImage === 'object'
      ) {
        await updateCustomImageMutation.mutateAsync({
          id: freshFlyer.logo.id,
          file: formData.customImage,
        })
      }

      submitCallback && submitCallback(freshFlyer)
    },
    [
      flyer,
      submitCallback,
      createFlyerMutation,
      updateFlyerMutation,
      updateCustomImageMutation,
      dispatch,
      t,
    ],
  )

  useEffect(() => {
    if (categoryIdCond) {
      if (productIdCond && productsData?.result?.length) {
        const filteredProducts = productsData.result.filter(
          (oneProduct) => oneProduct.id === productIdCond,
        )
        const selectedProduct = filteredProducts.length ? filteredProducts[0] : null

        if (
          !selectedProduct ||
          (selectedProduct && selectedProduct?.category?.id !== categoryIdCond)
        ) {
          methods.setValue('productId', null)
        }
      }
    } else {
      methods.setValue('productId', null)
    }
  }, [categoryIdCond, productIdCond, productsData?.result, methods])

  // Reset form values if role property was changed
  useEffect(() => {
    methods.setValue('categoryId', flyer?.product?.category?.id ? flyer.product.category.id : null)
    methods.setValue('productId', flyer?.product?.id ? flyer.product.id : null)
    methods.setValue('validFrom', flyer?.validFrom ? flyer.validFrom : null)
    methods.setValue('validTo', flyer?.validTo ? flyer.validTo : null)
    methods.setValue('isVisible', flyer?.isVisible ? flyer.isVisible : false)
    methods.setValue('oldPrice', flyer?.oldPrice ? flyer.oldPrice : null)
    methods.setValue('customImage', flyer?.logo?.src ? flyer.logo.src : null)
  }, [flyer, methods])

  return (
    <FormProvider {...methods}>
      <form
        className={classNames(cls.form, 'form')}
        autoComplete="off"
        onSubmit={methods.handleSubmit(submitHandler)}
      >
        <Spin spinning={isFormLoading}>
          <Row gutter={[15, 10]}>
            <Col span={24}>
              <HookedField
                label={t('flyers.form.category.label')}
                name="categoryId"
                placeholder={t('flyers.form.category.placeholder')}
                fieldType="selectField"
                type="select"
                allowClear={true}
                showSearch={true}
                filterOption={(input, option) => {
                  if (option?.label && input.length) {
                    return option.label.toString().toLowerCase().includes(input.toLowerCase())
                  }

                  return true
                }}
                disabled={isProductSelectLoading}
                loading={isProductSelectLoading}
                options={categoriesSelectOptions}
              />
            </Col>
            <Col span={24}>
              <HookedField
                label={t('flyers.form.product.label')}
                name="productId"
                placeholder={
                  categoryIdCond
                    ? t('flyers.form.product.placeholder')
                    : t('flyers.form.product.placeholder category depend')
                }
                fieldType="selectField"
                type="select"
                allowClear={true}
                showSearch={true}
                filterOption={(input, option) => {
                  if (option?.label && input.length) {
                    return option.label.toString().toLowerCase().includes(input.toLowerCase())
                  }

                  return true
                }}
                disabled={isProductSelectLoading}
                loading={isProductSelectLoading}
                options={productsSelectOptions}
              />
            </Col>
            <Col span={12}>
              <HookedField
                label={t('fields.valid from')}
                name="validFrom"
                placeholder={t('fields.set valid from')}
                disabledDate={(current) => current && current < moment().startOf('day')}
                isRequired={true}
                fieldType="dateTimeField"
                type="dateTimeField"
              />
            </Col>
            <Col span={12}>
              <HookedField
                label={t('fields.valid to')}
                name="validTo"
                placeholder={t('fields.set valid to')}
                disabledDate={(current) => current && current < moment().startOf('day')}
                isRequired={true}
                fieldType="dateTimeField"
                type="dateTimeField"
              />
            </Col>
            <Col span={24} md={{ span: 12 }}>
              <HookedField
                name="oldPrice"
                label={t('flyers.form.oldPrice.label')}
                placeholder={t('flyers.form.oldPrice.placeholder')}
                min={1}
                fieldType="numberField"
                type="number"
              />
            </Col>
            <Col span={24} md={{ span: 12 }}>
              <div className="form-visibility">
                <Icon className="form-visibility-icon" icon={ICONS.EYE} size={24} />
                <span className="form-visibility-text">{t('products.switch')}</span>
                <HookedField
                  className="form-visibility-switch"
                  name="isVisible"
                  fieldType="customField"
                  type="switch"
                />
              </div>
            </Col>
            <Col span={24}>
              <FlyerImageUploader methods={methods} formInputName={'customImage'} />
            </Col>
            <Col span={24} style={{ textAlign: 'center' }}>
              <Button
                className="save-btn"
                type="primary"
                size="large"
                htmlType="submit"
                disabled={isFormLoading}
                loading={isFormLoading}
              >
                {t('app.save')}
              </Button>
            </Col>
          </Row>
        </Spin>
      </form>
    </FormProvider>
  )
}

export default FlyerForm
