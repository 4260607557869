import { call, put, all, fork, takeEvery } from 'redux-saga/effects'
import { showLoading, hideLoading } from 'react-redux-loading-bar'
import { notify } from 'reapop'
import OrdersService from '../middlewares/orders'

import {
  GET_ORDERS_REQUEST,
  GET_ORDERS,
  GET_ORDER_REQUEST,
  GET_ORDER,
  GET_ORDERS_REQUEST_PENDING,
  GET_ORDERS_REQUEST_SUCCESS,
  GET_ORDERS_REQUEST_ERROR,
  CHANGE_PAGINATION,
} from '../types/orders'

function* getOrdersWatcher() {
  yield takeEvery(GET_ORDERS_REQUEST, getOrdersWorker)
}

function* getOrderWatcher() {
  yield takeEvery(GET_ORDER_REQUEST, getOrderWorker)
}

function* getOrdersWorker({ payload: { page, size, search, filters, sortType, sortOrder } }) {
  try {
    yield put(showLoading())
    yield put({ type: GET_ORDERS_REQUEST_PENDING })
    const response = yield call(
      OrdersService.getOrders,
      page,
      size,
      search,
      filters,
      sortType,
      sortOrder,
    )
    const { result, total } = response.data.data
    yield put({
      type: CHANGE_PAGINATION,
      payload: {
        current: page,
        pageSize: size,
        total: total,
      },
    })
    yield put({
      type: GET_ORDERS,
      payload: { items: result },
    })
    yield put({ type: GET_ORDERS_REQUEST_SUCCESS })
    yield put(hideLoading())
  } catch (error) {
    const errors = error.response.data.messages
    yield put({ type: GET_ORDERS_REQUEST_ERROR, payload: errors })
    yield all(
      errors.map((error) =>
        put(
          notify({
            title: error.message,
            status: 'error',
            dismissAfter: 6000,
            allowHTML: true,
          }),
        ),
      ),
    )
    yield put(hideLoading())
  }
}

function* getOrderWorker({ payload: { id, navigate } }) {
  try {
    yield put(showLoading())
    yield put({ type: GET_ORDERS_REQUEST_PENDING })
    const response = yield call(OrdersService.getOrderById, id)
    const order = response.data.data
    yield put({ type: GET_ORDER, payload: order })
    yield put({ type: GET_ORDERS_REQUEST_SUCCESS })
    yield put(hideLoading())
  } catch (error) {
    navigate('/main/orders')
    const errors = error.response.data.messages
    yield put({ type: GET_ORDERS_REQUEST_ERROR, payload: errors })
    yield all(
      errors.map((error) =>
        put(
          notify({
            title: error.message,
            status: 'error',
            dismissAfter: 6000,
            allowHTML: true,
          }),
        ),
      ),
    )
    yield put(hideLoading())
  }
}

export default function* orders() {
  yield all([fork(getOrdersWatcher), fork(getOrderWatcher)])
}
