import { useCallback, useEffect, useState } from 'react'

const useNodeSize = (ref) => {
  const [width, setWidth] = useState(undefined)
  const [height, setHeight] = useState(undefined)

  const getSizes = useCallback(() => {
    if (ref.current) {
      setHeight(ref.current.offsetHeight)
      setWidth(ref.current.offsetWidth)
    }
  }, [ref])

  // Listen page resize
  useEffect(() => {
    const checkInterval = window.setInterval(() => {
      getSizes()
    }, 1000)

    return () => window.clearInterval(checkInterval)
  }, [getSizes])

  return { width, height }
}

export default useNodeSize
