import Header from './Header'
import Sidebar from './Sidebar'

const ScrolledLayout = ({ children }) => {
  return (
    <div className="scrolledLayout">
      <Header />
      <Sidebar />
      <main className="main">
        <div className="container">
          <div className="container__inner">{children}</div>
        </div>
      </main>
    </div>
  )
}

export default ScrolledLayout
