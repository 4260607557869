export const CategoriesLabels = {
  LOGS: {
    categoryTitle: 'Logs',
    READ_LOGS: {
      permissionTitle: 'Read logs',
      key: 'READ',
    },
  },
  USERS: {
    categoryTitle: 'Customers',
    READ_USERS: {
      permissionTitle: 'Read customers',
      key: 'READ',
    },

    UPDATE_WALLET: {
      permissionTitle: 'Edit wallet',
      key: 'UPDATE',
    },
  },
  // FILES: {
  //   categoryTitle: 'Files',
  //   READ_FILES: {
  //     permissionTitle: 'Read files',
  //     key: 'READ',
  //   },
  //   DELETE_FILES: {
  //     permissionTitle: 'Delete files',
  //     key: 'DELETE',
  //   },
  //   UPDATE_FILES: {
  //     permissionTitle: 'Update files',
  //     key: 'UPDATE',
  //   },
  // },
  ROLES: {
    categoryTitle: 'Roles',
    READ_ROLES: {
      permissionTitle: 'Read roles',
      key: 'READ',
    },
    DELETE_ROLES: {
      permissionTitle: 'Delete roles',
      key: 'DELETE',
    },
    UPDATE_ROLES: {
      permissionTitle: 'Update roles',
      key: 'UPDATE',
    },
    CREATE_ROLES: {
      permissionTitle: 'Create roles',
      key: 'CREATE',
    },
  },
  FLIERS: {
    categoryTitle: 'Flyers',
    READ_FLIERS: {
      permissionTitle: 'Read flyers',
      key: 'READ',
    },
    DELETE_FLIERS: {
      permissionTitle: 'Delete flyers',
    },
    UPDATE_FLIERS: {
      permissionTitle: 'Update flyers',
    },
    CREATE_FLIERS: {
      permissionTitle: 'Create flyers',
    },
  },
  RATING: {
    categoryTitle: 'Rating',
    READ_RATING: {
      permissionTitle: 'Read rating',
    },
    // DELETE_RATING: {
    //   permissionTitle: 'Delete rating',
    // },
    UPDATE_RATING: {
      permissionTitle: 'Update rating',
    },
    // CREATE_RATING: {
    //   permissionTitle: 'Create rating',
    // },
  },
  ORDERS: {
    categoryTitle: 'Orders',
    READ_ORDERS: {
      permissionTitle: 'Read orders',
    },
    SEND_ORDERS: {
      permissionTitle: 'Send orders',
    },
    // UPDATE_ORDERS: {
    //   permissionTitle: 'Update orders',
    // },
  },
  OFFERS: {
    categoryTitle: 'Offers',
    READ_OFFERS: {
      permissionTitle: 'Read offers',
    },
    CREATE_OFFERS: {
      permissionTitle: 'Create offers',
    },
    UPDATE_OFFERS: {
      permissionTitle: 'Update offers',
    },
  },
  COUPONS: {
    categoryTitle: 'Coupons',
    READ_COUPONS: {
      permissionTitle: 'Read coupons',
    },
    DELETE_COUPONS: {
      permissionTitle: 'Delete coupons',
    },
    // UPDATE_COUPONS: {
    //   permissionTitle: 'Update coupons',
    // },
    CREATE_COUPONS: {
      permissionTitle: 'Create coupons',
    },
  },
  MOSQUES: {
    categoryTitle: 'Mosques',
    READ_MOSQUES: {
      permissionTitle: 'Read mosques',
    },
    UPDATE_MOSQUES: {
      permissionTitle: 'Update mosques',
    },
  },
  PRODUCTS: {
    categoryTitle: 'Products',
    READ_PRODUCTS: {
      permissionTitle: 'Read products',
    },
    UPDATE_PRODUCTS: {
      permissionTitle: 'Update products',
    },
  },
  UPSELLING: {
    categoryTitle: 'Upseling',
    READ_UPSELLING: {
      permissionTitle: 'Read upselling',
    },
    UPDATE_UPSELLING: {
      permissionTitle: 'Update upselling',
    },
  },
  DASHBOARD: {
    categoryTitle: 'Dashboard',
    READ_DASHBOARD: {
      permissionTitle: 'Read dashboard',
    },
  },
  PROMOTIONS: {
    categoryTitle: 'Promotions',
    READ_PROMOTIONS: {
      permissionTitle: 'Read promotions',
    },
    UPDATE_PROMOTIONS: {
      permissionTitle: 'Update promotions',
    },
  },
  PROMO: {
    categoryTitle: 'Promo codes',
    READ_PROMO_CODES: {
      permissionTitle: 'Read promo codes',
    },
    DELETE_PROMO_CODES: {
      permissionTitle: 'Delete promo codes',
    },
    UPDATE_PROMO_CODES: {
      permissionTitle: 'Update promo codes',
    },
    CREATE_PROMO_CODES: {
      permissionTitle: 'Create promo codes',
    },
  },
  NOTIFICATIONS: {
    categoryTitle: 'Notifications',
    READ_NOTIFICATIONS: {
      permissionTitle: 'Read notifications',
    },
    SEND_NOTIFICATIONS: {
      permissionTitle: 'Send notifications',
    },
    DELETE_NOTIFICATIONS: {
      permissionTitle: 'Delete notifications',
    },
    UPDATE_NOTIFICATIONS: {
      permissionTitle: 'Update notifications',
    },
    CREATE_NOTIFICATIONS: {
      permissionTitle: 'Create notifications',
    },
  },
  PRODUCT: {
    categoryTitle: 'Product categories',
    READ_PRODUCT_CATEGORIES: {
      permissionTitle: 'Read product categories',
    },
    UPDATE_PRODUCT_CATEGORIES: {
      permissionTitle: 'Update product categories',
    },
  },
  ADMIN: {
    categoryTitle: 'Admin users',
    READ_ADMIN_USERS: {
      permissionTitle: 'Read admin users',
    },
    // DELETE_ADMIN_USERS: {
    //   permissionTitle: 'Delete admin users',
    // },
    CREATE_ADMIN_USERS: {
      permissionTitle: 'Create admin users',
    },
  },
  CAMPAIGN: {
    categoryTitle: 'Campaigns',
  },
}

//   min 1 max 30
// check all existing roles

export const CategoriesForAllLabels = {
  LOGS: {
    // categoryTitle: 'Logs',
    permissions: [
      {
        permissionTitle: 'Read logs',
        key: 'READ',
        fullKey: 'READ_LOGS',
      },
    ],
  },
  USERS: {
    // categoryTitle: 'Users',
    permissions: [
      {
        permissionTitle: 'Read customers',
        key: 'READ',
        fullKey: 'READ_USERS',
      },
      {
        permissionTitle: 'Edit wallet',
        key: 'UPDATE',
        fullKey: 'UPDATE_WALLET',
      },
    ],
  },
  // FILES: {
  //   // categoryTitle: 'Files',
  //   permissions: [
  //     {
  //       permissionTitle: 'Read files',
  //       key: 'READ',
  //       fullKey: 'READ_FILES',
  //     },
  //     {
  //       permissionTitle: 'Delete files',
  //       key: 'DELETE',
  //       fullKey: 'DELETE_FILES',
  //     },
  //     {
  //       permissionTitle: 'Update files',
  //       key: 'UPDATE',
  //       fullKey: 'UPDATE_FILES',
  //     },
  //   ],
  // },
  ROLES: {
    // categoryTitle: 'Roles',
    permissions: [
      {
        permissionTitle: 'Read roles',
        key: 'READ',
        fullKey: 'READ_ROLES',
      },
      {
        permissionTitle: 'Delete roles',
        key: 'DELETE',
        fullKey: 'DELETE_ROLES',
      },
      {
        permissionTitle: 'Update roles',
        key: 'UPDATE',
        fullKey: 'UPDATE_ROLES',
      },
      {
        permissionTitle: 'Create roles',
        key: 'CREATE',
        fullKey: 'CREATE_ROLES',
      },
    ],
  },
  FLIERS: {
    // categoryTitle: 'Fliers',
    permissions: [
      {
        permissionTitle: 'Read fliers',
        key: 'READ',
        fullKey: 'READ_FLIERS',
      },
      {
        permissionTitle: 'Delete fliers',
        key: 'DELETE',
        fullKey: 'DELETE_FLIERS',
      },
      {
        permissionTitle: 'Update filers',
        key: 'UPDATE',
        fullKey: 'UPDATE_FLIERS',
      },
      {
        permissionTitle: 'Create fliers',
        key: 'CREATE',
        fullKey: 'CREATE_FLIERS',
      },
    ],
  },
  RATING: {
    // categoryTitle: 'Rating',
    permissions: [
      {
        permissionTitle: 'Read rating',
        key: 'READ',
        fullKey: 'READ_RATING',
      },
      // {
      //   permissionTitle: 'Delete rating',
      //   key: 'DELETE',
      //   fullKey: 'DELETE_RATING',
      // },
      {
        permissionTitle: 'Update rating',
        key: 'UPDATE',
        fullKey: 'UPDATE_RATING',
      },
      // {
      //   permissionTitle: 'Create rating',
      //   key: 'CREATE',
      //   fullKey: 'CREATE_RATING',
      // },
    ],
  },
  ORDERS: {
    // categoryTitle: 'Orders',
    permissions: [
      {
        permissionTitle: 'Read orders',
        key: 'READ',
        fullKey: 'READ_ORDERS',
      },
      {
        permissionTitle: 'Send orders',
        key: 'SEND',
        fullKey: 'SEND_ORDERS',
      },
      // {
      //   permissionTitle: 'Update orders',
      //   key: 'UPDATE',
      //   fullKey: 'UPDATE_ORDERS',
      // },
    ],
  },
  OFFERS: {
    // categoryTitle: 'Offers',
    permissions: [
      {
        permissionTitle: 'Read offers',
        key: 'READ',
        fullKey: 'READ_OFFERS',
      },
      {
        permissionTitle: 'Create offers',
        key: 'CREATE',
        fullKey: 'CREATE_OFFERS',
      },
      {
        permissionTitle: 'Update offers',
        key: 'UPDATE',
        fullKey: 'UPDATE_OFFERS',
      },
    ],
  },
  COUPONS: {
    // categoryTitle: 'Coupons',
    permissions: [
      {
        permissionTitle: 'Read coupons',
        key: 'READ',
        fullKey: 'READ_COUPONS',
      },
      {
        permissionTitle: 'Delete coupons',
        key: 'DELETE',
        fullKey: 'DELETE_COUPONS',
      },
      // {
      //   permissionTitle: 'Update coupons',
      //   key: 'UPDATE',
      //   fullKey: 'UPDATE_COUPONS',
      // },
      {
        permissionTitle: 'Create coupons',
        key: 'CREATE',
        fullKey: 'CREATE_COUPONS',
      },
    ],
  },
  MOSQUES: {
    // categoryTitle: 'Mosques',
    permissions: [
      {
        permissionTitle: 'Read mosques',
        key: 'READ',
        fullKey: 'READ_MOSQUES',
      },
      {
        permissionTitle: 'Update mosques',
        key: 'UPDATE',
        fullKey: 'UPDATE_MOSQUES',
      },
    ],
  },
  PRODUCTS: {
    // categoryTitle: 'Products',
    permissions: [
      {
        permissionTitle: 'Read products',
        key: 'READ',
        fullKey: 'READ_PRODUCTS',
      },
      {
        permissionTitle: 'Update products',
        key: 'UPDATE',
        fullKey: 'UPDATE_PRODUCTS',
      },
    ],
  },
  UPSELLING: {
    // categoryTitle: 'Upseling',
    permissions: [
      {
        permissionTitle: 'Read upselling',
        key: 'READ',
        fullKey: 'READ_UPSELLING',
      },
      {
        permissionTitle: 'Update upselling',
        key: 'UPDATE',
        fullKey: 'UPDATE_UPSELLING',
      },
    ],
  },
  DASHBOARD: {
    // categoryTitle: 'Dashboard',
    permissions: [
      {
        permissionTitle: 'Read dashboard',
        key: 'READ',
        fullKey: 'READ_DASHBOARD',
      },
    ],
  },
  PROMOTIONS: {
    // categoryTitle: 'Promotions',
    permissions: [
      {
        permissionTitle: 'Read promotions',
        key: 'READ',
        fullKey: 'READ_PROMOTIONS',
      },
      {
        permissionTitle: 'Update promotions',
        key: 'UPDATE',
        fullKey: 'UPDATE_PROMOTIONS',
      },
    ],
  },
  PROMO: {
    // categoryTitle: 'Promo codes',
    permissions: [
      {
        permissionTitle: 'Read promo codes',
        key: 'READ',
        fullKey: 'READ_PROMO_CODES',
      },
      {
        permissionTitle: 'Delete promo codes',
        key: 'DELETE',
        fullKey: 'DELETE_PROMO_CODES',
      },
      {
        permissionTitle: 'Update promo codes',
        key: 'UPDATE',
        fullKey: 'UPDATE_PROMO_CODES',
      },
      {
        permissionTitle: 'Create promo codes',
        key: 'CREATE',
        fullKey: 'CREATE_PROMO_CODES',
      },
    ],
  },
  NOTIFICATIONS: {
    // categoryTitle: 'Notifications',
    permissions: [
      {
        permissionTitle: 'Read notifications',
        key: 'READ',
        fullKey: 'READ_NOTIFICATIONS',
      },
      {
        permissionTitle: 'Send notifications',
        key: 'SEND',
        fullKey: 'SEND_NOTIFICATIONS',
      },
      {
        permissionTitle: 'Delete notifications',
        key: 'DELETE',
        fullKey: 'DELETE_NOTIFICATIONS',
      },
      {
        permissionTitle: 'Update notifications',
        key: 'UPDATE',
        fullKey: 'UPDATE_NOTIFICATIONS',
      },
      {
        permissionTitle: 'Create notifications',
        key: 'CREATE',
        fullKey: 'CREATE_NOTIFICATIONS',
      },
    ],
  },
  PRODUCT: {
    // categoryTitle: 'Product categories',
    permissions: [
      {
        permissionTitle: 'Read product categories',
        key: 'READ',
        fullKey: 'READ_PRODUCT_CATEGORIES',
      },
      {
        permissionTitle: 'Update product categories',
        key: 'UPDATE',
        fullKey: 'UPDATE_PRODUCT_CATEGORIES',
      },
    ],
  },
  ADMIN: {
    // categoryTitle: 'Admin users',
    permissions: [
      {
        permissionTitle: 'Read admin users',
        key: 'READ',
        fullKey: 'READ_ADMIN_USERS',
      },
      // {
      //   permissionTitle: 'Delete admin users',
      //   key: 'DELETE',
      //   fullKey: 'DELETE_ADMIN_USERS',
      // },
      {
        permissionTitle: 'Create admin users',
        key: 'CREATE',
        fullKey: 'CREATE_ADMIN_USERS',
      },
      {
        permissionTitle: 'Update admin users',
        key: 'UPDATE',
        fullKey: 'UPDATE_ADMIN_USERS',
      },
    ],
  },
  CAMPAIGN: {
    permissions: [
      {
        permissionTitle: 'Read campaign',
        key: 'READ',
        fullKey: 'READ_CAMPAIGN',
      },
      {
        permissionTitle: 'Create campaign',
        key: 'CREATE',
        fullKey: 'CREATE_CAMPAIGN',
      },
      {
        permissionTitle: 'Update campaign',
        key: 'UPDATE',
        fullKey: 'UPDATE_CAMPAIGN',
      },
      {
        permissionTitle: 'Delete campaign',
        key: 'DELETE',
        fullKey: 'DELETE_CAMPAIGN',
      },
    ],
  },
}
