export const customersData = [
  {
    id: '1',
    nameEn: 'Sasha Zakablukov',
    nameAr: 'عبد الله بن عبد المطلب',
    mobile: '+380956072063',
    email: 'zakablukov777@gmail.com',
    totalOrders: 20,
    lastOrder: '2021-09-23T09:20:38.455Z',
    area: 'area',
    address: 'address',
  },
  {
    id: '2',
    nameEn: 'Vova Zakablukov',
    nameAr: 'عبد الله بن عبد المطلب',
    mobile: '+380936072062',
    email: 'vzakablukov1213@gmail.com',
    totalOrders: 15,
    lastOrder: '2021-09-23T09:20:38.455Z',
    area: 'area',
    address: 'address',
  },
  {
    id: '3',
    nameEn: 'Abraham Zakablukov',
    nameAr: 'عبد الله بن عبد المطلب',
    mobile: '+380986072064',
    email: 'azakablukov1213@gmail.com',
    totalOrders: 17,
    lastOrder: '2021-09-23T09:20:38.455Z',
    area: 'area',
    address: 'address',
  },
]

export const bannersData = [
  {
    id: '1',
    nameEn: 'Banner name 1',
    nameAr: 'عبد الله بن عبد المطلب',
    action: 'https://www.google.com',
    place: 'Main page',
    visibility: 'Visible',
    period: '17.08.2021-24.08.2021',
  },
  {
    id: '2',
    nameEn: 'Banner name 2',
    nameAr: 'عبد الله بن عبد المطلب',
    action: 'https://www.google.com',
    place: 'Main page',
    visibility: 'Visible',
    period: '17.08.2021-24.08.2021',
  },
  {
    id: '3',
    nameEn: 'Banner name 3',
    nameAr: 'عبد الله بن عبد المطلب',
    action: 'https://www.google.com',
    place: 'Main page',
    visibility: 'Visible',
    period: '17.08.2021-24.08.2021',
  },
]

export const categoriesData = [
  {
    id: '1',
    nameEn: 'Sasha Zakablukov',
    nameAr: 'عبد الله بن عبد المطلب',
    description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam, purus',
    products: 20,
    visibility: 'Active',
  },
  {
    id: '2',
    nameEn: 'Sasha Zakablukov',
    nameAr: 'عبد الله بن عبد المطلب',
    description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam, purus',
    products: 50,
    visibility: 'Active',
  },
  {
    id: '3',
    nameEn: 'Sasha Zakablukov',
    nameAr: 'عبد الله بن عبد المطلب',
    description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam, purus',
    products: 24,
    visibility: 'Active',
  },
]

export const notificationsData = [
  {
    id: '1',
    notificationTitle: 'Lorem ipsum dolor sit amet.',
    notificationText:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pretium turpis vitae.',
    date: '28.07.2021',
  },
  {
    id: '2',
    notificationTitle: 'Lorem ipsum dolor sit amet.',
    notificationText:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pretium turpis vitae.',
    date: '28.07.2021',
  },
  {
    id: '3',
    notificationTitle: 'Lorem ipsum dolor sit amet.',
    notificationText:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pretium turpis vitae.',
    date: '28.07.2021',
  },
]

export const ordersData = [
  {
    id: '1',
    orderNumber: '672194',
    customerName: 'Mohammed Abdulatif Rashid Lootah',
    mobile: '+971 04 4099000',
    address: 'Down Town Burj Dubai, 31166',
    deliveryStatus: 'Awaiting Payment',
    createdDate: '12.08.2021',
  },
  {
    id: '2',
    orderNumber: '672194',
    customerName: 'Mohammed Abdulatif Rashid Lootah',
    mobile: '+971 04 4099000',
    address: 'Down Town Burj Dubai, 31166',
    deliveryStatus: 'Awaiting Payment',
    createdDate: '12.08.2021',
  },
  {
    id: '3',
    orderNumber: '672194',
    customerName: 'Mohammed Abdulatif Rashid Lootah',
    mobile: '+971 04 4099000',
    address: 'Down Town Burj Dubai, 31166',
    deliveryStatus: 'Awaiting Payment',
    createdDate: '12.08.2021',
  },
]

export const productsData = [
  {
    id: '1',
    nameEn: 'Sasha Zakablukov',
    nameAr: 'عبد الله بن عبد المطلب',
    unit: '1 x 24 Bottles',
    productCategory: 'Water',
    priceForOne: '0,700 KWD',
    priceForUnit: '14 KWD',
    visibility: 'Active',
  },
  {
    id: '2',
    nameEn: 'Sasha Zakablukov',
    nameAr: 'عبد الله بن عبد المطلب',
    unit: '1 x 24 Bottles',
    productCategory: 'Water',
    priceForOne: '0,700 KWD',
    priceForUnit: '14 KWD',
    visibility: 'Disable',
  },
  {
    id: '3',
    nameEn: 'Sasha Zakablukov',
    nameAr: 'عبد الله بن عبد المطلب',
    unit: '1 x 24 Bottles',
    productCategory: 'Water',
    priceForOne: '0,700 KWD',
    priceForUnit: '14 KWD',
    visibility: 'Active',
  },
]

export const promotionsData = [
  {
    id: '1',
    nameEn: 'Lorem ipsum dolor sit amet.',
    nameAr: 'عبد الله بن عبد المطلب',
    action: 'https://www.google.com',
    date: '28.07.2021',
  },
  {
    id: '2',
    nameEn: 'Lorem ipsum dolor sit amet.',
    nameAr: 'عبد الله بن عبد المطلب',
    action: 'https://www.google.com',
    date: '28.07.2021',
  },
  {
    id: '3',
    nameEn: 'Lorem ipsum dolor sit amet.',
    nameAr: 'عبد الله بن عبد المطلب',
    action: 'https://www.google.com',
    date: '28.07.2021',
  },
]

export const surveyData = [
  {
    id: '1',
    question: 'This text is generated to describe the domain number two.',
    rating: 5,
    createdDate: '17.07.1996',
    creator: 'Mohammed Rashid Lootah',
  },
  {
    id: '2',
    question: 'This text is generated to describe the domain number two.',
    rating: 25,
    createdDate: '17.07.1996',
    creator: 'Mohammed Rashid Lootah',
  },
  {
    id: '3',
    question: 'This text is generated to describe the domain number two.',
    rating: 15,
    createdDate: '17.07.1996',
    creator: 'Mohammed Rashid Lootah',
  },
]

export const usersData = [
  {
    id: '1',
    nameEn: 'Mohammed Abdulatif Rashid Lootah',
    mobile: '+380986072064',
    email: 'MohAbdRashid@mail.ru',
    position: 'Admin',
    entryDate: '28.07.2021',
  },
  {
    id: '2',
    nameEn: 'Mohammed Abdulatif Rashid Lootah',
    mobile: '+380986072064',
    email: 'MohAbdRashid@mail.ru',
    position: 'Marketing',
    entryDate: '28.07.2021',
  },
  {
    id: '3',
    nameEn: 'Mohammed Abdulatif Rashid Lootah',
    mobile: '+380986072064',
    email: 'MohAbdRashid@mail.ru',
    position: 'Sales',
    entryDate: '28.07.2021',
  },
]

export const usersNotificationsData = [
  {
    id: '1',
    notificationTitle: 'Lorem ipsum dolor sit amet.',
    notificationText:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pretium turpis vitae.',
    date: '28.07.2021',
  },
  {
    id: '2',
    notificationTitle: 'Lorem ipsum dolor sit amet.',
    notificationText:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pretium turpis vitae.',
    date: '28.07.2021',
  },
  {
    id: '3',
    notificationTitle: 'Lorem ipsum dolor sit amet.',
    notificationText:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pretium turpis vitae.',
    date: '28.07.2021',
  },
]
