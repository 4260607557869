import moment from 'moment'
import { useEffect, useCallback, useState, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'
import useClient from 'hooks/useClient'
import { getBanners, setBanners, toggleBannerVisibility } from 'redux/actions/banners'
import update from 'immutability-helper'
import { Tooltip } from 'antd'
import { DATE_TIME_FORMAT, ICONS, PERMISSIONS } from 'utils/constants'
import PageTable from 'components/PageTable'
import Icon from 'components/Icon'
import DraggableBodyRow from 'components/DraggableBodyRow'
import BannerFormModal from 'components/Modal/modals/BannerFormModal/BannerFormModal'
import { parseThousandsGroups } from 'helpers/numbers'

const Banners = () => {
  const { t } = useTranslation()
  const { isClientCan } = useClient()
  const dispatch = useDispatch()
  const { items, isGetLoading } = useSelector((state) => state.banners)
  const [activeBanner, setActiveBanner] = useState(null)
  const [isBannerModalVisible, setIsBannerModalVisible] = useState(false)

  // permissions for offers
  const isClientCanUpdateOffers = isClientCan(PERMISSIONS.UPDATE_OFFERS)
  const isCLientCanCreateOffers = isClientCan(PERMISSIONS.CREATE_OFFERS)

  useEffect(() => {
    dispatch(getBanners())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const columns = useMemo(() => {
    const bannersColumns = []
    bannersColumns.push({
      title: '№',
      dataIndex: 'id',
      render: (text, record, index) => index + 1,
      key: 'order',
      width: 50,
      align: 'center',
    })
    bannersColumns.push({
      title: t('fields.name'),
      dataIndex: ['product', 'title'],
      key: 'title',
      width: 497,
    })
    bannersColumns.push({
      title: t('fields.sap id'),
      dataIndex: 'sapId',
      key: 'sapId',
      width: 115,
      render: (_, record) => record.product.sapId,
    })
    bannersColumns.push({
      title: t('fields.valid from'),
      dataIndex: 'validFrom',
      key: 'validFrom',
      width: 185,
      render: (validFrom) => {
        if (validFrom) {
          return moment(validFrom).format(DATE_TIME_FORMAT)
        }

        return '-'
      },
    })
    bannersColumns.push({
      title: t('fields.valid to'),
      dataIndex: 'validTo',
      key: 'validTo',
      width: 185,
      render: (validTo) => {
        if (validTo) {
          return moment(validTo).format(DATE_TIME_FORMAT)
        }

        return '-'
      },
    })
    bannersColumns.push({
      title: t('fields.old price'),
      dataIndex: 'oldPrice',
      key: 'oldPrice',
      width: 108,
      render: (oldPrice) => {
        return oldPrice ? `${parseThousandsGroups(oldPrice)} KD` : '-'
      },
    })
    if (isClientCanUpdateOffers) {
      bannersColumns.push({
        title: t('fields.visibility'),
        align: 'center',
        width: 106,
        render: (text, { id, isVisible }) => {
          return (
            <div className="table-actions">
              <Tooltip placement="topRight" title={isVisible ? t('app.hide') : t('app.show')}>
                <button
                  className="action-btn action-icon--visibility"
                  onClick={(event) => {
                    event.stopPropagation()
                    dispatch(toggleBannerVisibility({ id }))
                  }}
                >
                  <Icon
                    className="action-icon"
                    icon={isVisible ? ICONS.VISIBLE : ICONS.INVISIBLE}
                    size={20}
                  />
                </button>
              </Tooltip>
            </div>
          )
        },
      })
    }

    return bannersColumns
  }, [dispatch, isClientCanUpdateOffers, t])

  const components = {
    body: {
      row: DraggableBodyRow,
    },
  }

  const moveRowHandler = useCallback(
    (dragIndex, hoverIndex) => {
      const dragRow = items[dragIndex]
      const data = update(items, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, dragRow],
        ],
      }).map((item) => item.id)

      dispatch(
        setBanners({
          data,
        }),
      )
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [items],
  )
  const moveRow = isCLientCanCreateOffers ? moveRowHandler : null

  const submitCallback = () => {
    setIsBannerModalVisible(false)
    dispatch(getBanners())
  }

  return (
    <>
      <PageTable
        pageTitle={t('banners.title')}
        // Right now action below does not need, but in future when we should implement banner adding feature it will be useful
        // action={
        //     <Button type="primary" onClick={() => props.history.push(`/main/banners/add/`)}>
        //         <Icon icon={ICONS.PLUS} className="btn-icon-suffix"/>
        //         <span className="btn-text">
        //             {t('banners.add button')}
        //         </span>
        //     </Button>
        // }
        baseData={items}
        columns={columns}
        components={components}
        onRow={
          isClientCanUpdateOffers
            ? (record, index) => ({
                index,
                moveRow,
                onClick: () => {
                  setActiveBanner(record)
                  setIsBannerModalVisible(true)
                },
              })
            : null
        }
        pagination={false}
        loading={isGetLoading}
        isDraggable={true}
      />
      <BannerFormModal
        banner={activeBanner}
        isVisible={isBannerModalVisible}
        onCancel={() => setIsBannerModalVisible(false)}
        submitCallback={submitCallback}
      />
    </>
  )
}

export default Banners
