import axios from 'axios'

class PaymentService {
  getPayment = (referenceNumber) =>
    axios({
      url: `payments/checkStatus/${referenceNumber}`,
      method: 'GET',
    })
}

export default new PaymentService()
