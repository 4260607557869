import { call, put, all, fork, takeEvery } from 'redux-saga/effects'

import { notify } from 'reapop'
import i18n from 'i18next'
import { showLoading, hideLoading } from 'react-redux-loading-bar'

import BannersService from '../middlewares/banners'

import {
  GET_BANNERS_REQUEST,
  GET_BANNERS,
  SET_BANNERS_REQUEST,
  TOGGLE_BANNER_VISIBILITY_REQUEST,
  GET_BANNERS_REQUEST_PENDING,
  GET_BANNERS_REQUEST_SUCCESS,
  GET_BANNERS_REQUEST_ERROR,
} from '../types/banners'

function* getBannersWatcher() {
  yield takeEvery(GET_BANNERS_REQUEST, getBannersWorker)
}

function* setBannersWatcher() {
  yield takeEvery(SET_BANNERS_REQUEST, setBannersWorker)
}

function* toggleBannerVisibilityWatcher() {
  yield takeEvery(TOGGLE_BANNER_VISIBILITY_REQUEST, toggleBannerVisibilityWorker)
}

function* getBannersWorker() {
  try {
    yield put(showLoading())
    yield put({ type: GET_BANNERS_REQUEST_PENDING })
    const response = yield call(BannersService.getBanners)
    const banners = response.data.data
    yield put({ type: GET_BANNERS, payload: banners })
    yield put({ type: GET_BANNERS_REQUEST_SUCCESS })
    yield put(hideLoading())
  } catch (error) {
    yield put({
      type: GET_BANNERS_REQUEST_ERROR,
      payload: error.response.data.messages,
    })
    yield put(hideLoading())
  }
}

function* setBannersWorker({ payload, payload: { data } }) {
  try {
    yield put(showLoading())
    yield put({ type: GET_BANNERS_REQUEST_PENDING })
    const response = yield call(BannersService.setBanners, data)
    const banners = response.data.data
    yield put({ type: GET_BANNERS, payload: banners })
    yield put({ type: GET_BANNERS_REQUEST_SUCCESS })
    yield put(
      notify({
        title: i18n.t('notifs.offer was changed'),
        status: 'info',
        dismissAfter: 4000,
        allowHTML: true,
      }),
    )
    yield put(hideLoading())
  } catch (error) {
    yield put({
      type: GET_BANNERS_REQUEST_ERROR,
      payload: error.response.data.messages,
    })
    yield put(hideLoading())
  }
}

function* toggleBannerVisibilityWorker({ payload: { id } }) {
  try {
    yield put(showLoading())
    yield put({ type: GET_BANNERS_REQUEST_PENDING })
    const response = yield call(BannersService.toggleBannerVisibility, id)
    const banners = response.data.data
    yield put({ type: GET_BANNERS, payload: banners })
    yield put({ type: GET_BANNERS_REQUEST_SUCCESS })
    const isVisible = banners.find((item) => item.id === id).isVisible
    yield put(
      notify({
        title: isVisible ? i18n.t('notifs.offer was shawn') : i18n.t('notifs.offer was hidden'),
        status: 'info',
        dismissAfter: 4000,
        allowHTML: true,
      }),
    )
    yield put(hideLoading())
  } catch (error) {
    yield put({
      type: GET_BANNERS_REQUEST_ERROR,
      payload: error.response.data.messages,
    })
    yield put(hideLoading())
  }
}

export default function* banners() {
  yield all([fork(getBannersWatcher), fork(setBannersWatcher), fork(toggleBannerVisibilityWatcher)])
}
