import { useCallback, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { FormProvider, useForm } from 'react-hook-form'
import { useMutation, useQuery } from '@tanstack/react-query'
import { yupResolver } from '@hookform/resolvers/yup'
import { useDispatch } from 'react-redux'
import { Button, Col, Row, message } from 'antd'

import HookedField from 'components/HookedField'

import { createRole, getRoleById, updateRole } from '../../../api/roles'
import { rolesSchema } from '../../../utils/schemas'
import { notify } from 'reapop'

const RolesForm = ({ roleId, onClose, onRoleSubmit }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const { data: role } = useQuery({
    queryKey: ['get-role-by-id', roleId],
    queryFn: () => {
      if (!roleId) return
      return getRoleById(roleId)
    },
  })

  const defaultFormValues = useMemo(() => {
    return {
      roleName: role?.roleName || '',
    }
  }, [role])

  const methods = useForm({
    mode: 'onSubmit',
    shouldFocusError: true,
    resolver: yupResolver(rolesSchema),
    defaultValues: defaultFormValues,
  })

  useEffect(() => {
    methods.reset(defaultFormValues)
  }, [defaultFormValues, methods])

  const createRoleMutation = useMutation(createRole, {
    onSuccess: async () => {
      return dispatch(
        notify({
          title: t('adminTeamManagement.roles.success.successfullyCreated'),
          status: 'success',
          dismissAfter: 4000,
        }),
      )
    },
    onError: (err) => {
      if (err.response?.data?.messages[0]?.message === 'ROLE_WITH_SUCH_NAME_ALREADY_EXIST') {
        return dispatch(
          notify({
            title: t('adminTeamManagement.roles.error.roleAlreadyExists'),
            status: 'error',
            dismissAfter: 4000,
          }),
        )
      }

      return message.error('Something went wrong')
    },
  })

  const updateRoleMutation = useMutation(updateRole, {
    onSuccess: async () => {
      return dispatch(
        notify({
          title: t('adminTeamManagement.roles.success.successfullyUpdated'),
          status: 'success',
          dismissAfter: 4000,
        }),
      )
    },
    onError: () => {
      return dispatch(
        notify({
          title: t('Something went wrong!'),
          status: 'error',
          dismissAfter: 4000,
        }),
      )
    },
  })

  const submitHandler = useCallback(
    async (formData) => {
      try {
        if (!roleId) {
          await createRoleMutation.mutateAsync(formData.roleName)
        } else {
          await updateRoleMutation.mutateAsync({
            id: roleId,
            data: { roleName: formData.roleName },
          })
        }

        onClose()
        onRoleSubmit()
      } catch (err) {
        console.log(err)
      }
    },
    [createRoleMutation, onClose, onRoleSubmit, roleId, updateRoleMutation],
  )

  return (
    <div style={{ width: '100%' }}>
      <FormProvider {...methods}>
        <form
          className="form"
          autoComplete="off"
          onSubmit={methods.handleSubmit(submitHandler)}
          defaultValue={methods.defaultValues}
        >
          <Row gutter={[24, 24]}>
            <Col span={24}>
              <HookedField
                label={t('fields.role name')}
                name="roleName"
                placeholder={t('fields.role name')}
                fieldType="textField"
                type="text"
                isRequired={true}
              />
            </Col>
          </Row>

          <Row gutter={[24, 24]}>
            <Col span={24}>
              <Button
                className="btn btn--normal btn--primary"
                type="primary"
                size="large"
                htmlType="submit"
                style={{ width: '100%' }}
                loading={createRoleMutation.isLoading || updateRoleMutation.isLoading}
              >
                {roleId ? t('Update role') : t('Create new role')}
              </Button>
            </Col>
          </Row>
        </form>
      </FormProvider>
    </div>
  )
}

export default RolesForm
