import moment from 'moment'

const parsePromocodeData = (formData) => {
  let parsedData = {}

  for (let field in formData) {
    switch (field) {
      case 'id':
      case 'defaultAwaitingRange':
      case 'defaultActiveRange':
      case 'defaultExpiredRange':
      case 'defaultNewRecord':
      case 'defaultInactive':
        break
      case 'productId':
        parsedData[field] = formData[field].indexOf('all') === -1 ? formData[field] : []
        break
      case 'startDate':
      case 'finishDate':
        parsedData[field] = parseInt(moment(formData[field]).format('x'))
        break
      default:
        parsedData[field] = formData[field]
        break
    }
  }

  return parsedData
}

export default parsePromocodeData
