import {
  GET_CUSTOMERS,
  GET_CUSTOMER,
  UPDATE_CUSTOMER,
  CREATE_CUSTOMER,
  DELETE_CUSTOMER,
  GET_CUSTOMERS_REQUEST_PENDING,
  GET_CUSTOMERS_REQUEST_SUCCESS,
  GET_CUSTOMERS_REQUEST_ERROR,
  CHANGE_CUSTOMERS_REQUEST_PENDING,
  CHANGE_CUSTOMERS_REQUEST_SUCCESS,
  CHANGE_CUSTOMERS_REQUEST_ERROR,
} from '../types/customers'

const initialState = {
  items: [],
  pagination: {
    current: 1,
    pageSize: 20,
  },
  item: {},
  isGetLoading: false,
  isChangeLoading: false,
  errors: [],
}

const customers = (state = initialState, action) => {
  switch (action.type) {
    case GET_CUSTOMERS:
      return { ...state, ...action.payload }
    case GET_CUSTOMER:
      return { ...state, item: action.payload }
    case UPDATE_CUSTOMER:
      const itemIndex = state.items.findIndex((item) => item.id === action.payload.id)
      const newItems = [...state.items]
      newItems[itemIndex] = { ...action.payload }
      return { ...state, items: newItems }
    case CREATE_CUSTOMER:
      return { ...state, items: [...state.items, action.payload] }
    case DELETE_CUSTOMER:
      return { ...state, items: state.items.filter((item) => item.id !== action.payload) }
    case GET_CUSTOMERS_REQUEST_PENDING:
      return { ...state, isGetLoading: true }
    case GET_CUSTOMERS_REQUEST_SUCCESS:
      return { ...state, errors: [], isGetLoading: false }
    case GET_CUSTOMERS_REQUEST_ERROR:
      return { ...state, errors: action.payload, isGetLoading: false }
    case CHANGE_CUSTOMERS_REQUEST_PENDING:
      return { ...state, isChangeLoading: true }
    case CHANGE_CUSTOMERS_REQUEST_SUCCESS:
      return { ...state, errors: [], isChangeLoading: false }
    case CHANGE_CUSTOMERS_REQUEST_ERROR:
      return { ...state, errors: action.payload, isChangeLoading: false }
    default:
      return state
  }
}

export default customers
