import axios from 'axios'

export const getRolesList = async () => {
  const response = await axios.get(`roles/list`)
  return response.data.data
}

export const getRoleById = async (roleId) => {
  const response = await axios.get(`roles/${roleId}`)
  return response.data.data
}

export const createRole = async (roleName) => {
  const body = {
    roleName,
    permissions: ['READ_DASHBOARD', 'READ_USERS', 'READ_FILES', 'DELETE_FILES', 'UPDATE_FILES'],
  }
  const response = await axios.post(`roles`, body)
  return response.data.data
}

export const updateRole = async ({ id, data }) => {
  const response = await axios.put(`roles/${id}`, data)
  return response.data.data
}

export const deleteRole = async (roleId) => {
  const response = await axios.delete(`roles/${roleId}`)
  return response.data.data
}

export const updateRolePermissions = async ({ roleId, permissionsArray }) => {
  const response = await axios.put(`roles/${roleId}`, {
    permissions: [...permissionsArray],
  })
  return response.data.data
}
