import {
  GET_USERS_REQUEST,
  UPDATE_USER_REQUEST,
  CREATE_USER_REQUEST,
  DELETE_USER_REQUEST,
} from '../types/users'

export function getUsers(payload) {
  return {
    type: GET_USERS_REQUEST,
    payload,
  }
}

export function updateUser(payload) {
  return {
    type: UPDATE_USER_REQUEST,
    payload,
  }
}

export function createUser(payload) {
  return {
    type: CREATE_USER_REQUEST,
    payload,
  }
}

export function deleteUser(payload) {
  return {
    type: DELETE_USER_REQUEST,
    payload,
  }
}
