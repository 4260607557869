import { useRef } from 'react'

import { useDrag, useDrop } from 'react-dnd'

import { DRAGGABLE_BODY_ROW } from '../utils/constants'

const DraggableBodyRow = ({ index, moveRow, className, style, ...restProps }) => {
  const ref = useRef()
  const [{ isOver, dropClassName }, drop] = useDrop({
    accept: DRAGGABLE_BODY_ROW,
    collect: (monitor) => {
      const { index: dragIndex } = monitor.getItem() || {}
      if (dragIndex === index) {
        return {}
      }
      return {
        isOver: monitor.isOver(),
        dropClassName: dragIndex < index ? ' drop-over-downward' : ' drop-over-upward',
      }
    },
    drop: (item) => {
      moveRow(item.index, index)
    },
  })
  const [, drag] = useDrag({
    type: DRAGGABLE_BODY_ROW,
    item: { index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  })
  drop(drag(ref))

  return (
    <tr
      ref={ref}
      className={`${className}${isOver ? dropClassName : ''}`}
      style={{ cursor: 'move', ...style }}
      {...restProps}
    />
  )
}

export default DraggableBodyRow
