import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getNotificationProducts } from 'redux/actions/usersNotifications'
import { getCoupon, deleteCoupon } from 'redux/actions/coupons'
import Page from 'components/Page'
import CouponsForm from 'components/forms/CouponsForm'
import { useNavigate, useParams } from 'react-router-dom'
import useClient from 'hooks/useClient'
import { PERMISSIONS } from 'utils/constants'

const CouponsView = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { isClientCan } = useClient()
  const { id } = useParams()
  const { item: coupon, isGetLoading, isChangeLoading } = useSelector((state) => state.coupons)
  const { products } = useSelector((state) => state.usersNotifications)

  // permissions for coupons
  const isClientCanDeleteCoupons = isClientCan(PERMISSIONS.DELETE_COUPONS)

  useEffect(() => {
    dispatch(getCoupon({ id }))
  }, [])

  const fetchProducts = (pageType) => {
    dispatch(getNotificationProducts({ pageType }))
  }

  useEffect(() => {
    fetchProducts('SKU_PAGE')
  }, [])

  const deleteHandler = (id) => {
    dispatch(
      deleteCoupon({
        id,
        navigate,
      }),
    )
  }

  return (
    <Page
      // pageTitle={product.title}
      isGetLoading={isGetLoading}
      isChangeLoading={isChangeLoading}
      deleteHandler={isClientCanDeleteCoupons ? () => deleteHandler(id) : null}
    >
      <CouponsForm defaultValues={coupon} loading={isGetLoading} products={products} />
    </Page>
  )
}

export default CouponsView
