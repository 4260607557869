import { loadingBarReducer } from 'react-redux-loading-bar'
import { reducer as notifsReducer } from 'reapop'

import auth from './auth'
import modal from './modal'
import customers from './customers'
import banners from './banners'
import categories from './categories'
import notifications from './notifications'
import orders from './orders'
import products from './products'
import promotions from './promotions'
import surveys from './surveys'
import users from './users'
import usersNotifications from './usersNotifications'
import payment from './payment'
import upselling from './upselling'
import coupons from './coupons'
import rating from './rating'
import mosques from './mosques'

const rootReducer = {
  auth,
  modal,
  customers,
  banners,
  categories,
  notifications,
  orders,
  products,
  promotions,
  surveys,
  users,
  usersNotifications,
  payment,
  upselling,
  coupons,
  rating,
  mosques,
  notifs: notifsReducer(),
  loadingBar: loadingBarReducer,
}

export default rootReducer
