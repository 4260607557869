import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from '@tanstack/react-query'
import moment from 'moment'
import { Col, DatePicker, Row, Select, Space, Typography } from 'antd'

import SortableColumnTitle from 'components/SortableColumnTitle'
import PageTable from 'components/PageTable'
import { getLogList } from '../../../api/logs'

import { getUsersList } from '../../../api/admin-users-managment'
import { CATALOG } from '../../../utils/constants'

const { Text } = Typography
const { RangePicker } = DatePicker

const Logs = () => {
  const { t } = useTranslation()
  const [pageSize, setPageSize] = useState(10)
  const [currentPage, setCurrentPage] = useState(1)
  const [sortType, setSortType] = useState('createdAt')
  const [sortOrder, setSortOrder] = useState('descend')
  const [userSearchString, setUserSearchString] = useState(null)
  const [filterByAdminId, setFilterByAdminId] = useState(null)
  const [filterByCatalog, setFilterByCatalog] = useState(null)
  const [startDate, setStartDate] = useState(moment().subtract(1, 'days').startOf('day'))
  const [finishDate, setFinishDate] = useState(moment())

  const { data: logs, isFetching } = useQuery(
    [
      'logs-list',
      currentPage,
      pageSize,
      sortType,
      sortOrder,
      filterByAdminId,
      filterByCatalog,
      startDate,
      finishDate,
    ],
    () => {
      const isoStartDate = startDate.toISOString()
      const isoFinishDate = finishDate.toISOString()

      return getLogList(
        currentPage,
        pageSize,
        sortType,
        sortOrder,
        filterByAdminId,
        filterByCatalog,
        isoStartDate,
        isoFinishDate,
      )
    },
  )

  const { data: usersList, isSuccess: isUsersFetched } = useQuery(
    ['admin-users-list'],
    async () => {
      return await getUsersList(null, null, null, null, userSearchString)
    },
  )

  const catalogOptions = Object.keys(CATALOG).map((catalog) => ({
    value: catalog,
    label: CATALOG[catalog],
  }))

  const tableChangeHandler = (pagination, filter, sorter) => {
    setCurrentPage(pageSize === pagination.pageSize ? pagination.current : 1)
    setPageSize(pagination.pageSize)
    setSortType(sorter.columnKey ?? 'time')
    setSortOrder(sorter.order ?? 'descend')
  }

  const handleUserSearch = (searchString) => {
    setUserSearchString(searchString)
  }

  const handleUserSelect = (selectedUserId) => {
    if (selectedUserId === '0') {
      setFilterByAdminId(null)
      return
    }

    setFilterByAdminId(selectedUserId)
  }

  const handleCatalogSelect = (selectedCatalog) => {
    if (selectedCatalog === '0') {
      setFilterByCatalog(null)
      return
    }

    setFilterByCatalog(selectedCatalog)
  }

  const handleDatePickerChange = (values) => {
    if (!values) return
    const [startDate, finishDate] = values

    setStartDate(startDate)
    setFinishDate(finishDate)
  }

  const columns = [
    {
      title: (props) => (
        <SortableColumnTitle
          {...props}
          title={t('adminTeamManagement.logs.table.time')}
          id="createdAt"
        />
      ),
      dataIndex: 'createdAt',
      defaultSortOrder: 'descend',
      key: 'createdAt',
      render: (createdAt) => <span>{moment(createdAt).format('MM/DD/YYYY hh:mm:ss')}</span>,
      sorter: true,
      width: 200,
    },
    {
      title: (props) => (
        <SortableColumnTitle
          {...props}
          title={t('adminTeamManagement.logs.table.author')}
          id="author"
        />
      ),
      dataIndex: 'admin',
      key: 'author',
      render: (admin) => <span>{admin.userName}</span>,
      sorter: true,
      width: 200,
    },
    {
      title: t('adminTeamManagement.logs.table.changePoint'),
      dataIndex: 'log',
      key: 'changePoint',
      render: (log) => <pre>{log}</pre>,
    },
    {
      title: (props) => (
        <SortableColumnTitle
          {...props}
          title={t('adminTeamManagement.logs.table.catalog')}
          id="catalog"
        />
      ),

      dataIndex: 'catalog',
      key: 'catalog',
      render: (catalog) => <span>{catalog}</span>,
      sorter: true,
    },
  ]
  const defaultUserSearchOptions = [
    {
      value: '0',
      label: 'All',
    },
  ]

  return (
    <PageTable
      pageTitle={t('adminTeamManagement.logs.title')}
      baseData={isFetching ? [] : logs.result}
      loading={isFetching}
      action={
        <Row gutter={20} style={{ padding: '0 10px' }} justify="space-between">
          <Col>
            <Text style={{ fontSize: 14 }}>{t('adminTeamManagement.logs.table.user')}:</Text>
            <Select
              bordered={false}
              dropdownMatchSelectWidth={false}
              onSearch={handleUserSearch}
              onSelect={handleUserSelect}
              options={
                isUsersFetched
                  ? defaultUserSearchOptions.concat(
                      usersList.result.map((user) => ({ value: user.id, label: user.userName })),
                    )
                  : defaultUserSearchOptions
              }
              optionFilterProp="children"
              showSearch
              style={{
                width: 50,
              }}
              placeholder="All"
              filterOption={(input, option) => (option?.label ?? '').includes(input)}
            />
          </Col>
          <Col>
            <Space>
              <Text style={{ fontSize: 14, paddingRight: '5px' }}>
                {t('adminTeamManagement.logs.table.date')}:
              </Text>
              <RangePicker
                bordered={false}
                onChange={handleDatePickerChange}
                style={{ padding: 0 }}
                size={'small'}
                value={[startDate, finishDate]}
              />
            </Space>
          </Col>
          <Col>
            <Text style={{ fontSize: 14 }}>
              {t('adminTeamManagement.logs.table.catalog')}:
              <Select
                bordered={false}
                dropdownMatchSelectWidth={false}
                onSelect={handleCatalogSelect}
                options={defaultUserSearchOptions.concat(catalogOptions)}
                style={{
                  width: 50,
                }}
                placeholder="All"
                optionFilterProp="children"
                filterSort={(optionA, optionB) =>
                  (optionA?.label ?? '')
                    .toLowerCase()
                    .localeCompare((optionB?.label ?? '').toLowerCase())
                }
              />
            </Text>
          </Col>
        </Row>
      }
      columns={columns}
      pagination={{
        pageSize,
        current: currentPage,
        total: logs?.total,
        showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
        showSizeChanger: logs?.total >= 10 || true,
      }}
      onChange={tableChangeHandler}
    />
  )
}

export default Logs
