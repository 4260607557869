import { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { FormProvider, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { useQuery, useMutation } from '@tanstack/react-query'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Col, Row } from 'antd'
import { notify } from 'reapop'
import moment from 'moment/moment'

import { CASHBACK_RULE_LABEL } from '../../../utils/constants'
import { cashbackSchema } from '../../../utils/schemas'
import { getProducts } from '../../../api/products'
import { createCashback, updateCashback } from '../../../api/cashback'

import HookedField from '../../HookedField'
import Button from '../../Button'

import styles from './CashbackForm.module.scss'

const CashbackForm = ({ cashback }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const { data: productsData, isLoading: isProductSelectLoading } = useQuery({
    queryKey: ['allProducts'],
    queryFn: async () => await getProducts(),
    cacheTime: 1000 * 60 * 60, // 2 hour
    staleTime: 1000 * 60 * 60, // 1 hour
  })

  const createCashbackMutation = useMutation(createCashback, {
    onSuccess: () => {
      navigate('/main/cashback')
      dispatch(notify({ title: t('notifs.success'), status: 'success', dismissAfter: 4000 }))
    },
    onError: (error) => {
      if (error?.response?.data?.messages?.length) {
        const errorMessage = error?.response?.data?.messages[0].message
        dispatch(
          notify({
            title: t(`cashback.error.${errorMessage}`),
            status: 'error',
            dismissAfter: 4000,
          }),
        )
      } else {
        dispatch(
          notify({
            title: t('notifs.error title'),
            status: 'error',
            dismissAfter: 4000,
          }),
        )
      }
    },
  })

  const updateCashbackMutation = useMutation(updateCashback, {
    onSuccess: () => {
      navigate('/main/cashback')
      dispatch(
        notify({
          title: t('cashback.messages.updated'),
          status: 'success',
          dismissAfter: 2000,
        }),
      )
    },
    onError: (error) => {
      if (error?.response?.data?.messages?.length) {
        const errorMessage = error?.response?.data?.messages[0].message
        dispatch(
          notify({
            title: t(`cashback.error.${errorMessage}`),
            status: 'error',
            dismissAfter: 4000,
          }),
        )
      } else {
        dispatch(
          notify({
            title: t('cashback.messages.updateFailed'),
            status: 'error',
            dismissAfter: 4000,
          }),
        )
      }
    },
  })

  const methods = useForm({
    mode: 'onSubmit',
    shouldFocusError: true,
    resolver: yupResolver(cashbackSchema),
  })

  const productOptions = useMemo(() => {
    if (isProductSelectLoading) return []

    return productsData.result.map((product) => ({ label: product.title, value: product.id }))
  }, [productsData, isProductSelectLoading])

  const submitHandler = useCallback(
    async (formData) => {
      const cashbackMutation = cashback ? updateCashbackMutation : createCashbackMutation

      await cashbackMutation.mutateAsync(formData)
    },
    [createCashbackMutation, updateCashbackMutation, cashback],
  )

  return (
    <div className={styles.cashbackForm}>
      <FormProvider {...methods}>
        <form className="form" autoComplete="off" onSubmit={methods.handleSubmit(submitHandler)}>
          {cashback && (
            <HookedField
              name="id"
              defaultValue={cashback.id}
              fieldType="hiddenField"
              type="hidden"
            />
          )}

          <Row>
            <Col span={12}>
              <HookedField
                name="name"
                fieldType="textField"
                type="text"
                disabled={false}
                label={t('cashback.form.name')}
                placeholder={t('cashback.form.namePlaceholder')}
                defaultValue={cashback?.name}
                isRequired
              />
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              <HookedField
                name="rule"
                fieldType="selectField"
                type="select"
                allowClear={false}
                disabled={false}
                defaultValue={cashback?.rule}
                isRequired
                label={t('cashback.form.rule')}
                placeholder={t('cashback.form.rulePlaceholder')}
                options={Object.entries(CASHBACK_RULE_LABEL).map(([value, label]) => ({
                  label,
                  value,
                }))}
              />
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              <HookedField
                name="productId"
                fieldType="selectField"
                type="select"
                defaultValue={cashback?.productId}
                label={t('cashback.form.product')}
                placeholder={t('cashback.form.productPlaceholder')}
                disabled={isProductSelectLoading}
                loading={isProductSelectLoading}
                isRequired
                options={productOptions}
              />
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              <HookedField
                name="cashbackAmount"
                fieldType="textField"
                type="text"
                label={t('cashback.form.cashbackAmount')}
                placeholder={t('cashback.form.cashbackAmountPlaceholder')}
                disabled={Boolean(cashback)}
                defaultValue={cashback?.cashbackAmount}
                isRequired
              />
            </Col>
          </Row>
          <Row gutter={[10, 0]}>
            <Col span={6}>
              <HookedField
                name="startDate"
                disabledDate={(current) => current && current < moment().startOf('day')}
                fieldType="dateTimeField"
                isRequired
                label={t('cashback.form.startDate')}
                placeholder={t('campaigns.form.startDatePlaceholder')}
                type="dateTimeField"
                disabled={false}
                defaultValue={cashback?.startDate}
              />
            </Col>
            <Col span={6}>
              <HookedField
                name="endDate"
                disabled={false}
                disabledDate={(current) => current && current < moment().startOf('day')}
                fieldType="dateTimeField"
                isRequired
                label={t('cashback.form.endDate')}
                placeholder={t('cashback.form.endDatePlaceholder')}
                type="dateTimeField"
                defaultValue={cashback?.endDate}
              />
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              <div className="form-visibility">
                <span className="form-visibility-text">{t('cashback.form.isActive')}</span>
                <HookedField
                  disabled={false}
                  className="form-visibility-switch"
                  name="isActive"
                  fieldType="customField"
                  type="switch"
                  defaultValue={cashback?.isActive}
                />
              </div>
            </Col>
          </Row>
          <Row className={styles.actions}>
            <Col span={12}>
              <Button
                className={styles.saveBtn}
                disabled={!methods.formState.isDirty}
                size="large"
                type="primary"
                htmlType="submit"
              >
                {t('app.save')}
              </Button>
            </Col>
          </Row>
        </form>
      </FormProvider>
    </div>
  )
}

export default CashbackForm
