import * as yup from 'yup'
import moment from 'moment'
import 'yup-phone'
import { DATE_TIME_FORMAT, PROMOCODE_TYPES } from 'utils/constants'
import { UPSELLING_RANDOM, UPSELLING_SELECTIVE } from 'components/forms/UpsellingForm'

export const PASSWORD_REGEXP = /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*\W)(?!.* ).{8,32}$/

export const signInSchema = yup.object().shape({
  email: yup.string().required('This field is required').email(),
  password: yup
    .string()
    .required('This field is required')
    .matches(/[0-9a-zA-Z=._@$!%*#?&]{8,256}/g, 'Must contain minimum 8 characters'),
})

export const customersSchema = yup.object().shape({
  nameEn: yup.string().required('This field is required'),
  nameAr: yup.string().required('This field is required'),
  email: yup.string().required('This field is required').email(),
  mobile: yup.string().required('This field is required').phone('UA', true, 'Invalid phone'),
  totalOrders: yup.number().typeError('It has to be a number').required('This field is required'),
  area: yup.string().required('This field is required'),
  lastOrder: yup.string().required('This field is required').typeError('This field is required'),
  address: yup.string().required('This field is required'),
})

export const productsSchema = yup.object().shape({
  title: yup.string().required('This field is required'),
  arabianTitle: yup.string().required('This field is required'),
  shortName: yup.string().required('This field is required'),
  shortNameArabian: yup.string().required('This field is required'),
  packName: yup.string().required('This field is required'),
  packNameArabian: yup.string().required('This field is required'),
  about: yup.string().required('This field is required'),
  isVisible: yup.bool().required('This field is required'),
  oldPrice: yup.number().notRequired().min(0).nullable(),
  volume: yup.number().required('This field is required').min(1).nullable(),
  productImage: yup.mixed(),
})

export const categoriesSchema = yup.object().shape({
  name: yup.string().required('This field is required'),
  arabianName: yup.string().required('This field is required'),
  isVisible: yup.bool().required('This field is required'),
  categoryImage: yup.mixed(),
})

export const userNotificationSchema = yup.object().shape({
  title: yup.string().required('This field is required'),
  landingPage: yup.string().required('This field is required'),
  url: yup.string().when('landingPage', {
    is: 'LINK',
    then: yup.string().url().required('This field is required').nullable(),
  }),
  product: yup.number().when('landingPage', (landingPage) => {
    if (landingPage === 'OFFER_PAGE' || landingPage === 'SKU_PAGE') {
      return yup.number().typeError('Select product please').required('This field is required')
    }
    return yup.number().nullable().notRequired()
  }),
  file: yup.string().nullable(),
  body: yup.string().required('This field is required'),
})

export const userNotificationSettingSchema = yup.object().shape({
  fields: yup.string().nullable(),
  types: yup.string().nullable(),
  number: yup.number().nullable(),
})

export const promocodeSchema = yup.object().shape({
  title: yup.string().required('This field is required').nullable(),
  promoCodeName: yup
    .string()
    .required('This field is required')
    .nullable()
    .min(3)
    .max(25)
    .matches(
      /^[a-km-zA-HJ-Z0-9\s_]+$/g,
      `Must contain only latin letters, except big "I" and small "l". Allowed spaces and underscore. Example "HOT summer ${moment().year()}"`,
    ),
  startDate: yup
    .date()
    .when(
      ['defaultActiveRange', 'defaultExpiredRange'],
      (defaultActiveRange, defaultExpiredRange) => {
        if (!defaultActiveRange && !defaultExpiredRange) {
          const nowMoment = moment().endOf('minute')

          return yup
            .date()
            .min(
              nowMoment,
              'Start date must be later than ' + moment(nowMoment).format(DATE_TIME_FORMAT),
            )
            .required('This field is required')
            .nullable()
        }

        return yup.date().required('This field is required').nullable()
      },
    ),
  finishDate: yup
    .date()
    .when(['startDate', 'defaultExpiredRange'], (startDate, defaultExpiredRange) => {
      if (!defaultExpiredRange) {
        const compareDate =
          startDate && moment().diff(moment(startDate)) < 0 // Must be bigger than now or than startDate
            ? moment(startDate)
            : moment().endOf('minute')

        return yup
          .date()
          .min(
            compareDate,
            'Finish date must be later than ' + moment(compareDate).format(DATE_TIME_FORMAT),
          )
          .required('This field is required')
          .nullable()
      }
    }),
  productId: yup.array().min(1).required('This field is required').nullable(),
  minOrderValue: yup.number().min(1).required('This field is required').nullable(),
  fullUsageCount: yup.number().min(1).required('This field is required').nullable(),
  userUsageCount: yup.number().when(['fullUsageCount'], (fullUsageCount) => {
    if (Number.isInteger(fullUsageCount) && fullUsageCount > 0) {
      return yup
        .number()
        .min(1)
        .max(
          fullUsageCount,
          "Max usage per user value can't be greater than max of promocode use value!",
        )
        .required('This field is required')
        .nullable()
    }

    return yup.number().min(1).required('This field is required').nullable()
  }),
  discountQuantity: yup.number().when('discountType', (discountType) => {
    switch (discountType) {
      case PROMOCODE_TYPES.PERCENT.KEY:
        return yup.number().min(0).max(100).required('This field is required').nullable()
      case PROMOCODE_TYPES.MONEY.KEY:
        return yup.number().min(0).required('This field is required').nullable()
      default:
        return yup.number().min(0).required('This field is required')
    }
  }),
})

export const upsellingSchema = yup.object().shape({
  isEnabled: yup.boolean().required(),
  type: yup.string().required('Select type').nullable(),
  randomItems: yup.array().when(['type'], (type) => {
    if (type === UPSELLING_RANDOM) {
      return yup.array().of(
        yup.object().shape({
          productId: yup.number().required('Select product').nullable(),
          message: yup.string().required('Enter message').nullable(),
        }),
      )
    }
  }),
  selectiveItem: yup.object().when(['type'], (type) => {
    if (type === UPSELLING_SELECTIVE) {
      return yup.object().shape({
        productId: yup.number().required('Select product').nullable(),
        message: yup.string().required('Enter message').nullable(),
      })
    }
  }),
})

export const flyerSchema = yup.object().shape({
  productId: yup.number().required().nullable(),
  validFrom: yup.date().notRequired().nullable(),
  validTo: yup.date().when(['validFrom'], (validFrom) => {
    if (validFrom) {
      return yup
        .date()
        .min(
          moment(validFrom).add(1, 'minute'),
          'Valid to date must be later than ' +
            moment(validFrom).add(1, 'minute').format(DATE_TIME_FORMAT),
        )
        .required('This field is required')
        .nullable()
    }

    return yup.date().notRequired().nullable()
  }),
  oldPrice: yup.number().notRequired().nullable(),
  customImage: yup.mixed().required(),
})

export const rolesSchema = yup.object().shape({
  roleName: yup.string().required('This field is required'),
})

export const editWalletSchema = yup.object().shape({
  walletAmount: yup
    .number()
    .typeError("Invalid input format. Please enter a numeric value using a dot, like '100.11'")
    .required('This field is required')
    .positive('Must be a positive value')
    .min(0, 'Must be greater than or equal to 0')
    .max(100000, 'Must be less than 100 000'),
})

export const campaignSchema = yup.object().shape({
  campaignName: yup
    .string()
    .min(3, 'Campaign name must be at least 3 characters')
    .max(255)
    .required('This field is required'),
  bonusAmount: yup
    .number()
    .typeError("Invalid input format. Please enter a numeric value using a dot, like '100.11'")
    .required('This field is required')
    .positive('Must be a positive value')
    .min(0, 'Must be greater than or equal to 0')
    .max(100000, 'Must be less than 100 000'),
  startDate: yup.date().required('This field is required'),
  finishDate: yup
    .date()
    .required('This field is required')
    .min(
      yup.ref('startDate'),
      'Campaign cannot end before it started. Please select another date and time.',
    ),
})

export const cashbackSchema = yup.object().shape({
  name: yup
    .string()
    .min(3, 'Cashback name must be at least 3 characters')
    .max(255)
    .required('This field is required'),
  rule: yup.string().required('This field is required'),
  productId: yup.number().required('This field is required'),
  cashbackAmount: yup
    .number()
    .typeError("Invalid input format. Please enter a numeric value using a dot, like '100.11'")
    .required('This field is required')
    .positive('Must be a positive value')
    .min(0, 'Must be greater than or equal to 0')
    .max(100000, 'Must be less than 100 000'),
  startDate: yup.date().required('This field is required'),
  endDate: yup
    .date()
    .required('This field is required')
    .min(
      yup.ref('startDate'),
      'Cashback duration cannot end before it started. Please select another date and time.',
    ),
})

export const resetPasswordSchema = yup.object().shape({
  password: yup
    .string()
    .min(3)
    .max(32)
    .required('This field is required')
    .matches(
      PASSWORD_REGEXP,
      'Must contain at least one digit, at least one lowercase letter, at least one uppercase letter, at least one special character',
    ),

  confirmPassword: yup.string().oneOf([yup.ref('password')], 'Passwords must match'),
})
