import classNames from 'classnames'
import PropTypes from 'prop-types'
import './styles.scss'

const DashboardChartWrap = ({ children, type, fullHeight, ...restProps }) => {
  const classes = classNames({
    dashboardChartWrap: true,
    [`dashboardChartWrap--${type}`]: type,
    [`dashboardChartWrap--fullHeight`]: fullHeight,
  })

  return (
    <div className={classes} {...restProps}>
      {children}
    </div>
  )
}

DashboardChartWrap.propTypes = {
  children: PropTypes.node,
  type: PropTypes.oneOf(['default', 'secondary']),
  fullHeight: PropTypes.bool,
}

DashboardChartWrap.defaultProps = {
  type: 'default',
  fullHeight: false,
}

export default DashboardChartWrap
