import axios from 'axios'

class UsersNotificationsService {
  getUsersNotifications = (page, size) =>
    axios({
      url: 'notifications',
      method: 'GET',
      params: { page, take: size },
    })

  updateUserNotifications = (id, data) =>
    axios({
      url: `notifications/${id}`,
      method: 'PATCH',
      data,
    })

  createUsersNotifications = (data) =>
    axios({
      url: 'notifications',
      method: 'POST',
      data,
    })

  deleteUsersNotifications = (id) =>
    axios({
      url: `notifications/${id}`,
      method: 'DELETE',
    })

  sendUsersNotification = (id, data, env) =>
    axios({
      url: `notifications/send/${id}?env=${env}`,
      method: 'POST',
      data,
    })

  getUsersNotificationById = (id) =>
    axios({
      url: `notifications/${id}`,
      method: 'GET',
    })

  getNotificationOffersId = () =>
    axios({
      url: 'categories/sap/offers',
      method: 'GET',
    })

  getNotificationSkuProducts = () =>
    axios({
      url: 'products',
      method: 'GET',
    })

  getNotificationOfferProducts = (id) =>
    axios({
      url: 'products',
      method: 'GET',
      params: { ...(id && { categoryId: id }) },
    })

  getNotificationDeliveryAreas = () =>
    axios({
      url: 'delivery-area',
      method: 'GET',
    })
}
export default new UsersNotificationsService()
