import axios from 'axios'

export const getUserTransactions = async (userId, params) => {
  const { currentPage, pageSize, sortType, sortOrder, transactionType } = params

  const response = await axios({
    url: `wallet-transactions/users/${userId}/list`,
    method: 'GET',
    params: {
      page: currentPage,
      take: pageSize,
      asc: sortType && sortOrder === 'ascend' ? sortType : null,
      desc: sortType && sortOrder === 'descend' ? sortType : null,
      transactionTypeFilter: transactionType,
    },
  })

  return response.data.data
}

export const getUserWallet = async (userId) => {
  const response = await axios.get(`wallet/user/${userId}`)
  return response.data.data
}

export const editWalletAmount = async ({ userId, data }) => {
  const response = await axios.post(`wallet-transactions/user/${userId}/wallet`, data)

  return response.data.data
}
