import { useCallback, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useQuery, useMutation } from '@tanstack/react-query'
import { Popconfirm, Tooltip } from 'antd'
import { notify } from 'reapop'
import { useDispatch } from 'react-redux'
import moment from 'moment'

import { deleteCampaign, getCampaignList, updateCampaign } from '../../../api/campaigns'
import { DATE_TIME_FORMAT, ICONS, PERMISSIONS } from '../../../utils/constants'

import useClient from '../../../hooks/useClient'

import PageTable from '../../../components/PageTable'
import Icon from '../../../components/Icon'
import Button from '../../../components/Button'
import SortableColumnTitle from '../../../components/SortableColumnTitle'

import styles from './Campaigns.module.scss'

const Campaigns = () => {
  const { t } = useTranslation()
  const { isClientCan } = useClient()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [currentPage, setCurrentPage] = useState(1)
  const [pageSize, setPageSize] = useState(20)
  const [sortType, setSortType] = useState('startDate')
  const [sortOrder, setSortOrder] = useState('descend')

  const isClientCanCreateCampaigns = isClientCan(PERMISSIONS.CREATE_CAMPAIGN)
  const isClientCanDeleteCampaigns = isClientCan(PERMISSIONS.DELETE_CAMPAIGN)
  const isClientCanUpdateCampaigns = isClientCan(PERMISSIONS.UPDATE_CAMPAIGN)

  const {
    data: campaignList,
    isFetching,
    refetch: refetchCampaigns,
  } = useQuery(
    ['campaign-list', currentPage, pageSize, sortType, sortOrder],
    async () =>
      await getCampaignList({
        currentPage,
        pageSize,
        sortType,
        sortOrder,
      }),
  )

  const updateCampaignMutation = useMutation(updateCampaign, {
    onSuccess: () => {
      dispatch(
        notify({
          title: t('campaigns.messages.updated'),
          status: 'success',
          dismissAfter: 2000,
        }),
      )
    },
    onError: (error) => {
      if (error?.response?.data?.messages?.length) {
        const errorMessage = error?.response?.data?.messages[0].message
        dispatch(
          notify({
            title: t(`campaigns.error.${errorMessage}`),
            status: 'error',
            dismissAfter: 4000,
          }),
        )
      } else {
        dispatch(
          notify({
            title: t('campaigns.messages.updateFailed'),
            status: 'error',
            dismissAfter: 4000,
          }),
        )
      }
    },
  })

  const deleteCampaignMutation = useMutation(deleteCampaign, {
    onSuccess: async () => {
      await refetchCampaigns()

      dispatch(
        notify({
          title: t('campaigns.messages.deleted'),
          status: 'success',
          dismissAfter: 2000,
        }),
      )
    },
    onError: () => {
      dispatch(
        notify({
          title: t('campaigns.messages.deleteFailed'),
          status: 'error',
          dismissAfter: 4000,
        }),
      )
    },
  })

  const toggleCampaign = useCallback(
    async (campaign) => {
      if (updateCampaignMutation.isLoading) {
        return false
      }

      await updateCampaignMutation.mutateAsync({
        id: campaign.id,
        isActive: !campaign.isActive,
      })

      await refetchCampaigns()
    },
    [updateCampaignMutation, refetchCampaigns],
  )

  const handleDeleteCampaign = async (event, campaignId) => {
    event.stopPropagation()

    await deleteCampaignMutation.mutate(campaignId)
  }

  const handleToggleCampaign = async (event, campaign) => {
    event.stopPropagation()

    await toggleCampaign(campaign)
  }

  const tableChangeHandler = (pagination, filter, sorter) => {
    setCurrentPage(pageSize === pagination.pageSize ? pagination.current : 1)
    setPageSize(pagination.pageSize)
    setSortType(sorter.columnKey || null)
    setSortOrder(sorter.order)
  }

  const columns = useMemo(() => {
    return [
      {
        title: '№',
        dataIndex: 'id',
        key: 'id',
        align: 'center',
        width: '30px',
        render: (_, record, index) => index + 1,
      },
      {
        title: (props) => (
          <SortableColumnTitle {...props} title={t('campaigns.table.name')} id="campaignName" />
        ),
        dataIndex: 'campaignName',
        key: 'campaignName',
        sorter: true,
        width: 250,
      },
      {
        title: (props) => (
          <SortableColumnTitle {...props} title={t('campaigns.table.amount')} id="bonusAmount" />
        ),
        dataIndex: 'bonusAmount',
        key: 'bonusAmount',
        align: 'right',
        width: 100,
        sorter: true,
      },
      {
        title: t('campaigns.table.totalInstalled'),
        dataIndex: 'installationsCount',
        key: 'installationsCount',
        align: 'right',
        width: 250,
      },
      {
        title: (props) => (
          <SortableColumnTitle {...props} title={t('campaigns.table.startDate')} id="startDate" />
        ),
        dataIndex: 'startDate',
        key: 'startDate',
        render: (startDate) => (startDate ? moment(startDate).format(DATE_TIME_FORMAT) : '-'),
        sorter: true,
      },
      {
        title: (props) => (
          <SortableColumnTitle {...props} title={t('campaigns.table.endDate')} id="finishDate" />
        ),
        dataIndex: 'finishDate',
        key: 'finishDate',
        render: (finishDate) => (finishDate ? moment(finishDate).format(DATE_TIME_FORMAT) : '-'),
        sorter: true,
      },
      {
        title: t('campaigns.table.action'),
        dataIndex: 'actions',
        key: 'actions',
        render: (_, record) => {
          return (
            <div className={styles.actionsRow}>
              {isClientCanUpdateCampaigns && (
                <Tooltip
                  placement="topRight"
                  title={record.isActive ? t('app.hide') : t('app.show')}
                >
                  <Icon
                    className={styles.isActiveCampaignIcon}
                    icon={record.isActive ? ICONS.VISIBLE : ICONS.INVISIBLE}
                    size={20}
                    onClick={(event) => handleToggleCampaign(event, record)}
                  />
                </Tooltip>
              )}

              {isClientCanDeleteCampaigns && (
                <Popconfirm
                  title={'Are you sure?'}
                  onConfirm={(e) => handleDeleteCampaign(e, record.id)}
                  onCancel={(e) => e.stopPropagation()}
                  okText="Delete"
                  okType="danger"
                  cancelText="No"
                >
                  <Icon
                    className={styles.deleteCampaignIcon}
                    icon={ICONS.TRASH}
                    size={20}
                    onClick={(e) => e.stopPropagation()}
                  />
                </Popconfirm>
              )}
            </div>
          )
        },
      },
    ]
  }, [t, isClientCanUpdateCampaigns, isClientCanDeleteCampaigns])

  return (
    <PageTable
      action={
        isClientCanCreateCampaigns && (
          <Button type="primary" onClick={() => navigate(`/main/campaigns/add`)}>
            <Icon icon={ICONS.PLUS} className="btn-icon-suffix" />
            <span className="btn-text">{t('campaigns.newCampaign')}</span>
          </Button>
        )
      }
      baseData={campaignList?.result || []}
      columns={columns}
      loading={isFetching}
      onChange={tableChangeHandler}
      onRow={
        isClientCanUpdateCampaigns
          ? (record) => ({
              onClick: () => navigate(`/main/campaigns/${record.id}`),
            })
          : null
      }
      pageTitle={t('campaigns.title')}
      pagination={{
        pageSize,
        current: currentPage,
        total: campaignList?.total,
        showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
        showSizeChanger: campaignList?.total >= 10 || true,
      }}
    />
  )
}

export default Campaigns
