import { Skeleton } from 'antd'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { useCallback } from 'react'
import { ICONS } from 'utils/constants'
import Button from './Button'
import Icon from './Icon'

const PageNoBox = ({
  pageTitle,
  withCancelBtn,
  duplicateHandler,
  deleteHandler,
  isGetLoading,
  isChangeLoading,
  children,
}) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const handleDuplicate = useCallback(() => duplicateHandler('id'), [duplicateHandler])
  const handleDelete = useCallback(() => deleteHandler('id'), [deleteHandler])

  return (
    <div className="page">
      <div className="page-header">
        {isGetLoading ? (
          <Skeleton.Input style={{ width: 200 }} active={true} size="medium" />
        ) : (
          <h3
            className="page-title"
            onClick={() => {
              navigate(-1)
            }}
          >
            <Icon icon={ICONS.ARROW_LEFT} />
            <span>{pageTitle}</span>
          </h3>
        )}

        <div className="page-header-actions">
          {duplicateHandler &&
            (isGetLoading ? (
              <Skeleton.Button style={{ width: 120 }} active={true} size="medium" />
            ) : (
              <Button disabled={isChangeLoading} type="simple" size="small" onClick={handleDelete}>
                <Icon icon={ICONS.DUPLICATE} size={16} />
                <span>{t('app.duplicate')}</span>
              </Button>
            ))}
          {deleteHandler &&
            (isGetLoading ? (
              <Skeleton.Button style={{ width: 120, marginLeft: 24 }} active={true} size="medium" />
            ) : (
              <Button
                disabled={isChangeLoading}
                type="simple"
                size="small"
                onClick={handleDuplicate}
              >
                <Icon icon={ICONS.DELETE} size={16} />
                <span>{t('app.delete')}</span>
              </Button>
            ))}
          {withCancelBtn && (
            <Button type="primary" onClick={navigate.goBack}>
              <span className="btn-text">{t('app.cancel')}</span>
            </Button>
          )}
        </div>
      </div>
      {children}
    </div>
  )
}

export default PageNoBox
