import { Modal } from 'antd'
import RolesForm from '../../../forms/RolesForm/RolesForm'

const RoleModalForm = ({ title, isVisible, onCancel, roleId, onRoleSubmit }) => {
  return (
    <Modal
      open={isVisible}
      onCancel={onCancel}
      title={title}
      footer={null}
      centered={true}
      destroyOnClose={true}
      width={550}
      closable={true}
    >
      <RolesForm roleId={roleId} onClose={onCancel} onRoleSubmit={onRoleSubmit} />
    </Modal>
  )
}

export default RoleModalForm
