import { useTranslation } from 'react-i18next'

import { FormProvider, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'

import { productsSchema } from 'utils/schemas'
import { ICONS } from '../../utils/constants'

import HookedField from 'components/HookedField'
import Button from '../Button'
import Icon from '../Icon'
import ProductImageUploader from '../UserImageUploader'

const ProductForm = ({ defaultValues = {}, submitHandler, loading }) => {
  const { t } = useTranslation()

  const methods = useForm({
    mode: 'onSubmit',
    shouldFocusError: true,
    resolver: yupResolver(productsSchema),
  })

  return (
    <div className="productForm">
      <FormProvider {...methods}>
        <form className="form" autoComplete="off" onSubmit={methods.handleSubmit(submitHandler)}>
          <fieldset className="form-fieldset">
            <div className="form-block">
              <div className="form-imgWrapper">
                <ProductImageUploader
                  methods={methods}
                  formInputName={'productImage'}
                  image={defaultValues?.image}
                />
              </div>
              <div className="form-meta">
                <div className="form-title">{t('fields.visibility')}</div>
                <div className="form-caption">{t('products.caption')}</div>
                <div className="form-visibility">
                  <Icon className="form-visibility-icon" icon={ICONS.EYE} size={24} />
                  <span className="form-visibility-text">{t('products.switch')}</span>
                  <HookedField
                    className="form-visibility-switch"
                    name="isVisible"
                    defaultValue={defaultValues?.isVisible}
                    fieldType="customField"
                    type="switch"
                  />
                </div>
              </div>
            </div>
          </fieldset>
          <fieldset className="form-fieldset">
            <div className="form-title">{t('orders.general info')}</div>
            <div className="form-grid">
              {defaultValues?.sapId && (
                <HookedField
                  label={t('fields.sap id')}
                  name="sapId"
                  disabled={true}
                  defaultValue={defaultValues.sapId}
                  placeholder="id"
                  fieldType="textField"
                  type="text"
                />
              )}
              <HookedField
                label={t('fields.category')}
                name="category"
                defaultValue={defaultValues?.category?.name || ''}
                placeholder={t('fields.category')}
                fieldType="textField"
                type="text"
                disabled={true}
              />
              <HookedField
                label={t('fields.name en')}
                name="title"
                defaultValue={defaultValues?.title || ''}
                placeholder={t('fields.name en')}
                fieldType="textField"
                type="text"
                isRequired={true}
              />
              <HookedField
                label={t('fields.name ar')}
                name="arabianTitle"
                defaultValue={defaultValues?.arabianTitle || ''}
                placeholder={t('fields.name ar')}
                fieldType="textField"
                type="text"
                dir="rtl"
                isRequired={true}
              />
              <HookedField
                label={t('fields.shortname en')}
                name="shortName"
                defaultValue={defaultValues?.shortName || ''}
                placeholder={t('fields.shortname ar')}
                fieldType="textField"
                type="text"
                isRequired={true}
              />
              <HookedField
                label={t('fields.shortname ar')}
                name="shortNameArabian"
                defaultValue={defaultValues?.shortNameArabian || ''}
                placeholder={t('fields.shortname ar')}
                fieldType="textField"
                type="text"
                dir="rtl"
                isRequired={true}
              />
              <HookedField
                label={t('fields.pack name en')}
                name="packName"
                defaultValue={defaultValues?.packName || ''}
                placeholder={t('fields.pack name en')}
                fieldType="textField"
                type="text"
                isRequired={true}
              />
              <HookedField
                label={t('fields.pack name ar')}
                name="packNameArabian"
                defaultValue={defaultValues?.packNameArabian || ''}
                placeholder={t('fields.pack name ar')}
                fieldType="textField"
                type="text"
                dir="rtl"
                isRequired={true}
              />
              <HookedField
                label={t('fields.about en')}
                name="about"
                defaultValue={defaultValues?.about}
                placeholder={t('fields.about en')}
                fieldType="textField"
                type="textarea"
                isRequired={true}
              />
              <HookedField
                label={t('fields.about ar')}
                name="aboutArabian"
                defaultValue={defaultValues?.aboutArabian}
                placeholder={t('fields.about ar')}
                fieldType="textField"
                type="textarea"
                dir="rtl"
                isRequired={true}
              />
              <HookedField
                label={t('fields.price')}
                name="currentPrice"
                defaultValue={defaultValues?.currentPrice || 0}
                placeholder={t('fields.price')}
                fieldType="textField"
                type="text"
                disabled={true}
              />
              <HookedField
                label={t('fields.old price')}
                name="oldPrice"
                defaultValue={defaultValues?.oldPrice || null}
                placeholder={t('fields.set old price')}
                fieldType="numberField"
                type="number"
                min={0}
              />
              <HookedField
                label={t('fields.volume')}
                name="volume"
                defaultValue={defaultValues?.volume || 1}
                placeholder={t('fields.set product volume')}
                fieldType="numberField"
                type="number"
                min={1}
                isRequired={true}
              />
            </div>
          </fieldset>
          <div className="form-grid">
            <Button
              className="save-btn"
              size="large"
              type="primary"
              htmlType="submit"
              disabled={loading || !methods.formState.isDirty}
            >
              {t('app.save')}
            </Button>
          </div>
        </form>
      </FormProvider>
    </div>
  )
}

export default ProductForm
