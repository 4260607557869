import { useTranslation } from 'react-i18next'

import Page from './Page'

const ComingSoonContent = () => {
  const { t } = useTranslation()

  return (
    <Page pageTitle={t('comingSoon.title')}>
      <div className="serviceText">
        <h3>{t('comingSoon.title')}...</h3>
        <p>{t('comingSoon.text')}</p>
      </div>
    </Page>
  )
}

export default ComingSoonContent
