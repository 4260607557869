import axios from 'axios'

class PromotionsService {
  getPromotions = (page, size) =>
    axios({
      url: 'promotions',
      method: 'GET',
      params: {
        page,
        take: size,
      },
    })

  getSupPromotions = () =>
    axios({
      url: 'promotions/pull',
      method: 'GET',
    })

  getPromotionById = (id) =>
    axios({
      url: `promotions/${id}`,
      method: 'GET',
    })

  updatePromotion = (id, data) =>
    axios({
      url: `/promotions/${id}`,
      method: 'PUT',
      data,
    })
}

export default new PromotionsService()
