import { useTranslation } from 'react-i18next'
import { useState } from 'react'
import { useQuery } from '@tanstack/react-query'
import { useChartsDates } from './hooks/useChartsDates'
import DashboardPeriodOffsetChanger from 'components/Dashboard/PeriodOffsetChanger/DashboardPeriodOffsetChanger'
import DashboardPeriodChanger from 'components/Dashboard/PeriodChanger/DashboardPeriodChanger'
import PreloaderBlock from 'components/PreloaderBlock/PreloaderBlock'
import { DASHBOARD_PERIODS } from 'utils/constants'
import DashboardService from 'redux/middlewares/dashboard'
import baseStyles from './styles/base.module.scss'

const MoreOneOrdersChart = ({ globalStartDate, globalFinishDate }) => {
  const { t } = useTranslation()
  const [datePeriod, setDatePeriod] = useState(DASHBOARD_PERIODS.MONTH)
  const [periodOffset, setPeriodOffset] = useState(0)
  const { startDate, finishDate } = useChartsDates({
    globalStartDate: globalStartDate,
    globalFinishDate: globalFinishDate,
    period: datePeriod,
    offset: periodOffset,
  })

  const { data: ordersMoreOneStats, isFetching: isOrdersMoreOneStatsFetching } = useQuery(
    ['dashboard-orders-more-one', startDate, finishDate],
    async () => {
      const response = await DashboardService.getOrdersMoreOneStats({
        startDate: startDate.toISOString(),
        finishDate: finishDate.toISOString(),
      })

      return response.data.data
    },
    {
      staleTime: 1000 * 60 * 10, // 10 minute
      refetchOnMount: 'always',
    },
  )

  return (
    <div className={baseStyles.chart}>
      <div className={baseStyles.chart__header}>
        <div className={baseStyles.chart__headerTop}>
          <p className={baseStyles.chart__headerTitle}>
            {t('dashboard.users who placed 1 or more orders')}
          </p>
          <DashboardPeriodChanger
            periods={[
              DASHBOARD_PERIODS.DAY,
              DASHBOARD_PERIODS.WEEK,
              DASHBOARD_PERIODS.MONTH,
              DASHBOARD_PERIODS.YEAR,
              DASHBOARD_PERIODS.CUSTOM_DATE,
            ]}
            defaultPeriod={datePeriod}
            onChange={(period) => setDatePeriod(period)}
          />
        </div>
      </div>
      <div className={baseStyles.chart__body}>
        {!isOrdersMoreOneStatsFetching ? (
          <div className="dashboard__textRowWrap dashboard__textRowWrap_leftAlign">
            <p className="dashboard__fatText">{ordersMoreOneStats}</p>
          </div>
        ) : (
          <PreloaderBlock height={40} size={'small'} />
        )}
      </div>
      <div className={baseStyles.chart__footer}>
        <div className={baseStyles.chart__offsetChangerWrap}>
          <DashboardPeriodOffsetChanger
            startDate={globalStartDate}
            finishDate={globalFinishDate}
            period={datePeriod}
            disabled={datePeriod === DASHBOARD_PERIODS.CUSTOM_DATE || isOrdersMoreOneStatsFetching}
            changeOffsetCallback={(newOffset) => {
              setPeriodOffset(newOffset)
            }}
          />
        </div>
      </div>
    </div>
  )
}

export default MoreOneOrdersChart
