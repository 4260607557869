import {
  GET_CATEGORIES,
  GET_CATEGORY,
  UPDATE_CATEGORY,
  CREATE_CATEGORY,
  DELETE_CATEGORY,
  GET_CATEGORIES_REQUEST_PENDING,
  GET_CATEGORIES_REQUEST_SUCCESS,
  GET_CATEGORIES_REQUEST_ERROR,
  CHANGE_CATEGORIES_REQUEST_PENDING,
  CHANGE_CATEGORIES_REQUEST_SUCCESS,
  CHANGE_CATEGORIES_REQUEST_ERROR,
} from '../types/categories'

const initialState = {
  items: [],
  pagination: {
    current: 1,
    pageSize: 10,
  },
  item: {},
  isGetLoading: false,
  isChangeLoading: false,
  errors: [],
}

const categories = (state = initialState, action) => {
  switch (action.type) {
    case GET_CATEGORIES:
      return { ...state, ...action.payload }
    case GET_CATEGORY:
      return { ...state, item: action.payload }
    case UPDATE_CATEGORY:
      const itemIndex = state.items.findIndex((item) => item.id === action.payload.id)
      const newItems = [...state.items]
      newItems[itemIndex] = { ...action.payload }
      return { ...state, items: newItems }
    case CREATE_CATEGORY:
      return { ...state, items: [...state.items, action.payload] }
    case DELETE_CATEGORY:
      return { ...state, items: state.items.filter((item) => item.id !== action.payload) }
    case GET_CATEGORIES_REQUEST_PENDING:
      return { ...state, isGetLoading: true }
    case GET_CATEGORIES_REQUEST_SUCCESS:
      return { ...state, errors: [], isGetLoading: false }
    case GET_CATEGORIES_REQUEST_ERROR:
      return { ...state, errors: action.payload, isGetLoading: false }
    case CHANGE_CATEGORIES_REQUEST_PENDING:
      return { ...state, isChangeLoading: true }
    case CHANGE_CATEGORIES_REQUEST_SUCCESS:
      return { ...state, errors: [], isChangeLoading: false }
    case CHANGE_CATEGORIES_REQUEST_ERROR:
      return { ...state, errors: action.payload, isChangeLoading: false }
    default:
      return state
  }
}

export default categories
