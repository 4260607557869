import { notify } from 'reapop'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import PromocodeForm from 'components/forms/PromocodeForm'
import Page from 'components/Page'
import { addPromocode } from 'api/promocodes'
import parsePromocodeData from 'helpers/promocodes/parsePromocodeData'
import postErrorHandler from 'helpers/promocodes/postErrorHandler'

const PromocodesAdd = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const queryClient = useQueryClient()

  const addPromocodeMutation = useMutation(addPromocode, {
    onSuccess: () => {
      queryClient.invalidateQueries(['promocodesTable'])
      dispatch(notify({ title: t('notifs.success'), status: 'success', dismissAfter: 4000 }))
      navigate('/main/promocodes')
    },
    onError: (error, variables) => postErrorHandler(error, dispatch, variables),
  })

  const submitHandler = (formData) => addPromocodeMutation.mutate(parsePromocodeData(formData))

  return (
    <Page pageTitle={t('promocodes.new promocode')}>
      <PromocodeForm submitHandler={submitHandler} submitLoading={addPromocodeMutation.isLoading} />
    </Page>
  )
}

export default PromocodesAdd
