import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import useClient from 'hooks/useClient'
import { getCoupons } from '../../../redux/actions/coupons'
import { sortByAlphabet } from '../../../utils/sorters'
import SortableColumnTitle from '../../../components/SortableColumnTitle'
import PageTable from '../../../components/PageTable'
import Button from '../../../components/Button'
import Icon from '../../../components/Icon'
import { ICONS, PERMISSIONS } from '../../../utils/constants'

const Coupons = () => {
  const { t } = useTranslation()
  const { isClientCan } = useClient()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { items, pagination, isGetLoading } = useSelector((state) => state.coupons)

  // permissions for coupons
  const isClientCanCreateCoupons = isClientCan(PERMISSIONS.CREATE_COUPONS)

  const refreshCoupons = (page, size) => {
    dispatch(
      getCoupons({
        page,
        size,
      }),
    )
  }

  useEffect(() => {
    if (!items.length) {
      refreshCoupons(pagination.current, pagination.pageSize)
    }
  }, [])

  const columns = [
    {
      title: '№',
      dataIndex: 'id',
      render: (text, record, index) => index + 1,
      key: 'id',
      align: 'center',
      width: '5%',
    },
    {
      title: (props) => (
        <SortableColumnTitle {...props} title={t('fields.name en')} id="products" />
      ),
      dataIndex: ['product', 'title'],
      key: 'product',
      sorter: (a, b) => sortByAlphabet(a.product, b.product),
    },
  ]
  const tableChangeHandler = (pagination) => {
    refreshCoupons(pagination.current, pagination.pageSize)
  }

  return (
    <PageTable
      pageTitle={t('coupons.title')}
      action={
        isClientCanCreateCoupons ? (
          <Button type="primary" onClick={() => navigate(`/main/coupons/add`)}>
            <Icon icon={ICONS.PLUS} className="btn-icon-suffix" />
            <span className="btn-text">{t('coupons.add button')}</span>
          </Button>
        ) : null
      }
      baseData={items}
      columns={columns}
      onRow={(record) => {
        return {
          onClick: () => {
            navigate(`/main/coupons/view/${record.id}`)
          },
        }
      }}
      loading={isGetLoading}
      pagination={{
        ...pagination,
        showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
        showSizeChanger: pagination.total >= 10,
      }}
      onChange={tableChangeHandler}
    />
  )
}

export default Coupons
