import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { createPromotion } from '../../../redux/actions/promotions'
import { useNavigate } from 'react-router-dom'
import Page from '../../../components/Page'
import PromotionForm from '../../../components/forms/PromotionsForm'

const PromotionsAdd = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { t } = useTranslation()

  const { isChangeLoading } = useSelector((state) => state.promotions)

  const submitHandler = (data) => {
    dispatch(
      createPromotion({
        data,
        navigate,
      }),
    )
  }

  return (
    <Page pageTitle={t('promotions.new promotion')} withCancelBtn={true}>
      <PromotionForm submitHandler={submitHandler} loading={isChangeLoading} />
    </Page>
  )
}

export default PromotionsAdd
