import { SHOW_MODAL, HIDE_MODAL, PREV_MODAL } from '../types/modal'

const initialState = {
  isOpened: false,
  dataArray: [],
}

const modal = (state = initialState, action) => {
  switch (action.type) {
    case SHOW_MODAL:
      return {
        ...state,
        isOpened: true,
        dataArray: [...state.dataArray, action.payload],
      }
    case PREV_MODAL:
      const newDataArray = state.dataArray.slice(0, state.dataArray.length - 1)
      return {
        ...state,
        isOpened: !!newDataArray.length,
        dataArray: newDataArray,
      }
    case HIDE_MODAL:
      return initialState
    default:
      return state
  }
}

export default modal
