import { useEffect, useState } from 'react'

export const DatesGroupModes = {
  MINUTES: 'MINUTES',
  HOURS: 'HOURS',
  WEEK_DAYS: 'WEEK_DAYS',
  DAYS: 'DAYS',
  MONTHS: 'MONTHS',
  YEARS: 'YEARS',
}

export const useDatesGroupMode = ({ startDate, finishDate }) => {
  const [datesGroupMode, setDatesGroupMode] = useState(DatesGroupModes.DAYS)

  useEffect(() => {
    const periodSize = finishDate.diff(startDate)

    if (periodSize <= 43199999) {
      // If period is half of day or smaller - Group by minutes
      setDatesGroupMode(DatesGroupModes.MINUTES)
    } else if (periodSize <= 86400000) {
      // If period is day or smaller - Group by hours
      setDatesGroupMode(DatesGroupModes.HOURS)
    } else if (periodSize <= 604799999) {
      setDatesGroupMode(DatesGroupModes.WEEK_DAYS)
    } else if (periodSize <= 7952399999) {
      // If period smaller than 3 month - Group by days
      setDatesGroupMode(DatesGroupModes.DAYS)
    } else if (periodSize <= 31622399999) {
      // If period smaller than 12 month - Group by month
      setDatesGroupMode(DatesGroupModes.MONTHS)
    } else {
      // If period is 12 month or bigger - Group by year
      setDatesGroupMode(DatesGroupModes.YEARS)
    }
  }, [startDate, finishDate])

  return datesGroupMode
}
