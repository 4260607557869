import { useEffect } from 'react'
import { Route, Routes, useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { configureApp } from './config/appConfig'
import useEnvironment from './hooks/useEnvironment'
import MainLayout from './layouts/MainLayout'
import ScrolledLayout from './layouts/ScrolledLayout'
import NotificationsSystem, { bootstrapTheme, dismissNotification } from 'reapop'
import { signOut } from './redux/actions/auth'
import { mainPages, mainScrolledPages, publicPages } from './router'
import Main from './pages/main/Main'
import './assets/styles/main.scss'

configureApp()

const App = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { isDev } = useEnvironment()
  const notifs = useSelector((state) => state.notifs)

  // Set document title
  useEffect(() => {
    document.title = isDev ? 'Abraaj Water (DEV)' : 'Abraaj Water'
  }, [isDev])

  return (
    <>
      <Routes>
        <Route
          path="/main"
          element={
            <Main logoutRequest={() => dispatch(signOut({ navigate }))} layout={MainLayout} />
          }
        >
          {mainPages.map((route, idx) => (
            <Route path={route.path} element={route.element} key={idx} />
          ))}
        </Route>
        <Route
          path="/main"
          element={
            <Main logoutRequest={() => dispatch(signOut({ navigate }))} layout={ScrolledLayout} />
          }
        >
          {mainScrolledPages.map((route, idx) => (
            <Route path={route.path} element={route.element} key={idx} />
          ))}
        </Route>
        {publicPages.map((route, idx) => (
          <Route path={route.path} element={route.element} key={idx} />
        ))}
      </Routes>
      <NotificationsSystem
        notifications={notifs}
        dismissNotification={(id) => dispatch(dismissNotification(id))}
        theme={bootstrapTheme}
      />
    </>
  )
}

export default App
