import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { createProduct } from 'redux/actions/products'
import { useNavigate } from 'react-router-dom'
import Page from 'components/Page'
import ProductForm from 'components/forms/ProductForm'

const ProductsAdd = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { t } = useTranslation()

  const { isChangeLoading } = useSelector((state) => state.products)

  const submitHandler = (data) =>
    dispatch(
      createProduct({
        data,
        navigate,
      }),
    )

  return (
    <Page pageTitle={t('products.new product')} withCancelBtn={true}>
      <ProductForm submitHandler={submitHandler} loading={isChangeLoading} />
    </Page>
  )
}

export default ProductsAdd
