import { useEffect, useState } from 'react'
import { message, Upload } from 'antd'
import Icon from './Icon'
import { ICONS } from 'utils/constants'

const AvatarFormField = ({ name, file, multiple = false, handleChange }) => {
  const [fileList, setFileList] = useState([])
  const [imageUrl, setImageUrl] = useState(null)

  useEffect(() => {
    if (file?.src) {
      setImageUrl(file.src)
    }
  }, [file])

  useEffect(() => {
    if (fileList[0]) {
      getBase64(fileList[0], (imgSource) => setImageUrl(imgSource))
    }
  }, [fileList])

  const getBase64 = (img, callback) => {
    const reader = new FileReader()
    reader.addEventListener('load', () => callback(reader.result))
    reader.readAsDataURL(img)
  }

  const props = {
    beforeUpload: async (file) => {
      const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png'
      if (!isJpgOrPng) {
        message.error('You can only upload JPG/PNG file!')
        return false
      }

      const isLt2M = file.size / 1024 / 1024 < 2
      if (!isLt2M) {
        message.error('Image must smaller than 2MB!')
        return false
      }

      if (multiple) {
        setFileList([...fileList, file])
        handleChange([...fileList, file])
      } else {
        setFileList([file])
        handleChange(file)
      }

      return false
    },
    fileList,
    multiple,
  }

  return (
    <>
      <div className="avatarUploader">
        <Upload {...props}>
          <div className="avatarUploader-imgWrapper">
            {imageUrl ? (
              <img src={imageUrl} alt={name} />
            ) : (
              <Icon icon={ICONS.IMAGE} size={64} color="#808898" />
            )}
          </div>
          <div className="avatarUploader-overlay">
            <Icon icon={ICONS.UPLOAD} size={24} />
          </div>
        </Upload>
      </div>
    </>
  )
}

export default AvatarFormField
