import { NavLink } from 'react-router-dom'

const Tabs = ({ tabs = [] }) => {
  return tabs.length ? (
    <ul className="tabs">
      {tabs.map(({ title, path }, index) => (
        <li className="tab" key={index}>
          <NavLink className="tab-link" activeClassName="tab-link--selected" to={path}>
            {title}
          </NavLink>
        </li>
      ))}
    </ul>
  ) : null
}

export default Tabs
