import axios from 'axios'

export const updateFile = async ({ id, file }) => {
  const response = await axios.patch(
    `/files/${id}`,
    {
      file: file,
    },
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    },
  )
  return response.data.data
}

export const getFile = async ({ id }) => {
  const response = await axios.get(`/files/${id}`)
  return response.data.data
}

export const deleteFile = async ({ id, logCatalog = undefined, givenEntityId = undefined }) => {
  const response = await axios.delete(`/files/${id}`, {
    data: {
      logCatalog: logCatalog,
      givenEntityId: givenEntityId,
    },
  })
  return response.data.data
}
