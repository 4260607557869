import { call, put, all, fork, takeEvery } from 'redux-saga/effects'

import { showLoading, hideLoading } from 'react-redux-loading-bar'

import CouponsService from '../middlewares/coupons'

import {
  GET_COUPONS,
  GET_COUPONS_REQUEST_ERROR,
  GET_COUPONS_REQUEST_PENDING,
  GET_COUPONS_REQUEST_SUCCESS,
  GET_COUPONS_REQUEST,
  CREATE_COUPON,
  CREATE_COUPON_REQUEST_ERROR,
  CREATE_COUPON_REQUEST,
  DELETE_COUPON,
  DELETE_COUPON_REQUEST_ERROR,
  DELETE_COUPON_REQUEST,
  GET_COUPON_REQUEST,
  GET_COUPON,
  GET_COUPON_REQUEST_SUCCESS,
  GET_COUPON_REQUEST_ERROR,
  GET_COUPON_REQUEST_PENDING,
} from '../types/coupons'

import { notify } from 'reapop'
import i18n from 'i18next'

function* getCouponsWatcher() {
  yield takeEvery(GET_COUPONS_REQUEST, getCouponsWorker)
}

function* getCouponWatcher() {
  yield takeEvery(GET_COUPON_REQUEST, getCouponWorker)
}

function* createCouponWatcher() {
  yield takeEvery(CREATE_COUPON_REQUEST, createCouponWorker)
}

function* deleteCouponWatcher() {
  yield takeEvery(DELETE_COUPON_REQUEST, deleteCouponWorker)
}
function* getCouponsWorker({ payload: { page, size } }) {
  try {
    yield put(showLoading())
    yield put({ type: GET_COUPONS_REQUEST_PENDING })
    const response = yield call(CouponsService.getCoupons, page, size)
    const { result, total } = response.data.data
    yield put({
      type: GET_COUPONS,
      payload: {
        items: result,
        pagination: {
          current: page,
          pageSize: size,
          total: total,
        },
      },
    })
    yield put({ type: GET_COUPONS_REQUEST_SUCCESS })
    yield put(hideLoading())
  } catch (error) {
    yield put({
      type: GET_COUPONS_REQUEST_ERROR,
      payload: error.response.data.messages,
    })
    yield put(hideLoading())
  }
}
function* getCouponWorker({ payload: { id } }) {
  try {
    yield put(showLoading())
    yield put({ type: GET_COUPON_REQUEST_PENDING })
    const response = yield call(CouponsService.getCouponById, id)
    const coupon = response.data.data
    yield put({ type: GET_COUPON, payload: coupon })
    yield put({ type: GET_COUPON_REQUEST_SUCCESS })
    yield put(hideLoading())
  } catch (error) {
    yield put({ type: GET_COUPON_REQUEST_ERROR, payload: error.response.data.messages })
    yield put(hideLoading())
  }
}
function* createCouponWorker({ payload: { data, navigate } }) {
  try {
    yield put(showLoading())
    const response = yield call(CouponsService.createCoupon, data)
    const coupon = response.data.data
    yield put({ type: CREATE_COUPON, payload: coupon })
    yield put(
      notify({
        title: i18n.t('notifs.created'),
        status: 'success',
        dismissAfter: 4000,
        allowHTML: true,
      }),
    )
    navigate('/main/coupons')
    yield put(hideLoading())
  } catch (error) {
    const errorMessage = i18n.t(`coupons.error.${error.response?.data?.messages[0]}`)
    yield put(
      notify({
        title: errorMessage,
        status: 'error',
        dismissAfter: 4000,
        allowHTML: true,
      }),
    )
    yield put({ type: CREATE_COUPON_REQUEST_ERROR, payload: error.message })
    yield put(hideLoading())
  }
}

function* deleteCouponWorker({ payload: { id, navigate } }) {
  try {
    yield put(showLoading())
    const response = yield call(CouponsService.deleteCoupon, id)
    const coupon = response.data.data
    yield put({ type: DELETE_COUPON, payload: coupon })
    yield put(
      notify({
        title: i18n.t('notifs.deleted'),
        status: 'success',
        dismissAfter: 4000,
        allowHTML: true,
      }),
    )
    navigate('/main/coupons')
    yield put(hideLoading())
  } catch (error) {
    yield put({ type: DELETE_COUPON_REQUEST_ERROR, payload: error.response.data.messages })
    yield put(hideLoading())
  }
}
export default function* coupons() {
  yield all([
    fork(getCouponsWatcher),
    fork(createCouponWatcher),
    fork(deleteCouponWatcher),
    fork(getCouponWatcher),
  ])
}
