import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import {
  deleteUsersNotification,
  getUsersNotification,
  updateUsersNotification,
} from 'redux/actions/usersNotifications'
import useClient from 'hooks/useClient'

import Page from 'components/Page'
import UserNotificationForm from 'components/forms/UserNotificationForm'
import { PERMISSIONS } from 'utils/constants'

const UsersNotificationsView = () => {
  const dispatch = useDispatch()
  const { isClientCan } = useClient()
  const navigate = useNavigate()
  const { id } = useParams()

  const isClientCanDeleteNotifications = isClientCan(PERMISSIONS.DELETE_NOTIFICATIONS)

  useEffect(() => {
    dispatch(getUsersNotification({ id }))
  }, [id, dispatch])

  const {
    item: usersNotification,
    isGetLoading,
    isChangeLoading,
  } = useSelector((state) => state.usersNotifications)

  const deleteHandler = (id) =>
    dispatch(
      deleteUsersNotification({
        id,
        navigate,
      }),
    )

  const submitHandler = async (id, data) => {
    const avatarFile = data.avatar || null
    const preparedData = {
      body: data.body,
      landingPage: data.landingPage,
      title: data.title,
    }

    if (data.landingPage === 'LINK') {
      preparedData.url = data.url
    } else {
      preparedData.product = data.product
    }

    dispatch(
      updateUsersNotification({
        id,
        data: preparedData,
        avatarFile,
        navigate,
      }),
    )
  }

  return (
    <Page
      pageTitle={usersNotification?.title}
      // duplicateHandler={duplicateHandler}
      deleteHandler={isClientCanDeleteNotifications ? () => deleteHandler(id) : null}
      isGetLoading={isGetLoading}
      isChangeLoading={isChangeLoading}
    >
      <UserNotificationForm
        defaultValues={usersNotification}
        submitHandler={(data) => submitHandler(id, data)}
        loading={isChangeLoading}
      />
    </Page>
  )
}

export default UsersNotificationsView
