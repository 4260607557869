import { Suspense } from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom'
import { Provider } from 'react-redux'
import { store } from './store'
import reportWebVitals from './reportWebVitals'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import LoadingBar from 'react-redux-loading-bar'
import { Spin } from 'antd'
import App from './App'
import Modal from './components/Modal/Modal'
import './i18n'
import { ClientProvider } from './providers/ClientProvider'

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
})

const app = (
  <BrowserRouter>
    <QueryClientProvider client={queryClient}>
      <Provider store={store}>
        <ClientProvider>
          <Suspense
            fallback={
              <Spin
                size="large"
                style={{
                  position: 'fixed',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                }}
              />
            }
          >
            <LoadingBar style={{ backgroundColor: '#101D37', height: '10px' }} />
            <App />
            <Modal />
          </Suspense>
        </ClientProvider>
      </Provider>
      {process.env.NODE_ENV !== 'production' && (
        <ReactQueryDevtools position={'bottom-right'} initialIsOpen={false} />
      )}
    </QueryClientProvider>
  </BrowserRouter>
)

ReactDOM.render(app, document.getElementById('root'))

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
