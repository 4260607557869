import { useTranslation } from 'react-i18next'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { FormProvider, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'

import AuthLayout from '../../layouts/AuthLayout'

import { resetPasswordSchema } from '../../utils/schemas'
import HookedField from '../../components/HookedField'
import Button from '../../components/Button'
import { useMutation } from '@tanstack/react-query'
import { resetAdminUserPassword } from '../../api/admin-users-managment'
import { notify } from 'reapop'
import { useDispatch } from 'react-redux'

const ResetPassword = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [searchParams] = useSearchParams()

  const token = searchParams.get('token')

  const resetAdminUserPasswordMutation = useMutation(resetAdminUserPassword, {
    onSuccess: () => {
      dispatch(
        notify({
          title: t('adminTeamManagement.users.success.passwordSuccessfullyReset'),
          status: 'success',
          dismissAfter: 4000,
        }),
      )
      navigate('/sign-in')
    },
    onError: (err) => {
      const errorCode = err.response?.data?.messages[0].message

      dispatch(
        notify({
          title: t(`adminTeamManagement.users.errors.${errorCode}`),
          status: 'error',
          dismissAfter: 4000,
        }),
      )
    },
  })

  const methods = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    shouldFocusError: true,
    resolver: yupResolver(resetPasswordSchema),
  })

  const signInHandler = async ({ password, confirmPassword }) => {
    if (password !== confirmPassword) return

    await resetAdminUserPasswordMutation.mutateAsync({ token, password })
  }

  return (
    <AuthLayout title={t('resetPassword.title')}>
      <div className="signIn">
        <div className="signIn-form">
          <FormProvider {...methods}>
            <form autoComplete="off" onSubmit={methods.handleSubmit(signInHandler)}>
              <div style={{ marginBottom: '26px' }}>
                <HookedField
                  label={t('fields.password')}
                  name="password"
                  defaultValue=""
                  fieldType="passwordField"
                  type="password"
                  placeholder={t('fields.password')}
                />
              </div>
              <div>
                <HookedField
                  label={t('fields.confirm password')}
                  name="confirmPassword"
                  defaultValue=""
                  fieldType="passwordField"
                  type="password"
                  placeholder={t('fields.confirm password')}
                />
              </div>
              <Button
                className="signIn-btn"
                size="large"
                type="primary"
                htmlType="submit"
                disabled={methods.formState.isSubmitted && !methods.formState.isValid}
              >
                {t('resetPassword.submit')}
              </Button>
            </form>
          </FormProvider>
        </div>
      </div>
    </AuthLayout>
  )
}

export default ResetPassword
