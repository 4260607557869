import { useTranslation } from 'react-i18next'
import { Skeleton } from 'antd'
import { useNavigate } from 'react-router-dom'
import { Fragment } from 'react'
import { ICONS } from 'utils/constants'
import Button from 'components/Button'
import Icon from 'components/Icon'

const Page = ({
  pageTitle,
  withCancelBtn,
  duplicateHandler,
  deleteHandler,
  customActions,
  isGetLoading,
  isChangeLoading,
  children,
}) => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  return (
    <div className="page">
      <div className="page-header">
        {isGetLoading ? (
          <Skeleton.Input style={{ width: 200 }} active={true} size="medium" />
        ) : (
          <h3 className="page-title">
            <Icon
              icon={ICONS.ARROW_LEFT}
              onClick={() => {
                navigate(-1)
              }}
            />
            <span>{pageTitle}</span>
          </h3>
        )}

        <div className="page-header-actions">
          {duplicateHandler &&
            (isGetLoading ? (
              <Skeleton.Button style={{ width: 120 }} active={true} size="medium" />
            ) : (
              <Button
                disabled={isChangeLoading}
                type="simple"
                size="small"
                onClick={() => duplicateHandler('id')}
              >
                <Icon icon={ICONS.DUPLICATE} size={16} />
                <span>{t('app.duplicate')}</span>
              </Button>
            ))}
          {deleteHandler &&
            (isGetLoading ? (
              <Skeleton.Button style={{ width: 120, marginLeft: 24 }} active={true} size="medium" />
            ) : (
              <Button
                disabled={isChangeLoading}
                type="simple"
                size="small"
                onClick={() => deleteHandler('id')}
              >
                <Icon icon={ICONS.DELETE} size={16} />
                <span>{t('app.delete')}</span>
              </Button>
            ))}
          {customActions &&
            customActions.map((node, index) => <Fragment key={index}>{node}</Fragment>)}
          {withCancelBtn && (
            <Button
              type="primary"
              onClick={() => {
                navigate(-1)
              }}
            >
              <span className="btn-text">{t('app.cancel')}</span>
            </Button>
          )}
        </div>
      </div>
      <div className="page-wrapper">{isGetLoading ? <Skeleton active={true} /> : children}</div>
    </div>
  )
}

export default Page
