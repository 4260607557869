import axios from 'axios'

class CustomersService {
  getCustomers = (page, size, sortType, sortOrder, search) =>
    axios({
      url: 'users',
      method: 'GET',
      params: {
        page,
        take: size,
        sortType,
        sortOrder,
        search: search?.length ? search : null,
      },
    })

  getCustomerById = (id) =>
    axios({
      url: `users/${id}`,
      method: 'GET',
    })

  exportUsers = ({
    startDate,
    finishDate,
    addDeliveryAddress,
    addArea,
    filterByAreaId,
    sortOrder,
    sortType,
  }) =>
    axios.get('users/download', {
      responseType: 'blob',
      params: {
        startDate,
        finishDate,
        addDeliveryAddress: !!addDeliveryAddress,
        addArea: !!addArea,
        filterByAreaId: filterByAreaId ? filterByAreaId : null,
        sortOrder: sortOrder ? sortOrder : null,
        sortType: sortType ? sortType : null,
      },
    })
}

export default new CustomersService()
