import { call, put, all, fork, takeEvery } from 'redux-saga/effects'

import { showLoading, hideLoading } from 'react-redux-loading-bar'

import UpsellingService from '../middlewares/upselling'

import { notify } from 'reapop'
import i18n from 'i18next'

import {
  GET_UPSELLING_RANDOM_PENDING,
  GET_UPSELLING_RANDOM,
  GET_UPSELLING_RANDOM_SUCCESS,
  GET_UPSELLING_RANDOM_ERROR,
  GET_UPSELLING_RANDOM_REQUEST,
  GET_UPSELLING_ACTIVATE_TYPE_PENDING,
  GET_UPSELLING_ACTIVATE_TYPE,
  GET_UPSELLING_ACTIVATE_TYPE_REQUEST,
  GET_UPSELLING_ACTIVATE_TYPE_ERROR,
  GET_UPSELLING_ACTIVATE_TYPE_SUCCESS,
  UPDATE_UPSELLING_RANDOM_LIST_REQUEST,
  UPDATE_UPSELLING_RANDOM_LIST,
  UPDATE_UPSELLING_RANDOM_LIST_SUCCESS,
  UPDATE_UPSELLING_RANDOM_LIST_ERROR,
  UPDATE_UPSELLING_RANDOM_LIST_PENDING,
  GET_UPSELLING_SELECTIVE_REQUEST,
  GET_UPSELLING_SELECTIVE_PENDING,
  GET_UPSELLING_SELECTIVE,
  GET_UPSELLING_SELECTIVE_SUCCESS,
  GET_UPSELLING_SELECTIVE_ERROR,
  UPDATE_UPSELLING_SELECTIVE_PRODUCT_PENDING,
  UPDATE_UPSELLING_SELECTIVE_PRODUCT_SUCCESS,
  UPDATE_UPSELLING_SELECTIVE_PRODUCT_ERROR,
  UPDATE_UPSELLING_SELECTIVE_PRODUCT_REQUEST,
  UPDATE_UPSELLING_SELECTIVE_PRODUCT,
} from '../types/upselling'

function* getUpsellingRandomProductWatcher() {
  yield takeEvery(GET_UPSELLING_RANDOM_REQUEST, getUpsellingRandomProductWorker)
}

function* putUpsellingTypeWatcher() {
  yield takeEvery(GET_UPSELLING_ACTIVATE_TYPE_REQUEST, putUpsellingTypeWorker)
}

function* postUpsellingRandomProductWatcher() {
  yield takeEvery(UPDATE_UPSELLING_RANDOM_LIST_REQUEST, postUpsellingRandomProductWorker)
}

function* getUpsellingSelectiveProductWatcher() {
  yield takeEvery(GET_UPSELLING_SELECTIVE_REQUEST, getUpsellingSelectiveProductWorker)
}
function* postUpsellingSelectiveProductWatcher() {
  yield takeEvery(UPDATE_UPSELLING_SELECTIVE_PRODUCT_REQUEST, postUpsellingSelectiveProductWorker)
}
function* getUpsellingRandomProductWorker() {
  try {
    yield put(showLoading())
    yield put({ type: GET_UPSELLING_RANDOM_PENDING })
    const response = yield call(UpsellingService.getUpsellingRandom)
    const items = response.data.data
    console.log('getUpsellingProductWorker', items)
    yield put({ type: GET_UPSELLING_RANDOM, payload: items })
    yield put({ type: GET_UPSELLING_RANDOM_SUCCESS })
    yield put(hideLoading())
  } catch (error) {
    yield put({
      type: GET_UPSELLING_RANDOM_ERROR,
      payload: error.response.data.messages,
    })
    yield put(hideLoading())
  }
}
function* putUpsellingTypeWorker({ payload: { data } }) {
  try {
    yield put(showLoading())
    yield put({ type: GET_UPSELLING_ACTIVATE_TYPE_PENDING })
    const response = yield call(UpsellingService.putUpsellingTypeActivate, data)
    const type = response.data.data
    console.log('getType', type)
    yield put({
      type: GET_UPSELLING_ACTIVATE_TYPE,
      payload: type,
    })
    yield put({ type: GET_UPSELLING_ACTIVATE_TYPE_SUCCESS })
    yield put(
      notify({
        title: i18n.t('notifs.updated'),
        status: 'success',
        dismissAfter: 4000,
        allowHTML: true,
      }),
    )
    yield put(hideLoading())
  } catch (error) {
    yield put({
      type: GET_UPSELLING_ACTIVATE_TYPE_ERROR,
      payload: error.response.data.messages,
    })
    yield put(hideLoading())
  }
}

function* postUpsellingRandomProductWorker({ payload: { data } }) {
  try {
    yield put(showLoading())
    yield put({ type: UPDATE_UPSELLING_RANDOM_LIST_PENDING })
    const response = yield call(UpsellingService.postUpsellingRandomProducts, data)
    const list = response.data.data
    console.log('postUpsellingRandomProductWorker', list)
    yield put({ type: UPDATE_UPSELLING_RANDOM_LIST, payload: list })
    yield put({ type: UPDATE_UPSELLING_RANDOM_LIST_SUCCESS })
    yield put(hideLoading())
  } catch (error) {
    yield put({
      type: UPDATE_UPSELLING_RANDOM_LIST_ERROR,
      payload: error.response.data.messages,
    })
    yield put(hideLoading())
  }
}
function* getUpsellingSelectiveProductWorker() {
  try {
    yield put(showLoading())
    yield put({ type: GET_UPSELLING_SELECTIVE_PENDING })
    const response = yield call(UpsellingService.getUpsellingSelective)
    const item = response.data.data
    console.log('getUpsellingSelectiveProductWorker', item)
    yield put({
      type: GET_UPSELLING_SELECTIVE,
      payload: item,
    })
    yield put({ type: GET_UPSELLING_SELECTIVE_SUCCESS })
    yield put(hideLoading())
  } catch (error) {
    yield put({
      type: GET_UPSELLING_SELECTIVE_ERROR,
      payload: error.response.data.messages,
    })
    yield put(hideLoading())
  }
}
function* postUpsellingSelectiveProductWorker({ payload: { data } }) {
  try {
    yield put(showLoading())
    yield put({ type: UPDATE_UPSELLING_SELECTIVE_PRODUCT_PENDING })
    const response = yield call(UpsellingService.postUpsellingSelectiveProduct, data)
    const list = response.data.data
    console.log('postUpsellingRandomProductWorker', list)
    yield put({
      type: UPDATE_UPSELLING_SELECTIVE_PRODUCT,
      payload: list,
    })
    yield put({ type: UPDATE_UPSELLING_SELECTIVE_PRODUCT_SUCCESS })
    yield put(hideLoading())
  } catch (error) {
    yield put({
      type: UPDATE_UPSELLING_SELECTIVE_PRODUCT_ERROR,
      payload: error.response.data.messages,
    })
    yield put(hideLoading())
  }
}
export default function* upselling() {
  yield all([
    fork(getUpsellingRandomProductWatcher),
    fork(putUpsellingTypeWatcher),
    fork(postUpsellingRandomProductWatcher),
    fork(getUpsellingSelectiveProductWatcher),
    fork(postUpsellingSelectiveProductWatcher),
  ])
}
