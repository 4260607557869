import { call, put, all, fork, takeEvery, select } from 'redux-saga/effects'

import { showLoading, hideLoading } from 'react-redux-loading-bar'

import PromotionsService from '../middlewares/promotions'

import {
  GET_PROMOTIONS_REQUEST,
  GET_PROMOTIONS,
  GET_PROMOTION_REQUEST,
  GET_PROMOTION,
  GET_PROMOTIONS_REQUEST_PENDING,
  GET_PROMOTIONS_REQUEST_SUCCESS,
  GET_PROMOTIONS_REQUEST_ERROR,
  GET_SUP_PROMOTIONS_REQUEST,
  CHANGE_PROMOTIONS_REQUEST_PENDING,
  CHANGE_PROMOTIONS_REQUEST_SUCCESS,
  UPDATE_PROMOTION,
  CHANGE_PROMOTIONS_REQUEST_ERROR,
  UPDATE_PROMOTION_REQUEST,
} from '../types/promotions'

import { notify } from 'reapop'
import i18n from 'i18next'

function* getPromotionsWatcher() {
  yield takeEvery(GET_PROMOTIONS_REQUEST, getPromotionsWorker)
}

function* getSupPromotionsWatcher() {
  yield takeEvery(GET_SUP_PROMOTIONS_REQUEST, getSupPromotionsWorker)
}

function* getPromotionWatcher() {
  yield takeEvery(GET_PROMOTION_REQUEST, getPromotionWorker)
}
function* updatePromotionWatcher() {
  yield takeEvery(UPDATE_PROMOTION_REQUEST, updatePromotionWorker)
}
function* getPromotionsWorker({ payload: { page, size } }) {
  try {
    yield put(showLoading())
    yield put({ type: GET_PROMOTIONS_REQUEST_PENDING })
    const response = yield call(PromotionsService.getPromotions, page, size)
    const { result, total } = response.data.data
    yield put({
      type: GET_PROMOTIONS,
      payload: {
        items: result,
        pagination: {
          current: page,
          pageSize: size,
          total: total,
        },
      },
    })
    yield put({ type: GET_PROMOTIONS_REQUEST_SUCCESS })
    yield put(hideLoading())
  } catch (error) {
    yield put({ type: GET_PROMOTIONS_REQUEST_ERROR, payload: error.response.data.messages })
    yield put(hideLoading())
  }
}

function* updatePromotionWorker({ payload: { id, data, navigate } }) {
  try {
    yield put(showLoading())
    yield put({ type: CHANGE_PROMOTIONS_REQUEST_PENDING })
    const response = yield call(PromotionsService.updatePromotion, id, data)
    const promotion = response.data.data
    yield put({ type: UPDATE_PROMOTION, payload: promotion })
    yield put({ type: CHANGE_PROMOTIONS_REQUEST_SUCCESS })
    yield put(
      notify({
        title: i18n.t('notifs.updated'),
        status: 'success',
        dismissAfter: 4000,
        allowHTML: true,
      }),
    )
    navigate('/main/promotions')
    yield put(hideLoading())
  } catch (error) {
    yield put({ type: CHANGE_PROMOTIONS_REQUEST_ERROR, payload: error.response.data.messages })
    yield put(hideLoading())
  }
}

function* getSupPromotionsWorker() {
  try {
    yield put(showLoading())
    yield put({ type: GET_PROMOTIONS_REQUEST_PENDING })
    yield call(PromotionsService.getSupPromotions)
    const { current, pageSize } = yield select((state) => state.promotions.pagination)
    yield put({
      type: GET_PROMOTIONS_REQUEST,
      payload: {
        page: current,
        size: pageSize,
      },
    })
    yield put({ type: GET_PROMOTIONS_REQUEST_SUCCESS })
    yield put(hideLoading())
  } catch (error) {
    yield put({ type: GET_PROMOTIONS_REQUEST_ERROR, payload: error.response.data.messages })
    yield put(hideLoading())
  }
}

function* getPromotionWorker({ payload: { id } }) {
  try {
    yield put(showLoading())
    yield put({ type: GET_PROMOTIONS_REQUEST_PENDING })
    const response = yield call(PromotionsService.getPromotionById, id)
    const promotion = response.data.data
    yield put({ type: GET_PROMOTION, payload: promotion })
    yield put({ type: GET_PROMOTIONS_REQUEST_SUCCESS })
    yield put(hideLoading())
  } catch (error) {
    yield put({ type: GET_PROMOTIONS_REQUEST_ERROR, payload: error.response.data.messages })
    yield put(hideLoading())
  }
}

export default function* promotions() {
  yield all([
    fork(getPromotionsWatcher),
    fork(getPromotionWatcher),
    fork(getSupPromotionsWatcher),
    fork(updatePromotionWatcher),
  ])
}
