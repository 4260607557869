import Page from '../../../components/Page'

const UsersManagementView = () => {
  const duplicateHandler = (id) => {
    console.log('duplicate', id)
  }

  const deleteHandler = (id) => {
    console.log('delete', id)
  }

  return (
    <Page
      pageTitle="Some User"
      duplicateHandler={duplicateHandler}
      deleteHandler={deleteHandler}
    ></Page>
  )
}

export default UsersManagementView
