import { useTranslation } from 'react-i18next'
import { FormProvider, useForm } from 'react-hook-form'
import { getStatusOptions } from 'utils/functions'
import HookedField from 'components/HookedField'
import ProductList from 'components/ProductList'
import OrderSummary from 'components/OrderSummary'

const OrderForm = ({ defaultValues = {}, orderPromocode = null, submitHandler, loading }) => {
  const { t } = useTranslation()

  const methods = useForm({
    mode: 'onSubmit',
    shouldFocusError: true,
  })

  return (
    <div className="customer-form">
      <FormProvider {...methods}>
        <form className="form" autoComplete="off" onSubmit={methods.handleSubmit(submitHandler)}>
          <fieldset className="form-fieldset">
            <div className="form-title">{t('orders.general info')}</div>
            <div className="form-grid">
              {defaultValues.id && (
                <HookedField
                  label={t('fields.id')}
                  name="id"
                  disabled={true}
                  defaultValue={defaultValues.id}
                  placeholder="id"
                  fieldType="textField"
                  type="text"
                />
              )}
              <HookedField
                label={t('fields.order number for customer')}
                name="orderNumber"
                defaultValue={defaultValues?.orderNumber || ''}
                placeholder={t('fields.order number for customer')}
                fieldType="textField"
                type="text"
                disabled={true}
              />
              <HookedField
                label={t('fields.created date')}
                name="createdAt"
                defaultValue={defaultValues?.createdAt || null}
                placeholder={t('fields.created date')}
                fieldType="datePickerField"
                type="datePicker"
                disabled={true}
              />
              <HookedField
                label={t('fields.status')}
                name="status"
                defaultValue={defaultValues?.status || ''}
                placeholder={t('fields.status')}
                fieldType="selectField"
                type="select"
                options={getStatusOptions()}
                disabled={true}
              />
              <HookedField
                label={t('fields.customer name')}
                name="fullName"
                defaultValue={defaultValues?.fullName || ''}
                placeholder={t('fields.customer name')}
                fieldType="textField"
                type="text"
                disabled={true}
              />
              <HookedField
                label={t('fields.mobile')}
                name="phoneNumber"
                defaultValue={defaultValues?.phoneNumber || ''}
                placeholder={t('fields.mobile')}
                fieldType="textField"
                type="tel"
                disabled={true}
              />
              <HookedField
                label={t('fields.email')}
                name="email"
                defaultValue={defaultValues?.email || ''}
                placeholder={t('fields.email')}
                fieldType="textField"
                type="text"
                disabled={true}
              />
              <HookedField
                label={t('fields.payment status')}
                name="paymentStatus"
                defaultValue={defaultValues?.paymentStatus || ''}
                placeholder={t('fields.email')}
                fieldType="textField"
                type="text"
                disabled={true}
              />
              <HookedField
                label={t('fields.payment method')}
                name="paymentMethod"
                defaultValue={defaultValues?.paymentMethod || ''}
                placeholder={t('fields.email')}
                fieldType="textField"
                type="text"
                disabled={true}
              />
            </div>
          </fieldset>
          <fieldset className="form-fieldset">
            <div className="form-title">{t('orders.delivery info')}</div>
            <div className="form-grid">
              <HookedField
                label={t('fields.area')}
                name="area"
                defaultValue={defaultValues?.deliveryAddress?.area?.name || ''}
                fieldType="textField"
                type="text"
                disabled={true}
              />
              <HookedField
                label={t('fields.block')}
                name="block"
                defaultValue={defaultValues?.deliveryAddress?.block || ''}
                fieldType="textField"
                type="text"
                disabled={true}
              />
              <HookedField
                label={t('fields.street')}
                name="street"
                defaultValue={defaultValues?.deliveryAddress?.street || ''}
                fieldType="textField"
                type="text"
                disabled={true}
              />
              <HookedField
                label={t('fields.avenue')}
                name="avenue"
                defaultValue={defaultValues?.deliveryAddress?.avenue || ''}
                fieldType="textField"
                type="text"
                disabled={true}
              />
              <HookedField
                label={t('fields.building number')}
                name="houseBuildingNumber"
                defaultValue={defaultValues?.deliveryAddress?.houseBuildingNumber || ''}
                fieldType="textField"
                type="text"
                disabled={true}
              />
              <HookedField
                label={t('fields.floor number')}
                name="floorNumber"
                defaultValue={defaultValues?.deliveryAddress?.floorNumber || ''}
                fieldType="textField"
                type="text"
                disabled={true}
              />
              <HookedField
                label={t('fields.apartment number')}
                name="placementNumber"
                defaultValue={defaultValues?.deliveryAddress?.placementNumber || ''}
                fieldType="textField"
                type="text"
                disabled={true}
              />
            </div>
          </fieldset>
          <fieldset className="form-fieldset">
            <div className="form-grid form-grid--delivery">
              <HookedField
                label={t('fields.delivery date')}
                name="deliveryDate"
                defaultValue={defaultValues?.deliveryDate || null}
                placeholder={t('fields.delivery date')}
                fieldType="datePickerField"
                type="datePicker"
                disabled={true}
              />
              <HookedField
                label={t('fields.delivery time')}
                name="deliveryTime"
                defaultValue={defaultValues?.deliveryTimeSlot?.value || ''}
                placeholder={t('fields.delivery time')}
                fieldType="textField"
                type="text"
                disabled={true}
              />
              <HookedField
                label={t('fields.delivery insructions')}
                name="comment"
                defaultValue={defaultValues?.comment}
                placeholder={t('fields.delivery insructions')}
                fieldType="textField"
                type="textarea"
                disabled={true}
              />
            </div>
          </fieldset>
          <fieldset className="form-fieldset">
            <div className="form-title">{t('orders.order info')}</div>
            <div className="form-grid">
              {defaultValues?.items?.length ? <ProductList products={defaultValues.items} /> : null}
              <OrderSummary
                subTotalAmount={defaultValues?.subTotalAmount}
                totalAmount={defaultValues?.totalAmount}
                freeItemsSum={defaultValues?.calculationFreeItems}
                promocodeDiscountSum={defaultValues?.calculationPromocodeDiscount}
                deliveryCost={defaultValues?.deliveryCost}
                orderPromocode={orderPromocode}
                orderWalletDiscount={defaultValues?.walletDiscountAmount}
              />
            </div>
          </fieldset>
          {/* We refuse of update order for a while */}
          {/*<div className="button-grid">*/}
          {/*    <Button*/}
          {/*        className="save-btn"*/}
          {/*        size="large"*/}
          {/*        type="primary"*/}
          {/*        htmlType="submit"*/}
          {/*        disabled={*/}
          {/*            loading ||*/}
          {/*            !methods.formState.isDirty ||*/}
          {/*            ( methods.formState.isSubmitted && !methods.formState.isValid)*/}
          {/*        }*/}
          {/*    >*/}
          {/*        {t('app.save')}*/}
          {/*    </Button>*/}
          {/*</div>*/}
        </form>
      </FormProvider>
    </div>
  )
}

export default OrderForm
