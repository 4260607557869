import { useQuery } from '@tanstack/react-query'
import { Skeleton, Space } from 'antd'
import UpsellingForm from 'components/forms/UpsellingForm'
import PageNoBox from 'components/PageNoBox'
import UsersNotificationsService from 'redux/middlewares/usersNotifications'
import UpsellingService from 'redux/middlewares/upselling'

const UpsellingView = () => {
  const { data: products, isFetching: isProductsFetching } = useQuery(
    ['notification-SKU-Products'],
    async () => {
      const response = await UsersNotificationsService.getNotificationSkuProducts()
      return response.data.data.result
    },
  )

  const { data: upsellingTypes, isFetching: isUpsellingTypesFetching } = useQuery(
    ['getUpsellingCurrentType'],
    async () => {
      const response = await UpsellingService.getUpsellingActiveType()
      return response.data
    },
  )

  const { data: selectiveProduct, isFetching: isSelectiveProductFetching } = useQuery(
    ['getUpsellingSelectiveItems'],
    async () => {
      const response = await UpsellingService.getUpsellingSelective()
      return response.data.data
    },
  )

  const { data: randomProducts, isFetching: isRandomProductsFetching } = useQuery(
    ['getUpsellingRandomItems'],
    async () => {
      const response = await UpsellingService.getUpsellingRandom()
      return response.data.data
    },
  )

  return (
    <PageNoBox pageTitle="Upselling">
      {!isProductsFetching &&
      !isRandomProductsFetching &&
      !isSelectiveProductFetching &&
      !isUpsellingTypesFetching ? (
        <UpsellingForm
          products={products}
          upsellingTypes={upsellingTypes}
          randomProducts={randomProducts}
          selectiveProduct={selectiveProduct}
        />
      ) : (
        <Space direction={'vertical'} size={50} style={{ width: '100%' }}>
          <Skeleton active />
          <Skeleton active />
          <Skeleton active />
        </Space>
      )}
    </PageNoBox>
  )
}

export default UpsellingView
