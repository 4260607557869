import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useMutation, useQuery } from '@tanstack/react-query'
import classNames from 'classnames'
import {
  Button,
  Checkbox,
  Col,
  Dropdown,
  message,
  Modal,
  Row,
  Skeleton,
  Spin,
  Typography,
} from 'antd'

import { notify } from 'reapop'
import { useNavigate } from 'react-router-dom'

import useClient from 'hooks/useClient'
import PermissionCard from 'components/PermissionCard/PermissionCard'

import Icon from 'components/Icon'

import { ADMIN_ROLE_ID, ICONS, PERMISSIONS } from 'utils/constants'
import { CategoriesForAllLabels, CategoriesLabels } from '../constants'

import cls from './RoleStyles.module.scss'

import RoleModalForm from '../../../components/Modal/modals/RoleFormModal/RoleModalForm'
import { deleteRole, getRoleById, getRolesList, updateRolePermissions } from '../../../api/roles'

const { Title, Text } = Typography
const Roles = () => {
  const { t } = useTranslation()
  const { isClientCan } = useClient()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [selectedRoleId, setSelectedRole] = useState(2)
  const [permissionsCheckboxState, setPermissionsCheckboxState] = useState(null)
  const [shouldUpdatePermissions, setShouldUpdatePermissions] = useState(false)
  const [isRoleWasDeleted, setIsRoleWasDeleted] = useState(false)
  const [isCreatingRole, setIsCreatingRole] = useState(false)
  const [isEditingRole, setIsEditingRole] = useState(false)

  // permissions for roles
  const isClientCanCreateRoles = isClientCan(PERMISSIONS.CREATE_ROLES)
  const isClientCanDeleteRoles = isClientCan(PERMISSIONS.DELETE_ROLES)
  const isClientCanUpdateRoles = isClientCan(PERMISSIONS.UPDATE_ROLES)

  const {
    data: _rolesList,
    isFetching: isRoleListFetching,
    refetch: refetchRolesList,
  } = useQuery(['roles-list', isRoleWasDeleted], async () => await getRolesList())

  const { data: selectedRole, isSuccess: isSelectedRoleSuccess } = useQuery(
    ['permissions-list', selectedRoleId],
    async () => await getRoleById(selectedRoleId),
  )

  const roles = useMemo(() => {
    if (isRoleListFetching) return []

    return _rolesList
      .filter((role) => role.id !== ADMIN_ROLE_ID)
      .sort((a, b) => Number(a.id) - Number(b.id))
  }, [_rolesList, isRoleListFetching])

  const getArrayOfPermissions = () => {
    const permissions = []

    for (const category in permissionsCheckboxState) {
      permissionsCheckboxState[category]?.forEach((permission) => {
        if (permission[permission?.name]) {
          const fullKey = CategoriesForAllLabels[category]?.permissions?.find(
            (permissionTemplate) => {
              return permissionTemplate?.key === permission?.name
            },
          )
          permissions.push(fullKey)
        }
      })
    }

    const keysArray = []
    if (permissions?.length) {
      permissions?.forEach((permission) => {
        keysArray.push(permission?.fullKey)
      })
    }
    return keysArray
  }

  const modifiedPermissions = getArrayOfPermissions()

  const updateRolePermissionsMutation = useMutation(updateRolePermissions, {
    onSuccess: async () => setShouldUpdatePermissions(false),
    onError: () => setShouldUpdatePermissions(false),
  })

  useEffect(() => {
    if (permissionsCheckboxState !== null && selectedRoleId && shouldUpdatePermissions) {
      return updateRolePermissionsMutation.mutateAsync({
        roleId: selectedRoleId,
        permissionsArray: modifiedPermissions,
      })
    }
  }, [
    selectedRoleId,
    permissionsCheckboxState,
    shouldUpdatePermissions,
    modifiedPermissions,
    updateRolePermissionsMutation,
  ])

  const checkBoxPermissionLabels = useMemo(() => {
    if (!isSelectedRoleSuccess) return []

    return selectedRole.permissions.map((permission) => {
      const [value, label] = permission.permission.split('_')
      return { label, permissions: value }
    })
  }, [selectedRole, isSelectedRoleSuccess])

  const getCategoriesWithPermissions = () => {
    return Object.keys(CategoriesForAllLabels).map((category) => ({
      label: category,
      permissions: CategoriesForAllLabels[category].permissions.map((permission) => permission.key),
    }))
  }

  const collectRolePermissions = () => {
    return checkBoxPermissionLabels.reduce((categories, { label, permissions }) => {
      const category = label === 'WALLET' ? 'USERS' : label
      if (categories[category]) {
        categories[category].permissions.push(permissions)
      } else {
        categories[category] = { category, permissions: [permissions] }
      }
      return categories
    }, {})
  }

  const deleteRoleMutation = useMutation(deleteRole, {
    onSuccess: async () => {
      dispatch(
        notify({
          title: t('adminTeamManagement.roles.success.successfullyDeleted'),
          status: 'success',
          dismissAfter: 4000,
        }),
      )
      setIsRoleWasDeleted(true)
    },
    onError: (err) => {
      if (
        err?.response?.data?.messages[0]?.message ===
        'IS_NOT_POSSIBLE_DELETE_ROLE_FOR_THE_REASON_OF_ALREADY_CREATING_USERS'
      ) {
        dispatch(
          notify({
            title: t('adminTeamManagement.roles.error.impossibleToDelete'),
            status: 'success',
            dismissAfter: 4000,
          }),
        )
      }
    },
  })

  const onEditRole = () => {
    setIsEditingRole(true)
    setIsCreatingRole(false)
  }

  const onRoleCancel = () => {
    setIsEditingRole(false)
    setIsCreatingRole(false)
  }
  const rolePermissionsData = collectRolePermissions()
  const categoriesWithPermissions = getCategoriesWithPermissions()

  const deleteRoleModal = () => {
    const role = roles.find((role) => role.id === selectedRoleId)

    Modal.confirm({
      title: t('adminTeamManagement.roles.warning.deleteRoleConfirm'),
      content: t('adminTeamManagement.roles.warning.deleteRoleMessage', {
        roleName: role.roleName,
      }),
      okType: 'danger',
      onOk() {
        deleteRoleMutation.mutateAsync(role.id).then((mutationData) => {
          if (mutationData?.data?.data?.result === 'Success') {
            return message.success(t('adminTeamManagement.role.success.successfullyDeleted'))
          }
        })
      },
    })
  }

  const handleMenuClick = (e) => {
    if (e.key === 'edit-role') {
      onEditRole()
    } else {
      if (isClientCanDeleteRoles) {
        deleteRoleModal()
      }
    }
  }

  const items = [
    {
      label: <Text>{t('adminTeamManagement.roles.editRole')}</Text>,
      key: 'edit-role',
    },
    {
      label: (
        <Text type={'danger'} disabled={!isClientCanDeleteRoles}>
          {t('adminTeamManagement.roles.deleteRole')}
        </Text>
      ),
      key: 'delete-role',
    },
  ]

  const roleClickHandler = (id) => {
    setSelectedRole(id)
  }

  const isSelected = ({ id, selectedRoleId }) => {
    return selectedRoleId === id
  }

  const onRoleSubmit = async () => {
    setIsEditingRole(false)
    await refetchRolesList()
  }

  const onUsersByRoleClick = (id) => {
    if (id !== selectedRoleId) return

    navigate(`/main/users?filterByRoleId=${selectedRoleId}`)
  }

  const onCheckboxChange = ({ category, permissionItem }) => {
    setPermissionsCheckboxState((prevState) => {
      const currentState = prevState?.[category]?.find((el) => el?.name === permissionItem)
      const updatedCurrentState = {
        name: permissionItem,
        [permissionItem]: !currentState?.[permissionItem],
      }
      const updatedStateArray = prevState[category].with(
        prevState[category]?.indexOf(currentState),
        updatedCurrentState,
      )
      return { ...prevState, [category]: updatedStateArray }
    })
    setShouldUpdatePermissions(true)
  }
  const mapStateForPermissions = () => {
    return categoriesWithPermissions.reduce((checkboxState, { label, permissions }) => {
      checkboxState[label] = permissions.map((permission) => {
        const permissionState = rolePermissionsData[label]?.permissions?.includes(permission)

        return {
          name: permission,
          [permission]: permissionState,
        }
      })
      return checkboxState
    }, {})
  }

  const stateForCheckboxes = mapStateForPermissions()

  useEffect(() => {
    setPermissionsCheckboxState(stateForCheckboxes)
    // eslint-disable-next-line
  }, [selectedRole])

  useEffect(() => {
    roles && roles?.length && setSelectedRole(roles?.[0]?.id)
  }, [roles])

  const addNewRoleBtn = isRoleListFetching ? (
    <Skeleton.Button active={true} size="medium" />
  ) : (
    <Button
      className="btn btn--normal btn--primary"
      type="primary"
      onClick={() => setIsCreatingRole(true)}
    >
      <div className={cls.rolesWrapper__btnTextContainer}>
        <Icon icon={ICONS.PLUS} />
        <span>{t('adminTeamManagement.roles.addNewRole')}</span>
      </div>
    </Button>
  )

  const renderPermissionCard = (label) => {
    const category = categoriesWithPermissions.find((category) => category.label === label)
    const categoryPermissions = permissionsCheckboxState[label]

    return category.permissions.map((permission, index) => {
      const currentState = categoryPermissions.find((permit) => permission === permit.name)
      let checkboxText = permission.toLowerCase()

      if (label === 'USERS' && permission === 'UPDATE') {
        checkboxText = 'edit wallet'
      }

      if (label === 'ADMIN' && permission === 'UPDATE') {
        checkboxText = 'reset password'
      }

      const handleChange = () => {
        onCheckboxChange({
          category: label,
          permissionItem: permission,
        })
      }

      return (
        <Col span={7} key={index}>
          <Checkbox
            name={''}
            checked={currentState[permission]}
            onChange={handleChange}
            loading={selectedRole}
            disabled={!isClientCanUpdateRoles}
          >
            {checkboxText}
          </Checkbox>
        </Col>
      )
    })
  }

  return (
    <>
      <Row className={cls.rolesWrapper__rolesTitle} justify="space-between">
        <Col span={20}>
          <Title level={4}>{t('Role permissions')}</Title>
        </Col>
        <Col span={3}>{isClientCanCreateRoles ? addNewRoleBtn : null}</Col>
      </Row>
      <div className={cls.rolesWrapper}>
        <Row>
          <Col span={6}>
            {roles.map((role) => {
              return (
                <div
                  key={role.id}
                  className={classNames([
                    cls.rolesWrapper__roleContainer,
                    isSelected({ id: role.id, selectedRoleId }) && cls.selected,
                  ])}
                  onClick={() => roleClickHandler(role.id)}
                >
                  <div className={cls.rolesWrapper__roleContainer__head}>
                    <div className={cls.rolesWrapper__roleContainer__head__wrapper}>
                      <div className={cls.rolesWrapper__roleContainer__head__title}>
                        <p>{role.roleName}</p>
                      </div>
                      <div className={cls.rolesWrapper__roleContainer__head__extra}>
                        <Dropdown
                          menu={{ items, onClick: handleMenuClick }}
                          placement="bottom"
                          trigger={['click']}
                        >
                          <Icon icon={ICONS.MORE} size={16} style={{ cursor: 'pointer' }} />
                        </Dropdown>
                      </div>
                    </div>
                  </div>
                  <div
                    className={cls.rolesWrapper__roleContainer__body}
                    style={{
                      cursor: role.id === selectedRoleId ? 'pointer' : 'default',
                      display: 'inline-block',
                    }}
                    onClick={() => onUsersByRoleClick(role.id)}
                  >
                    <p>{`${role.adminsCount} users`}</p>
                  </div>
                </div>
              )
            })}
          </Col>
          <Col className={cls.rolesWrapper__permissionsContainer} span={18}>
            <div className={cls.rolesWrapper__permissionsContainer__cardsContainer}>
              {roles.length && selectedRole ? (
                categoriesWithPermissions.map(({ label }, index) => (
                  <PermissionCard
                    key={index}
                    style={{ marginBottom: 5, width: 365 }}
                    title={`${CategoriesLabels[label]?.categoryTitle}`}
                    loading={!rolePermissionsData}
                    children={renderPermissionCard(label)}
                  />
                ))
              ) : (
                <Spin
                  size="large"
                  style={{
                    position: 'fixed',
                    top: '50%',
                    left: '70%',
                    transform: 'translate(-50%, -50%)',
                  }}
                />
              )}
            </div>
          </Col>
        </Row>
        <RoleModalForm
          title={'New Role'}
          isVisible={isCreatingRole}
          onRoleSubmit={onRoleSubmit}
          onCancel={onRoleCancel}
          roleId={null}
        />
        <RoleModalForm
          title={'Update Role'}
          isVisible={isEditingRole}
          onRoleSubmit={onRoleSubmit}
          onCancel={onRoleCancel}
          roleId={selectedRoleId}
          isEdit={isEditingRole}
        />
      </div>
    </>
  )
}

export default Roles
