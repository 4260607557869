import axios from 'axios'

class ProductsService {
  getProducts = (page, size, sortType, sortOrder, filterByCategoryId, search) =>
    axios({
      url: 'products',
      method: 'GET',
      params: {
        page,
        take: size,
        asc: sortType && sortOrder === 'ascend' ? sortType : null,
        desc: sortType && sortOrder === 'descend' ? sortType : null,
        categoryId: filterByCategoryId,
        search: search ? search : null,
      },
    })
  getSupProducts = () =>
    axios({
      url: 'products/pull',
      method: 'GET',
    })

  getProductById = (id) =>
    axios({
      url: `products/${id}`,
      method: 'GET',
    })

  updateProduct = (id, data) =>
    axios({
      url: `products/${id}`,
      method: 'PUT',
      data,
    })
}

export default new ProductsService()
