import { useTranslation } from 'react-i18next'
import { Modal } from 'antd'
import FlyerForm from 'components/forms/FlyerForm'

const FlyerFormModal = ({ isVisible, onCancel, flyer, submitCallback }) => {
  const { t } = useTranslation()

  return (
    <Modal
      open={isVisible}
      onCancel={onCancel}
      title={!!flyer ? t('flyers.update flyer') : t('flyers.new flyer')}
      footer={null}
      centered={true}
      destroyOnClose={true}
      width={550}
    >
      <FlyerForm flyer={flyer} submitCallback={submitCallback} />
    </Modal>
  )
}

export default FlyerFormModal
