import {
  GET_SURVEYS,
  GET_SURVEY,
  UPDATE_SURVEY,
  CREATE_SURVEY,
  DELETE_SURVEY,
  GET_SURVEYS_REQUEST_PENDING,
  GET_SURVEYS_REQUEST_SUCCESS,
  GET_SURVEYS_REQUEST_ERROR,
  CHANGE_SURVEYS_REQUEST_PENDING,
  CHANGE_SURVEYS_REQUEST_SUCCESS,
  CHANGE_SURVEYS_REQUEST_ERROR,
} from '../types/surveys'

const initialState = {
  items: [],
  item: {},
  isGetLoading: false,
  isChangeLoading: false,
  errors: [],
}

const surveys = (state = initialState, action) => {
  switch (action.type) {
    case GET_SURVEYS:
      return { ...state, items: action.payload }
    case GET_SURVEY:
      return { ...state, item: action.payload }
    case UPDATE_SURVEY:
      const itemIndex = state.items.findIndex((item) => item.id === action.payload.id)
      const newItems = [...state.items]
      newItems[itemIndex] = { ...action.payload }
      return { ...state, items: newItems }
    case CREATE_SURVEY:
      return { ...state, items: [...state.items, action.payload] }
    case DELETE_SURVEY:
      return {
        ...state,
        items: state.items.filter((item) => item.id !== action.payload),
      }
    case GET_SURVEYS_REQUEST_PENDING:
      return { ...state, isGetLoading: true }
    case GET_SURVEYS_REQUEST_SUCCESS:
      return { ...state, errors: [], isGetLoading: false }
    case GET_SURVEYS_REQUEST_ERROR:
      return { ...state, errors: action.payload, isGetLoading: false }
    case CHANGE_SURVEYS_REQUEST_PENDING:
      return { ...state, isChangeLoading: true }
    case CHANGE_SURVEYS_REQUEST_SUCCESS:
      return { ...state, errors: [], isChangeLoading: false }
    case CHANGE_SURVEYS_REQUEST_ERROR:
      return { ...state, errors: action.payload, isChangeLoading: false }
    default:
      return state
  }
}

export default surveys
