import { call, put, all, fork, takeEvery } from 'redux-saga/effects'

import { showLoading, hideLoading } from 'react-redux-loading-bar'

import UsersNotificationsService from '../middlewares/usersNotifications'
import FilesService from 'redux/middlewares/files'

import { notify } from 'reapop'
import i18n from 'i18next'

import {
  GET_USERS_NOTIFICATIONS_REQUEST,
  GET_USERS_NOTIFICATIONS,
  GET_USERS_NOTIFICATION_REQUEST,
  GET_USERS_NOTIFICATION,
  GET_USERS_NOTIFICATIONS_REQUEST_PENDING,
  GET_USERS_NOTIFICATIONS_REQUEST_SUCCESS,
  GET_USERS_NOTIFICATIONS_REQUEST_ERROR,
  UPDATE_USERS_NOTIFICATION_REQUEST,
  UPDATE_USERS_NOTIFICATION,
  CHANGE_USERS_NOTIFICATIONS_REQUEST_PENDING,
  CHANGE_USERS_NOTIFICATIONS_REQUEST_SUCCESS,
  CHANGE_USERS_NOTIFICATIONS_REQUEST_ERROR,
  CREATE_USERS_NOTIFICATION,
  CREATE_USERS_NOTIFICATION_REQUEST,
  CREATE_USERS_NOTIFICATIONS_REQUEST_ERROR,
  DELETE_USERS_NOTIFICATION,
  DELETE_USERS_NOTIFICATION_REQUEST,
  DELETE_USERS_NOTIFICATIONS_REQUEST_ERROR,
  SEND_USERS_NOTIFICATION,
  SEND_USERS_NOTIFICATION_REQUEST,
  GET_NOTIFICATION_PRODUCTS,
  GET_NOTIFICATION_PRODUCTS_REQUEST,
  GET_NOTIFICATION_PRODUCTS_REQUEST_PENDING,
  GET_NOTIFICATION_AREAS_PENDING,
  GET_NOTIFICATION_AREAS,
  GET_NOTIFICATION_AREAS_SUCCESS,
  GET_NOTIFICATION_AREAS_ERROR,
  GET_NOTIFICATION_AREAS_REQUEST,
  GET_NOTIFICATION_PRODUCTS_REQUEST_SUCCESS,
} from '../types/usersNotifications'

function* getUsersNotificationsWatcher() {
  yield takeEvery(GET_USERS_NOTIFICATIONS_REQUEST, getUsersNotificationsWorker)
}

function* getUsersNotificationWatcher() {
  yield takeEvery(GET_USERS_NOTIFICATION_REQUEST, getUsersNotificationWorker)
}

function* updateUsersNotificationWatcher() {
  yield takeEvery(UPDATE_USERS_NOTIFICATION_REQUEST, updateUsersNotificationWorker)
}

function* createUsersNotificationWatcher() {
  yield takeEvery(CREATE_USERS_NOTIFICATION_REQUEST, createUsersNotificationWorker)
}

function* deleteUsersNotificationWatcher() {
  yield takeEvery(DELETE_USERS_NOTIFICATION_REQUEST, deleteUsersNotificationWorker)
}

function* sendUsersNotificationWatcher() {
  yield takeEvery(SEND_USERS_NOTIFICATION_REQUEST, sendUsersNotificationWorker)
}

function* getNotificationProductsWatcher() {
  yield takeEvery(GET_NOTIFICATION_PRODUCTS_REQUEST, getNotificationProductsWorker)
}

function* getNotificationAreasWatcher() {
  yield takeEvery(GET_NOTIFICATION_AREAS_REQUEST, getNotificationAreasWorker)
}
function* getUsersNotificationsWorker({ payload: { page, size } }) {
  try {
    yield put(showLoading())
    yield put({ type: GET_USERS_NOTIFICATIONS_REQUEST_PENDING })
    const response = yield call(UsersNotificationsService.getUsersNotifications, page, size)
    const { result, total } = response.data.data
    yield put({
      type: GET_USERS_NOTIFICATIONS,
      payload: {
        items: result,
        pagination: {
          current: page,
          pageSize: size,
          total: total,
        },
      },
    })

    yield put({ type: GET_USERS_NOTIFICATIONS_REQUEST_SUCCESS })
    yield put(hideLoading())
  } catch (error) {
    yield put({
      type: GET_USERS_NOTIFICATIONS_REQUEST_ERROR,
      payload: error.response.data.messages,
    })
    yield put(hideLoading())
  }
}

function* getUsersNotificationWorker({ payload: { id } }) {
  try {
    yield put(showLoading())
    yield put({ type: GET_USERS_NOTIFICATIONS_REQUEST_PENDING })
    const response = yield call(UsersNotificationsService.getUsersNotificationById, id)
    const notification = response.data.data
    yield put({ type: GET_USERS_NOTIFICATION, payload: notification })
    yield put({ type: GET_USERS_NOTIFICATIONS_REQUEST_SUCCESS })
    yield put(hideLoading())
  } catch (error) {
    yield put({
      type: GET_USERS_NOTIFICATIONS_REQUEST_ERROR,
      payload: error.response.data.messages,
    })
    yield put(hideLoading())
  }
}

function* sendUsersNotificationWorker({ payload: { id, data, env } }) {
  try {
    yield put(showLoading())
    const response = yield call(UsersNotificationsService.sendUsersNotification, id, data, env)
    if (response) {
      yield put(
        notify({
          title: i18n.t('notifs.success'),
          status: 'success',
          dismissAfter: 4000,
          allowHTML: true,
        }),
      )
    }

    const notification = response.data.data
    yield put({ type: SEND_USERS_NOTIFICATION, payload: notification })
    yield put(hideLoading())
  } catch (error) {
    yield put({
      type: CHANGE_USERS_NOTIFICATIONS_REQUEST_ERROR,
      payload: error.response.data.messages,
    })
    yield put(hideLoading())
  }
}

function* updateUsersNotificationWorker({ payload: { id, data, avatarFile, navigate } }) {
  try {
    yield put(showLoading())
    yield put({ type: CHANGE_USERS_NOTIFICATIONS_REQUEST_PENDING })
    const response = yield call(UsersNotificationsService.updateUserNotifications, id, data)
    const notification = response.data.data
    yield put({ type: UPDATE_USERS_NOTIFICATION, payload: notification })
    yield put({ type: CHANGE_USERS_NOTIFICATIONS_REQUEST_SUCCESS })
    yield put(
      notify({
        title: i18n.t('notifs.updated'),
        status: 'success',
        dismissAfter: 4000,
        allowHTML: true,
      }),
    )

    // Upload avatar file after notification create
    if (notification?.image?.id && avatarFile) {
      const formData = new FormData()
      formData.append('file', avatarFile)
      yield call(FilesService.uploadFile, formData, notification.image.id)
    }

    navigate('/main/users-notifications')
    yield put(hideLoading())
  } catch (error) {
    yield put({
      type: CHANGE_USERS_NOTIFICATIONS_REQUEST_ERROR,
      payload: error.response.data.messages,
    })
    yield put(hideLoading())
  }
}

function* createUsersNotificationWorker({ payload: { data, avatarFile, navigate } }) {
  try {
    yield put(showLoading())
    const response = yield call(UsersNotificationsService.createUsersNotifications, data)
    const notification = response.data.data
    yield put({ type: CREATE_USERS_NOTIFICATION, payload: notification })
    yield put(
      notify({
        title: i18n.t('notifs.created'),
        status: 'success',
        dismissAfter: 4000,
        allowHTML: true,
      }),
    )

    // Upload avatar file after notification create
    if (notification?.image?.id && avatarFile) {
      const formData = new FormData()
      formData.append('file', avatarFile)
      yield call(FilesService.uploadFile, formData, notification.image.id)
    }

    navigate('/main/users-notifications')
    yield put(hideLoading())
  } catch (error) {
    yield put({
      type: CREATE_USERS_NOTIFICATIONS_REQUEST_ERROR,
      payload: error.response.data.messages,
    })
    yield put(hideLoading())
  }
}

function* deleteUsersNotificationWorker({ payload: { id, navigate } }) {
  try {
    yield put(showLoading())
    const response = yield call(UsersNotificationsService.deleteUsersNotifications, id)
    const notification = response.data.data
    yield put({ type: DELETE_USERS_NOTIFICATION, payload: { id, notification } })
    yield put(
      notify({
        title: i18n.t('notifs.deleted'),
        status: 'success',
        dismissAfter: 4000,
        allowHTML: true,
      }),
    )
    navigate('/main/users-notifications')
    yield put(hideLoading())
  } catch (error) {
    yield put({
      type: DELETE_USERS_NOTIFICATIONS_REQUEST_ERROR,
      payload: error.response.data.messages,
    })
    yield put(hideLoading())
  }
}

function* getNotificationProductsWorker({ payload: { pageType } }) {
  try {
    yield put(showLoading())
    yield put({ type: GET_NOTIFICATION_PRODUCTS_REQUEST_PENDING })
    let response
    switch (pageType) {
      case 'SKU_PAGE':
        response = yield call(UsersNotificationsService.getNotificationSkuProducts)
        break
      case 'OFFER_PAGE':
        const res = yield call(UsersNotificationsService.getNotificationOffersId)
        const categoryId = res?.data?.data?.id
        if (categoryId) {
          response = yield call(UsersNotificationsService.getNotificationOfferProducts, categoryId)
        }
        break
      default:
        return null
    }
    const { result } = response.data.data
    const items = result.map((item) => {
      return { label: item.title, value: item.id }
    })
    yield put({ type: GET_NOTIFICATION_PRODUCTS, payload: items })

    yield put({ type: GET_NOTIFICATION_PRODUCTS_REQUEST_SUCCESS })
    yield put(hideLoading())
  } catch (error) {
    yield put({
      type: GET_USERS_NOTIFICATIONS_REQUEST_ERROR,
      payload: error.response.data.messages,
    })
    yield put(hideLoading())
  }
}

function* getNotificationAreasWorker() {
  try {
    yield put(showLoading())
    yield put({ type: GET_NOTIFICATION_AREAS_PENDING })
    const response = yield call(UsersNotificationsService.getNotificationDeliveryAreas)
    const { result } = response.data.data
    const areas = result.map((item) => {
      return { label: item.name, value: item.id }
    })
    yield put({ type: GET_NOTIFICATION_AREAS, payload: areas })

    yield put({ type: GET_NOTIFICATION_AREAS_SUCCESS })
    yield put(hideLoading())
  } catch (error) {
    yield put({ type: GET_NOTIFICATION_AREAS_ERROR, payload: error.response.data.messages })
    yield put(hideLoading())
  }
}
export default function* usersNotifications() {
  yield all([
    fork(getUsersNotificationsWatcher),
    fork(getUsersNotificationWatcher),
    fork(updateUsersNotificationWatcher),
    fork(createUsersNotificationWatcher),
    fork(deleteUsersNotificationWatcher),
    fork(sendUsersNotificationWatcher),
    fork(getNotificationProductsWatcher),
    fork(getNotificationAreasWatcher),
  ])
}
