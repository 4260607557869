import axios from 'axios'

class FilesService {
  uploadFile = (data, id = '') =>
    axios({
      url: `files/${id}`,
      method: 'PATCH',
      data,
    })
}

export default new FilesService()
