import moment from 'moment'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import usePrevious from 'hooks/usePrevious'
import useClient from 'hooks/useClient'
import { useDispatch, useSelector } from 'react-redux'
import { getMosques, getSupMosques } from 'redux/actions/mosques'
import { sortByAlphabet, sortByDate } from 'utils/sorters'
import { DATE_FORMAT, PERMISSIONS } from 'utils/constants'
import SortableColumnTitle from 'components/SortableColumnTitle'
import PageTable from 'components/PageTable'
import Button from 'components/Button'

const Mosques = () => {
  const { t } = useTranslation()
  const { isClientCan } = useClient()
  const dispatch = useDispatch()
  const [search, setSearch] = useState('')
  const prevSearch = usePrevious(search)

  const { items, pagination, isGetLoading } = useSelector((state) => state.mosques)

  const isClientCanUpdateMosques = isClientCan(PERMISSIONS.UPDATE_MOSQUES)

  const refreshMosques = (page, size, search) => dispatch(getMosques({ page, size, search }))

  useEffect(() => {
    if (!items.length) {
      refreshMosques(pagination.current, pagination.pageSize, search)
    }
  }, [])

  // Reload when search values was changed and set first page
  useEffect(() => {
    if (prevSearch !== undefined && search !== prevSearch) {
      refreshMosques(1, pagination.pageSize, search)
    }
  }, [search, prevSearch])

  const tableChangeHandler = (pagination) => refreshMosques(pagination.current, pagination.pageSize)

  const columns = [
    {
      title: '№',
      dataIndex: 'id',
      key: 'id',
      width: '80px',
      align: 'center',
    },
    {
      title: 'SapID',
      dataIndex: 'sapId',
      key: 'sapId',
    },
    {
      title: (props) => <SortableColumnTitle {...props} title={t('sidebar.mosques')} id="name" />,
      dataIndex: 'name',
      key: 'name',
      sorter: (a, b) => sortByAlphabet(a.name, b.name),
    },
    {
      title: (props) => <SortableColumnTitle {...props} title={t('fields.date')} id="date" />,
      dataIndex: 'createdAt',
      key: 'createdAt',
      sorter: (a, b) => sortByDate(a.createdAt, b.createdAt),
      render: (createdAt) => createdAt && moment(createdAt).format(DATE_FORMAT),
      defaultSortOrder: 'descend',
    },
  ]

  const refreshSupMosques = () => dispatch(getSupMosques())

  return (
    <PageTable
      pageTitle={t('sidebar.mosques')}
      action={
        isClientCanUpdateMosques ? (
          <Button type="primary" onClick={refreshSupMosques}>
            <span className="btn-text">{t('promotions.pull')}</span>
          </Button>
        ) : null
      }
      baseData={items}
      columns={columns}
      loading={isGetLoading}
      onChange={tableChangeHandler}
      searchHandler={(value) => setSearch(value.trim())}
      pagination={{
        ...pagination,
        showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
        showSizeChanger: pagination.total >= 10,
      }}
    />
  )
}

export default Mosques
