import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { getNotificationProducts } from '../../../redux/actions/usersNotifications'
import { useNavigate } from 'react-router-dom'
import Page from '../../../components/Page'
import CouponForm from '../../../components/forms/CouponsForm'
import { createCoupon } from '../../../redux/actions/coupons'

const CouponsAdd = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { isChangeLoading } = useSelector((state) => state.products)
  const { products } = useSelector((state) => state.usersNotifications)

  const submitHandler = (data) => {
    dispatch(
      createCoupon({
        data,
        navigate,
      }),
    )
  }

  const fetchProducts = (pageType) => {
    dispatch(getNotificationProducts({ pageType }))
  }

  useEffect(() => {
    fetchProducts('SKU_PAGE')
  }, [])

  return (
    <Page pageTitle={t('coupons.new coupon')} withCancelBtn={true}>
      <CouponForm products={products} submitHandler={submitHandler} loading={isChangeLoading} />
    </Page>
  )
}

export default CouponsAdd
