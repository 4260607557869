import {
  CREATE_COUPON,
  DELETE_COUPON,
  GET_COUPON,
  GET_COUPON_REQUEST_PENDING,
  GET_COUPON_REQUEST_SUCCESS,
  GET_COUPON_REQUEST_ERROR,
  GET_COUPONS,
  CREATE_COUPON_REQUEST_ERROR,
} from 'redux/types/coupons'

const initialState = {
  items: [],
  pagination: {
    current: 1,
    pageSize: 20,
  },
  item: {},
  isGetLoading: false,
  isChangeLoading: false,
  errors: [],
}

const coupons = (state = initialState, action) => {
  switch (action.type) {
    case GET_COUPONS:
      return { ...state, ...action.payload }
    case GET_COUPON:
      return { ...state, item: action.payload }
    case CREATE_COUPON:
      return { ...state, items: [...state.items, action.payload] }
    case DELETE_COUPON:
      return { ...state, items: state.items.filter((item) => item.id !== action.payload.id) }
    case GET_COUPON_REQUEST_PENDING:
      return { ...state, isGetLoading: true, errors: [] }
    case GET_COUPON_REQUEST_SUCCESS:
      return { ...state, isGetLoading: false }
    case GET_COUPON_REQUEST_ERROR:
    case CREATE_COUPON_REQUEST_ERROR:
      return { ...state, isGetLoading: false, errors: [...state.errors, action.payload] }
    default:
      return state
  }
}

export default coupons
