import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from '@tanstack/react-query'
import { useChartsDates } from './hooks/useChartsDates'
import { Col, Row } from 'antd'
import DashboardPeriodOffsetChanger from 'components/Dashboard/PeriodOffsetChanger/DashboardPeriodOffsetChanger'
import DashboardPeriodChanger from 'components/Dashboard/PeriodChanger/DashboardPeriodChanger'
import DashboardInfoCard from 'components/Dashboard/InfoCard/DashboardInfoCard'
import PreloaderBlock from 'components/PreloaderBlock/PreloaderBlock'
import DashboardService from 'redux/middlewares/dashboard'
import { DASHBOARD_PERIODS } from 'utils/constants'
import baseStyles from './styles/base.module.scss'

const OrdersConversionChart = ({ globalStartDate, globalFinishDate }) => {
  const { t } = useTranslation()
  const [datePeriod, setDatePeriod] = useState(DASHBOARD_PERIODS.CUSTOM_DATE)
  const [periodOffset, setPeriodOffset] = useState(0)
  const { startDate, finishDate } = useChartsDates({
    globalStartDate: globalStartDate,
    globalFinishDate: globalFinishDate,
    period: datePeriod,
    offset: periodOffset,
  })

  const { data: orderConversionStats, isFetching: isOrderConversionStatsFetching } = useQuery(
    ['dashboard-orders-conversion', startDate, finishDate],
    async () => {
      const response = await DashboardService.getOrdersConversionStats({
        startDate: startDate.toISOString(),
        finishDate: finishDate.toISOString(),
      })

      return response.data.data
    },
    {
      staleTime: 1000 * 60 * 10, // 10 minute
      refetchOnMount: 'always',
    },
  )

  return (
    <div className={baseStyles.chart}>
      <div className={baseStyles.chart__header}>
        <div className={baseStyles.chart__headerTop}>
          <p className={baseStyles.chart__headerTitle}>{t('dashboard.orders conversion')}</p>
          <DashboardPeriodChanger
            periods={[
              DASHBOARD_PERIODS.DAY,
              DASHBOARD_PERIODS.WEEK,
              DASHBOARD_PERIODS.MONTH,
              DASHBOARD_PERIODS.YEAR,
              DASHBOARD_PERIODS.CUSTOM_DATE,
            ]}
            defaultPeriod={datePeriod}
            onChange={(period) => setDatePeriod(period)}
          />
        </div>
      </div>
      <div className={baseStyles.chart__body}>
        {!isOrderConversionStatsFetching ? (
          <Row gutter={[20, 20]} style={{ width: '100%' }}>
            <Col span={24} lg={{ span: 8 }}>
              <DashboardInfoCard
                title={t('dashboard.new customers')}
                value={orderConversionStats.totalUserCountForPeriod}
                height={137}
                colorTheme={'mediumBlue'}
                fatValue={true}
              />
            </Col>
            <Col span={24} lg={{ span: 8 }}>
              <DashboardInfoCard
                title={t('dashboard.active and total users')}
                value={`${orderConversionStats.activeUserCountForPeriod} / ${orderConversionStats.totalUserCountForPeriod}`}
                height={137}
                fatValue={true}
              />
            </Col>
            <Col span={24} lg={{ span: 8 }}>
              <DashboardInfoCard
                title={t('dashboard.active users percentage')}
                value={
                  orderConversionStats.activeUserPercentage !== null
                    ? orderConversionStats.activeUserPercentage % 1 !== 0
                      ? `${orderConversionStats.activeUserPercentage.toFixed(2)}%`
                      : `${orderConversionStats.activeUserPercentage}%`
                    : '0%'
                }
                height={137}
                fatValue={true}
              />
            </Col>
          </Row>
        ) : (
          <PreloaderBlock height={137} />
        )}
      </div>
      <div className={baseStyles.chart__footer}>
        <div className={baseStyles.chart__offsetChangerWrap}>
          <DashboardPeriodOffsetChanger
            startDate={globalStartDate}
            finishDate={globalFinishDate}
            period={datePeriod}
            disabled={
              datePeriod === DASHBOARD_PERIODS.CUSTOM_DATE || isOrderConversionStatsFetching
            }
            changeOffsetCallback={(newOffset) => {
              setPeriodOffset(newOffset)
            }}
          />
        </div>
      </div>
    </div>
  )
}

export default OrdersConversionChart
