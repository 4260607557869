import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { createCustomer } from '../../../redux/actions/customers'
import { useNavigate } from 'react-router-dom'
import Page from '../../../components/Page'
import CustomerForm from '../../../components/forms/CustomerForm'

const CustomersAdd = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const navigate = useNavigate()

  const { isChangeLoading } = useSelector((state) => state.customers)

  const submitHandler = (data) => {
    dispatch(
      createCustomer({
        data,
        navigate,
      }),
    )
  }

  return (
    <Page pageTitle={t('customers.new customer')} withCancelBtn={true}>
      <CustomerForm submitHandler={submitHandler} loading={isChangeLoading} />
    </Page>
  )
}

export default CustomersAdd
