import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { getPayment } from '../../redux/actions/payment'
import { Spin } from 'antd'
import Button from '../../components/Button'
import moment from 'moment'
import { DATE_FORMAT } from '../../utils/constants'

function useQuery() {
  return new URLSearchParams(useLocation().search)
}

const Payment = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { isSuccessPayment, isLoading, order, payment } = useSelector((state) => state.payment)
  const responseGetaway = JSON.parse(payment?.gatewayResponse)
  const query = useQuery()

  const checkPayment = (referenceNumber) => {
    dispatch(
      getPayment({
        referenceNumber,
      }),
    )
  }

  const queryOrder = query.get('order_no')
  const queryReference = query.get('reference_number')

  useEffect(() => {
    if (queryOrder && queryReference) {
      checkPayment(queryReference)
    }
  }, [queryOrder, queryReference])

  return (
    <div className="paymentPage">
      <Spin spinning={isLoading} />
      <div className="">
        {isSuccessPayment ? (
          <>
            <h3>{t('payment.order successful')}</h3>
            <p>{t('payment.thank you for shopping with us')}</p>
            <p className="caption-text">{t('payment.order summary')}</p>
            <p>
              {t('payment.date of Order')}:{moment(payment?.createdAt).format(DATE_FORMAT)}
            </p>
            <p>
              {t('payment.order number')}: {payment?.orderNumber}
            </p>
            <p>
              {t('payment.name')}: {order?.fullName}
            </p>
            <p>
              {t('payment.mobile number')}: {order?.phoneNumber}
            </p>
            <p>
              {t('payment.total amount')}: {order?.subTotalAmount}
            </p>
            <p>
              {t('payment.payment type')}: {order?.paymentMethod}
            </p>
            <p>
              {t('payment.payment status')}: {order?.paymentStatus}
            </p>
            <p>Payment ID: {responseGetaway ? responseGetaway?.paymentid : null}</p>
            <p>Auth. Code: {responseGetaway ? responseGetaway?.auth : null}</p>
            <p>Track ID: {responseGetaway ? responseGetaway?.trackid : null}</p>
            <p>{t('payment.date of delivery')}: Your order will be delivered within 48 hours</p>
            <p>{t('payment.delivery address')}:</p>
            <Button className="notFound-btn">{t('payment.go to shop')}</Button>
          </>
        ) : (
          <p>
            {' '}
            {t('payment.status')} :{t('notifs.error title')}
          </p>
        )}
      </div>
    </div>
  )
}

export default Payment
