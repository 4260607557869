import classNames from 'classnames'
import { useCallback, useEffect, useMemo, useRef } from 'react'
import { Button, Carousel } from 'antd'
import Icon from 'components/Icon'
import { DASHBOARD_PERIODS, DATE_FORMAT, ICONS } from 'utils/constants'
import {
  getCustomPeriodDates,
  getAmPeriodDates,
  getPmPeriodDates,
  getDayPeriodDates,
  getMonthPeriodDates,
  getWeekPeriodDates,
  getYearPeriodDates,
} from '../Charts/helpers/datesRangeGenerators'
import styles from './style.module.scss'

const DashboardPeriodOffsetChanger = ({
  startDate,
  finishDate,
  period,
  changeOffsetCallback,
  disabled = false,
}) => {
  const carouselRef = useRef()
  const maxPeriodsCount = 100

  // Construct periods slides
  const slides = useMemo(() => {
    let slides = []

    for (let i = maxPeriodsCount; i > 0; i--) {
      const slideOffset = maxPeriodsCount - i
      let generatedDates

      switch (period) {
        case DASHBOARD_PERIODS.CUSTOM_DATE:
          generatedDates = getCustomPeriodDates(startDate, finishDate)
          break
        case DASHBOARD_PERIODS.YEAR:
          generatedDates = getYearPeriodDates(slideOffset)
          break
        case DASHBOARD_PERIODS.MONTH:
          generatedDates = getMonthPeriodDates(slideOffset)
          break
        case DASHBOARD_PERIODS.WEEK:
          generatedDates = getWeekPeriodDates(slideOffset)
          break
        case DASHBOARD_PERIODS.DAY:
          generatedDates = getDayPeriodDates(slideOffset)
          break
        case DASHBOARD_PERIODS.AM:
          generatedDates = getAmPeriodDates(slideOffset)
          break
        case DASHBOARD_PERIODS.PM:
          generatedDates = getPmPeriodDates(slideOffset)
          break
        default:
          generatedDates = getDayPeriodDates(slideOffset)
          break
      }

      const slide = (
        <div
          key={i}
          className={classNames({
            [`${styles.slide}`]: true,
            [`${styles.slide_disabled}`]: disabled,
          })}
        >
          <span className={styles.slide__text}>
            {[
              DASHBOARD_PERIODS.CUSTOM_DATE,
              DASHBOARD_PERIODS.YEAR,
              DASHBOARD_PERIODS.MONTH,
              DASHBOARD_PERIODS.WEEK,
            ].includes(period) &&
              `${generatedDates.startDate.format(DATE_FORMAT)} - ${generatedDates.finishDate.format(
                DATE_FORMAT,
              )}`}
            {[DASHBOARD_PERIODS.DAY].includes(period) &&
              generatedDates.startDate.format(DATE_FORMAT)}
            {[DASHBOARD_PERIODS.AM, DASHBOARD_PERIODS.PM].includes(period) &&
              `${generatedDates.startDate.format(
                'DD MMM (HH:mm)',
              )} - ${generatedDates.finishDate.format('DD MMM (HH:mm)')}`}
          </span>
        </div>
      )
      slides = [slide, ...slides]
    }

    return slides
  }, [maxPeriodsCount, startDate, finishDate, period, disabled])

  // Set last slide as initial slide
  const initialSlide = useMemo(() => slides.length, [slides])

  // Slide change handler
  const handleSlideChange = useCallback(
    (currentSlide) => {
      const newOffset = slides.length - 1 - currentSlide
      changeOffsetCallback && changeOffsetCallback(newOffset)
    },
    [slides, changeOffsetCallback],
  )

  // Reset offset when period was changed
  useEffect(() => {
    changeOffsetCallback && changeOffsetCallback(0)
    if (carouselRef.current) {
      carouselRef.current.goTo(initialSlide, false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [period])

  // Go to initial slide first time
  useEffect(() => {
    if (carouselRef.current) {
      carouselRef.current.goTo(initialSlide, false)
    }
  }, [carouselRef, initialSlide])

  const SlickArrowLeft = ({ currentSlide, slideCount, ...props }) => {
    const isDisabled = currentSlide === 0 || disabled

    return (
      <Button
        {...props}
        className={classNames({
          [`${styles.navButton}`]: true,
          [`slick-prev`]: true,
          [`slick-arrow`]: true,
          [`slick-disabled`]: isDisabled,
        })}
        aria-hidden="true"
        aria-disabled={currentSlide === 0}
        type={'text'}
        disabled={isDisabled}
        icon={
          <Icon icon={ICONS.CHEVRON_LEFT} color={!isDisabled ? '#101d37' : '#c2c2c2'} size={24} />
        }
      ></Button>
    )
  }

  const SlickArrowRight = ({ currentSlide, slideCount, ...props }) => {
    const isDisabled = currentSlide === slideCount - 1 || disabled

    return (
      <Button
        {...props}
        className={classNames({
          [`${styles.navButton}`]: true,
          [`slick-next`]: true,
          [`slick-arrow`]: true,
          [`slick-disabled`]: isDisabled,
        })}
        aria-hidden="true"
        aria-disabled={currentSlide === slideCount - 1}
        type={'text'}
        disabled={isDisabled}
        icon={
          <Icon icon={ICONS.CHEVRON_RIGHT} color={!isDisabled ? '#101d37' : '#c2c2c2'} size={24} />
        }
      ></Button>
    )
  }

  return (
    <div>
      <Carousel
        ref={carouselRef}
        infinite={false}
        prevArrow={<SlickArrowLeft />}
        nextArrow={<SlickArrowRight />}
        initialSlide={initialSlide}
        dots={false}
        arrows={true}
        speed={300}
        afterChange={handleSlideChange}
      >
        {slides && slides.map((oneSlide) => oneSlide)}
      </Carousel>
    </div>
  )
}

export default DashboardPeriodOffsetChanger
