import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useQuery } from '@tanstack/react-query'
import { useNavigate, useParams } from 'react-router-dom'
import { Button, Col, Divider, Row, Table, Typography } from 'antd'
import moment from 'moment/moment'
import classNames from 'classnames'

import { getCustomer, setCustomer, updateCustomer } from '../../../redux/actions/customers'
import { getUserTransactions, getUserWallet } from '../../../api/wallet-transactions'

import Page from '../../../components/Page'
import SortableColumnTitle from '../../../components/SortableColumnTitle'
import CustomerForm from '../../../components/forms/CustomerForm'
import EditWalletModal from '../../../components/Modal/modals/EditWalletModal/EditWalletModal'

import styles from './CustomersView.module.scss'

const { Title } = Typography

const ACTIVE_BUTTON = {
  ALL: 'ALL',
  WITHDRAW: 'WITHDRAW',
  DEPOSIT: 'DEPOSIT',
}

const CustomersView = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { id } = useParams()
  const [activeButton, setActiveButton] = useState(ACTIVE_BUTTON.ALL)
  const [isModalVisible, setIsModalVisible] = useState(false)
  const { item: customer, isGetLoading, isChangeLoading } = useSelector((state) => state.customers)
  const [pageSize, setPageSize] = useState(10)
  const [currentPage, setCurrentPage] = useState(1)
  const [sortType, setSortType] = useState('createdAt')
  const [sortOrder, setSortOrder] = useState('descend')
  const [transactionType, setTransactionType] = useState(ACTIVE_BUTTON.ALL)

  const {
    data: transactions,
    isFetching,
    refetch: refetchTransactions,
    isError,
  } = useQuery(
    ['wallet-transactions', currentPage, pageSize, sortType, sortOrder, transactionType],
    async () =>
      await getUserTransactions(id, {
        currentPage,
        pageSize,
        sortType,
        sortOrder,
        transactionType,
      }),
  )

  const {
    data: wallet,
    isFetching: isWalletFetching,
    refetch: refetchWallet,
  } = useQuery(['wallet'], async () => await getUserWallet(id))

  const handleButtonClick = (buttonName) => {
    setActiveButton(buttonName === activeButton ? ACTIVE_BUTTON.ALL : buttonName)
    setTransactionType(buttonName)
  }

  const columns = [
    {
      title: '№',
      dataIndex: 'id',
      key: 'id',
      align: 'center',
      width: 57,
      render: (_, __, index) => index + 1 + pageSize * (currentPage - 1),
    },
    {
      title: (props) => (
        <SortableColumnTitle {...props} title={t('wallet.table.order')} id="orderNumber" />
      ),
      dataIndex: 'orderNumber',
      key: 'orderNumber',
      sorter: true,
      render: (_, record) => {
        return <span>{record.orderNumber || '-'}</span>
      },
    },
    {
      title: t('wallet.table.orderAmount'),
      dataIndex: 'orderAmount',
      key: 'orderAmount',

      render: (_, record) => {
        const amount = record.orderAmount?.slice(0, -1)
        return <span>{record.orderAmount ? t('wallet.currency', { amount }) : '-'}</span>
      },
    },
    {
      title: t('wallet.table.performedBy'),
      dataIndex: 'actorType',
      key: 'actorType',
      align: 'center',
      render: (_, record) => {
        return <span>{record.actorType === 'ADMIN' ? record.adminName : record.userName}</span>
      },
    },
    {
      title: t('wallet.table.type'),
      dataIndex: 'transactionType',
      key: 'transactionType',
      align: 'center',
      render: (_, record) => {
        return <span>{record.transactionType === 'WITHDRAW' ? 'Outcome' : 'Income'}</span>
      },
    },
    {
      title: t('wallet.table.amount'),
      dataIndex: 'transactionAmount',
      key: 'transactionAmount',
      align: 'center',
      render: (_, record) => {
        const amount = record.transactionAmount.slice(0, -1)
        return <span>{t('wallet.currency', { amount })}</span>
      },
    },
    {
      title: (props) => (
        <SortableColumnTitle {...props} title={t('wallet.table.date')} id="createdAt" />
      ),
      dataIndex: 'createdAt',
      defaultSortOrder: 'descend',
      key: 'createdAt',
      render: (createdAt) => <span>{moment(createdAt).format('MM/DD/YYYY hh:mm:ss')}</span>,
      sorter: true,
      width: 200,
    },
  ]

  const submitHandler = ({ id, ...data }) => {
    dispatch(
      updateCustomer({
        id,
        data,
        navigate,
      }),
    )
  }

  const handleWalletAmountEdit = () => {
    setIsModalVisible(true)
  }

  const handleWalletEditOnSubmit = async () => {
    setIsModalVisible(false)
    await refetchTransactions()
    await refetchWallet()
  }

  const handleTableChange = (paginate, filter, sort) => {
    setCurrentPage(paginate.current)
    setPageSize(paginate.pageSize)
    setSortType(sort.columnKey)
    setSortOrder(sort.order)
  }

  useEffect(() => {
    dispatch(
      getCustomer({
        id,
        navigate,
      }),
    )
    return () => {
      // clear redux on unmount component
      dispatch(setCustomer({}))
    }
  }, [id, dispatch, navigate])

  return (
    <Page
      pageTitle={customer.users_fullName}
      isGetLoading={isGetLoading}
      isChangeLoading={isChangeLoading}
    >
      <CustomerForm
        defaultValues={{ ...customer, walletBalance: isWalletFetching ? '0' : wallet.balance }}
        submitHandler={submitHandler}
        loading={isChangeLoading || isWalletFetching}
        handleWalletAmountEdit={handleWalletAmountEdit}
      />
      <Divider />
      <Title level={3}>{t('wallet.table.header')}</Title>

      <Row className={classNames([styles.tableHeader])} justify="space-between">
        <Col span={5}>
          <Button
            className={classNames([
              styles.tableHeader__button,
              activeButton === ACTIVE_BUTTON.ALL && styles.tableHeader__button_active,
            ])}
            type="text"
            size="large"
            onClick={() => handleButtonClick(ACTIVE_BUTTON.ALL)}
          >
            {t('wallet.table.all')}
          </Button>
        </Col>
        <Col span={6}>
          <Button
            className={classNames([
              styles.tableHeader__button,
              activeButton === ACTIVE_BUTTON.DEPOSIT && styles.tableHeader__button_active,
            ])}
            type="text"
            size="large"
            onClick={() => handleButtonClick(ACTIVE_BUTTON.DEPOSIT)}
          >
            {t('wallet.table.income')}
          </Button>
        </Col>
        <Col span={8}>
          <Button
            className={classNames([
              styles.tableHeader__button,
              activeButton === ACTIVE_BUTTON.WITHDRAW && styles.tableHeader__button_active,
            ])}
            type="text"
            size="large"
            onClick={() => handleButtonClick(ACTIVE_BUTTON.WITHDRAW)}
          >
            {t('wallet.table.outcome')}
          </Button>
        </Col>
      </Row>
      <Table
        columns={columns}
        dataSource={isFetching || isError ? [] : transactions.items}
        rowKey={(record) => record.id}
        style={{ height: 'auto' }}
        onChange={handleTableChange}
        onRow={(record) => {
          return {
            onClick: () => {
              if (record.orderId) navigate(`/main/orders/view/${record.orderId}`)
            },
          }
        }}
        loading={isFetching}
        pagination={{
          pageSize,
          current: currentPage,
          showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,

          total: isFetching || isError ? 0 : transactions.total,
        }}
      />
      <EditWalletModal
        isVisible={isModalVisible}
        onSubmit={handleWalletEditOnSubmit}
        onClose={() => setIsModalVisible(false)}
        walletAmount={isWalletFetching ? 0 : wallet.balance}
        userId={id}
      />
    </Page>
  )
}

export default CustomersView
