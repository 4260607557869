import axios from 'axios'
import { notify } from 'reapop'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Skeleton, Space, Switch } from 'antd'
import { useNavigate, useParams } from 'react-router-dom'
import { useQueryClient, useMutation, useQuery } from '@tanstack/react-query'
import { useDispatch } from 'react-redux'
import Page from 'components/Page'
import PromocodeForm from 'components/forms/PromocodeForm'
import { getPromocodeById, updatePromocode } from 'api/promocodes'
import parsePromocodeData from 'helpers/promocodes/parsePromocodeData'

const PromocodesView = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const queryClient = useQueryClient()
  const navigate = useNavigate()
  const { id } = useParams()

  const {
    data: promocode,
    isLoading: isPromocodeLoading,
    isFetching: isPromocodeFetching,
    refetch: refetchPromocode,
  } = useQuery([`getPromocode-${id}`], async () => await getPromocodeById(id))

  const updateMutation = useMutation(updatePromocode, {
    onSuccess: () => {
      queryClient.invalidateQueries(['promocodesTable', `getPromocode-${id}`])
      dispatch(notify({ title: t('notifs.success'), status: 'success', dismissAfter: 4000 }))
      navigate('/main/promocodes')
    },
    onError: (error) =>
      axios.isAxiosError(error) &&
      dispatch(notify({ title: error.message, status: 'error', dismissAfter: 4000 })),
  })

  const isActiveMutation = useMutation(updatePromocode, {
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries(['promocodesTable', `getPromocode-${id}`])
      refetchPromocode()
      dispatch(
        notify({
          title: t(
            'promocodes.notify promocode was ' + (data.isActive ? 'activated' : 'deactivated'),
          ),
          status: data.isActive ? 'success' : 'warning',
          dismissAfter: 4000,
        }),
      )
    },
    onError: (error) =>
      axios.isAxiosError(error) &&
      dispatch(notify({ title: error.message, status: 'error', dismissAfter: 4000 })),
  })

  const submitHandler = (formData) =>
    updateMutation.mutate({ id, promocode: parsePromocodeData(formData) })

  const isActiveSwitch = useMemo(() => {
    if (!promocode) return <Skeleton.Button active={true} style={{ width: '92px' }} />

    return (
      <Space>
        <Switch
          defaultChecked={promocode.isActive}
          onChange={(isActive) => {
            promocode.isActive = isActive
            isActiveMutation.mutate({ id, promocode: { isActive } })
          }}
          disabled={isActiveMutation.isLoading}
          loading={isActiveMutation.isLoading}
        />
        <span style={{ fontSize: '13px' }}>Turn {promocode.isActive ? 'On' : 'Off'}</span>
      </Space>
    )
  }, [isActiveMutation, promocode, id])

  return (
    <Page pageTitle={promocode?.title || ''} customActions={[isActiveSwitch]}>
      <PromocodeForm
        submitHandler={submitHandler}
        recordLoading={isPromocodeLoading || isPromocodeFetching}
        submitLoading={updateMutation.isLoading}
        defaultValues={promocode}
      />
    </Page>
  )
}

export default PromocodesView
