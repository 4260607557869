import moment from 'moment'
import classNames from 'classnames'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { yupResolver } from '@hookform/resolvers/yup'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { notify } from 'reapop'
import { useNavigate } from 'react-router-dom'
import { Col, Popconfirm, Row, Skeleton, Space } from 'antd'
import { FormProvider, useForm } from 'react-hook-form'
import HookedField from 'components/HookedField'
import Button from 'components/Button'
import PromocodeFormSkeleton from './PromocodeFormSkeleton'
import { deletePromocode } from 'api/promocodes'
import { getProducts } from 'api/products'
import { promocodeSchema } from 'utils/schemas'
import { PROMOCODE_TYPES } from 'utils/constants'
import 'assets/styles/forms/_promocodeForm.scss'

const ALL_PRODUCTS_OPTION = {
  KEY: 'all',
  LABEL: 'Everything',
}

const PromocodeForm = ({
  defaultValues = null,
  submitHandler,
  recordLoading = false,
  submitLoading = false,
}) => {
  const { t } = useTranslation()
  const queryClient = useQueryClient()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [isAwaitingRange, setIsAwaitingRange] = useState(false)
  const [isActiveRange, setIsActiveRange] = useState(false)
  const [isExpiredRange, setIsExpiredRange] = useState(false)
  const [isNewRecord, setIsNewRecord] = useState(false)
  const [isInactive, setIsInactive] = useState(false)
  const [isDeleteError, setIsDeleteError] = useState(false)

  const { isLoading: isProductSelectLoading, data: productsData } = useQuery(
    ['allProducts'],
    async () => await getProducts(),
  )

  const deletePromocodeMutation = useMutation(deletePromocode, {
    onSuccess: () => {
      queryClient.invalidateQueries(['promocodesTable', `getPromocode-${defaultValues?.id}`])
      dispatch(notify({ title: t('notifs.success'), status: 'success', dismissAfter: 4000 }))
      navigate('/main/promocodes')
    },
    onError: () => {
      setIsDeleteError(true)
      dispatch(
        notify({
          title: 'This promocode not available for delete!',
          status: 'error',
          dismissAfter: 4000,
        }),
      )
    },
  })

  const methods = useForm({
    mode: 'onSubmit',
    shouldFocusError: true,
    resolver: yupResolver(promocodeSchema),
  })

  const discountType = methods.watch('discountType')
  const selectedProducts = methods.watch('productId')
  const maxCodeAmount = useMemo(
    () => (discountType === PROMOCODE_TYPES.PERCENT.KEY ? 100 : null),
    [discountType],
  )
  const productsSelectOptions = useMemo(() => {
    const options = [
      {
        label: ALL_PRODUCTS_OPTION.LABEL,
        value: ALL_PRODUCTS_OPTION.KEY,
      },
    ]

    if (productsData && Array.isArray(productsData.result)) {
      productsData.result.map((oneProduct) =>
        options.push({
          label: oneProduct.title,
          value: oneProduct.id,
        }),
      )
    }

    return options
  }, [productsData])

  useEffect(() => {
    const now = moment()
    setIsAwaitingRange((defaultValues?.id && now.diff(defaultValues.startDate) < 0) || false)
    setIsActiveRange(
      (defaultValues?.id &&
        now.diff(defaultValues.startDate) > 0 &&
        now.diff(defaultValues.finishDate) < 0) ||
        false,
    )
    setIsExpiredRange((defaultValues?.id && now.diff(defaultValues.finishDate) > 0) || false)
    setIsNewRecord(isNaN(parseInt(defaultValues?.id)))
    setIsInactive((defaultValues?.id && !defaultValues.isActive) || false)

    methods.setValue(
      'discountType',
      defaultValues?.discountType ? defaultValues.discountType : PROMOCODE_TYPES.MONEY.KEY,
    )
  }, [defaultValues])

  useEffect(() => {
    methods.setValue('defaultAwaitingRange', isAwaitingRange)
    methods.setValue('defaultActiveRange', isActiveRange)
    methods.setValue('defaultExpiredRange', isExpiredRange)
    methods.setValue('defaultNewRecord', isNewRecord)
    methods.setValue('defaultInactive', isInactive)
  }, [isAwaitingRange, isActiveRange, isExpiredRange, isNewRecord, isInactive])

  useEffect(() => {
    // Reset promocode amount to max value of percents range
    if (
      discountType === PROMOCODE_TYPES.PERCENT.KEY &&
      methods.getValues('discountQuantity') > 100
    ) {
      methods.setValue('discountQuantity', 100)
    }
  }, [discountType])

  useEffect(() => {
    if (Array.isArray(selectedProducts) && selectedProducts.length > 1) {
      const lastSelectedProduct = selectedProducts[selectedProducts.length - 1] || null
      if (lastSelectedProduct === ALL_PRODUCTS_OPTION.KEY) {
        // Remove another products from select, if user select all products option
        methods.setValue('productId', [ALL_PRODUCTS_OPTION.KEY])
      } else {
        // Remove all products option, if user select one another product
        if (selectedProducts.indexOf(ALL_PRODUCTS_OPTION.KEY) !== -1) {
          methods.setValue(
            'productId',
            selectedProducts.filter((option) => option !== ALL_PRODUCTS_OPTION.KEY),
          )
        }
      }
    }
  }, [selectedProducts])

  if (recordLoading) return <PromocodeFormSkeleton />

  return (
    <div className="promocode-form">
      <FormProvider {...methods}>
        <form className="form" autoComplete="off" onSubmit={methods.handleSubmit(submitHandler)}>
          <div className="form-title">{t('promocodes.general info')}</div>
          {/* START: Extra data for YUP validation */}
          <HookedField
            name="id"
            defaultValue={defaultValues?.id}
            fieldType="hiddenField"
            type="hidden"
          />
          <HookedField
            name="defaultAwaitingRange"
            defaultValue={isAwaitingRange}
            fieldType="hiddenField"
            type="hidden"
          />
          <HookedField
            name="defaultActiveRange"
            defaultValue={isActiveRange}
            fieldType="hiddenField"
            type="hidden"
          />
          <HookedField
            name="defaultExpiredRange"
            defaultValue={isExpiredRange}
            fieldType="hiddenField"
            type="hidden"
          />
          <HookedField
            name="defaultNewRecord"
            defaultValue={isNewRecord}
            fieldType="hiddenField"
            type="hidden"
          />
          <HookedField
            name="defaultInactive"
            defaultValue={isInactive}
            fieldType="hiddenField"
            type="hidden"
          />
          {/* End: Extra data for YUP validation */}
          <Row gutter={[20, 5]}>
            <Col span={24} lg={{ span: 12 }}>
              <HookedField
                label={t('promocodes.field title title')}
                name="title"
                defaultValue={defaultValues?.title && defaultValues.title}
                disabled={!isNewRecord}
                placeholder={t('promocodes.field title placeholder')}
                isRequired={true}
                fieldType="textField"
                type="text"
              />
            </Col>
            <Col span={24} lg={{ span: 12 }}>
              <HookedField
                label={t('promocodes.field promoCodeName title')}
                name="promoCodeName"
                defaultValue={defaultValues?.promoCodeName && defaultValues.promoCodeName}
                disabled={isActiveRange || isExpiredRange}
                placeholder={t('promocodes.field promoCodeName placeholder')}
                isRequired={true}
                fieldType="textField"
                type="text"
              />
            </Col>
            <Col span={24} lg={{ span: 12 }}>
              <HookedField
                label={t('promocodes.field startDate title')}
                name="startDate"
                defaultValue={defaultValues?.startDate && defaultValues.startDate}
                disabled={isActiveRange || isExpiredRange}
                placeholder={t('promocodes.field startDate placeholder')}
                disabledDate={(current) => current && current < moment().startOf('day')}
                isRequired={true}
                fieldType="dateTimeField"
                type="dateTimeField"
              />
            </Col>
            <Col span={24} lg={{ span: 12 }}>
              <HookedField
                label={t('promocodes.field finishDate title')}
                name="finishDate"
                defaultValue={defaultValues?.finishDate && defaultValues.finishDate}
                disabled={isExpiredRange}
                placeholder={t('promocodes.field finishDate placeholder')}
                disabledDate={(current) => current && current < moment().startOf('day')}
                isRequired={true}
                fieldType="dateTimeField"
                type="dateTimeField"
              />
            </Col>
            <Col span={24} lg={{ span: 12 }}>
              {!isProductSelectLoading ? (
                <HookedField
                  label={t('promocodes.field productId title')}
                  name="productId"
                  defaultValue={
                    Array.isArray(defaultValues?.productPromoCode) &&
                    defaultValues?.productPromoCode.length
                      ? defaultValues.productPromoCode.map((product) => product.productId)
                      : ['all']
                  }
                  placeholder={t('promocodes.field productId placeholder')}
                  mode="multiple"
                  options={productsSelectOptions}
                  filterOption={(input, option) =>
                    option.label.toLowerCase().includes(input.trim().toLowerCase())
                  }
                  popupClassName={'promocode-form-dropdown'}
                  loading={isProductSelectLoading}
                  disabled={isActiveRange || isExpiredRange}
                  isRequired={true}
                  fieldType="selectField"
                  type="select"
                />
              ) : (
                <Skeleton.Input active size={'large'} block={true} style={{ height: 50 }} />
              )}
            </Col>
          </Row>
          <div className={'promocode-form__divider'}></div>
          <div className="form-title">{t('promocodes.promotion details')}</div>
          <Space direction={'vertical'} size={15} style={{ width: '100%' }}>
            <Row gutter={[20, 5]}>
              <Col span={24} lg={{ span: 12 }}>
                <HookedField
                  name="discountType"
                  defaultValue={defaultValues?.discountType && defaultValues.discountType}
                  disabled={isActiveRange || isExpiredRange}
                  fieldType="hiddenField"
                  type="hidden"
                />
                <HookedField
                  label={t('promocodes.field discountQuantity title')}
                  name="discountQuantity"
                  defaultValue={defaultValues?.discountQuantity && defaultValues.discountQuantity}
                  disabled={isActiveRange || isExpiredRange}
                  min={0}
                  max={maxCodeAmount}
                  className={classNames({
                    'promocode-form__discountQuantityField': true,
                    'promocode-form__discountQuantityField--disabled':
                      isActiveRange || isExpiredRange,
                  })}
                  addonAfter={
                    <Space className={'promocode-form__code-type-changer'} gutter={20}>
                      <span
                        className={classNames({
                          'promocode-form__code-type-changer-item': true,
                          'promocode-form__code-type-changer-item--active':
                            discountType === PROMOCODE_TYPES.MONEY.KEY,
                        })}
                        onClick={() =>
                          !isActiveRange &&
                          !isExpiredRange &&
                          methods.setValue('discountType', PROMOCODE_TYPES.MONEY.KEY, {
                            shouldDirty: true,
                          })
                        }
                      >
                        {PROMOCODE_TYPES.MONEY.LABEL}
                      </span>
                      <span
                        className={classNames({
                          'promocode-form__code-type-changer-item': true,
                          'promocode-form__code-type-changer-item--active':
                            discountType === PROMOCODE_TYPES.PERCENT.KEY,
                        })}
                        onClick={() =>
                          !isActiveRange &&
                          !isExpiredRange &&
                          methods.setValue('discountType', PROMOCODE_TYPES.PERCENT.KEY, {
                            shouldDirty: true,
                          })
                        }
                      >
                        {PROMOCODE_TYPES.PERCENT.LABEL}
                      </span>
                    </Space>
                  }
                  parser={(value) =>
                    value.length > 0
                      ? value % 1 === 0
                        ? parseInt(value)
                        : parseFloat(value).toFixed(2)
                      : ''
                  }
                  placeholder={t('promocodes.field discountQuantity placeholder')}
                  isRequired={true}
                  fieldType="numberField"
                  type="number"
                />
              </Col>
              <Col span={24} lg={{ span: 12 }}>
                <HookedField
                  label={t('promocodes.field minOrderValue title')}
                  name="minOrderValue"
                  defaultValue={defaultValues?.minOrderValue && defaultValues.minOrderValue}
                  disabled={isActiveRange || isExpiredRange}
                  min={0}
                  prefix={PROMOCODE_TYPES.MONEY.LABEL}
                  parser={(value) =>
                    value.length > 0
                      ? value % 1 === 0
                        ? parseInt(value)
                        : parseFloat(value).toFixed(2)
                      : ''
                  }
                  placeholder={t('promocodes.field minOrderValue placeholder')}
                  isRequired={true}
                  fieldType="numberField"
                  type="number"
                />
              </Col>
              <Col span={24} lg={{ span: 12 }}>
                <HookedField
                  label={t('promocodes.field fullUsageCount title')}
                  name="fullUsageCount"
                  defaultValue={defaultValues?.fullUsageCount && defaultValues.fullUsageCount}
                  disabled={isActiveRange || isExpiredRange}
                  min={1}
                  placeholder={t('promocodes.field fullUsageCount placeholder')}
                  isRequired={true}
                  fieldType="numberField"
                  type="number"
                />
              </Col>
              <Col span={24} lg={{ span: 12 }}>
                <HookedField
                  label={t('promocodes.field userUsageCount title')}
                  name="userUsageCount"
                  defaultValue={defaultValues?.userUsageCount && defaultValues.userUsageCount}
                  disabled={isActiveRange || isExpiredRange}
                  min={1}
                  placeholder={t('promocodes.field userUsageCount placeholder')}
                  isRequired={true}
                  fieldType="numberField"
                  type="number"
                />
              </Col>
            </Row>
            <Row gutter={[20, 20]}>
              <Col span={24} lg={{ span: 12 }}>
                <Button
                  className="save-btn"
                  size="large"
                  type="primary"
                  htmlType="submit"
                  disabled={submitLoading || !methods.formState.isDirty}
                >
                  {t('app.save')}
                </Button>
              </Col>
              {defaultValues && defaultValues.id && (
                <Col span={24} lg={{ span: 12 }}>
                  <Popconfirm
                    title={'Are you sure?'}
                    onConfirm={() => deletePromocodeMutation.mutate(defaultValues.id)}
                    okText="Delete"
                    okType="danger"
                    cancelText="No"
                    disabled={isDeleteError}
                  >
                    <Button
                      className="delete-btn"
                      size="large"
                      type="danger"
                      disabled={isDeleteError}
                    >
                      {t('app.delete')}
                    </Button>
                  </Popconfirm>
                </Col>
              )}
            </Row>
          </Space>
        </form>
      </FormProvider>
    </div>
  )
}

export default PromocodeForm
