import { ORDER_STATUSES } from './constants'

export const getFromLocalStorage = (key) => {
  return localStorage.getItem(key)
}

export const setToLocalStorage = (key, value) => {
  localStorage.setItem(key, value)
}

export const removeFromLocalStorage = (key) => {
  if (localStorage && localStorage.getItem(key)) {
    localStorage.removeItem(key)
  }
}

export const getImageId = (src) => {
  let paths = []
  if (typeof src === 'string') {
    paths = src.split('/')
  }
  return paths[paths.length - 1] || null
}

export const roundPrice = (value) => {
  let price = parseFloat(value)
  if (!price) return 0
  return parseFloat(price.toFixed(3))
}

export const getStatusOptions = () => {
  const options = []

  for (let key in ORDER_STATUSES) {
    options.push({
      label: ORDER_STATUSES[key],
      value: key,
    })
  }

  return options
}

export const isObjectsEqual = (...objects) =>
  objects.every((obj) => JSON.stringify(obj) === JSON.stringify(objects[0]))

// Deep-clone a node and preserve contents/properties.
export const cloneNode = function cloneNode(node, javascriptEnabled) {
  // Recursively clone the node.
  var clone = node.nodeType === 3 ? document.createTextNode(node.nodeValue) : node.cloneNode(false)
  for (var child = node.firstChild; child; child = child.nextSibling) {
    if (javascriptEnabled === true || child.nodeType !== 1 || child.nodeName !== 'SCRIPT') {
      clone.appendChild(cloneNode(child, javascriptEnabled))
    }
  }

  if (node.nodeType === 1) {
    // Preserve contents/properties of special nodes.
    if (node.nodeName === 'CANVAS') {
      clone.width = node.width
      clone.height = node.height
      clone.getContext('2d').drawImage(node, 0, 0)
    } else if (node.nodeName === 'TEXTAREA' || node.nodeName === 'SELECT') {
      clone.value = node.value
    }

    // Preserve the node's scroll position when it loads.
    clone.addEventListener(
      'load',
      function () {
        clone.scrollTop = node.scrollTop
        clone.scrollLeft = node.scrollLeft
      },
      true,
    )
  }

  // Return the cloned node.
  return clone
}
