import {
  GET_USERS_NOTIFICATIONS_REQUEST,
  GET_USERS_NOTIFICATION_REQUEST,
  UPDATE_USERS_NOTIFICATION_REQUEST,
  CREATE_USERS_NOTIFICATION_REQUEST,
  DELETE_USERS_NOTIFICATION_REQUEST,
  SEND_USERS_NOTIFICATION_REQUEST,
  GET_NOTIFICATION_PRODUCTS_REQUEST,
  GET_NOTIFICATION_AREAS_REQUEST,
  GET_NOTIFICATION_AREAS,
} from '../types/usersNotifications'

export function getUsersNotifications(payload) {
  return {
    type: GET_USERS_NOTIFICATIONS_REQUEST,
    payload,
  }
}

export function getUsersNotification(payload) {
  return {
    type: GET_USERS_NOTIFICATION_REQUEST,
    payload,
  }
}

export function updateUsersNotification(payload) {
  return {
    type: UPDATE_USERS_NOTIFICATION_REQUEST,
    payload,
  }
}

export function createUsersNotification(payload) {
  return {
    type: CREATE_USERS_NOTIFICATION_REQUEST,
    payload,
  }
}

export function deleteUsersNotification(payload) {
  return {
    type: DELETE_USERS_NOTIFICATION_REQUEST,
    payload,
  }
}

export function sendUsersNotification(payload) {
  return {
    type: SEND_USERS_NOTIFICATION_REQUEST,
    payload,
  }
}

export function getNotificationProducts(payload) {
  return {
    type: GET_NOTIFICATION_PRODUCTS_REQUEST,
    payload,
  }
}

export function getNotificationAreas(payload) {
  return {
    type: GET_NOTIFICATION_AREAS_REQUEST,
    payload,
  }
}

export function setNotificationAreas(payload) {
  return {
    type: GET_NOTIFICATION_AREAS,
    payload,
  }
}
