import classNames from 'classnames'
import { ICONS } from 'utils/constants'
import Icon from 'components/Icon'

const ErrorList = ({ errors = [], isOnRequest = false }) => {
  const classes = classNames({
    errorList: true,
    'errorList--onRequest': isOnRequest,
  })

  return errors.length ? (
    <ul className={classes}>
      {errors.map((error, index) => (
        <li className="errorItem" key={index}>
          <Icon className="errorItem-icon" icon={ICONS.ALERT} size={12} />
          <span className="errorItem-text">{error}</span>
        </li>
      ))}
    </ul>
  ) : null
}

export default ErrorList
