import {
  CREATE_COUPON_REQUEST,
  DELETE_COUPON_REQUEST,
  GET_COUPON_REQUEST,
  GET_COUPONS_REQUEST,
} from '../types/coupons'

export function getCoupons(payload) {
  return {
    type: GET_COUPONS_REQUEST,
    payload,
  }
}

export function createCoupon(payload) {
  return {
    type: CREATE_COUPON_REQUEST,
    payload,
  }
}

export function getCoupon(payload) {
  return {
    type: GET_COUPON_REQUEST,
    payload,
  }
}

export function deleteCoupon(payload) {
  return {
    type: DELETE_COUPON_REQUEST,
    payload,
  }
}
