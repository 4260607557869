import { useTranslation } from 'react-i18next'

import Page from '../../../components/Page'

const UsersManagementAdd = () => {
  const { t } = useTranslation()

  return <Page pageTitle={t('usersManagement.new user')} withCancelBtn={true}></Page>
}

export default UsersManagementAdd
