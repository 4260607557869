import axios from 'axios'

export const getProducts = async (take = null, page = null) => {
  const response = await axios.get('/products', {
    params: {
      take,
      page,
    },
  })

  return response.data.data
}
