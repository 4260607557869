import {
  GET_NOTIFICATIONS_REQUEST,
  GET_NOTIFICATION_REQUEST,
  UPDATE_NOTIFICATION_REQUEST,
  CREATE_NOTIFICATION_REQUEST,
  DELETE_NOTIFICATION_REQUEST,
} from '../types/notifications'

export function getNotifications(payload) {
  return {
    type: GET_NOTIFICATIONS_REQUEST,
    payload,
  }
}

export function getNotification(payload) {
  return {
    type: GET_NOTIFICATION_REQUEST,
    payload,
  }
}

export function updateNotification(payload) {
  return {
    type: UPDATE_NOTIFICATION_REQUEST,
    payload,
  }
}

export function createNotification(payload) {
  return {
    type: CREATE_NOTIFICATION_REQUEST,
    payload,
  }
}

export function deleteNotification(payload) {
  return {
    type: DELETE_NOTIFICATION_REQUEST,
    payload,
  }
}
