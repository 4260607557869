import moment from 'moment'
import { useState } from 'react'
import { useQuery } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'
import { Col, Row, DatePicker, Space } from 'antd'
import DashboardChartWrap from 'components/Dashboard/ChartWrap/DashboardChartWrap'
import DashboardInfoCard from 'components/Dashboard/InfoCard/DashboardInfoCard'
import DashboardToPDFButton from 'components/Dashboard/ToPDFButton/DashboardToPDFButton'
import PreloaderBlock from 'components/PreloaderBlock/PreloaderBlock'
import Icon from 'components/Icon'
import OrdersChart from 'components/Dashboard/Charts/OrdersChart'
import OrdersAverageChart from 'components/Dashboard/Charts/OrdersAverageChart'
import OrdersValuesChart from 'components/Dashboard/Charts/OrdersValuesChart'
import MoreOneOrdersChart from 'components/Dashboard/Charts/MoreOneOrdersChart'
import OrdersPerAreaChart from 'components/Dashboard/Charts/OrdersPerAreaChart'
import OrdersAveragePerAreaChart from 'components/Dashboard/Charts/OrdersAveragePerAreaChart'
import KNetVsCashChart from 'components/Dashboard/Charts/KNetVsCashChart'
import OrdersAverageRevenueChart from 'components/Dashboard/Charts/OrdersAverageRevenueChart'
import OrdersConversionChart from 'components/Dashboard/Charts/OrdersConversionChart'
import SalesSummaryChart from 'components/Dashboard/Charts/SalesSummaryChart'
import OrdersRepeatChart from 'components/Dashboard/Charts/OrdersRepeatChart'
import OrdersValuesPerAreaChart from 'components/Dashboard/Charts/OrdersValuesPerAreaChart'
import PromoCodesCountChart from 'components/Dashboard/Charts/PromoCodesCountChart'
import PromoCodesRevenueChart from 'components/Dashboard/Charts/PromoCodesRevenueChart'
import TopAreas from 'components/Dashboard/Charts/TopAreas'
import TopSKU from 'components/Dashboard/Charts/TopSKU'
import TopPromotions from 'components/Dashboard/Charts/TopPromotions'
import TopOffers from 'components/Dashboard/Charts/TopOffers'
import PopularProducts from 'components/Dashboard/Charts/PopularProducts'
import { DATE_FORMAT, ICONS } from 'utils/constants'
import DashboardService from 'redux/middlewares/dashboard'
import { parseThousandsGroups } from 'helpers/numbers'
import './styles.scss'

const { RangePicker } = DatePicker

const now = moment()
const todayEnd = moment().endOf('day')
const monthStart = moment().startOf('month')

const Dashboard = () => {
  const { t } = useTranslation()
  const [reportDates, setReportDates] = useState([monthStart, now])
  const [isProcessingPDF, setIsProcessingPDF] = useState(false)

  const { data: generalStats, isFetching: isGeneralStatsFetching } = useQuery(
    ['dashboard-general', reportDates],
    async () => {
      const response = await DashboardService.getGeneralStats({
        startDate: reportDates[0].toISOString(),
        finishDate: reportDates[1].toISOString(),
      })

      return response.data.data
    },
    {
      staleTime: 1000 * 60 * 10, // 10 minute
    },
  )

  return (
    <section className={'dashboard'}>
      <Space size={20} direction={'vertical'} style={{ width: '100%' }}>
        <Row gutter={[20, 20]}>
          <Col span={24}>
            <div className={'dashboard__header'}>
              <h3>{t('dashboard.sales summary ytd')}</h3>
              <DashboardToPDFButton
                onGenerateStart={() => setIsProcessingPDF(true)}
                onGenerateFinish={() => setIsProcessingPDF(false)}
              />
            </div>
          </Col>
        </Row>
        <Row gutter={[20, 20]}>
          <Col span={24}>
            <DashboardChartWrap data-print-target="SalesSummaryChart">
              <SalesSummaryChart />
            </DashboardChartWrap>
          </Col>
        </Row>
        <Row gutter={[20, 20]}>
          <Col span={24}>
            <div className={'dashboard__header'}>
              <div></div>
              <RangePicker
                className={'dashboard__datesPicker'}
                clearIcon={<Icon size={20} icon={ICONS.CLOSE} />}
                format={DATE_FORMAT}
                value={reportDates}
                onChange={(dates) => {
                  setReportDates([dates[0].startOf('day'), dates[1].endOf('day')])
                }}
                allowClear={false}
                disabledDate={(current) => current > todayEnd}
              />
            </div>
          </Col>
        </Row>
        <Row gutter={[20, 20]}>
          <Col span={24}>
            <Row gutter={[20, 20]}>
              <Col span={24} xxl={{ span: 12 }}>
                <DashboardChartWrap data-print-target="OrdersConversionInfoCard">
                  <OrdersConversionChart
                    globalStartDate={reportDates[0]}
                    globalFinishDate={reportDates[1]}
                  />
                </DashboardChartWrap>
              </Col>
              <Col span={24} xxl={{ span: 12 }}>
                <Row gutter={[20, 20]}>
                  <Col span={24} md={{ span: 12 }} xl={{ span: 12 }}>
                    {isGeneralStatsFetching ? (
                      <PreloaderBlock height={125} size={'small'} />
                    ) : (
                      <DashboardInfoCard
                        title={t('dashboard.new customers')}
                        value={generalStats?.users || '0'}
                        height={125}
                        colorTheme={'mediumBlue'}
                        data-print-target="NewCustomersInfoCard"
                        fatValue={true}
                      />
                    )}
                  </Col>
                  <Col span={24} md={{ span: 12 }} xl={{ span: 12 }}>
                    {isGeneralStatsFetching ? (
                      <PreloaderBlock height={125} size={'small'} />
                    ) : (
                      <DashboardInfoCard
                        title={t('dashboard.completed orders')}
                        value={!!generalStats ? generalStats.finishedOrders.toString() : ''}
                        height={125}
                        colorTheme={'mediumBlue'}
                        data-print-target="CompletedOrdersInfoCard"
                        fatValue={true}
                      />
                    )}
                  </Col>
                  <Col span={24} md={{ span: 12 }} xl={{ span: 12 }}>
                    {isGeneralStatsFetching ? (
                      <PreloaderBlock height={125} size={'small'} />
                    ) : (
                      <DashboardInfoCard
                        title={t('dashboard.total number of promocode use')}
                        value={
                          generalStats?.totalPromocodeUsage !== null
                            ? generalStats.totalPromocodeUsage
                            : '0'
                        }
                        height={125}
                        colorTheme={'mediumBlue'}
                        data-print-target="TotalNumberOfPromocodeUseInfoCard"
                        fatValue={true}
                      />
                    )}
                  </Col>
                  <Col span={24} md={{ span: 12 }} xl={{ span: 12 }}>
                    {isGeneralStatsFetching ? (
                      <PreloaderBlock height={125} size={'small'} />
                    ) : (
                      <DashboardInfoCard
                        title={t('dashboard.revenue')}
                        value={
                          (generalStats?.income ? parseThousandsGroups(generalStats.income) : '0') +
                          ' KD'
                        }
                        height={125}
                        colorTheme={'mediumBlue'}
                        data-print-target="RevenueInfoCard"
                        fatValue={true}
                      />
                    )}
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row gutter={[20, 20]}>
          <Col span={24}>
            <div className={'dashboard__header'}>
              <h3>{t('dashboard.sales analysis')}</h3>
            </div>
          </Col>
        </Row>
        <Row gutter={[20, 20]}>
          <Col span={24}>
            <DashboardChartWrap fullHeight={true} data-print-target="OrdersChart">
              <OrdersChart globalStartDate={reportDates[0]} globalFinishDate={reportDates[1]} />
            </DashboardChartWrap>
          </Col>
          <Col span={24}>
            <DashboardChartWrap fullHeight={true} data-print-target="OrdersValuesChart">
              <OrdersValuesChart
                globalStartDate={reportDates[0]}
                globalFinishDate={reportDates[1]}
              />
            </DashboardChartWrap>
          </Col>
          <Col span={24}>
            <DashboardChartWrap fullHeight={true} data-print-target="OrdersAverageChart">
              <OrdersAverageChart
                globalStartDate={reportDates[0]}
                globalFinishDate={reportDates[1]}
              />
            </DashboardChartWrap>
          </Col>
          <Col span={24}>
            <DashboardChartWrap fullHeight={true} data-print-target="KNetVsCashChart">
              <KNetVsCashChart globalStartDate={reportDates[0]} globalFinishDate={reportDates[1]} />
            </DashboardChartWrap>
          </Col>
          <Col span={24} xl={{ span: 12 }}>
            <DashboardChartWrap fullHeight={true} data-print-target="OrdersRepeatChart">
              <OrdersRepeatChart
                globalStartDate={reportDates[0]}
                globalFinishDate={reportDates[1]}
              />
            </DashboardChartWrap>
          </Col>
          <Col span={24} xl={{ span: 12 }}>
            <DashboardChartWrap fullHeight={true} data-print-target="PopularProductsChart">
              <PopularProducts globalStartDate={reportDates[0]} globalFinishDate={reportDates[1]} />
            </DashboardChartWrap>
          </Col>
        </Row>
        <Row gutter={[20, 20]}>
          <Col span={24} xl={{ span: 12 }}>
            <DashboardChartWrap fullHeight={true} data-print-target="MoreOneOrdersChart">
              <MoreOneOrdersChart
                globalStartDate={reportDates[0]}
                globalFinishDate={reportDates[1]}
              />
            </DashboardChartWrap>
          </Col>
          <Col span={24} xl={{ span: 12 }}>
            <DashboardChartWrap fullHeight={true} data-print-target="OrdersAverageRevenueChart">
              <OrdersAverageRevenueChart
                globalStartDate={reportDates[0]}
                globalFinishDate={reportDates[1]}
              />
            </DashboardChartWrap>
          </Col>
        </Row>
        <Row gutter={[20, 20]}>
          <Col span={24} xl={{ span: 12 }}>
            <DashboardChartWrap fullHeight={true} data-print-target="OrdersPerAreaChart">
              <OrdersPerAreaChart
                globalStartDate={reportDates[0]}
                globalFinishDate={reportDates[1]}
              />
            </DashboardChartWrap>
          </Col>
          <Col span={24} xl={{ span: 12 }}>
            <DashboardChartWrap fullHeight={true} data-print-target="OrdersValuesPerAreaChart">
              <OrdersValuesPerAreaChart
                globalStartDate={reportDates[0]}
                globalFinishDate={reportDates[1]}
              />
            </DashboardChartWrap>
          </Col>
          <Col span={24} xl={{ span: 24 }}>
            <DashboardChartWrap fullHeight={true} data-print-target="OrdersAveragePerAreaChart">
              <OrdersAveragePerAreaChart
                globalStartDate={reportDates[0]}
                globalFinishDate={reportDates[1]}
              />
            </DashboardChartWrap>
          </Col>
          <Col span={24}>
            <DashboardChartWrap fullHeight={true} data-print-target="PromoCodesCountChart">
              <PromoCodesCountChart
                globalStartDate={reportDates[0]}
                globalFinishDate={reportDates[1]}
              />
            </DashboardChartWrap>
          </Col>
          <Col span={24}>
            <DashboardChartWrap fullHeight={true} data-print-target="PromoCodesRevenueChart">
              <PromoCodesRevenueChart
                globalStartDate={reportDates[0]}
                globalFinishDate={reportDates[1]}
              />
            </DashboardChartWrap>
          </Col>
        </Row>
        <Row gutter={[20, 20]}>
          <Col span={24} xl={{ span: 12 }}>
            <DashboardChartWrap fullHeight={true} data-print-target="TopAreas">
              <TopAreas globalStartDate={reportDates[0]} globalFinishDate={reportDates[1]} />
            </DashboardChartWrap>
          </Col>
          <Col span={24} xl={{ span: 12 }}>
            <DashboardChartWrap fullHeight={true} data-print-target="TopSKU">
              <TopSKU globalStartDate={reportDates[0]} globalFinishDate={reportDates[1]} />
            </DashboardChartWrap>
          </Col>
          <Col span={24} xl={{ span: 12 }}>
            <DashboardChartWrap fullHeight={true} data-print-target="TopPromotions">
              <TopPromotions globalStartDate={reportDates[0]} globalFinishDate={reportDates[1]} />
            </DashboardChartWrap>
          </Col>
          <Col span={24} xl={{ span: 12 }}>
            <DashboardChartWrap fullHeight={true} data-print-target="TopOffers">
              <TopOffers globalStartDate={reportDates[0]} globalFinishDate={reportDates[1]} />
            </DashboardChartWrap>
          </Col>
        </Row>
      </Space>
      {isProcessingPDF && <PreloaderBlock asOverlay={true} />}
    </section>
  )
}

export default Dashboard
