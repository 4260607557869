import classNames from 'classnames'
import { useState } from 'react'
import { useQuery } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'
import { Select } from 'antd'
import PreloaderBlock from 'components/PreloaderBlock/PreloaderBlock'
import { parseThousandsGroups } from 'helpers/numbers'
import DashboardService from 'redux/middlewares/dashboard'
import { topsTypes, topsTypesLabels } from './constants/topsTypes'
import baseStyles from './styles/base.module.scss'
import selectStyles from './styles/headerSelect.module.scss'
import tableStyle from './styles/topTable.module.scss'

const TopAreas = ({ globalStartDate, globalFinishDate }) => {
  const { t } = useTranslation()
  const [responseType, setResponseType] = useState(topsTypes.COUNT)

  // Load tops data
  const { data: topsPositions, isFetching: isTopsPositionsFetching } = useQuery(
    ['dashboard-tops-positions', globalStartDate, globalFinishDate, responseType],
    async () => {
      const response = await DashboardService.getOrdersTopPositionsStats({
        startDate: globalStartDate.toISOString(),
        finishDate: globalFinishDate.toISOString(),
        responseType,
      })

      return response.data.data
    },
    {
      staleTime: 1000 * 60 * 10, // 10 minute
      refetchOnMount: 'always',
    },
  )

  return (
    <div className={baseStyles.chart}>
      <div className={baseStyles.chart__header}>
        <div className={baseStyles.chart__headerTop}>
          <p className={baseStyles.chart__headerTitle}>{t('dashboard.top selling areas')}</p>
          <div className={'field-wrapper'}>
            <Select
              className={selectStyles.headerSelect}
              options={[
                { value: topsTypes.COUNT, label: t(topsTypesLabels.COUNT) },
                { value: topsTypes.REVENUE, label: t(topsTypesLabels.REVENUE) },
              ]}
              value={responseType}
              onChange={(topsType) => setResponseType(topsType)}
            />
          </div>
        </div>
      </div>
      <div
        className={classNames([tableStyle.table, tableStyle.table_stickyHead])}
        style={{ maxHeight: 289 }}
      >
        {!isTopsPositionsFetching && topsPositions?.areas ? (
          <div className={tableStyle.table}>
            <table>
              <thead>
                <tr>
                  <th>#</th>
                  <th>{t('dashboard.area')}</th>
                  <th>{t(topsTypesLabels[responseType])}</th>
                </tr>
              </thead>
              <tbody>
                {topsPositions?.areas.map((areaStat, index) => {
                  return (
                    <tr key={areaStat.area_id}>
                      <td>{index + 1}</td>
                      <td>{areaStat.area_name}</td>
                      {responseType === topsTypes.COUNT && <td>{areaStat.total_count_by_area}</td>}
                      {responseType === topsTypes.REVENUE && (
                        <td>{parseThousandsGroups(parseFloat(areaStat.revenue_per_area))}</td>
                      )}
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
        ) : (
          <PreloaderBlock minHeight={248} />
        )}
      </div>
    </div>
  )
}

export default TopAreas
