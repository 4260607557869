import { call, put, all, fork, takeEvery } from 'redux-saga/effects'

import { showLoading, hideLoading } from 'react-redux-loading-bar'

import NotificationsService from '../middlewares/notifications'

import {
  GET_NOTIFICATIONS_REQUEST,
  GET_NOTIFICATIONS,
  GET_NOTIFICATION_REQUEST,
  GET_NOTIFICATION,
  GET_NOTIFICATIONS_REQUEST_PENDING,
  GET_NOTIFICATIONS_REQUEST_SUCCESS,
  GET_NOTIFICATIONS_REQUEST_ERROR,
} from '../types/notifications'

function* getNotificationsWatcher() {
  yield takeEvery(GET_NOTIFICATIONS_REQUEST, getNotificationsWorker)
}

function* getNotificationWatcher() {
  yield takeEvery(GET_NOTIFICATION_REQUEST, getNotificationWorker)
}

function* getNotificationsWorker() {
  try {
    yield put(showLoading())
    yield put({ type: GET_NOTIFICATIONS_REQUEST_PENDING })
    const data = yield call(NotificationsService.getNotifications)
    yield put({ type: GET_NOTIFICATIONS, payload: data })
    yield put({ type: GET_NOTIFICATIONS_REQUEST_SUCCESS })
    yield put(hideLoading())
  } catch (error) {
    yield put({ type: GET_NOTIFICATIONS_REQUEST_ERROR, payload: error.response.data.messages })
    yield put(hideLoading())
  }
}

function* getNotificationWorker({ payload: { id } }) {
  try {
    yield put(showLoading())
    yield put({ type: GET_NOTIFICATIONS_REQUEST_PENDING })
    const response = yield call(NotificationsService.getNotificationById, id)
    yield put({ type: GET_NOTIFICATION, payload: response })
    yield put({ type: GET_NOTIFICATIONS_REQUEST_SUCCESS })
    yield put(hideLoading())
  } catch (error) {
    yield put({ type: GET_NOTIFICATIONS_REQUEST_ERROR, payload: error.response.data.messages })
    yield put(hideLoading())
  }
}

export default function* notifications() {
  yield all([fork(getNotificationsWatcher), fork(getNotificationWatcher)])
}
