import i18n from 'i18next'
import classNames from 'classnames'
import { useMemo, useState, useRef, useEffect } from 'react'
import { useQuery } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'
import { useChartsDates } from './hooks/useChartsDates'
import useNodeSize from './hooks/useNodeSize'
import { Chart, registerables } from 'chart.js'
import { Bar } from 'react-chartjs-2'
import DashboardPeriodOffsetChanger from 'components/Dashboard/PeriodOffsetChanger/DashboardPeriodOffsetChanger'
import DashboardPeriodChanger from 'components/Dashboard/PeriodChanger/DashboardPeriodChanger'
import PreloaderBlock from 'components/PreloaderBlock/PreloaderBlock'
import { DASHBOARD_PERIODS } from 'utils/constants'
import DashboardService from 'redux/middlewares/dashboard'
import { topsTypes } from './constants/topsTypes'
import baseStyles from './styles/base.module.scss'
import chartStyles from './styles/popularProducts.module.scss'

Chart.register(...registerables)

const PopularProducts = ({ globalStartDate, globalFinishDate }) => {
  const { t } = useTranslation()
  const chartRef = useRef()
  const chartSize = useNodeSize(chartRef)
  const [reRenderFlag, setReRenderFlag] = useState(false)
  const [datePeriod, setDatePeriod] = useState(DASHBOARD_PERIODS.CUSTOM_DATE)
  const [periodOffset, setPeriodOffset] = useState(0)
  const { startDate, finishDate, prevStartDate, prevFinishDate } = useChartsDates({
    globalStartDate: globalStartDate,
    globalFinishDate: globalFinishDate,
    period: datePeriod,
    offset: periodOffset,
  })

  // Load current tops data
  const { data: currentTops, isFetching: isCurrentTopsFetching } = useQuery(
    ['dashboard-tops-positions', startDate, finishDate],
    async () => {
      const response = await DashboardService.getOrdersTopPositionsStats({
        startDate: startDate.toISOString(),
        finishDate: finishDate.toISOString(),
        responseType: topsTypes.COUNT,
      })

      return response.data.data
    },
    {
      staleTime: 1000 * 60 * 10, // 10 minute
      refetchOnMount: 'always',
    },
  )

  // Load previous tops data
  const { data: previousTops, isFetching: isPreviousTopsFetching } = useQuery(
    ['dashboard-tops-positions', prevStartDate, prevFinishDate],
    async () => {
      const response = await DashboardService.getOrdersTopPositionsStats({
        startDate: prevStartDate.toISOString(),
        finishDate: prevFinishDate.toISOString(),
        responseType: topsTypes.COUNT,
      })

      return response.data.data
    },
    {
      staleTime: 1000 * 60 * 10, // 10 minute
      refetchOnMount: 'always',
    },
  )

  const chartData = useMemo(() => {
    const chartData = []
    chartData['labels'] = []
    chartData['currentValues'] = []
    chartData['prevValues'] = []

    if (!currentTops?.products || !previousTops?.products) {
      return chartData
    }

    currentTops.products.forEach((currentProduct, index) => {
      chartData['labels'][index] =
        i18n.language === 'ar' ? currentProduct.product_arabianTitle : currentProduct.product_title

      chartData['currentValues'][index] = parseInt(currentProduct.count)
      chartData['prevValues'][index] = 0

      previousTops.products.forEach((prevProduct) => {
        if (prevProduct.product_id === currentProduct.product_id) {
          chartData['prevValues'][index] = parseInt(prevProduct.count)
        }
      })
    })

    return chartData
  }, [currentTops, previousTops])

  // Trigger rerender if target chart width was changed
  useEffect(() => {
    setReRenderFlag(true)

    const reRenderTimeout = window.setTimeout(() => {
      setReRenderFlag(false)
    }, 50)

    return () => {
      window.clearTimeout(reRenderTimeout)
    }
  }, [chartSize.width, chartData.labels])

  return (
    <div className={baseStyles.chart} ref={chartRef}>
      <div className={baseStyles.chart__header}>
        <div className={baseStyles.chart__headerTop}>
          <p className={baseStyles.chart__headerTitle}>{t('dashboard.popular products')}</p>
          <DashboardPeriodChanger
            periods={[
              DASHBOARD_PERIODS.DAY,
              DASHBOARD_PERIODS.WEEK,
              DASHBOARD_PERIODS.MONTH,
              DASHBOARD_PERIODS.YEAR,
              DASHBOARD_PERIODS.CUSTOM_DATE,
            ]}
            defaultPeriod={datePeriod}
            onChange={(period) => setDatePeriod(period)}
          />
        </div>
      </div>
      <div
        className={classNames([baseStyles.chart__body, baseStyles.chart__body_yScrollable])}
        style={{ maxHeight: 320 }}
      >
        {!isCurrentTopsFetching && !isPreviousTopsFetching && !reRenderFlag ? (
          <>
            {chartData.labels.length > 1 ? (
              <Bar
                data={{
                  labels: chartData.labels,
                  datasets: [
                    {
                      label: t('dashboard.orders count'),
                      data: chartData.currentValues,
                      type: 'bar',
                      barPercentage: 1,
                      borderColor: '#4c5973',
                      backgroundColor: '#4c5973',
                    },
                    {
                      label: t('dashboard.prev period orders count'),
                      data: chartData.prevValues,
                      type: 'bar',
                      barPercentage: 1,
                      borderColor: 'rgba(76,89,115,0.3)',
                      backgroundColor: 'rgba(76,89,115,0.3)',
                    },
                  ],
                }}
                width={chartSize.width}
                height={
                  chartData.labels.length * 40 + 50 >= 320 ? chartData.labels.length * 40 + 50 : 320
                }
                options={{
                  indexAxis: 'y',
                  elements: {
                    bar: {
                      borderWidth: 0,
                    },
                  },
                  responsive: false,
                  maintainAspectRatio: true,
                  plugins: {
                    legend: {
                      display: false,
                    },
                    title: {
                      display: false,
                    },
                    tooltip: {
                      displayColors: false,
                    },
                  },
                }}
              />
            ) : (
              <div className={chartStyles.popularProducts__noData}>
                <span className={chartStyles.popularProducts__noDataText}>
                  {t('dashboard.no orders')}
                </span>
              </div>
            )}
          </>
        ) : (
          <PreloaderBlock minHeight={320} />
        )}
      </div>
      <div className={baseStyles.chart__footer}>
        <div className={baseStyles.chart__offsetChangerWrap}>
          <DashboardPeriodOffsetChanger
            startDate={globalStartDate}
            finishDate={globalFinishDate}
            period={datePeriod}
            disabled={
              datePeriod === DASHBOARD_PERIODS.CUSTOM_DATE ||
              isCurrentTopsFetching ||
              isPreviousTopsFetching
            }
            changeOffsetCallback={(newOffset) => {
              setPeriodOffset(newOffset)
            }}
          />
        </div>
      </div>
    </div>
  )
}

export default PopularProducts
