import { Component } from 'react'
import PropTypes from 'prop-types'
import axios from 'axios'

import { getFromLocalStorage } from '../utils/functions'

const withAuth = (WrappedComponent) => {
  class ComposedComponent extends Component {
    requestInterceptor = axios.interceptors.request.use((req) => {
      req.headers.Authorization = `Bearer ${getFromLocalStorage('access_token')}`
      return req
    })

    responseInterceptor = axios.interceptors.response.use(
      (res) => res,
      (err) => {
        const { logoutRequest, isLoggedIn, postRefreshTokenRequest } = this.props
        const { status } = err.response
        if (status === 401 || status === 403) {
          if (isLoggedIn) {
            return postRefreshTokenRequest()
          }
          logoutRequest()
          return Promise.reject(err)
        }
        if (status >= 500) {
          return Promise.reject(err)
        }
        return Promise.reject(err)
      },
    )

    componentWillUnmount() {
      axios.interceptors.request.eject(this.requestInterceptor)
      axios.interceptors.response.eject(this.responseInterceptor)
    }

    render() {
      // eslint-disable-next-line react/jsx-props-no-spreading
      return <WrappedComponent {...this.props} />
    }
  }

  ComposedComponent.propTypes = {
    logoutRequest: PropTypes.func,
    postRefreshTokenRequest: PropTypes.func,
    isLoggedIn: PropTypes.bool,
  }

  ComposedComponent.defaultProps = {
    logoutRequest: () => {},
    postRefreshTokenRequest: () => {},
    isLoggedIn: false,
  }

  return ComposedComponent
}

export default withAuth
