import { useEffect, useMemo, useState } from 'react'
import { useQuery } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'
import { useNavigate, useSearchParams } from 'react-router-dom'

import SortableColumnTitle from 'components/SortableColumnTitle'
import PageTable from 'components/PageTable'
import Icon from '../../components/Icon'
import Button from 'components/Button'

import usePrevious from '../../hooks/usePrevious'
import { getUsersList } from 'api/admin-users-managment'
import { getRolesList } from '../../api/roles'

import { ICONS } from '../../utils/constants'

const Users = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  let [searchParams] = useSearchParams()
  const roleId = searchParams.get('filterByRoleId')

  const [pageSize, setPageSize] = useState(10)
  const [currentPage, setCurrentPage] = useState(1)
  const [sortType, setSortType] = useState('id')
  const [sortOrder, setSortOrder] = useState('ascend')
  const [filterByRoleId, setFilterByRoleId] = useState(roleId || null)
  const [search, setSearch] = useState('')
  const prevSearch = usePrevious(search)

  const {
    data: usersList,
    isFetching: isUserListFetching,
    refetch: refetchUsersList,
  } = useQuery(
    ['admin-users-list', currentPage, pageSize, sortType, sortOrder, filterByRoleId],
    async () => {
      return await getUsersList(currentPage, pageSize, sortType, sortOrder, filterByRoleId, search)
    },
  )

  const { data: allRoles, isFetching } = useQuery(['allRoles'], async () => {
    return await getRolesList()
  })

  useEffect(async () => {
    if (!roleId) {
      setFilterByRoleId(null)
    }

    if ((prevSearch !== undefined && prevSearch !== search) || !roleId) {
      if (currentPage !== 1) {
        setCurrentPage(1)
      } else {
        await refetchUsersList()
      }
    }
  }, [search, prevSearch, currentPage, roleId])

  const rolesFilerOptions = useMemo(() => {
    if (isFetching) return []

    return allRoles.map(({ roleName, id }) => ({
      text: roleName,
      value: id,
    }))
  }, [allRoles, isFetching])

  const tableChangeHandler = (pagination, filter, sorter) => {
    const roleId = filter.roleName ? filter.roleName[0] : null

    setCurrentPage(pageSize === pagination.pageSize ? pagination.current : 1)
    setPageSize(pagination.pageSize)
    setSortType(sorter.columnKey || 'id')
    setSortOrder(sorter.order || 'ascend')
    setFilterByRoleId(roleId)
  }

  const columns = [
    {
      title: (props) => <SortableColumnTitle {...props} title={'№'} id="id" />,
      dataIndex: 'id',
      key: 'id',
      align: 'center',
      width: 57,
      sorter: true,
    },
    {
      title: (props) => (
        <SortableColumnTitle
          {...props}
          title={t('adminTeamManagement.users.userName')}
          id="userName"
        />
      ),
      dataIndex: 'userName',
      key: 'userName',
      sorter: true,
      render: (_, record) => {
        return <span>{record.userName}</span>
      },
      width: 500,
    },
    {
      title: (props) => (
        <SortableColumnTitle {...props} title={t('adminTeamManagement.users.email')} id="email" />
      ),
      dataIndex: 'email',
      key: 'email',
      sorter: true,
      render: (_, record) => {
        return <span>{record.email}</span>
      },
    },
    {
      title: t('adminTeamManagement.users.roleName'),
      dataIndex: ['role', 'roleName'],
      key: 'roleName',
      width: 160,
      filters: rolesFilerOptions,
      filterMultiple: false,
      defaultFilteredValue: roleId,
    },
  ]

  return (
    <PageTable
      pageTitle={
        <h3
          className="page-title"
          onClick={() => {
            navigate(-1)
          }}
        >
          <Icon icon={ICONS.ARROW_LEFT} />
          <span>{t('adminTeamManagement.users.title')}</span>
        </h3>
      }
      baseData={usersList?.result || []}
      action={
        <Button type="primary" onClick={() => navigate('/main/create-user')}>
          <span className="btn-text">{t('adminTeamManagement.users.addNewRole')}</span>
        </Button>
      }
      columns={columns}
      loading={isUserListFetching}
      onRow={(record) => {
        return {
          onClick: () => navigate(`/main/edit-user/${record.id}`),
        }
      }}
      onChange={tableChangeHandler}
      searchHandler={(value) => setSearch(value.trim())}
      pagination={{
        pageSize,
        current: currentPage,
        showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
        showSizeChanger: true,
        total: usersList?.total,
      }}
    />
  )
}

export default Users
