import {
  GET_BANNERS,
  GET_BANNER,
  UPDATE_BANNER,
  CREATE_BANNER,
  DELETE_BANNER,
  GET_BANNERS_REQUEST_PENDING,
  GET_BANNERS_REQUEST_SUCCESS,
  GET_BANNERS_REQUEST_ERROR,
  CHANGE_BANNERS_REQUEST_PENDING,
  CHANGE_BANNERS_REQUEST_SUCCESS,
  CHANGE_BANNERS_REQUEST_ERROR,
} from '../types/banners'

const initialState = {
  items: [],
  item: {},
  isGetLoading: false,
  isChangeLoading: false,
  errors: [],
}

const banners = (state = initialState, action) => {
  switch (action.type) {
    case GET_BANNERS:
      return { ...state, items: action.payload }
    case GET_BANNER:
      return { ...state, item: action.payload }
    case UPDATE_BANNER:
      const itemIndex = state.items.findIndex((item) => item.id === action.payload.id)
      const newItems = [...state.items]
      newItems[itemIndex] = { ...action.payload }
      return { ...state, items: newItems }
    case CREATE_BANNER:
      return { ...state, items: [...state.items, action.payload] }
    case DELETE_BANNER:
      return {
        ...state,
        items: state.items.filter((item) => item.id !== action.payload),
      }
    case GET_BANNERS_REQUEST_PENDING:
      return { ...state, isGetLoading: true }
    case GET_BANNERS_REQUEST_SUCCESS:
      return { ...state, errors: [], isGetLoading: false }
    case GET_BANNERS_REQUEST_ERROR:
      return { ...state, errors: action.payload, isGetLoading: false }
    case CHANGE_BANNERS_REQUEST_PENDING:
      return { ...state, isChangeLoading: true }
    case CHANGE_BANNERS_REQUEST_SUCCESS:
      return { ...state, errors: [], isChangeLoading: false }
    case CHANGE_BANNERS_REQUEST_ERROR:
      return { ...state, errors: action.payload, isChangeLoading: false }
    default:
      return state
  }
}

export default banners
