import axios from 'axios'

class OrdersService {
  getOrders = (page, size, search, filters, sortType, sortOrder) =>
    axios({
      url: 'order',
      method: 'GET',
      params: {
        page,
        take: size,
        search: search ? search : null,
        filters,
        sortType: sortType ? sortType : null,
        sortOrder: sortOrder ? sortOrder : null,
      },
    })

  getOrderById = (id) =>
    axios({
      url: `order/${id}`,
      method: 'GET',
    })

  exportOrders = ({ startDate, finishDate, addRemovedOrders }) =>
    axios.get('order/download', {
      responseType: 'blob',
      params: {
        startDate,
        finishDate,
        addRemovedOrders,
      },
    })

  sendOrderToSap = (orderNumber) => axios.post(`order/send/sap/${orderNumber}`)
}

export default new OrdersService()
