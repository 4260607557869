import React from 'react'

import LogoVertical from '../assets/images/logo-vertical.svg'

const AuthLayout = ({ title, children }) => {
  return (
    <div className="authLayout">
      <main className="authLayout-wrapper">
        <img className="authLayout-logo" src={LogoVertical} alt="ABRAAJ WATER" />
        <h2 className="authLayout-title">{title}</h2>
        {children}
      </main>
    </div>
  )
}

export default AuthLayout
