import moment from 'moment'
import classNames from 'classnames'
import { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from '@tanstack/react-query'
import { Tooltip } from 'antd'
import PreloaderBlock from 'components/PreloaderBlock/PreloaderBlock'
import { parseThousandsGroups } from 'helpers/numbers'
import DashboardService from 'redux/middlewares/dashboard'
import styles from './styles/salesSummaryChart.module.scss'

const now = moment()

const SalesSummaryChart = () => {
  const { t } = useTranslation()
  const startOfCurrentYear = now.clone().startOf('year')
  const finishOfCurrentPeriod = now.clone()
  const startOfPreviousYear = now.clone().subtract(1, 'year').startOf('year')
  const finishOfPreviousPeriod = now.clone().subtract(1, 'year')

  // Load current year stats
  const { data: currentYearStats, isFetching: isCurrentYearStatsFetching } = useQuery(
    ['dashboard-sales-summary', startOfCurrentYear, finishOfCurrentPeriod],
    async () => {
      const response = await DashboardService.getGeneralStats({
        startDate: startOfCurrentYear.toISOString(),
        finishDate: finishOfCurrentPeriod.toISOString(),
      })

      return response.data.data
    },
    {
      staleTime: 1000 * 60 * 10, // 10 minute
      refetchOnMount: 'always',
    },
  )

  // Load current year stats
  const { data: previousYearStats, isFetching: isPreviousYearStatsFetching } = useQuery(
    ['dashboard-sales-summary', startOfPreviousYear, finishOfPreviousPeriod],
    async () => {
      const response = await DashboardService.getGeneralStats({
        startDate: startOfPreviousYear.toISOString(),
        finishDate: finishOfPreviousPeriod.toISOString(),
      })

      return response.data.data
    },
    {
      staleTime: 1000 * 60 * 10, // 10 minute
      refetchOnMount: 'always',
    },
  )

  const salesDiffPercentage = useMemo(() => {
    let percentage = 0

    if (!currentYearStats || !previousYearStats) {
      return percentage
    }

    if (previousYearStats.income !== 0) {
      percentage = Math.round((currentYearStats.income / previousYearStats.income - 1) * 100)
    } else if (currentYearStats.income > 0) {
      percentage = 100
    }

    return percentage
  }, [currentYearStats, previousYearStats])

  const ordersDiffPercentage = useMemo(() => {
    let percentage = 0

    if (!currentYearStats || !previousYearStats) {
      return percentage
    }

    if (previousYearStats.finishedOrders !== 0) {
      percentage = Math.round(
        (currentYearStats.finishedOrders / previousYearStats.finishedOrders - 1) * 100,
      )
    } else if (currentYearStats.finishedOrders > 0) {
      percentage = 100
    }

    return percentage
  }, [currentYearStats, previousYearStats])

  const customersDiffPercentage = useMemo(() => {
    let percentage = 0

    if (!currentYearStats || !previousYearStats) {
      return percentage
    }

    if (previousYearStats.users !== 0) {
      percentage = Math.round((currentYearStats.users / previousYearStats.users - 1) * 100)
    } else if (currentYearStats.users > 0) {
      percentage = 100
    }

    return percentage
  }, [currentYearStats, previousYearStats])

  const currentAverageOrdersValue = useMemo(() => {
    if (!currentYearStats) {
      return 0
    }

    return currentYearStats.finishedOrders > 0
      ? currentYearStats.income / currentYearStats.finishedOrders
      : 0
  }, [currentYearStats])

  const previousAverageOrdersValue = useMemo(() => {
    if (!previousYearStats) {
      return 0
    }

    return previousYearStats.finishedOrders > 0
      ? previousYearStats.income / previousYearStats.finishedOrders
      : 0
  }, [previousYearStats])

  const averageOrderDiffPercentage = useMemo(() => {
    let percentage = 0

    if (!currentYearStats || !previousYearStats) {
      return percentage
    }

    if (previousAverageOrdersValue !== 0) {
      percentage = Math.round((currentAverageOrdersValue / previousAverageOrdersValue - 1) * 100)
    } else if (currentAverageOrdersValue > 0) {
      percentage = 100
    }

    return percentage
  }, [currentYearStats, previousYearStats, currentAverageOrdersValue, previousAverageOrdersValue])

  const constructPercentageNode = useCallback((percentage) => {
    if (percentage === 0) {
      return null
    }

    const classes = classNames({
      [`${styles.text}`]: true,
      [`${styles.text_red}`]: percentage < 0,
      [`${styles.text_green}`]: percentage > 0,
    })

    return (
      <span className={classes}>
        {percentage > 0 && '+'}
        {percentage}%
      </span>
    )
  }, [])

  if (isCurrentYearStatsFetching || isPreviousYearStatsFetching) {
    return <PreloaderBlock minHeight={64} />
  }

  return (
    <div>
      <div className={styles.flexBox}>
        <div className={styles.flexBox__row}>
          <div className={styles.flexBox__rowTitle}>
            <span className={classNames([styles.text, styles.text_grayTitle])}>
              {t('dashboard.total sales')}
            </span>
          </div>
          <div className={styles.flexBox__rowBody}>
            <div className={styles.sumStatsRow}>
              <Tooltip title={t('dashboard.summary sales value hint')}>
                <span className={classNames([styles.text, styles.text_fat])}>
                  {currentYearStats?.income ? parseThousandsGroups(currentYearStats.income) : 0} KD
                </span>
              </Tooltip>
              <Tooltip title={t('dashboard.summary sales percentage diff hint')}>
                {constructPercentageNode(salesDiffPercentage)}
              </Tooltip>
            </div>
          </div>
        </div>
        <div className={styles.flexBox__row}>
          <div className={styles.flexBox__rowTitle}>
            <span className={classNames([styles.text, styles.text_grayTitle])}>
              {t('dashboard.average order value')}
            </span>
          </div>
          <div className={styles.flexBox__rowBody}>
            <div className={styles.sumStatsRow}>
              <Tooltip title={t('dashboard.summary average order hint')}>
                <span className={classNames([styles.text, styles.text_fat])}>
                  {parseThousandsGroups(currentAverageOrdersValue)} KD
                </span>
              </Tooltip>
              <Tooltip title={t('dashboard.summary average order percentage diff hint')}>
                {constructPercentageNode(averageOrderDiffPercentage)}
              </Tooltip>
            </div>
          </div>
        </div>
        <div className={styles.flexBox__row}>
          <div className={styles.flexBox__rowTitle}>
            <span className={classNames([styles.text, styles.text_grayTitle])}>
              {t('dashboard.total orders')}
            </span>
          </div>
          <div className={styles.flexBox__rowBody}>
            <div className={styles.sumStatsRow}>
              <Tooltip title={t('dashboard.summary orders hint')}>
                <span className={classNames([styles.text, styles.text_fat])}>
                  {currentYearStats?.finishedOrders ? currentYearStats.finishedOrders : 0}
                </span>
              </Tooltip>
              <Tooltip title={t('dashboard.summary orders percentage diff hint')}>
                {constructPercentageNode(ordersDiffPercentage)}
              </Tooltip>
            </div>
          </div>
        </div>
        <div className={styles.flexBox__row}>
          <div className={styles.flexBox__rowTitle}>
            <span className={classNames([styles.text, styles.text_grayTitle])}>
              {t('dashboard.total customers')}
            </span>
          </div>
          <div className={styles.flexBox__rowBody}>
            <div className={styles.sumStatsRow}>
              <Tooltip title={t('dashboard.summary customers hint')}>
                <span className={classNames([styles.text, styles.text_fat])}>
                  {currentYearStats?.users ? currentYearStats.users : 0}
                </span>
              </Tooltip>
              <Tooltip title={t('dashboard.summary customers percentage diff hint')}>
                {constructPercentageNode(customersDiffPercentage)}
              </Tooltip>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SalesSummaryChart
