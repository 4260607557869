import axios from 'axios'
import { notify } from 'reapop'

const postErrorHandler = (error, dispatch, variables) => {
  if (axios.isAxiosError(error)) {
    if (Array.isArray(error.response?.data?.messages)) {
      error.response.data.messages.map((oneError) => {
        if (oneError.message) {
          switch (oneError.message) {
            case 'PROMO_CODE_WITH_SUCH_NAME_ALREADY_EXIST':
              return dispatch(
                notify({
                  title: 'Promocode "' + variables?.title + '" is already exist!',
                  status: 'error',
                  dismissAfter: 4000,
                }),
              )
            case 'PROMO_CODE_WITH_SUCH_NAME_ALREADY_ACTIVATED':
              return dispatch(
                notify({
                  title:
                    'Promocode with name "' + variables?.promoCodeName + '" is already activated!',
                  status: 'error',
                  dismissAfter: 4000,
                }),
              )
            default:
              return dispatch(
                notify({ title: oneError.message, status: 'error', dismissAfter: 4000 }),
              )
          }
        } else if (oneError.property) {
          return dispatch(
            notify({
              title: 'Property "' + oneError.property + '" incorrect',
              status: 'error',
              dismissAfter: 4000,
            }),
          )
        } else {
          return oneError
        }
      })
    } else {
      dispatch(notify({ title: error.message, status: 'error', dismissAfter: 4000 }))
    }
  }
}

export default postErrorHandler
