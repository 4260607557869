import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useQuery } from '@tanstack/react-query'

import { fetchCashback } from '../../../api/cashback'

import Page from '../../../components/Page'
import CashbackForm from '../../../components/forms/CashbackForm/CashbackForm'
import CashbackFormSkeleton from '../../../components/forms/CashbackForm/CashbackFormSkeleton'

const CashbackView = () => {
  const { id } = useParams()
  const { t } = useTranslation()

  const { data: cashback, isFetching } = useQuery(
    [`cashback-${id}`],
    async () => await fetchCashback(id),
  )

  if (isFetching) {
    return <CashbackFormSkeleton />
  }

  return (
    <Page pageTitle={t('cashback.view.title')}>
      <CashbackForm cashback={cashback} />
    </Page>
  )
}

export default CashbackView
