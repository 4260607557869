import classNames from 'classnames'
import { useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from '@tanstack/react-query'
import { useChartsDates } from './hooks/useChartsDates'
import useNodeSize from './hooks/useNodeSize'
import { Chart, registerables } from 'chart.js'
import { Bar } from 'react-chartjs-2'
import PreloaderBlock from 'components/PreloaderBlock/PreloaderBlock'
import DashboardPeriodChanger from 'components/Dashboard/PeriodChanger/DashboardPeriodChanger'
import DashboardPeriodOffsetChanger from 'components/Dashboard/PeriodOffsetChanger/DashboardPeriodOffsetChanger'
import PromoCodesCountAnalytics from './PromoCodesCountAnalytics'
import DashboardService from 'redux/middlewares/dashboard'
import { DASHBOARD_PERIODS } from 'utils/constants'
import { topsTypes } from './constants/topsTypes'
import { PROMOCODE_TYPES } from 'utils/constants'
import baseStyles from './styles/base.module.scss'
import chartStyles from './styles/promoCodesCountChart.module.scss'

Chart.register(...registerables)

const PromoCodesCountChart = ({ globalStartDate, globalFinishDate }) => {
  const { t } = useTranslation()
  const targetYLinesCount = 7
  const analyticsBlockWidth = 415
  const chartRef = useRef()
  const chartSize = useNodeSize(chartRef)
  const [reRenderFlag, setReRenderFlag] = useState(false)
  const [datePeriod, setDatePeriod] = useState(DASHBOARD_PERIODS.CUSTOM_DATE)
  const [periodOffset, setPeriodOffset] = useState(0)
  const {
    startDate,
    finishDate,
    prevStartDate,
    prevFinishDate,
    prevYearStartDate,
    prevYearFinishDate,
  } = useChartsDates({
    globalStartDate: globalStartDate,
    globalFinishDate: globalFinishDate,
    period: datePeriod,
    offset: periodOffset,
  })

  // Load promo codes stats
  const { data: promoCodesStats, isFetching: isPromoCodesStatsFetching } = useQuery(
    ['dashboard-promo-codes-count', startDate, finishDate],
    async () => {
      const response = await DashboardService.getPromoCodesStats({
        startDate: startDate.toISOString(),
        finishDate: finishDate.toISOString(),
        responseType: topsTypes.COUNT,
      })

      return response.data.data
    },
    {
      staleTime: 1000 * 60 * 10, // 10 minute
      refetchOnMount: 'always',
    },
  )

  // Load previous period promo codes stats
  const { data: prevPromoCodesStats, isFetching: isPrevPromoCodesStatsFetching } = useQuery(
    ['dashboard-promo-codes-count', prevStartDate, prevFinishDate],
    async () => {
      const response = await DashboardService.getPromoCodesStats({
        startDate: prevStartDate.toISOString(),
        finishDate: prevFinishDate.toISOString(),
        responseType: topsTypes.COUNT,
      })

      return response.data.data
    },
    {
      staleTime: 1000 * 60 * 10, // 10 minute
      refetchOnMount: 'always',
    },
  )

  // Load previous year period promo codes stats
  const { data: prevYearPromoCodesStats, isFetching: isPrevYearPromoCodesStatsFetching } = useQuery(
    ['dashboard-promo-codes-count', prevYearStartDate, prevYearFinishDate],
    async () => {
      const response = await DashboardService.getPromoCodesStats({
        startDate: prevYearStartDate.toISOString(),
        finishDate: prevYearFinishDate.toISOString(),
        responseType: topsTypes.COUNT,
      })

      return response.data.data
    },
    {
      staleTime: 1000 * 60 * 10, // 10 minute
      refetchOnMount: 'always',
    },
  )

  const chartData = useMemo(() => {
    const currentLabels = []
    const currentValues = []

    if (promoCodesStats === undefined) {
      return { currentLabels, currentValues }
    }

    promoCodesStats.forEach((statItem, index) => {
      currentLabels[index] = `${statItem.promo_code_title} (${
        statItem.promo_code_discount_quantity
      }${statItem.promo_code_discount_type === PROMOCODE_TYPES.PERCENT.KEY ? '%' : 'KD'})`
      currentValues[index] = parseInt(statItem.count)
    })

    return { currentLabels, currentValues }
  }, [promoCodesStats])

  // Get max value in data stack
  const maxValue = useMemo(() => {
    let maxValue = 0

    chartData.currentValues.forEach((oneValue) => {
      maxValue = oneValue > maxValue ? oneValue : maxValue
    })

    return maxValue
  }, [chartData.currentValues])

  // Get target chart width
  const targetCanvasWidth = useMemo(() => {
    const needSize = chartData.currentLabels.length * 70

    if (chartSize.width !== undefined && chartSize.width > needSize + analyticsBlockWidth) {
      return chartSize.width - analyticsBlockWidth
    }

    return needSize
  }, [chartData.currentLabels, chartSize.width])

  // Trigger rerender if target chart width was changed
  useEffect(() => {
    setReRenderFlag(true)

    const reRenderTimeout = window.setTimeout(() => {
      setReRenderFlag(false)
    }, 50)

    return () => {
      window.clearTimeout(reRenderTimeout)
    }
  }, [targetCanvasWidth])

  // Calculate step size depends on max value in data stack
  const stepSize = useMemo(
    () => (maxValue >= targetYLinesCount ? Math.ceil(maxValue / targetYLinesCount) : 1),
    [maxValue, targetYLinesCount],
  )

  return (
    <div className={baseStyles.chart} ref={chartRef}>
      <div className={baseStyles.chart__header}>
        <div className={baseStyles.chart__headerTop}>
          <p className={baseStyles.chart__headerTitle}>{t('dashboard.promocode usage')}</p>
          <DashboardPeriodChanger
            periods={[
              DASHBOARD_PERIODS.AM,
              DASHBOARD_PERIODS.PM,
              DASHBOARD_PERIODS.DAY,
              DASHBOARD_PERIODS.WEEK,
              DASHBOARD_PERIODS.MONTH,
              DASHBOARD_PERIODS.YEAR,
              DASHBOARD_PERIODS.CUSTOM_DATE,
            ]}
            defaultPeriod={datePeriod}
            onChange={(period) => setDatePeriod(period)}
          />
        </div>
      </div>
      <div className={classNames([baseStyles.chart__body, baseStyles.chart__body_xScrollable])}>
        {!isPromoCodesStatsFetching &&
        !isPrevPromoCodesStatsFetching &&
        !isPrevYearPromoCodesStatsFetching &&
        targetCanvasWidth &&
        !reRenderFlag ? (
          <>
            {chartData.currentLabels.length > 0 ? (
              <>
                <PromoCodesCountAnalytics
                  currentStartDate={startDate}
                  currentFinishDate={finishDate}
                  currentStats={promoCodesStats || []}
                  prevPeriodStartDate={prevStartDate}
                  prevPeriodFinishDate={prevFinishDate}
                  prevPeriodStats={prevPromoCodesStats || []}
                  prevYearStartDate={prevYearStartDate}
                  prevYearFinishDate={prevYearFinishDate}
                  prevYearStats={prevYearPromoCodesStats || []}
                />
                <Bar
                  data={{
                    labels: chartData.currentLabels,
                    datasets: [
                      {
                        label: t('dashboard.promocode usage'),
                        data: chartData.currentValues,
                        borderColor: '#6BBB6E',
                        backgroundColor: '#6BBB6E',
                      },
                    ],
                  }}
                  width={targetCanvasWidth}
                  height={chartSize.width && targetCanvasWidth <= chartSize.width ? 320 : 308} // Additional space for scrollbar
                  options={{
                    indexAxis: 'x',
                    elements: {
                      bar: {
                        borderWidth: 0,
                      },
                    },
                    responsive: false,
                    maintainAspectRatio: true,
                    plugins: {
                      legend: {
                        display: false,
                      },
                      title: {
                        display: false,
                      },
                      tooltip: {
                        displayColors: false,
                        callbacks: {
                          label(tooltipItem) {
                            return `${t('dashboard.usages')}: ${tooltipItem.formattedValue}`
                          },
                        },
                      },
                    },
                    scales: {
                      y: {
                        beginAtZero: true,
                        suggestedMax: stepSize * targetYLinesCount,
                        ticks: {
                          stepSize: stepSize,
                        },
                      },
                    },
                  }}
                />
              </>
            ) : (
              <div className={chartStyles.promoCodesCountChart__noData}>
                <span className={chartStyles.promoCodesCountChart__noDataText}>
                  {t('dashboard.no promocodes')}
                </span>
              </div>
            )}
          </>
        ) : (
          <PreloaderBlock minHeight={320} />
        )}
      </div>
      <div className={baseStyles.chart__footer}>
        <div className={baseStyles.chart__offsetChangerWrap}>
          <DashboardPeriodOffsetChanger
            startDate={globalStartDate}
            finishDate={globalFinishDate}
            period={datePeriod}
            disabled={
              datePeriod === DASHBOARD_PERIODS.CUSTOM_DATE ||
              isPromoCodesStatsFetching ||
              isPrevPromoCodesStatsFetching ||
              isPrevYearPromoCodesStatsFetching
            }
            changeOffsetCallback={(newOffset) => {
              setPeriodOffset(newOffset)
            }}
          />
        </div>
      </div>
    </div>
  )
}

export default PromoCodesCountChart
