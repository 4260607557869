import * as yup from 'yup'

export const PASSWORD_REGEXP = /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*\W)(?!.* ).{8,32}$/

export default yup.object().shape({
  email: yup.string().required('this field is required').email('not valid email'),
  roleId: yup.number().required('this field is required'),
  userName: yup
    .string()
    .required('this field is required')
    .min(3, 'minimum 3 symbols')
    .max(64, 'maximum 64 symbols'),
  password: yup.string().when(['id'], (id) => {
    if (id !== null) {
      return yup
        .string()
        .notRequired()
        .matches(PASSWORD_REGEXP, 'is not correct format')
        .min(8, 'minimum 8 symbols')
        .max(64, 'maximum 64 symbols')
        .nullable()
        .transform((value) => (!!value ? value : null))
    }

    return yup
      .string()
      .required('this field is required')
      .matches(PASSWORD_REGEXP, 'is not correct format')
      .min(8, 'minimum 8 symbols')
      .max(64, 'maximum 64 symbols')
  }),
  confirmPassword: yup.string().when(['id'], (id) => {
    if (id !== null) {
      return yup
        .string()
        .notRequired()
        .oneOf([yup.ref('password')], "Passwords don't match!")
        .min(8, 'minimum 8 symbols')
        .max(64, 'maximum 64 symbols')
        .nullable()
        .transform((value) => (!!value ? value : null))
    }

    return yup
      .string()
      .required('this field is required')
      .oneOf([yup.ref('password')], "Passwords don't match!")
      .min(8, 'minimum 8 symbols')
      .max(64, 'maximum 64 symbols')
  }),
})
