import { SIGN_IN_REQUEST, SIGN_UP_REQUEST, SIGN_OUT_REQUEST } from '../types/auth'

export function signIn(payload) {
  return {
    type: SIGN_IN_REQUEST,
    payload,
  }
}

export function signUp(payload) {
  return {
    type: SIGN_UP_REQUEST,
    payload,
  }
}

export function signOut(payload) {
  return {
    type: SIGN_OUT_REQUEST,
    payload,
  }
}
