import moment from 'moment/moment'
import classNames from 'classnames'
import { useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from '@tanstack/react-query'
import { useDebounce } from 'hooks/useDebounce'
import { useChartsDates } from './hooks/useChartsDates'
import { DatesGroupModes, useDatesGroupMode } from './hooks/useDatesGroupMode'
import useNodeSize from './hooks/useNodeSize'
import { Chart, registerables } from 'chart.js'
import { Bar } from 'react-chartjs-2'
import { InputNumber, Space } from 'antd'
import PreloaderBlock from 'components/PreloaderBlock/PreloaderBlock'
import DashboardPeriodChanger from 'components/Dashboard/PeriodChanger/DashboardPeriodChanger'
import DashboardPeriodOffsetChanger from 'components/Dashboard/PeriodOffsetChanger/DashboardPeriodOffsetChanger'
import DashboardService from 'redux/middlewares/dashboard'
import { DASHBOARD_PERIODS } from 'utils/constants'
import baseStyles from './styles/base.module.scss'

Chart.register(...registerables)

const OrdersRepeatChart = ({ globalStartDate, globalFinishDate }) => {
  const { t } = useTranslation()
  const targetYLinesCount = 7
  const analyticsBlockWidth = 0
  const chartRef = useRef()
  const chartSize = useNodeSize(chartRef)
  const [reRenderFlag, setReRenderFlag] = useState(false)
  const [datePeriod, setDatePeriod] = useState(DASHBOARD_PERIODS.CUSTOM_DATE)
  const [periodOffset, setPeriodOffset] = useState(0)
  const [ordersCount, setOrdersCount] = useState(1)
  const debouncedOrdersCount = useDebounce(ordersCount)
  const { startDate, finishDate } = useChartsDates({
    globalStartDate: globalStartDate,
    globalFinishDate: globalFinishDate,
    period: datePeriod,
    offset: periodOffset,
  })
  const dateGroupMode = useDatesGroupMode({ startDate, finishDate })

  // Load repeated orders stats
  const { data: repeatedOrdersStats, isFetching: isRepeatedOrdersStatsFetching } = useQuery(
    ['dashboard-repeated-orders', startDate, finishDate, debouncedOrdersCount],
    async () => {
      const response = await DashboardService.getRepeatedOrdersStats({
        startDate: startDate.toISOString(),
        finishDate: finishDate.toISOString(),
        numberOfRepeatedOrders: debouncedOrdersCount,
      })

      return response.data.data
    },
    {
      staleTime: 1000 * 60 * 10, // 10 minute
      refetchOnMount: 'always',
    },
  )

  const chartData = useMemo(() => {
    const currentLabels = []
    const currentValues = []

    if (repeatedOrdersStats === undefined) {
      return { currentLabels, currentValues }
    }

    if (dateGroupMode === DatesGroupModes.MINUTES) {
      // If period is half of day or smaller - Group by 15 minutes
      const currentDateCounter = startDate.clone()
      let currentIndex = 0

      // Fill current period data
      while (currentDateCounter <= finishDate) {
        currentLabels[currentIndex] = currentDateCounter.toISOString()
        const uniqueUsersId = []

        const nextDateLimit = currentDateCounter.clone().add(15, 'minutes')

        repeatedOrdersStats.activeUserCountForPeriod.forEach((statItem) => {
          if (
            moment(statItem.orders_by_time) >= currentDateCounter &&
            moment(statItem.orders_by_time) < nextDateLimit
          ) {
            statItem.users.forEach((userId) => {
              if (uniqueUsersId.indexOf(userId) === -1) {
                uniqueUsersId.push(userId)
              }
            })
          }
        })

        currentValues[currentIndex] = uniqueUsersId.length
        currentDateCounter.add(15, 'minutes')
        currentIndex++
      }
    } else if (dateGroupMode === DatesGroupModes.HOURS) {
      // If period is day or smaller - Group by hours
      const currentDateCounter = startDate.clone()
      const hoursInCurrentRange = finishDate
        .clone()
        .endOf('day')
        .diff(startDate.clone().startOf('day'), 'hours')

      // Fill current period data
      for (let i = 0; i <= hoursInCurrentRange; i++) {
        currentLabels[i] = currentDateCounter.toISOString()
        const uniqueUsersId = []

        repeatedOrdersStats.activeUserCountForPeriod.forEach((statItem) => {
          if (moment(statItem.orders_by_time).isSame(currentDateCounter, 'hour')) {
            statItem.users.forEach((userId) => {
              if (uniqueUsersId.indexOf(userId) === -1) {
                uniqueUsersId.push(userId)
              }
            })
          }
        })

        currentValues[i] = uniqueUsersId.length
        currentDateCounter.add(1, 'hour')
      }
    } else if (
      dateGroupMode === DatesGroupModes.WEEK_DAYS ||
      dateGroupMode === DatesGroupModes.DAYS
    ) {
      // If period smaller than 3 month - Group by days
      const currentDateCounter = startDate.clone()
      const daysInCurrentRange = finishDate
        .clone()
        .endOf('day')
        .diff(startDate.clone().startOf('day'), 'days')

      // Fill current period data
      for (let i = 0; i <= daysInCurrentRange; i++) {
        currentLabels[i] = currentDateCounter.toISOString()
        const uniqueUsersId = []

        repeatedOrdersStats.activeUserCountForPeriod.forEach((statItem) => {
          if (moment(statItem.orders_by_time).isSame(currentDateCounter, 'day')) {
            statItem.users.forEach((userId) => {
              if (uniqueUsersId.indexOf(userId) === -1) {
                uniqueUsersId.push(userId)
              }
            })
          }
        })

        currentValues[i] = uniqueUsersId.length
        currentDateCounter.add(1, 'day')
      }
    } else if (dateGroupMode === DatesGroupModes.MONTHS) {
      // If period smaller than 12 month - Group by month
      const currentDateCounter = startDate.clone()
      const monthInCurrentRange = finishDate
        .clone()
        .endOf('month')
        .diff(startDate.clone().startOf('month'), 'month')

      // Fill current period data
      for (let i = 0; i <= monthInCurrentRange; i++) {
        currentLabels[i] = currentDateCounter.toISOString()
        const uniqueUsersId = []

        repeatedOrdersStats.activeUserCountForPeriod.forEach((statItem) => {
          if (moment(statItem.orders_by_time).isSame(currentDateCounter, 'month')) {
            statItem.users.forEach((userId) => {
              if (uniqueUsersId.indexOf(userId) === -1) {
                uniqueUsersId.push(userId)
              }
            })
          }
        })

        currentValues[i] = uniqueUsersId.length
        currentDateCounter.add(1, 'month')
      }
    } else if (dateGroupMode === DatesGroupModes.YEARS) {
      // If period is 12 month or bigger - Group by year
      const currentDateCounter = startDate.clone()
      const yearsInCurrentRange = finishDate
        .clone()
        .endOf('year')
        .diff(startDate.clone().startOf('year'), 'year')

      // Fill current period data
      for (let i = 0; i <= yearsInCurrentRange; i++) {
        currentLabels[i] = currentDateCounter.toISOString()
        const uniqueUsersId = []

        repeatedOrdersStats.activeUserCountForPeriod.forEach((statItem) => {
          if (moment(statItem.orders_by_time).isSame(currentDateCounter, 'year')) {
            statItem.users.forEach((userId) => {
              if (uniqueUsersId.indexOf(userId) === -1) {
                uniqueUsersId.push(userId)
              }
            })
          }
        })

        currentValues[i] = uniqueUsersId.length
        currentDateCounter.add(1, 'year')
      }
    }

    return { currentLabels, currentValues }
  }, [startDate, finishDate, repeatedOrdersStats, dateGroupMode])

  // Get max value in data stack
  const maxValue = useMemo(() => {
    let maxValue = 0

    if (chartData === undefined) {
      return maxValue
    }

    chartData.currentValues.forEach((value) => {
      maxValue = value > maxValue ? value : maxValue
    })

    return maxValue
  }, [chartData])

  // Get target chart width
  const targetCanvasWidth = useMemo(() => {
    const needSize = chartData.currentLabels.length * 35

    if (chartSize.width !== undefined && chartSize.width > needSize + analyticsBlockWidth) {
      return chartSize.width - analyticsBlockWidth
    }

    return needSize
  }, [chartData.currentLabels, chartSize.width])

  // Trigger rerender if target chart width was changed
  useEffect(() => {
    setReRenderFlag(true)

    const reRenderTimeout = window.setTimeout(() => {
      setReRenderFlag(false)
    }, 50)

    return () => {
      window.clearTimeout(reRenderTimeout)
    }
  }, [targetCanvasWidth])

  // Calculate step size depends on max value in data stack
  const stepSize = useMemo(
    () => (maxValue >= targetYLinesCount ? Math.ceil(maxValue / targetYLinesCount) : 1),
    [maxValue, targetYLinesCount],
  )

  return (
    <div className={baseStyles.chart} ref={chartRef}>
      <div className={baseStyles.chart__header}>
        <div className={baseStyles.chart__headerTop}>
          <p className={baseStyles.chart__headerTitle}>{t('dashboard.repeated orders')}</p>
          <DashboardPeriodChanger
            periods={[
              DASHBOARD_PERIODS.AM,
              DASHBOARD_PERIODS.PM,
              DASHBOARD_PERIODS.DAY,
              DASHBOARD_PERIODS.WEEK,
              DASHBOARD_PERIODS.MONTH,
              DASHBOARD_PERIODS.YEAR,
              DASHBOARD_PERIODS.CUSTOM_DATE,
            ]}
            defaultPeriod={datePeriod}
            onChange={(period) => setDatePeriod(period)}
          />
        </div>
        <div className={baseStyles.chart__headerBottom}>
          <Space size={10}>
            <span>{t('dashboard.orders count')}:</span>
            <div className={'field-wrapper'}>
              <InputNumber
                style={{
                  padding: '2px 12px',
                  width: ordersCount.toString().length * 10 + 50,
                }}
                min={1}
                value={ordersCount}
                onChange={(count) => setOrdersCount(!!count ? count : 1)}
                disabled={isRepeatedOrdersStatsFetching}
              />
            </div>
          </Space>
        </div>
      </div>
      <div className={classNames([baseStyles.chart__body, baseStyles.chart__body_xScrollable])}>
        {!isRepeatedOrdersStatsFetching && targetCanvasWidth && !reRenderFlag ? (
          <Bar
            data={{
              labels: chartData.currentLabels,
              datasets: [
                {
                  label: t('dashboard.customers count'),
                  data: chartData.currentValues,
                  type: 'bar',
                  borderColor: '#4c5973',
                  backgroundColor: '#4c5973',
                },
              ],
            }}
            width={targetCanvasWidth}
            height={chartSize.width && targetCanvasWidth <= chartSize.width ? 320 : 308} // Additional space for scrollbar
            options={{
              indexAxis: 'x',
              elements: {
                bar: {
                  borderWidth: 0,
                },
                point: {
                  pointStyle: 'circle',
                  radius: 3,
                  hoverRadius: 5,
                },
              },
              responsive: false,
              maintainAspectRatio: true,
              plugins: {
                legend: {
                  display: false,
                },
                title: {
                  display: false,
                },
                tooltip: {
                  displayColors: false,
                  callbacks: {
                    title(tooltipItems) {
                      const currentDateTime = chartData.currentLabels[tooltipItems[0].dataIndex]

                      if ([DatesGroupModes.HOURS, DatesGroupModes.MINUTES].includes(dateGroupMode))
                        return moment(currentDateTime).format('D MMM HH:mm')
                      if (dateGroupMode === DatesGroupModes.WEEK_DAYS)
                        return moment(currentDateTime).format('D MMM (ddd)')
                      if (dateGroupMode === DatesGroupModes.DAYS)
                        return moment(currentDateTime).format('D MMM')
                      if (dateGroupMode === DatesGroupModes.MONTHS)
                        return moment(currentDateTime).format('MMM YYYY')
                      if (dateGroupMode === DatesGroupModes.YEARS)
                        return moment(currentDateTime).format('YYYY')

                      return ''
                    },
                  },
                },
              },
              scales: {
                y: {
                  beginAtZero: true,
                  suggestedMax: stepSize * targetYLinesCount,
                  ticks: {
                    stepSize: stepSize,
                  },
                },
                x: {
                  ticks: {
                    callback: (value, index) => {
                      if ([DatesGroupModes.HOURS, DatesGroupModes.MINUTES].includes(dateGroupMode))
                        return moment(chartData.currentLabels[index]).format('HH:mm')
                      if (dateGroupMode === DatesGroupModes.WEEK_DAYS)
                        return moment(chartData.currentLabels[index]).format('ddd')
                      if (dateGroupMode === DatesGroupModes.DAYS)
                        return moment(chartData.currentLabels[index]).format('D MMM')
                      if (dateGroupMode === DatesGroupModes.MONTHS)
                        return moment(chartData.currentLabels[index]).format('MMM YYYY')
                      if (dateGroupMode === DatesGroupModes.YEARS)
                        return moment(chartData.currentLabels[index]).format('YYYY')
                    },
                  },
                },
              },
            }}
          />
        ) : (
          <PreloaderBlock minHeight={320} />
        )}
      </div>
      <div className={baseStyles.chart__footer}>
        <div className={baseStyles.chart__offsetChangerWrap}>
          <DashboardPeriodOffsetChanger
            startDate={globalStartDate}
            finishDate={globalFinishDate}
            period={datePeriod}
            disabled={datePeriod === DASHBOARD_PERIODS.CUSTOM_DATE || isRepeatedOrdersStatsFetching}
            changeOffsetCallback={(newOffset) => {
              setPeriodOffset(newOffset)
            }}
          />
        </div>
      </div>
    </div>
  )
}

export default OrdersRepeatChart
