import {
  GET_CUSTOMERS_REQUEST,
  GET_CUSTOMER_REQUEST,
  GET_CUSTOMER,
  UPDATE_CUSTOMER_REQUEST,
  CREATE_CUSTOMER_REQUEST,
  DELETE_CUSTOMER_REQUEST,
} from '../types/customers'

export function getCustomers(payload) {
  return {
    type: GET_CUSTOMERS_REQUEST,
    payload,
  }
}

export function getCustomer(payload) {
  return {
    type: GET_CUSTOMER_REQUEST,
    payload,
  }
}

export function setCustomer(payload) {
  return {
    type: GET_CUSTOMER,
    payload,
  }
}

export function updateCustomer(payload) {
  return {
    type: UPDATE_CUSTOMER_REQUEST,
    payload,
  }
}

export function createCustomer(payload) {
  return {
    type: CREATE_CUSTOMER_REQUEST,
    payload,
  }
}

export function deleteCustomer(payload) {
  return {
    type: DELETE_CUSTOMER_REQUEST,
    payload,
  }
}
