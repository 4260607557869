import moment from 'moment/moment'
import classNames from 'classnames'
import { useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from '@tanstack/react-query'
import { useChartsDates } from './hooks/useChartsDates'
import { DatesGroupModes, useDatesGroupMode } from './hooks/useDatesGroupMode'
import useNodeSize from './hooks/useNodeSize'
import { Chart, registerables } from 'chart.js'
import { Line } from 'react-chartjs-2'
import PreloaderBlock from 'components/PreloaderBlock/PreloaderBlock'
import DashboardPeriodChanger from 'components/Dashboard/PeriodChanger/DashboardPeriodChanger'
import DashboardPeriodOffsetChanger from 'components/Dashboard/PeriodOffsetChanger/DashboardPeriodOffsetChanger'
import OrdersAnalytics from 'components/Dashboard/Charts/OrdersAnalytics'
import DashboardService from 'redux/middlewares/dashboard'
import { DASHBOARD_PERIODS } from 'utils/constants'
import baseStyles from './styles/base.module.scss'

Chart.register(...registerables)

const OrdersChart = ({ globalStartDate, globalFinishDate }) => {
  const { t } = useTranslation()
  const targetYLinesCount = 7
  const analyticsBlockWidth = 415
  const chartRef = useRef()
  const chartSize = useNodeSize(chartRef)
  const [reRenderFlag, setReRenderFlag] = useState(false)
  const [datePeriod, setDatePeriod] = useState(DASHBOARD_PERIODS.CUSTOM_DATE)
  const [periodOffset, setPeriodOffset] = useState(0)
  const {
    startDate,
    finishDate,
    prevStartDate,
    prevFinishDate,
    prevYearStartDate,
    prevYearFinishDate,
  } = useChartsDates({
    globalStartDate: globalStartDate,
    globalFinishDate: globalFinishDate,
    period: datePeriod,
    offset: periodOffset,
  })
  const dateGroupMode = useDatesGroupMode({ startDate, finishDate })

  // Load orders statistic
  const { data: ordersStatistic, isFetching: isOrdersStatisticFetching } = useQuery(
    ['dashboard-orders', startDate, finishDate],
    async () => {
      const response = await DashboardService.getOrdersStats({
        startDate: startDate.toISOString(),
        finishDate: finishDate.toISOString(),
      })

      return response.data.data
    },
    {
      staleTime: 1000 * 60 * 10, // 10 minute
      refetchOnMount: 'always',
    },
  )

  // Load previous period orders statistic
  const { data: prevOrdersStatistic, isFetching: isPrevOrdersStatisticFetching } = useQuery(
    ['dashboard-orders', prevStartDate, prevFinishDate],
    async () => {
      const response = await DashboardService.getOrdersStats({
        startDate: prevStartDate.toISOString(),
        finishDate: prevFinishDate.toISOString(),
      })

      return response.data.data
    },
    {
      staleTime: 1000 * 60 * 10, // 10 minute
      refetchOnMount: 'always',
    },
  )

  // Load previous year period orders statistic
  const { data: prevYearOrdersStatistic, isFetching: isPrevYearOrdersStatisticFetching } = useQuery(
    ['dashboard-orders', prevYearStartDate, prevYearFinishDate],
    async () => {
      const response = await DashboardService.getOrdersStats({
        startDate: prevYearStartDate.toISOString(),
        finishDate: prevYearFinishDate.toISOString(),
      })

      return response.data.data
    },
    {
      staleTime: 1000 * 60 * 10, // 10 minute
      refetchOnMount: 'always',
    },
  )

  const chartData = useMemo(() => {
    const currentLabels = []
    const currentValues = []
    const prevLabels = []
    const prevValues = []

    if (ordersStatistic === undefined || prevOrdersStatistic === undefined) {
      return { currentLabels, currentValues, prevLabels, prevValues }
    }

    if (dateGroupMode === DatesGroupModes.MINUTES) {
      // If period is half of day or smaller - Group by 15 minutes
      const currentDateCounter = startDate.clone()
      const prevDateCounter = prevStartDate.clone()
      let currentIndex = 0
      let prevPeriodIndex = 0

      // Fill current period data
      while (currentDateCounter <= finishDate) {
        currentLabels[currentIndex] = currentDateCounter.toISOString()
        currentValues[currentIndex] = 0

        const nextDateLimit = currentDateCounter.clone().add(15, 'minutes')

        // eslint-disable-next-line no-loop-func
        ordersStatistic.forEach((ordersInDay) => {
          if (
            moment(ordersInDay.orders_by_time) >= currentDateCounter &&
            moment(ordersInDay.orders_by_time) < nextDateLimit
          ) {
            currentValues[currentIndex] += parseInt(ordersInDay.total_order_per_day)
          }
        })

        currentDateCounter.add(15, 'minutes')
        currentIndex++
      }

      // Fill previous period data
      while (prevDateCounter <= prevFinishDate) {
        prevLabels[prevPeriodIndex] = prevDateCounter.toISOString()
        prevValues[prevPeriodIndex] = 0

        const nextDateLimit = prevDateCounter.clone().add(15, 'minutes')

        // eslint-disable-next-line no-loop-func
        prevOrdersStatistic.forEach((ordersInDay) => {
          if (
            moment(ordersInDay.orders_by_time) >= prevDateCounter &&
            moment(ordersInDay.orders_by_time) < nextDateLimit
          ) {
            prevValues[prevPeriodIndex] += parseInt(ordersInDay.total_order_per_day)
          }
        })

        prevDateCounter.add(15, 'minutes')
        prevPeriodIndex++
      }
    } else if (dateGroupMode === DatesGroupModes.HOURS) {
      // If period is day or smaller - Group by hours
      const currentDateCounter = startDate.clone()
      const prevDateCounter = prevStartDate.clone()
      const hoursInCurrentRange = finishDate
        .clone()
        .endOf('day')
        .diff(startDate.clone().startOf('day'), 'hours')
      const hoursInPrevRange = prevFinishDate
        .clone()
        .endOf('day')
        .diff(prevStartDate.clone().startOf('day'), 'hours')

      // Fill current period data
      for (let i = 0; i <= hoursInCurrentRange; i++) {
        currentLabels[i] = currentDateCounter.toISOString()
        currentValues[i] = 0

        ordersStatistic.forEach((ordersInDay) => {
          if (moment(ordersInDay.orders_by_time).isSame(currentDateCounter, 'hour')) {
            currentValues[i] += parseInt(ordersInDay.total_order_per_day)
          }
        })

        currentDateCounter.add(1, 'hour')
      }

      // Fill previous period data
      for (let i = 0; i <= hoursInPrevRange; i++) {
        prevLabels[i] = prevDateCounter.toISOString()
        prevValues[i] = 0

        prevOrdersStatistic.forEach((ordersInDay) => {
          if (moment(ordersInDay.orders_by_time).isSame(prevDateCounter, 'hour')) {
            prevValues[i] += parseInt(ordersInDay.total_order_per_day)
          }
        })

        prevDateCounter.add(1, 'hour')
      }
    } else if (
      dateGroupMode === DatesGroupModes.WEEK_DAYS ||
      dateGroupMode === DatesGroupModes.DAYS
    ) {
      // If period smaller than 3 month - Group by days
      const currentDateCounter = startDate.clone()
      const prevDateCounter = prevStartDate.clone()
      const daysInCurrentRange = finishDate
        .clone()
        .endOf('day')
        .diff(startDate.clone().startOf('day'), 'days')

      // Fill current period data
      for (let i = 0; i <= daysInCurrentRange; i++) {
        currentLabels[i] = currentDateCounter.toISOString()
        currentValues[i] = 0

        ordersStatistic.forEach((ordersInDay) => {
          if (moment(ordersInDay.orders_by_time).isSame(currentDateCounter, 'day')) {
            currentValues[i] += parseInt(ordersInDay.total_order_per_day)
          }
        })

        currentDateCounter.add(1, 'day')
      }

      // Fill previous period data
      for (let i = 0; i <= daysInCurrentRange; i++) {
        const currentPeriodDay = moment(currentLabels[i]).format('DD')
        const previousPeriodDay = prevDateCounter.format('DD')

        if (datePeriod === DASHBOARD_PERIODS.MONTH && currentPeriodDay !== previousPeriodDay) {
          if (currentPeriodDay === '01') {
            // Skip extra dates
            while (prevDateCounter.format('DD') !== '01') {
              prevDateCounter.add(1, 'day')
            }

            prevLabels[i] = prevDateCounter.toISOString()
            prevValues[i] = 0

            prevOrdersStatistic.forEach((ordersInDay) => {
              if (moment(ordersInDay.orders_by_time).isSame(prevDateCounter, 'day')) {
                prevValues[i] += parseInt(ordersInDay.total_order_per_day)
              }
            })

            prevDateCounter.add(1, 'day')
          }

          if (previousPeriodDay === '01') {
            // Add empty point and not increase counter
            prevLabels[i] = ''
            prevValues[i] = 0
          }
        } else {
          // Standard flow
          prevLabels[i] = prevDateCounter.toISOString()
          prevValues[i] = 0

          prevOrdersStatistic.forEach((ordersInDay) => {
            if (moment(ordersInDay.orders_by_time).isSame(prevDateCounter, 'day')) {
              prevValues[i] += parseInt(ordersInDay.total_order_per_day)
            }
          })

          prevDateCounter.add(1, 'day')
        }
      }
    } else if (dateGroupMode === DatesGroupModes.MONTHS) {
      // If period smaller than 12 month - Group by month
      const currentDateCounter = startDate.clone()
      const prevDateCounter = prevStartDate.clone()
      const monthInCurrentRange = finishDate
        .clone()
        .endOf('month')
        .diff(startDate.clone().startOf('month'), 'month')
      const monthInPrevRange = prevFinishDate
        .clone()
        .endOf('month')
        .diff(prevStartDate.clone().startOf('month'), 'month')

      // Fill current period data
      for (let i = 0; i <= monthInCurrentRange; i++) {
        currentLabels[i] = currentDateCounter.toISOString()
        currentValues[i] = 0

        ordersStatistic.forEach((ordersInDay) => {
          if (moment(ordersInDay.orders_by_time).isSame(currentDateCounter, 'month')) {
            currentValues[i] += parseInt(ordersInDay.total_order_per_day)
          }
        })

        currentDateCounter.add(1, 'month')
      }

      // Fill previous period data
      for (let i = 0; i <= monthInPrevRange; i++) {
        prevLabels[i] = currentDateCounter.toISOString()
        prevValues[i] = 0

        prevOrdersStatistic.forEach((ordersInDay) => {
          if (moment(ordersInDay.orders_by_time).isSame(prevDateCounter, 'month')) {
            prevValues[i] += parseInt(ordersInDay.total_order_per_day)
          }
        })

        prevDateCounter.add(1, 'month')
      }
    } else if (dateGroupMode === DatesGroupModes.YEARS) {
      // If period is 12 month or bigger - Group by year
      const currentDateCounter = startDate.clone()
      const prevDateCounter = prevStartDate.clone()
      const yearsInCurrentRange = finishDate
        .clone()
        .endOf('year')
        .diff(startDate.clone().startOf('year'), 'year')
      const yearsInPrevRange = prevFinishDate
        .clone()
        .endOf('year')
        .diff(prevStartDate.clone().startOf('year'), 'year')

      // Fill current period data
      for (let i = 0; i <= yearsInCurrentRange; i++) {
        currentLabels[i] = currentDateCounter.toISOString()
        currentValues[i] = 0

        ordersStatistic.forEach((ordersInDay) => {
          if (moment(ordersInDay.orders_by_time).isSame(currentDateCounter, 'year')) {
            currentValues[i] += parseInt(ordersInDay.total_order_per_day)
          }
        })

        currentDateCounter.add(1, 'year')
      }

      // Fill previous period data
      for (let i = 0; i <= yearsInPrevRange; i++) {
        prevLabels[i] = currentDateCounter.toISOString()
        prevValues[i] = 0

        prevOrdersStatistic.forEach((ordersInDay) => {
          if (moment(ordersInDay.orders_by_time).isSame(prevDateCounter, 'year')) {
            prevValues[i] += parseInt(ordersInDay.total_order_per_day)
          }
        })

        prevDateCounter.add(1, 'year')
      }
    }

    return { currentLabels, currentValues, prevLabels, prevValues }
  }, [
    startDate,
    finishDate,
    prevStartDate,
    prevFinishDate,
    ordersStatistic,
    prevOrdersStatistic,
    datePeriod,
    dateGroupMode,
  ])

  // Get max value in data stack
  const maxValue = useMemo(() => {
    let maxValue = 0

    if (ordersStatistic === undefined || prevOrdersStatistic === undefined) {
      return maxValue
    }

    ordersStatistic.forEach((ordersInDay) => {
      const ordersInDayVal = parseInt(ordersInDay.total_order_per_day)
      maxValue = ordersInDayVal > maxValue ? ordersInDayVal : maxValue
    })

    prevOrdersStatistic.forEach((ordersInDay) => {
      const ordersInDayVal = parseInt(ordersInDay.total_order_per_day)
      maxValue = ordersInDayVal > maxValue ? ordersInDayVal : maxValue
    })

    return maxValue
  }, [ordersStatistic, prevOrdersStatistic])

  // Get target chart width
  const targetCanvasWidth = useMemo(() => {
    const needSize = chartData.currentLabels.length * 35

    if (chartSize.width !== undefined && chartSize.width > needSize + analyticsBlockWidth) {
      return chartSize.width - analyticsBlockWidth
    }

    return needSize
  }, [chartData.currentLabels, chartSize.width])

  // Trigger rerender if target chart width was changed
  useEffect(() => {
    setReRenderFlag(true)

    const reRenderTimeout = window.setTimeout(() => {
      setReRenderFlag(false)
    }, 50)

    return () => {
      window.clearTimeout(reRenderTimeout)
    }
  }, [targetCanvasWidth])

  // Calculate step size depends on max value in data stack
  const stepSize = useMemo(
    () => (maxValue >= targetYLinesCount ? Math.ceil(maxValue / targetYLinesCount) : 1),
    [maxValue, targetYLinesCount],
  )

  return (
    <div className={baseStyles.chart} ref={chartRef}>
      <div className={baseStyles.chart__header}>
        <div className={baseStyles.chart__headerTop}>
          <p className={baseStyles.chart__headerTitle}>{t('dashboard.number of orders')}</p>
          <DashboardPeriodChanger
            periods={[
              DASHBOARD_PERIODS.AM,
              DASHBOARD_PERIODS.PM,
              DASHBOARD_PERIODS.DAY,
              DASHBOARD_PERIODS.WEEK,
              DASHBOARD_PERIODS.MONTH,
              DASHBOARD_PERIODS.YEAR,
              DASHBOARD_PERIODS.CUSTOM_DATE,
            ]}
            defaultPeriod={datePeriod}
            onChange={(period) => setDatePeriod(period)}
          />
        </div>
      </div>
      <div className={classNames([baseStyles.chart__body, baseStyles.chart__body_xScrollable])}>
        {!isOrdersStatisticFetching &&
        !isPrevOrdersStatisticFetching &&
        !isPrevYearOrdersStatisticFetching &&
        targetCanvasWidth &&
        !reRenderFlag ? (
          <>
            <OrdersAnalytics
              currentStartDate={startDate}
              currentFinishDate={finishDate}
              currentStats={ordersStatistic}
              prevPeriodStartDate={prevStartDate}
              prevPeriodFinishDate={prevFinishDate}
              prevPeriodStats={prevOrdersStatistic}
              prevYearStartDate={prevYearStartDate}
              prevYearFinishDate={prevYearFinishDate}
              prevYearStats={prevYearOrdersStatistic}
            />
            <Line
              data={{
                labels: chartData.currentLabels,
                datasets: [
                  {
                    label: t('dashboard.orders'),
                    data: chartData.currentValues,
                    fill: {
                      target: 'origin',
                      above: 'rgba(107, 187, 110, 0.2)',
                    },
                    borderColor: '#6BBB6E',
                    backgroundColor: '#6BBB6E',
                  },
                  {
                    borderDash: [10, 5],
                    label: t('dashboard.previous period orders'),
                    data: chartData.prevValues,
                    borderColor: 'rgba(0, 0, 0, 0.2)',
                    backgroundColor: 'rgba(0, 0, 0, 0.2)',
                  },
                ],
              }}
              width={targetCanvasWidth}
              height={chartSize.width && targetCanvasWidth <= chartSize.width ? 320 : 308} // Additional space for scrollbar
              options={{
                indexAxis: 'x',
                elements: {
                  point: {
                    pointStyle: 'circle',
                    radius: 4,
                    hoverRadius: 7,
                  },
                  bar: {
                    borderWidth: 0,
                  },
                },
                responsive: false,
                maintainAspectRatio: true,
                plugins: {
                  legend: {
                    display: false,
                  },
                  title: {
                    display: false,
                  },
                  tooltip: {
                    displayColors: false,
                    callbacks: {
                      title(tooltipItems) {
                        const currentDateTime = chartData.currentLabels[tooltipItems[0].dataIndex]
                        const prevDateTime = chartData.prevLabels[tooltipItems[0].dataIndex]

                        if (tooltipItems.length === 2) {
                          if (
                            [DatesGroupModes.HOURS, DatesGroupModes.MINUTES].includes(dateGroupMode)
                          )
                            return `${moment(currentDateTime).format('D MMM HH:mm')} / ${moment(
                              prevDateTime,
                            ).format('D MMM HH:mm')}`
                          if (dateGroupMode === DatesGroupModes.WEEK_DAYS)
                            return `${moment(currentDateTime).format('D MMM (ddd)')} / ${moment(
                              prevDateTime,
                            ).format('D MMM (ddd)')}`
                          if (dateGroupMode === DatesGroupModes.DAYS)
                            return `${moment(currentDateTime).format('D MMM')} / ${moment(
                              prevDateTime,
                            ).format('D MMM')}`
                          if (dateGroupMode === DatesGroupModes.MONTHS)
                            return `${moment(currentDateTime).format('MMM YYYY')} / ${moment(
                              prevDateTime,
                            ).format('MMM YYYY')}`
                          if (dateGroupMode === DatesGroupModes.YEARS)
                            return `${moment(currentDateTime).format('YYYY')} / ${moment(
                              prevDateTime,
                            ).format('YYYY')}`
                        } else if (
                          tooltipItems.length === 1 &&
                          tooltipItems[0].datasetIndex === 0
                        ) {
                          const currentDateTime = chartData.currentLabels[tooltipItems[0].dataIndex]
                          if (
                            [DatesGroupModes.HOURS, DatesGroupModes.MINUTES].includes(dateGroupMode)
                          )
                            return moment(currentDateTime).format('D MMM HH:mm')
                          if (dateGroupMode === DatesGroupModes.WEEK_DAYS)
                            return moment(currentDateTime).format('D MMM (ddd)')
                          if (dateGroupMode === DatesGroupModes.DAYS)
                            return moment(currentDateTime).format('D MMM')
                          if (dateGroupMode === DatesGroupModes.MONTHS)
                            return moment(currentDateTime).format('MMM YYYY')
                          if (dateGroupMode === DatesGroupModes.YEARS)
                            return moment(currentDateTime).format('YYYY')
                        } else {
                          if (
                            [DatesGroupModes.HOURS, DatesGroupModes.MINUTES].includes(dateGroupMode)
                          )
                            return moment(prevDateTime).format('D MMM HH:mm')
                          if (dateGroupMode === DatesGroupModes.WEEK_DAYS)
                            return moment(prevDateTime).format('D MMM (ddd)')
                          if (dateGroupMode === DatesGroupModes.DAYS)
                            return moment(prevDateTime).format('D MMM')
                          if (dateGroupMode === DatesGroupModes.MONTHS)
                            return moment(prevDateTime).format('MMM YYYY')
                          if (dateGroupMode === DatesGroupModes.YEARS)
                            return moment(prevDateTime).format('YYYY')
                        }

                        return ''
                      },
                    },
                  },
                },
                scales: {
                  y: {
                    beginAtZero: true,
                    suggestedMax: stepSize * targetYLinesCount,
                    ticks: {
                      stepSize: stepSize,
                    },
                  },
                  x: {
                    ticks: {
                      callback: (value, index) => {
                        if (dateGroupMode === DatesGroupModes.MINUTES)
                          return moment(chartData.currentLabels[index]).format('D MMM HH:mm')
                        if (dateGroupMode === DatesGroupModes.HOURS)
                          return moment(chartData.currentLabels[index]).format('HH:mm')
                        if (dateGroupMode === DatesGroupModes.WEEK_DAYS)
                          return moment(chartData.currentLabels[index]).format('ddd')
                        if (dateGroupMode === DatesGroupModes.DAYS)
                          return moment(chartData.currentLabels[index]).format('D MMM')
                        if (dateGroupMode === DatesGroupModes.MONTHS)
                          return moment(chartData.currentLabels[index]).format('MMM YYYY')
                        if (dateGroupMode === DatesGroupModes.YEARS)
                          return moment(chartData.currentLabels[index]).format('YYYY')
                      },
                    },
                  },
                },
              }}
            />
          </>
        ) : (
          <PreloaderBlock minHeight={320} />
        )}
      </div>
      <div className={baseStyles.chart__footer}>
        <div className={baseStyles.chart__offsetChangerWrap}>
          <DashboardPeriodOffsetChanger
            startDate={globalStartDate}
            finishDate={globalFinishDate}
            period={datePeriod}
            disabled={
              datePeriod === DASHBOARD_PERIODS.CUSTOM_DATE ||
              isOrdersStatisticFetching ||
              isPrevOrdersStatisticFetching ||
              isPrevYearOrdersStatisticFetching
            }
            changeOffsetCallback={(newOffset) => {
              setPeriodOffset(newOffset)
            }}
          />
        </div>
      </div>
    </div>
  )
}

export default OrdersChart
