import { call, put, all, fork, takeEvery } from 'redux-saga/effects'

import { showLoading, hideLoading } from 'react-redux-loading-bar'

import UsersService from '../middlewares/users'

import {
  GET_USERS_REQUEST,
  GET_USERS,
  USERS_REQUEST_PENDING,
  USERS_REQUEST_SUCCESS,
  USERS_REQUEST_ERROR,
} from '../types/users'

function* getUsersWatcher() {
  yield takeEvery(GET_USERS_REQUEST, getUsersWorker)
}

function* getUsersWorker() {
  try {
    yield put(showLoading())
    yield put({ type: USERS_REQUEST_PENDING })
    const data = yield call(UsersService.getUsers)
    yield put({ type: GET_USERS, payload: data })
    yield put({ type: USERS_REQUEST_SUCCESS })
    yield put(hideLoading())
  } catch (error) {
    yield put({ type: USERS_REQUEST_ERROR, payload: error.response.data.messages })
    yield put(hideLoading())
  }
}

export default function* users() {
  yield all([fork(getUsersWatcher)])
}
