import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'

import { fetchCampaign } from '../../../api/campaigns'

import Page from '../../../components/Page'
import CampaignForm from '../../../components/forms/CampaignForm/CampaignForm'

import CampaignFormSkeleton from '../../../components/forms/CampaignForm/CampaignFormSkeleton'

const CampaignView = () => {
  const { t } = useTranslation()
  const { id } = useParams()
  const { data: campaign, isFetching } = useQuery(
    [`campaign-${id}`],
    async () => await fetchCampaign(id),
  )

  if (isFetching) {
    return <CampaignFormSkeleton />
  }

  return (
    <Page pageTitle={t('campaigns.view.title')}>
      {campaign && <CampaignForm defaultValues={campaign} />}
    </Page>
  )
}

export default CampaignView
