export const GET_SURVEYS_REQUEST = 'GET_SURVEYS_REQUEST'
export const GET_SURVEYS = 'GET_SURVEYS'

export const GET_SURVEY_REQUEST = 'GET_SURVEY_REQUEST'
export const GET_SURVEY = 'GET_SURVEY'

export const UPDATE_SURVEY_REQUEST = 'UPDATE_SURVEY_REQUEST'
export const UPDATE_SURVEY = 'UPDATE_SURVEY'

export const CREATE_SURVEY_REQUEST = 'CREATE_SURVEY_REQUEST'
export const CREATE_SURVEY = 'CREATE_SURVEY'

export const DELETE_SURVEY_REQUEST = 'DELETE_SURVEY_REQUEST'
export const DELETE_SURVEY = 'DELETE_SURVEY'

export const GET_SURVEYS_REQUEST_PENDING = 'GET_SURVEYS_REQUEST_PENDING'
export const GET_SURVEYS_REQUEST_SUCCESS = 'GET_SURVEYS_REQUEST_SUCCESS'
export const GET_SURVEYS_REQUEST_ERROR = 'GET_SURVEYS_REQUEST_ERROR'

export const CHANGE_SURVEYS_REQUEST_PENDING = 'CHANGE_SURVEYS_REQUEST_PENDING'
export const CHANGE_SURVEYS_REQUEST_SUCCESS = 'CHANGE_SURVEYS_REQUEST_SUCCESS'
export const CHANGE_SURVEYS_REQUEST_ERROR = 'CHANGE_SURVEYS_REQUEST_ERROR'
