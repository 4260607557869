import { call, put, all, fork, takeEvery, select } from 'redux-saga/effects'

import { showLoading, hideLoading } from 'react-redux-loading-bar'

import MosquesService from '../middlewares/mosques'

import {
  GET_MOSQUES,
  GET_MOSQUES_REQUEST,
  GET_MOSQUES_REQUEST_ERROR,
  GET_MOSQUES_REQUEST_PENDING,
  GET_MOSQUES_REQUEST_SUCCESS,
  GET_SUP_MOSQUES_REQUEST,
} from '../types/mosques'

function* getMosquesWatcher() {
  yield takeEvery(GET_MOSQUES_REQUEST, getMosquesWorker)
}

function* getSupMosquesWatcher() {
  yield takeEvery(GET_SUP_MOSQUES_REQUEST, getSupMosquesWorker)
}

function* getMosquesWorker({ payload: { page, size, search } }) {
  try {
    yield put(showLoading())
    yield put({ type: GET_MOSQUES_REQUEST_PENDING })
    const response = yield call(MosquesService.getMosques, page, size, search)
    const { result, total } = response.data.data
    yield put({
      type: GET_MOSQUES,
      payload: {
        items: result,
        pagination: {
          current: page,
          pageSize: size,
          total: total,
        },
      },
    })
    yield put({ type: GET_MOSQUES_REQUEST_SUCCESS })
    yield put(hideLoading())
  } catch (error) {
    yield put({ type: GET_MOSQUES_REQUEST_ERROR, payload: error.response.data.messages })
    yield put(hideLoading())
  }
}

function* getSupMosquesWorker() {
  try {
    yield put(showLoading())
    yield put({ type: GET_MOSQUES_REQUEST_PENDING })
    yield call(MosquesService.getSupMosques)
    const { current, pageSize } = yield select((state) => state.mosques.pagination)
    yield put({
      type: GET_MOSQUES_REQUEST,
      payload: {
        page: current,
        size: pageSize,
      },
    })
    yield put({ type: GET_MOSQUES_REQUEST_SUCCESS })
    yield put(hideLoading())
  } catch (error) {
    yield put({
      type: GET_MOSQUES_REQUEST_ERROR,
      payload: error.response.data.messages,
    })
    yield put(hideLoading())
  }
}

export default function* mosques() {
  yield all([fork(getMosquesWatcher), fork(getSupMosquesWatcher)])
}
