import axios from 'axios'

export const fetchCategoryList = async (params) => {
  const response = await axios({
    method: 'GET',
    url: 'categories/sap',
    params: {
      page: params.page,
      take: params.size,
      asc: params.sortType && params.sortOrder === 'ascend' ? params.sortType : null,
      desc: params.sortType && params.sortOrder === 'descend' ? params.sortType : null,
    },
  })

  return response.data.data
}

export const pullCategoriesFromSap = async () => {
  const response = await axios.get('categories/sap/pull')

  return response.data.data
}

export const updateCategory = async ({ id, displayIndex }) => {
  const response = await axios.put(`/categories/sap/change-order/${id}`, {
    displayIndex,
  })

  return response.data.data
}
