import axios from 'axios'

export const getCashbackList = async (params) => {
  const response = await axios({
    method: 'GET',
    url: 'cashback/list',
    params: {
      page: params.page,
      take: params.size,
      asc: params.sortType && params.sortOrder === 'ascend' ? params.sortType : null,
      desc: params.sortType && params.sortOrder === 'descend' ? params.sortType : null,
    },
  })

  return response.data.data
}

export const createCashback = async (data) => {
  const response = await axios.post('cashback', data)

  return response.data.data
}

export const updateCashback = async (data) => {
  const response = await axios.put('cashback', data)

  return response.data.data
}

export const fetchCashback = async (id) => {
  const response = await axios.get(`cashback/${id}`)

  return response.data.data
}
