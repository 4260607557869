import {
  GET_USERS,
  UPDATE_USER,
  CREATE_USER,
  DELETE_USER,
  USERS_REQUEST_PENDING,
  USERS_REQUEST_SUCCESS,
  USERS_REQUEST_ERROR,
} from '../types/users'

const initialState = {
  items: [],
  loading: false,
  errors: [],
}

const users = (state = initialState, action) => {
  switch (action.type) {
    case USERS_REQUEST_PENDING:
      return { ...state, loading: true }
    case USERS_REQUEST_SUCCESS:
      return { ...state, errors: [], loading: false }
    case USERS_REQUEST_ERROR:
      return { ...state, errors: action.payload, loading: false }
    case GET_USERS:
      return { ...state, items: action.payload }
    case UPDATE_USER:
      const itemIndex = state.items.findIndex((item) => item.id === action.payload.id)
      const newItems = [...state.items]
      newItems[itemIndex] = { ...action.payload }
      return { ...state, items: newItems }
    case CREATE_USER:
      return { ...state, items: [...state.items, action.payload] }
    case DELETE_USER:
      return { ...state, items: state.items.filter((item) => item.id !== action.payload) }
    default:
      return state
  }
}

export default users
