import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { getUsers } from 'redux/actions/users'
import { sortByAlphabet, sortByDate } from 'utils/sorters'
import { ICONS } from 'utils/constants'
import SortableColumnTitle from 'components/SortableColumnTitle'
import PageTable from 'components/PageTable'
import Icon from 'components/Icon'
import Button from 'components/Button'

const UsersManagement = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { items: users, loading } = useSelector((state) => state.users)

  useEffect(() => {
    dispatch(getUsers())
  }, [dispatch])

  const columns = [
    {
      title: '№',
      dataIndex: 'id',
      key: 'id',
      align: 'center',
    },
    {
      title: (props) => <SortableColumnTitle {...props} title={t('fields.name en')} id="nameEn" />,
      dataIndex: 'nameEn',
      key: 'nameEn',
      sorter: (a, b) => sortByAlphabet(a.nameEn, b.nameEn),
    },
    {
      title: t('fields.mobile'),
      dataIndex: 'mobile',
      key: 'mobile',
    },
    {
      title: (props) => <SortableColumnTitle {...props} title={t('fields.email')} id="email" />,
      dataIndex: 'email',
      key: 'email',
      sorter: (a, b) => sortByAlphabet(a.email, b.email),
    },
    {
      title: (props) => (
        <SortableColumnTitle {...props} title={t('fields.position')} id="position" />
      ),
      dataIndex: 'position',
      key: 'position',
      sorter: (a, b) => sortByAlphabet(a.position, b.position),
    },
    {
      title: (props) => (
        <SortableColumnTitle {...props} title={t('fields.entry date')} id="entryDate" />
      ),
      dataIndex: 'entryDate',
      key: 'entryDate',
      sorter: (a, b) => sortByDate(a.entryDate, b.entryDate),
    },
  ]

  return (
    <PageTable
      pageTitle={t('usersManagement.title')}
      action={
        <Button type="primary" onClick={() => navigate(`/main/users-management/add`)}>
          <Icon icon={ICONS.PLUS} className="btn-icon-suffix" />
          <span className="btn-text">{t('usersManagement.add button')}</span>
        </Button>
      }
      baseData={users}
      columns={columns}
      onRow={(record) => {
        return {
          onClick: () => {
            navigate(`/main/users-management/view/${record.id}`)
          },
        }
      }}
      loading={loading}
    />
  )
}

export default UsersManagement
