export const GET_UPSELLING_PRODUCT_REQUEST = 'GET_UPSELLING_PRODUCT_REQUEST'
export const GET_UPSELLING_PRODUCT = 'GET_UPSELLING_PRODUCT'

export const GET_UPSELLING_PRODUCT_PENDING = 'GET_UPSELLING_PRODUCT_PENDING'
export const GET_UPSELLING_PRODUCT_SUCCESS = 'GET_UPSELLING_PRODUCT_SUCCESS'
export const GET_UPSELLING_PRODUCT_ERROR = 'GET_UPSELLING_PRODUCT_ERROR'

export const GET_UPSELLING_TYPE_REQUEST = 'GET_UPSELLING_TYPE_REQUEST'
export const GET_UPSELLING_TYPE_PRODUCT = 'GET_UPSELLING_TYPE_PRODUCT'

export const GET_UPSELLING_TYPE_PENDING = 'GET_UPSELLING_TYPE_PENDING'
export const GET_UPSELLING_TYPE_SUCCESS = 'GET_UPSELLING_TYPE_SUCCESS'
export const GET_UPSELLING_TYPE_ERROR = 'GET_UPSELLING_TYPE_ERROR'

export const GET_UPSELLING_SELECTIVE_REQUEST = 'GET_UPSELLING_SELECTIVE_REQUEST'
export const GET_UPSELLING_SELECTIVE = 'GET_UPSELLING_SELECTIVE'

export const GET_UPSELLING_SELECTIVE_PENDING = 'GET_UPSELLING_SELECTIVE_PENDING'
export const GET_UPSELLING_SELECTIVE_SUCCESS = 'GET_UPSELLING_SELECTIVE_SUCCESS'
export const GET_UPSELLING_SELECTIVE_ERROR = 'GET_UPSELLING_SELECTIVE_ERROR'

export const GET_UPSELLING_RANDOM_REQUEST = 'GET_UPSELLING_RANDOM_REQUEST'
export const GET_UPSELLING_RANDOM = 'GET_UPSELLING_RANDOM'

export const GET_UPSELLING_RANDOM_PENDING = 'GET_UPSELLING_RANDOM_PENDING'
export const GET_UPSELLING_RANDOM_SUCCESS = 'GET_UPSELLING_RANDOM_SUCCESS'
export const GET_UPSELLING_RANDOM_ERROR = 'GET_UPSELLING_RANDOM_ERROR'

export const GET_UPSELLING_ACTIVATE_TYPE_REQUEST = 'GET_UPSELLING_ACTIVATE_TYPE_REQUEST'
export const GET_UPSELLING_ACTIVATE_TYPE = 'GET_UPSELLING_ACTIVATE_TYPE'

export const GET_UPSELLING_ACTIVATE_TYPE_PENDING = 'GET_UPSELLING_ACTIVATE_TYPE_PENDING'
export const GET_UPSELLING_ACTIVATE_TYPE_SUCCESS = 'GET_UPSELLING_ACTIVATE_TYPE_SUCCESS'
export const GET_UPSELLING_ACTIVATE_TYPE_ERROR = 'GET_UPSELLING_ACTIVATE_TYPE_ERROR'

export const UPDATE_UPSELLING_SELECTIVE_PRODUCT_REQUEST =
  'UPDATE_UPSELLING_SELECTIVE_PRODUCT_REQUEST'
export const UPDATE_UPSELLING_SELECTIVE_PRODUCT = 'UPDATE_UPSELLING_SELECTIVE_PRODUCT'

export const UPDATE_UPSELLING_SELECTIVE_PRODUCT_PENDING =
  'UPDATE_UPSELLING_SELECTIVE_PRODUCT_PENDING'
export const UPDATE_UPSELLING_SELECTIVE_PRODUCT_SUCCESS =
  'UPDATE_UPSELLING_SELECTIVE_PRODUCT_SUCCESS'
export const UPDATE_UPSELLING_SELECTIVE_PRODUCT_ERROR = 'UPDATE_UPSELLING_SELECTIVE_PRODUCT_ERROR'

export const UPDATE_UPSELLING_RANDOM_LIST_REQUEST = 'UPDATE_UPSELLING_RANDOM_LIST_REQUEST'
export const UPDATE_UPSELLING_RANDOM_LIST = 'UPDATE_UPSELLING_RANDOM_LIST'
export const UPDATE_UPSELLING_RANDOM_LIST_PENDING = 'UPDATE_UPSELLING_RANDOM_LIST_PENDING'
export const UPDATE_UPSELLING_RANDOM_LIST_SUCCESS = 'UPDATE_UPSELLING_RANDOM_LIST_SUCCESS'
export const UPDATE_UPSELLING_RANDOM_LIST_ERROR = 'UPDATE_UPSELLING_RANDOM_LIST_ERROR'

export const ADD_UPSELLING_ITEM = 'ADD_UPSELLING_ITEM'
export const REMOVE_UPSELLING_ITEM = 'REMOVE_UPSELLING_ITEM'
