// import axios from "axios";
// It is simulation of real request
import { usersData } from '../../utils/data'

class UsersService {
  getUsers = () => {
    return new Promise((resolve, reject) => {
      setTimeout(() => resolve(usersData), 500)
    })
  }
}

export default new UsersService()
