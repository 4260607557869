import { useTranslation } from 'react-i18next'

import { FormProvider, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'

import { customersSchema } from '../../utils/schemas'

import HookedField from '../../components/HookedField'
import Button from '../Button'

const NotificationForm = ({ defaultValues = {}, submitHandler, loading }) => {
  const { t } = useTranslation()

  const methods = useForm({
    mode: 'onSubmit',
    shouldFocusError: true,
    resolver: yupResolver(customersSchema),
  })

  return (
    <div className="customer-form">
      <FormProvider {...methods}>
        <form className="form" autoComplete="off" onSubmit={methods.handleSubmit(submitHandler)}>
          <div className="inputs">
            {defaultValues.id && (
              <HookedField
                label={t('fields.id')}
                name="id"
                disabled={true}
                defaultValue={defaultValues.id}
                placeholder="id"
                fieldType="textField"
                type="text"
                isRequired={true}
              />
            )}
            <HookedField
              label={t('fields.name en')}
              name="nameEn"
              defaultValue={defaultValues.nameEn || ''}
              placeholder={t('fields.name en')}
              fieldType="textField"
              type="text"
              isRequired={true}
            />
            <HookedField
              label={t('fields.name ar')}
              name="nameAr"
              defaultValue={defaultValues.nameAr || ''}
              placeholder={t('fields.name ar')}
              fieldType="textField"
              type="text"
              isRequired={true}
            />
            <HookedField
              label={t('fields.mobile')}
              name="mobile"
              defaultValue={defaultValues.mobile || ''}
              placeholder={t('fields.mobile')}
              fieldType="textField"
              type="tel"
              isRequired={true}
            />
            <HookedField
              label={t('fields.email')}
              name="email"
              defaultValue={defaultValues.email || ''}
              placeholder={t('fields.email')}
              fieldType="textField"
              type="text"
              isRequired={true}
            />
            <HookedField
              label={t('fields.area')}
              name="area"
              defaultValue={defaultValues.area || ''}
              placeholder={t('fields.area')}
              fieldType="textField"
              type="text"
              isRequired={true}
            />
            <HookedField
              label={t('fields.total orders')}
              name="totalOrders"
              defaultValue={defaultValues.totalOrders || null}
              placeholder={t('fields.total orders')}
              fieldType="numberField"
              type="number"
              isRequired={true}
            />
            <HookedField
              label={t('fields.last order')}
              name="lastOrder"
              defaultValue={defaultValues.lastOrder || ''}
              placeholder={t('fields.last order')}
              fieldType="dateField"
              type="datePicker"
              isRequired={true}
            />
            <HookedField
              label={t('fields.address')}
              name="address"
              defaultValue={defaultValues.address || ''}
              placeholder={t('fields.address')}
              fieldType="textField"
              type="text"
              isRequired={true}
            />
          </div>
          <div className="button-grid">
            <Button
              className="save-btn"
              size="large"
              type="primary"
              htmlType="submit"
              disabled={
                loading ||
                !methods.formState.isDirty ||
                (methods.formState.isSubmitted && !methods.formState.isValid)
              }
            >
              {t('app.save')}
            </Button>
          </div>
        </form>
      </FormProvider>
    </div>
  )
}

export default NotificationForm
