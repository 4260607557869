import classNames from 'classnames'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Upload } from 'antd'
import ErrorList from 'components/ErrorList'
import Icon from 'components/Icon'
import { ICONS } from 'utils/constants'

import cls from './styles.module.scss'

const { Dragger } = Upload

const UserImageUploader = ({ methods, formInputName, disabled = false, image, ...rest }) => {
  const { t } = useTranslation()
  const [error, setError] = useState(null)
  const [imageBase64, setImageBase64] = useState(null)
  const imageWatch = methods && formInputName ? methods.watch(formInputName) : undefined

  const classes = classNames({
    [`${cls.userImageUploader}`]: true,
    [`${cls.userImageUploader_hasError}`]: !!error,
  })

  // Define allowed file format
  const acceptedFormats = useMemo(() => {
    return ['image/jpeg', 'image/jpg', 'image/png']
  }, [])

  const onDeleteHandler = useCallback(
    (event) => {
      event.stopPropagation()
      setError(null)
      setImageBase64(null)

      if (methods && formInputName) {
        methods.setValue(formInputName, null)
      }
    },
    [formInputName, methods],
  )

  const beforeUploadHandler = useCallback(
    (file) => {
      // Reset error message
      setError(null)

      // Validate format
      if (!acceptedFormats.includes(file.type)) {
        setError(t('flyers.form.customImage.format error'))
        return false
      }

      // Validate file size
      const isLt2M = file.size / 1024 / 1024 < 2

      if (!isLt2M) {
        setError(t('flyers.form.customImage.size error'))
        return false
      }

      // Read image in base64 format and set it
      const reader = new FileReader()
      reader.addEventListener('load', () => setImageBase64(reader.result))
      reader.readAsDataURL(file)

      // Set selected file
      if (methods && formInputName) {
        methods.setValue(formInputName, { id: image.id, file }, { shouldDirty: true })
      }

      // Clear errors and prevent uploading
      setError(null)
      return false
    },
    [acceptedFormats, formInputName, methods, image?.id, t],
  )

  // Check error in form state and set it in local state
  useEffect(() => {
    if (formInputName && Object.keys(methods.formState.errors).length) {
      if (!!methods.formState.errors[formInputName]) {
        setError(methods.formState.errors[formInputName].message)
        return
      }
    }

    setError(error)
  }, [methods?.formState?.errors, error, formInputName])

  // Show exits image from form data
  useEffect(() => {
    if (imageWatch) {
      // Has image in string URL format
      if (typeof imageWatch === 'string') {
        setImageBase64(imageWatch)
      }

      setError(null)
    }

    if (image?.src) {
      setImageBase64(image.src)
    }
  }, [imageWatch])

  return (
    <div className={'field'}>
      <div className="field-header">
        <label>{t('Avatar')}</label>
      </div>
      <Dragger
        className={classes}
        maxCount={1}
        multiple={false}
        showUploadList={false}
        // fullWidth={true}
        accept={acceptedFormats.join(',')}
        beforeUpload={beforeUploadHandler}
        error={error}
        disabled={disabled}
        {...rest}
      >
        {imageBase64 ? (
          <div className={cls.imageHolder}>
            <img
              className={cls.imageHolder__img}
              src={imageBase64.toString()}
              alt={'avatar-preview'}
            />
            <div
              className={cls.imageHolder__deleteOverlay}
              onClick={(event) => onDeleteHandler(event)}
            >
              <Button
                className={cls.imageHolder__deleteBtn}
                size={'large'}
                shape="circle"
                icon={<Icon size={14} icon={ICONS.CLOSE} />}
              />
            </div>
          </div>
        ) : (
          <>
            <Icon icon={ICONS.DOWNLOAD} size={28} />
          </>
        )}
      </Dragger>
      {error ? <ErrorList errors={[error]} /> : null}
    </div>
  )
}

export default UserImageUploader
