import moment from 'moment/moment'
import classNames from 'classnames'
import { useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from '@tanstack/react-query'
import { useChartsDates } from './hooks/useChartsDates'
import { DatesGroupModes, useDatesGroupMode } from './hooks/useDatesGroupMode'
import useNodeSize from './hooks/useNodeSize'
import { Chart, registerables } from 'chart.js'
import { Bar } from 'react-chartjs-2'
import PreloaderBlock from 'components/PreloaderBlock/PreloaderBlock'
import DashboardPeriodChanger from 'components/Dashboard/PeriodChanger/DashboardPeriodChanger'
import DashboardPeriodOffsetChanger from 'components/Dashboard/PeriodOffsetChanger/DashboardPeriodOffsetChanger'
import DashboardService from 'redux/middlewares/dashboard'
import { DASHBOARD_PERIODS } from 'utils/constants'
import baseStyles from './styles/base.module.scss'

Chart.register(...registerables)

const KNetVsCashChart = ({ globalStartDate, globalFinishDate }) => {
  const { t } = useTranslation()
  const targetYLinesCount = 7
  const analyticsBlockWidth = 0
  const chartRef = useRef()
  const chartSize = useNodeSize(chartRef)
  const [reRenderFlag, setReRenderFlag] = useState(false)
  const [datePeriod, setDatePeriod] = useState(DASHBOARD_PERIODS.CUSTOM_DATE)
  const [periodOffset, setPeriodOffset] = useState(0)
  const { startDate, finishDate, prevStartDate, prevFinishDate } = useChartsDates({
    globalStartDate: globalStartDate,
    globalFinishDate: globalFinishDate,
    period: datePeriod,
    offset: periodOffset,
  })
  const dateGroupMode = useDatesGroupMode({ startDate, finishDate })

  // Load orders payment statistic
  const {
    data: currentOrdersPaymentStatistic,
    isFetching: isCurrentOrdersPaymentStatisticFetching,
  } = useQuery(
    ['dashboard-orders-payment', startDate, finishDate],
    async () => {
      const response = await DashboardService.getOrdersPaymentStats({
        startDate: startDate.toISOString(),
        finishDate: finishDate.toISOString(),
      })

      return response.data.data
    },
    {
      staleTime: 1000 * 60 * 10, // 10 minute
      refetchOnMount: 'always',
    },
  )

  // Load previous period orders payment statistic
  const { data: prevOrdersPaymentStatistic, isFetching: isPrevOrdersPaymentStatisticFetching } =
    useQuery(
      ['dashboard-orders-payment', prevStartDate, prevFinishDate],
      async () => {
        const response = await DashboardService.getOrdersPaymentStats({
          startDate: prevStartDate.toISOString(),
          finishDate: prevFinishDate.toISOString(),
        })

        return response.data.data
      },
      {
        staleTime: 1000 * 60 * 10, // 10 minute
        refetchOnMount: 'always',
      },
    )

  const chartData = useMemo(() => {
    const currentLabels = []
    const currentValues = []
    const prevLabels = []
    const prevValues = []

    if (currentOrdersPaymentStatistic === undefined || prevOrdersPaymentStatistic === undefined) {
      return { currentLabels, currentValues, prevLabels, prevValues }
    }

    if (dateGroupMode === DatesGroupModes.MINUTES) {
      // If period is half of day or smaller - Group by 15 minutes
      const currentDateCounter = startDate.clone()
      const prevDateCounter = prevStartDate.clone()
      let currentIndex = 0
      let prevPeriodIndex = 0

      // Fill current period data
      while (currentDateCounter <= finishDate) {
        currentLabels[currentIndex] = currentDateCounter.toISOString()
        currentValues[currentIndex] = []
        currentValues[currentIndex]['cashSum'] = 0
        currentValues[currentIndex]['knetSum'] = 0
        currentValues[currentIndex]['cashOrders'] = 0
        currentValues[currentIndex]['knetOrders'] = 0

        const nextDateLimitForCash = currentDateCounter.clone().add(15, 'minutes')
        const nextDateLimit2ForKnet = currentDateCounter.clone().add(15, 'minutes')

        // eslint-disable-next-line no-loop-func
        currentOrdersPaymentStatistic.knetOrders.forEach((statItem) => {
          if (
            moment(statItem.orders_by_time) >= currentDateCounter &&
            moment(statItem.orders_by_time) < nextDateLimitForCash
          ) {
            currentValues[currentIndex]['cashSum'] += parseFloat(statItem.total_order_sum_per_time)
            currentValues[currentIndex]['cashOrders'] += parseInt(statItem.orders_count)
          }
        })

        // eslint-disable-next-line no-loop-func
        currentOrdersPaymentStatistic.cashOrders.forEach((statItem) => {
          if (
            moment(statItem.orders_by_time) >= currentDateCounter &&
            moment(statItem.orders_by_time) < nextDateLimit2ForKnet
          ) {
            currentValues[currentIndex]['knetSum'] += parseFloat(statItem.total_order_sum_per_time)
            currentValues[currentIndex]['knetOrders'] += parseInt(statItem.orders_count)
          }
        })

        currentDateCounter.add(15, 'minutes')
        currentIndex++
      }

      // Fill previous period data
      while (prevDateCounter <= prevFinishDate) {
        prevLabels[prevPeriodIndex] = prevDateCounter.toISOString()
        prevValues[prevPeriodIndex] = []
        prevValues[prevPeriodIndex]['cashSum'] = 0
        prevValues[prevPeriodIndex]['knetSum'] = 0
        prevValues[prevPeriodIndex]['cashOrders'] = 0
        prevValues[prevPeriodIndex]['knetOrders'] = 0

        const nextDateLimitForCash = prevDateCounter.clone().add(15, 'minutes')
        const nextDateLimit2ForKnet = prevDateCounter.clone().add(15, 'minutes')

        // eslint-disable-next-line no-loop-func
        prevOrdersPaymentStatistic.cashOrders.forEach((statItem) => {
          if (
            moment(statItem.orders_by_time) >= prevDateCounter &&
            moment(statItem.orders_by_time) < nextDateLimitForCash
          ) {
            prevValues[prevPeriodIndex]['cashSum'] += parseFloat(statItem.total_order_sum_per_time)
            prevValues[prevPeriodIndex]['cashOrders'] += parseInt(statItem.orders_count)
          }
        })

        // eslint-disable-next-line no-loop-func
        prevOrdersPaymentStatistic.knetOrders.forEach((statItem) => {
          if (
            moment(statItem.orders_by_time) >= prevDateCounter &&
            moment(statItem.orders_by_time) < nextDateLimit2ForKnet
          ) {
            prevValues[prevPeriodIndex]['knetSum'] += parseFloat(statItem.total_order_sum_per_time)
            prevValues[prevPeriodIndex]['knetOrders'] += parseInt(statItem.orders_count)
          }
        })

        prevDateCounter.add(15, 'minutes')
        prevPeriodIndex++
      }
    } else if (dateGroupMode === DatesGroupModes.HOURS) {
      // If period is day or smaller - Group by hours
      const currentDateCounter = startDate.clone()
      const prevDateCounter = prevStartDate.clone()
      const hoursInCurrentRange = finishDate
        .clone()
        .endOf('day')
        .diff(startDate.clone().startOf('day'), 'hours')
      const hoursInPrevRange = prevFinishDate
        .clone()
        .endOf('day')
        .diff(prevStartDate.clone().startOf('day'), 'hours')

      // Fill current period data
      for (let i = 0; i <= hoursInCurrentRange; i++) {
        currentLabels[i] = currentDateCounter.toISOString()
        currentValues[i] = []
        currentValues[i]['cashSum'] = 0
        currentValues[i]['knetSum'] = 0
        currentValues[i]['cashOrders'] = 0
        currentValues[i]['knetOrders'] = 0

        currentOrdersPaymentStatistic.cashOrders.forEach((statItem) => {
          if (moment(statItem.orders_by_time).isSame(currentDateCounter, 'hour')) {
            currentValues[i]['cashSum'] += parseFloat(statItem.total_order_sum_per_time)
            currentValues[i]['cashOrders'] += parseInt(statItem.orders_count)
          }
        })

        currentOrdersPaymentStatistic.knetOrders.forEach((statItem) => {
          if (moment(statItem.orders_by_time).isSame(currentDateCounter, 'hour')) {
            currentValues[i]['knetSum'] += parseFloat(statItem.total_order_sum_per_time)
            currentValues[i]['knetOrders'] += parseInt(statItem.orders_count)
          }
        })

        currentDateCounter.add(1, 'hour')
      }

      // Fill previous period data
      for (let i = 0; i <= hoursInPrevRange; i++) {
        prevLabels[i] = prevDateCounter.toISOString()
        prevValues[i] = []
        prevValues[i]['cashSum'] = 0
        prevValues[i]['knetSum'] = 0
        prevValues[i]['cashOrders'] = 0
        prevValues[i]['knetOrders'] = 0

        prevOrdersPaymentStatistic.cashOrders.forEach((statItem) => {
          if (moment(statItem.orders_by_time).isSame(prevDateCounter, 'hour')) {
            prevValues[i]['cashSum'] += parseFloat(statItem.total_order_sum_per_time)
            prevValues[i]['cashOrders'] += parseInt(statItem.orders_count)
          }
        })

        prevOrdersPaymentStatistic.knetOrders.forEach((statItem) => {
          if (moment(statItem.orders_by_time).isSame(prevDateCounter, 'hour')) {
            prevValues[i]['knetSum'] += parseFloat(statItem.total_order_sum_per_time)
            prevValues[i]['knetOrders'] += parseInt(statItem.orders_count)
          }
        })

        prevDateCounter.add(1, 'hour')
      }
    } else if (
      dateGroupMode === DatesGroupModes.WEEK_DAYS ||
      dateGroupMode === DatesGroupModes.DAYS
    ) {
      // If period smaller than 3 month - Group by days
      const currentDateCounter = startDate.clone()
      const prevDateCounter = prevStartDate.clone()
      const daysInCurrentRange = finishDate
        .clone()
        .endOf('day')
        .diff(startDate.clone().startOf('day'), 'days')

      // Fill current period data
      for (let i = 0; i <= daysInCurrentRange; i++) {
        currentLabels[i] = currentDateCounter.toISOString()
        currentValues[i] = []
        currentValues[i]['cashSum'] = 0
        currentValues[i]['knetSum'] = 0
        currentValues[i]['cashOrders'] = 0
        currentValues[i]['knetOrders'] = 0

        currentOrdersPaymentStatistic.cashOrders.forEach((statItem) => {
          if (moment(statItem.orders_by_time).isSame(currentDateCounter, 'day')) {
            currentValues[i]['cashSum'] += parseFloat(statItem.total_order_sum_per_time)
            currentValues[i]['cashOrders'] += parseInt(statItem.orders_count)
          }
        })

        currentOrdersPaymentStatistic.knetOrders.forEach((statItem) => {
          if (moment(statItem.orders_by_time).isSame(currentDateCounter, 'day')) {
            currentValues[i]['knetSum'] += parseFloat(statItem.total_order_sum_per_time)
            currentValues[i]['knetOrders'] += parseInt(statItem.orders_count)
          }
        })

        currentDateCounter.add(1, 'day')
      }

      // Fill previous period data
      for (let i = 0; i <= daysInCurrentRange; i++) {
        const currentPeriodDay = moment(currentLabels[i]).format('DD')
        const previousPeriodDay = prevDateCounter.format('DD')

        if (datePeriod === DASHBOARD_PERIODS.MONTH && currentPeriodDay !== previousPeriodDay) {
          if (currentPeriodDay === '01') {
            // Skip extra dates
            while (prevDateCounter.format('DD') !== '01') {
              prevDateCounter.add(1, 'day')
            }

            prevLabels[i] = prevDateCounter.toISOString()
            prevValues[i] = []
            prevValues[i]['cashSum'] = 0
            prevValues[i]['knetSum'] = 0
            prevValues[i]['cashOrders'] = 0
            prevValues[i]['knetOrders'] = 0

            prevOrdersPaymentStatistic.cashOrders.forEach((statItem) => {
              if (moment(statItem.orders_by_time).isSame(prevDateCounter, 'day')) {
                prevValues[i]['cashSum'] += parseFloat(statItem.total_order_sum_per_time)
                prevValues[i]['cashOrders'] += parseInt(statItem.orders_count)
              }
            })

            prevOrdersPaymentStatistic.knetOrders.forEach((statItem) => {
              if (moment(statItem.orders_by_time).isSame(prevDateCounter, 'day')) {
                prevValues[i]['knetSum'] += parseFloat(statItem.total_order_sum_per_time)
                prevValues[i]['knetOrders'] += parseInt(statItem.orders_count)
              }
            })

            prevDateCounter.add(1, 'day')
          }

          if (previousPeriodDay === '01') {
            // Add empty point and not increase counter
            prevLabels[i] = ''
            prevValues[i] = []
            prevValues[i]['cashSum'] = 0
            prevValues[i]['knetSum'] = 0
            prevValues[i]['cashOrders'] = 0
            prevValues[i]['knetOrders'] = 0
          }
        } else {
          // Standard flow
          prevLabels[i] = prevDateCounter.toISOString()
          prevValues[i] = []
          prevValues[i]['cashSum'] = 0
          prevValues[i]['knetSum'] = 0
          prevValues[i]['cashOrders'] = 0
          prevValues[i]['knetOrders'] = 0

          prevOrdersPaymentStatistic.cashOrders.forEach((statItem) => {
            if (moment(statItem.orders_by_time).isSame(prevDateCounter, 'day')) {
              prevValues[i]['cashSum'] += parseFloat(statItem.total_order_sum_per_time)
              prevValues[i]['cashOrders'] += parseInt(statItem.orders_count)
            }
          })

          prevOrdersPaymentStatistic.knetOrders.forEach((statItem) => {
            if (moment(statItem.orders_by_time).isSame(prevDateCounter, 'day')) {
              prevValues[i]['knetSum'] += parseFloat(statItem.total_order_sum_per_time)
              prevValues[i]['knetOrders'] += parseInt(statItem.orders_count)
            }
          })

          prevDateCounter.add(1, 'day')
        }
      }
    } else if (dateGroupMode === DatesGroupModes.MONTHS) {
      // If period smaller than 12 month - Group by month
      const currentDateCounter = startDate.clone()
      const prevDateCounter = prevStartDate.clone()
      const monthInCurrentRange = finishDate
        .clone()
        .endOf('month')
        .diff(startDate.clone().startOf('month'), 'month')
      const monthInPrevRange = prevFinishDate
        .clone()
        .endOf('month')
        .diff(prevStartDate.clone().startOf('month'), 'month')

      // Fill current period data
      for (let i = 0; i <= monthInCurrentRange; i++) {
        currentLabels[i] = currentDateCounter.toISOString()
        currentValues[i] = []
        currentValues[i]['cashSum'] = 0
        currentValues[i]['knetSum'] = 0
        currentValues[i]['cashOrders'] = 0
        currentValues[i]['knetOrders'] = 0

        currentOrdersPaymentStatistic.cashOrders.forEach((statItem) => {
          if (moment(statItem.orders_by_time).isSame(currentDateCounter, 'month')) {
            currentValues[i]['cashSum'] += parseFloat(statItem.total_order_sum_per_time)
            currentValues[i]['cashOrders'] += parseInt(statItem.orders_count)
          }
        })

        currentOrdersPaymentStatistic.knetOrders.forEach((statItem) => {
          if (moment(statItem.orders_by_time).isSame(currentDateCounter, 'month')) {
            currentValues[i]['knetSum'] += parseFloat(statItem.total_order_sum_per_time)
            currentValues[i]['knetOrders'] += parseInt(statItem.orders_count)
          }
        })

        currentDateCounter.add(1, 'month')
      }

      // Fill previous period data
      for (let i = 0; i <= monthInPrevRange; i++) {
        prevLabels[i] = currentDateCounter.toISOString()
        prevValues[i] = []
        prevValues[i]['cashSum'] = 0
        prevValues[i]['knetSum'] = 0
        prevValues[i]['cashOrders'] = 0
        prevValues[i]['knetOrders'] = 0

        prevOrdersPaymentStatistic.cashOrders.forEach((statItem) => {
          if (moment(statItem.orders_by_time).isSame(prevDateCounter, 'month')) {
            prevValues[i]['cashSum'] += parseFloat(statItem.total_order_sum_per_time)
            prevValues[i]['cashOrders'] += parseInt(statItem.orders_count)
          }
        })

        prevOrdersPaymentStatistic.knetOrders.forEach((statItem) => {
          if (moment(statItem.orders_by_time).isSame(prevDateCounter, 'month')) {
            prevValues[i]['knetSum'] += parseFloat(statItem.total_order_sum_per_time)
            prevValues[i]['knetOrders'] += parseInt(statItem.orders_count)
          }
        })

        prevDateCounter.add(1, 'month')
      }
    } else if (dateGroupMode === DatesGroupModes.YEARS) {
      // If period is 12 month or bigger - Group by year
      const currentDateCounter = startDate.clone()
      const prevDateCounter = prevStartDate.clone()
      const yearsInCurrentRange = finishDate
        .clone()
        .endOf('year')
        .diff(startDate.clone().startOf('year'), 'year')
      const yearsInPrevRange = prevFinishDate
        .clone()
        .endOf('year')
        .diff(prevStartDate.clone().startOf('year'), 'year')

      // Fill current period data
      for (let i = 0; i <= yearsInCurrentRange; i++) {
        currentLabels[i] = currentDateCounter.toISOString()
        currentValues[i] = []
        currentValues[i]['cashSum'] = 0
        currentValues[i]['knetSum'] = 0
        currentValues[i]['cashOrders'] = 0
        currentValues[i]['knetOrders'] = 0

        currentOrdersPaymentStatistic.cashOrders.forEach((statItem) => {
          if (moment(statItem.orders_by_time).isSame(currentDateCounter, 'year')) {
            currentValues[i]['cashSum'] += parseFloat(statItem.total_order_sum_per_time)
            currentValues[i]['cashOrders'] += parseInt(statItem.orders_count)
          }
        })

        currentOrdersPaymentStatistic.knetOrders.forEach((statItem) => {
          if (moment(statItem.orders_by_time).isSame(currentDateCounter, 'year')) {
            currentValues[i]['knetSum'] += parseFloat(statItem.total_order_sum_per_time)
            currentValues[i]['knetOrders'] += parseInt(statItem.orders_count)
          }
        })

        currentDateCounter.add(1, 'year')
      }

      // Fill previous period data
      for (let i = 0; i <= yearsInPrevRange; i++) {
        prevLabels[i] = currentDateCounter.toISOString()
        prevValues[i] = []
        prevValues[i]['cashSum'] = 0
        prevValues[i]['knetSum'] = 0
        prevValues[i]['cashOrders'] = 0
        prevValues[i]['knetOrders'] = 0

        prevOrdersPaymentStatistic.cashOrders.forEach((statItem) => {
          if (moment(statItem.orders_by_time).isSame(prevDateCounter, 'year')) {
            prevValues[i]['cashSum'] += parseFloat(statItem.total_order_sum_per_time)
            prevValues[i]['cashOrders'] += parseInt(statItem.orders_count)
          }
        })

        prevOrdersPaymentStatistic.knetOrders.forEach((statItem) => {
          if (moment(statItem.orders_by_time).isSame(prevDateCounter, 'year')) {
            prevValues[i]['knetSum'] += parseFloat(statItem.total_order_sum_per_time)
            prevValues[i]['knetOrders'] += parseInt(statItem.orders_count)
          }
        })

        prevDateCounter.add(1, 'year')
      }
    }

    return { currentLabels, currentValues, prevLabels, prevValues }
  }, [
    startDate,
    finishDate,
    prevStartDate,
    prevFinishDate,
    currentOrdersPaymentStatistic,
    prevOrdersPaymentStatistic,
    datePeriod,
    dateGroupMode,
  ])

  // Get max value in data stack
  const maxValue = useMemo(() => {
    let maxValue = 0

    if (currentOrdersPaymentStatistic === undefined || prevOrdersPaymentStatistic === undefined) {
      return maxValue
    }

    currentOrdersPaymentStatistic.cashOrders.forEach((statItem) => {
      const statItemVal = parseFloat(statItem.total_order_sum_per_time)
      maxValue = statItemVal > maxValue ? statItemVal : maxValue
    })

    currentOrdersPaymentStatistic.knetOrders.forEach((statItem) => {
      const statItemVal = parseFloat(statItem.total_order_sum_per_time)
      maxValue = statItemVal > maxValue ? statItemVal : maxValue
    })

    prevOrdersPaymentStatistic.cashOrders.forEach((statItem) => {
      const statItemVal = parseFloat(statItem.total_order_sum_per_time)
      maxValue = statItemVal > maxValue ? statItemVal : maxValue
    })

    prevOrdersPaymentStatistic.knetOrders.forEach((statItem) => {
      const statItemVal = parseFloat(statItem.total_order_sum_per_time)
      maxValue = statItemVal > maxValue ? statItemVal : maxValue
    })

    return maxValue
  }, [currentOrdersPaymentStatistic, prevOrdersPaymentStatistic])

  // Get target chart width
  const targetCanvasWidth = useMemo(() => {
    const needSize = chartData.currentLabels.length * 60

    if (chartSize.width !== undefined && chartSize.width > needSize + analyticsBlockWidth) {
      return chartSize.width - analyticsBlockWidth
    }

    return needSize
  }, [chartData.currentLabels, chartSize.width])

  // Trigger rerender if target chart width was changed
  useEffect(() => {
    setReRenderFlag(true)

    const reRenderTimeout = window.setTimeout(() => {
      setReRenderFlag(false)
    }, 50)

    return () => {
      window.clearTimeout(reRenderTimeout)
    }
  }, [targetCanvasWidth])

  // Calculate step size depends on max value in data stack
  const stepSize = useMemo(
    () => (maxValue >= targetYLinesCount ? Math.ceil(maxValue / targetYLinesCount) : 1),
    [maxValue, targetYLinesCount],
  )

  return (
    <div className={baseStyles.chart} ref={chartRef}>
      <div className={baseStyles.chart__header}>
        <div className={baseStyles.chart__headerTop}>
          <p className={baseStyles.chart__headerTitle}>{t('dashboard.knet vs cash')}</p>
          <DashboardPeriodChanger
            periods={[
              DASHBOARD_PERIODS.AM,
              DASHBOARD_PERIODS.PM,
              DASHBOARD_PERIODS.DAY,
              DASHBOARD_PERIODS.WEEK,
              DASHBOARD_PERIODS.MONTH,
              DASHBOARD_PERIODS.YEAR,
              DASHBOARD_PERIODS.CUSTOM_DATE,
            ]}
            defaultPeriod={datePeriod}
            onChange={(period) => setDatePeriod(period)}
          />
        </div>
      </div>
      <div className={classNames([baseStyles.chart__body, baseStyles.chart__body_xScrollable])}>
        {!isCurrentOrdersPaymentStatisticFetching &&
        !isPrevOrdersPaymentStatisticFetching &&
        targetCanvasWidth &&
        !reRenderFlag ? (
          <Bar
            data={{
              labels: chartData.currentLabels,
              datasets: [
                {
                  label: t('dashboard.cash'),
                  data: chartData.currentValues.map((oneStat) => oneStat['cashSum']),
                  type: 'bar',
                  barPercentage: 0.7,
                  borderColor: '#6BBB6E',
                  backgroundColor: '#6BBB6E',
                },
                {
                  label: t('dashboard.knet'),
                  data: chartData.currentValues.map((oneStat) => oneStat['knetSum']),
                  type: 'bar',
                  barPercentage: 0.7,
                  borderColor: '#4C5973',
                  backgroundColor: '#4C5973',
                },
                {
                  label: t('dashboard.prev period cash'),
                  data: chartData.prevValues.map((oneStat) => oneStat['cashSum']),
                  type: 'line',
                  borderDash: [10, 5],
                  borderColor: 'rgba(229,134,52,0.7)',
                  backgroundColor: 'rgba(229,134,52,0.7)',
                },
                {
                  label: t('dashboard.prev period knet'),
                  data: chartData.prevValues.map((oneStat) => oneStat['knetSum']),
                  type: 'line',
                  borderDash: [10, 5],
                  borderColor: 'rgba(229,134,52,0.4)',
                  backgroundColor: 'rgba(229,134,52,0.4)',
                },
              ],
            }}
            width={targetCanvasWidth}
            height={chartSize.width && targetCanvasWidth <= chartSize.width ? 320 : 308} // Additional space for scrollbar
            options={{
              indexAxis: 'x',
              elements: {
                bar: {
                  borderWidth: 0,
                },
                point: {
                  pointStyle: 'circle',
                  radius: 3,
                  hoverRadius: 5,
                },
              },
              responsive: false,
              maintainAspectRatio: true,
              plugins: {
                legend: {
                  display: true,
                },
                title: {
                  display: false,
                },
                tooltip: {
                  displayColors: true,
                  callbacks: {
                    title(tooltipItems) {
                      if (
                        tooltipItems.length === 1 &&
                        [0, 1].includes(tooltipItems[0].datasetIndex)
                      ) {
                        const currentDateTime = chartData.currentLabels[tooltipItems[0].dataIndex]

                        if (
                          [DatesGroupModes.HOURS, DatesGroupModes.MINUTES].includes(dateGroupMode)
                        )
                          return moment(currentDateTime).format('D MMM HH:mm')
                        if (dateGroupMode === DatesGroupModes.WEEK_DAYS)
                          return moment(currentDateTime).format('D MMM (ddd)')
                        if (dateGroupMode === DatesGroupModes.DAYS)
                          return moment(currentDateTime).format('D MMM')
                        if (dateGroupMode === DatesGroupModes.MONTHS)
                          return moment(currentDateTime).format('MMM YYYY')
                        if (dateGroupMode === DatesGroupModes.YEARS)
                          return moment(currentDateTime).format('YYYY')
                      }

                      if (
                        tooltipItems.length === 2 ||
                        (tooltipItems.length === 1 && [2, 3].includes(tooltipItems[0].datasetIndex))
                      ) {
                        const prevDateTime = chartData.prevLabels[tooltipItems[0].dataIndex]

                        if (
                          [DatesGroupModes.HOURS, DatesGroupModes.MINUTES].includes(dateGroupMode)
                        )
                          return moment(prevDateTime).format('D MMM HH:mm')
                        if (dateGroupMode === DatesGroupModes.WEEK_DAYS)
                          return moment(prevDateTime).format('D MMM (ddd)')
                        if (dateGroupMode === DatesGroupModes.DAYS)
                          return moment(prevDateTime).format('D MMM')
                        if (dateGroupMode === DatesGroupModes.MONTHS)
                          return moment(prevDateTime).format('MMM YYYY')
                        if (dateGroupMode === DatesGroupModes.YEARS)
                          return moment(prevDateTime).format('YYYY')
                      }

                      return ''
                    },
                    label(tooltipItem) {
                      let ordersCount = 0

                      if (tooltipItem.datasetIndex === 0) {
                        ordersCount = chartData.currentValues[tooltipItem.dataIndex]['cashOrders']
                      } else if (tooltipItem.datasetIndex === 1) {
                        ordersCount = chartData.currentValues[tooltipItem.dataIndex]['knetOrders']
                      } else if (tooltipItem.datasetIndex === 2) {
                        ordersCount = chartData.prevValues[tooltipItem.dataIndex]['cashOrders']
                      } else if (tooltipItem.datasetIndex === 3) {
                        ordersCount = chartData.prevValues[tooltipItem.dataIndex]['knetOrders']
                      }

                      if (ordersCount > 0) {
                        return `${tooltipItem.dataset.label}: ${
                          tooltipItem.formattedValue
                        } KD (${ordersCount} ${t('dashboard.orders').toLowerCase()})`
                      }

                      return `${tooltipItem.dataset.label}: ${tooltipItem.formattedValue} KD`
                    },
                  },
                },
              },
              scales: {
                y: {
                  beginAtZero: true,
                  suggestedMax: stepSize * targetYLinesCount,
                  ticks: {
                    stepSize: stepSize,
                    callback: (tickValue) => `${tickValue} KD`,
                  },
                },
                x: {
                  ticks: {
                    callback: (value, index) => {
                      if ([DatesGroupModes.HOURS, DatesGroupModes.MINUTES].includes(dateGroupMode))
                        return moment(chartData.currentLabels[index]).format('HH:mm')
                      if (dateGroupMode === DatesGroupModes.WEEK_DAYS)
                        return moment(chartData.currentLabels[index]).format('ddd')
                      if (dateGroupMode === DatesGroupModes.DAYS)
                        return moment(chartData.currentLabels[index]).format('D MMM')
                      if (dateGroupMode === DatesGroupModes.MONTHS)
                        return moment(chartData.currentLabels[index]).format('MMM YYYY')
                      if (dateGroupMode === DatesGroupModes.YEARS)
                        return moment(chartData.currentLabels[index]).format('YYYY')
                    },
                  },
                },
              },
            }}
          />
        ) : (
          <PreloaderBlock minHeight={320} />
        )}
      </div>
      <div className={baseStyles.chart__footer}>
        <div className={baseStyles.chart__offsetChangerWrap}>
          <DashboardPeriodOffsetChanger
            startDate={globalStartDate}
            finishDate={globalFinishDate}
            period={datePeriod}
            disabled={
              datePeriod === DASHBOARD_PERIODS.CUSTOM_DATE ||
              isCurrentOrdersPaymentStatisticFetching ||
              isPrevOrdersPaymentStatisticFetching
            }
            changeOffsetCallback={(newOffset) => {
              setPeriodOffset(newOffset)
            }}
          />
        </div>
      </div>
    </div>
  )
}

export default KNetVsCashChart
