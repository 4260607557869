import React from 'react'

import ProductItem from './ProductItem'

const ProductList = ({ products }) => {
  return (
    <div className="productList">
      {products.map((product) => (
        <ProductItem data={product} key={product.id} />
      ))}
    </div>
  )
}

export default ProductList
