import axios from 'axios'

class CouponsService {
  getCoupons = (page, size) =>
    axios({
      url: 'coupon',
      method: 'GET',
      params: {
        page,
        take: size,
      },
    })

  createCoupon = (data) =>
    axios({
      url: 'coupon',
      method: 'POST',
      data,
    })

  getCouponById = (id) =>
    axios({
      url: `coupon/${id}`,
      method: 'GET',
    })

  deleteCoupon = (id) =>
    axios({
      url: `coupon/${id}`,
      method: 'DELETE',
    })
}

export default new CouponsService()
