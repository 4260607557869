import axios from 'axios'
import { setUpNotifications } from 'reapop'

const configureReaPop = () => {
  setUpNotifications({
    defaultProps: {
      position: 'top-right',
      dismissible: true,
    },
  })
}

const configureAxios = () => {
  axios.defaults.baseURL = process.env.REACT_APP_API_BASE_URL
}

export const configureApp = () => {
  configureAxios()
  configureReaPop()
}
