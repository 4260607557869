import classNames from 'classnames'
import PropTypes from 'prop-types'
import styles from './styles.module.scss'

const DashboardInfoCard = ({
  title,
  value,
  height,
  hint,
  colorTheme,
  fatTitle,
  titleOneLine,
  fatValue,
  ...restProps
}) => {
  const customStyles = {
    height: Number.isInteger(height) ? height : undefined,
  }

  const classes = classNames({
    [`${styles.card}`]: true,
    [`${styles.card_mediumBlue}`]: colorTheme === 'mediumBlue',
    [`${styles.card_titleOneLine}`]: titleOneLine,
  })

  const titleClasses = classNames({
    [`${styles.card__title}`]: true,
    [`${styles.card__title_fat}`]: !!fatTitle,
  })

  const valueClasses = classNames({
    [`${styles.card__value}`]: true,
    [`${styles.card__value_fat}`]: !!fatValue,
  })

  return (
    <div className={classes} style={customStyles} {...restProps}>
      <div className={styles.card__titleWrap}>
        <span className={styles.card__hint}>{hint}</span>
        <h2 className={titleClasses} title={titleOneLine ? title : ''}>
          {title}
        </h2>
      </div>
      <div className={styles.card__valueWrap}>
        <span className={valueClasses}>{value}</span>
      </div>
    </div>
  )
}

DashboardInfoCard.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.number,
  hint: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  colorTheme: PropTypes.oneOf(['gray', 'mediumBlue']),
  fatTitle: PropTypes.bool,
  titleOneLine: PropTypes.bool,
  fatValue: PropTypes.bool,
}

DashboardInfoCard.defaultProps = {
  colorTheme: 'gray',
  fatTitle: false,
  titleOneLine: false,
  fatValue: false,
}

export default DashboardInfoCard
