import { useEffect, useMemo, useState } from 'react'
import { useForm, useFieldArray, FormProvider } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import { useMutation } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'
import { notify } from 'reapop'
import { Col, Popconfirm, Row, Space, Switch } from 'antd'
import HookedField from 'components/HookedField'
import Button from 'components/Button'
import Icon from 'components/Icon'
import { ICONS, PERMISSIONS } from 'utils/constants'
import { yupResolver } from '@hookform/resolvers/yup'
import { upsellingSchema } from 'utils/schemas'
import UpsellingService from 'redux/middlewares/upselling'
import useClient from 'hooks/useClient'

export const UPSELLING_RANDOM = 'RANDOM'
export const UPSELLING_RANDOM_LABEL = 'Random'
export const UPSELLING_SELECTIVE = 'SELECTIVE'
export const UPSELLING_SELECTIVE_LABEL = 'Selective'
export const UPSELLING_DISABLED = 'DISABLED'
export const UPSELLING_DISABLED_LABEL = 'Disabled'

const emptyRandomProduct = {
  productId: null,
  message: '',
}

const UpsellingForm = ({ products, upsellingTypes, randomProducts, selectiveProduct }) => {
  const { t } = useTranslation()
  const { isClientCan } = useClient()
  const dispatch = useDispatch()
  const [isFormUploading, setIsFormUploading] = useState(false)

  const isClientCanUpdateUpselling = isClientCan(PERMISSIONS.UPDATE_UPSELLING)

  // ================================================== QUERIES START ==================================================

  const setIsActiveUpsellingMutation = useMutation(UpsellingService.putUpsellingTypeActivate, {
    onError: () => {
      dispatch(notify({ title: t('notifs.error title'), status: 'error', dismissAfter: 4000 }))
      setIsFormUploading(false)
    },
  })

  const postUpsellingRandomProductsMutation = useMutation(
    UpsellingService.postUpsellingRandomProducts,
    {
      onError: () => {
        dispatch(notify({ title: t('notifs.error title'), status: 'error', dismissAfter: 4000 }))
        setIsFormUploading(false)
      },
    },
  )

  const postUpsellingSelectiveProductMutation = useMutation(
    UpsellingService.postUpsellingSelectiveProduct,
    {
      onError: () => {
        dispatch(notify({ title: t('notifs.error title'), status: 'error', dismissAfter: 4000 }))
        setIsFormUploading(false)
      },
    },
  )

  // =================================================== QUERIES END ===================================================

  // ============================================= ADDITIONAL DATA 1 START =============================================

  // Get products select options
  const productsOptions = useMemo(() => {
    const options = []

    if (Array.isArray(products)) {
      products.map((product) => {
        options.push({
          label: product.title,
          value: product.id,
        })

        return product
      })
    }

    return options
  }, [products])

  // Get is upselling disabled
  const defaultUpsellingEnabled = useMemo(() => {
    return !!upsellingTypes.data
  }, [upsellingTypes])

  // Get upselling active type
  const defaultUpsellingType = useMemo(() => {
    return upsellingTypes.data?.upsellingType ? upsellingTypes.data.upsellingType : null
  }, [upsellingTypes])

  // Get loaded random items as default form values
  const defaultRandomProducts = useMemo(() => {
    const defaultRandomProducts = []

    if (Array.isArray(randomProducts)) {
      randomProducts.map((oneProduct) => {
        defaultRandomProducts.push({
          productId: oneProduct.product.id,
          message: oneProduct.text,
        })

        return oneProduct
      })
    }

    return defaultRandomProducts
  }, [randomProducts])

  // ============================================== ADDITIONAL DATA 1 END ==============================================

  // =============================================== FORM SETTINGS START ===============================================

  const methods = useForm({
    mode: 'onSubmit',
    shouldFocusError: true,
    defaultValues: {
      isEnabled: defaultUpsellingEnabled,
      type: defaultUpsellingType,
      selectiveItem: {
        productId: selectiveProduct?.product?.id || null,
        message: selectiveProduct?.text || '',
      },
      randomItems: defaultRandomProducts,
    },
    resolver: yupResolver(upsellingSchema),
  })

  const {
    fields: randomItemsFields,
    append: appendRandomItem,
    remove: removeRandomItem,
  } = useFieldArray({
    control: methods.control,
    name: 'randomItems',
  })

  // ================================================ FORM SETTINGS END ================================================

  // ================================================== WATCHERS START =================================================

  const isEnabledCond = methods.watch('isEnabled')
  const typeCond = methods.watch('type')

  // =================================================== WATCHERS END ==================================================

  // ============================================= ADDITIONAL DATA 2 START =============================================

  // Generate type select options
  const typeSelectOptions = useMemo(() => {
    if (isEnabledCond) {
      return [
        { label: UPSELLING_RANDOM_LABEL, value: UPSELLING_RANDOM },
        { label: UPSELLING_SELECTIVE_LABEL, value: UPSELLING_SELECTIVE },
      ]
    }

    return [{ label: UPSELLING_DISABLED_LABEL, value: UPSELLING_DISABLED, disabled: true }]
  }, [isEnabledCond])

  // ============================================== ADDITIONAL DATA 2 END ==============================================

  // ================================================== EFFECTS START ==================================================

  useEffect(() => {
    if (!isEnabledCond) {
      methods.setValue('type', UPSELLING_DISABLED)
    } else {
      methods.setValue('type', defaultUpsellingType || null)
    }
  }, [isEnabledCond, defaultUpsellingType, methods])

  // =================================================== EFFECTS END ===================================================

  // ================================================== HANDLERS START =================================================

  const submitHandler = async (data) => {
    setIsFormUploading(true)

    // Save type
    await setIsActiveUpsellingMutation.mutateAsync({
      type: data.type !== UPSELLING_DISABLED ? data.type : UPSELLING_RANDOM,
      disableUpselling: !data.isEnabled,
    })

    // Save random items
    if (data.type === UPSELLING_RANDOM) {
      const randomItems = []

      data.randomItems.map((oneRandomItem) => {
        randomItems.push({
          productId: oneRandomItem.productId,
          message: oneRandomItem.message,
        })

        return oneRandomItem
      })

      await postUpsellingRandomProductsMutation.mutateAsync({
        items: randomItems,
      })
    }

    // Save selective items
    if (data.type === UPSELLING_SELECTIVE) {
      await postUpsellingSelectiveProductMutation.mutateAsync({
        productId: data.selectiveItem.productId,
        message: data.selectiveItem.message,
      })
    }

    dispatch(notify({ title: t('notifs.updated'), status: 'success', dismissAfter: 4000 }))
    setIsFormUploading(false)
  }

  // =================================================== HANDLERS END ==================================================

  // =================================================== RENDER START ==================================================

  return (
    <FormProvider {...methods}>
      <form className="form" autoComplete="off" onSubmit={methods.handleSubmit(submitHandler)}>
        <Row>
          <Col span={24}>
            <div style={{ display: 'flex', justifyContent: 'end' }}>
              <Switch
                checkedChildren="Enabled"
                unCheckedChildren="Disabled"
                defaultChecked={defaultUpsellingEnabled}
                disabled={!isClientCanUpdateUpselling}
                onChange={(flag) => {
                  methods.setValue('isEnabled', flag, { shouldDirty: true })
                }}
              />
            </div>
          </Col>
        </Row>

        <Row style={{ marginBottom: 15 }}>
          <Col span={24}>
            <div className="form-title">Type</div>
          </Col>
          <Col span={24} lg={{ span: 12 }} xl={{ span: 6 }}>
            <HookedField
              type="select"
              name="type"
              placeholder={t('fields.select type')}
              fieldType="selectField"
              label={t('fields.select type')}
              options={typeSelectOptions}
              disabled={!isEnabledCond || !isClientCanUpdateUpselling}
            />
          </Col>
        </Row>

        {typeCond === UPSELLING_RANDOM && (
          <div className="page-wrapper productForm">
            <Row>
              <Col span={24}>
                <div className="form-title">Random</div>
              </Col>
            </Row>

            <Space direction={'vertical'} size={25} style={{ width: '100%' }}>
              {randomItemsFields.map((field, index) => {
                return (
                  <Row key={field.id} gutter={25} align={'middle'}>
                    <Col span={24} xl={{ span: 9 }} xxl={{ span: 10 }}>
                      <HookedField
                        label="Message"
                        name={`randomItems.${index}.message`}
                        placeholder={t('fields.shortname ar')}
                        fieldType="textField"
                        type="text"
                        disabled={!isEnabledCond || !isClientCanUpdateUpselling}
                      />
                    </Col>
                    <Col span={24} xl={{ span: 10 }} xxl={{ span: 10 }}>
                      <HookedField
                        label="Product"
                        name={`randomItems.${index}.productId`}
                        placeholder={t('fields.pack name en')}
                        fieldType="selectField"
                        type="select"
                        options={productsOptions}
                        disabled={!isEnabledCond || !isClientCanUpdateUpselling}
                      />
                    </Col>
                    <Col span={24} xl={{ span: 5 }} xxl={{ span: 4 }}>
                      <Popconfirm
                        title={'Are you sure?'}
                        onConfirm={() => removeRandomItem(index)}
                        okText="Delete"
                        okType="danger"
                        cancelText="No"
                        disabled={!isEnabledCond || isClientCanUpdateUpselling}
                      >
                        <Button disabled={!isEnabledCond || !isClientCanUpdateUpselling}>
                          Remove
                        </Button>
                      </Popconfirm>
                    </Col>
                  </Row>
                )
              })}
              <Row style={{ marginTop: 20 }}>
                <Col>
                  <Button
                    onClick={() => appendRandomItem(emptyRandomProduct)}
                    disabled={!isEnabledCond || !isClientCanUpdateUpselling}
                  >
                    <Icon icon={ICONS.PLUS} />
                    Add
                  </Button>
                </Col>
              </Row>
            </Space>
          </div>
        )}

        {typeCond === UPSELLING_SELECTIVE && (
          <div className="page-wrapper productForm">
            <Row>
              <Col span={24}>
                <div className="form-title">Selective</div>
              </Col>
            </Row>
            <Row gutter={25} align={'middle'}>
              <Col span={24} xl={{ span: 12 }}>
                <HookedField
                  label="Message"
                  name="selectiveItem.message"
                  placeholder={t('fields.shortname ar')}
                  fieldType="textField"
                  type="text"
                  disabled={!isEnabledCond || !isClientCanUpdateUpselling}
                />
              </Col>
              <Col span={24} xl={{ span: 12 }}>
                <HookedField
                  label="Product"
                  name="selectiveItem.productId"
                  placeholder={t('fields.pack name en')}
                  fieldType="selectField"
                  type="select"
                  options={productsOptions}
                  disabled={!isEnabledCond || !isClientCanUpdateUpselling}
                />
              </Col>
            </Row>
          </div>
        )}

        <div className="form-grid" style={{ marginTop: 35 }}>
          <Button
            className="save-btn"
            size="large"
            type="primary"
            htmlType="submit"
            disabled={!methods.formState.isDirty || isFormUploading || !isClientCanUpdateUpselling}
          >
            {isFormUploading ? 'Loading...' : t('app.save')}
          </Button>
        </div>
      </form>
    </FormProvider>
  )
}

export default UpsellingForm
