// import axios from "axios";
// It is simulation of real request
import { notificationsData } from 'utils/data'

class NotificationsService {
  getNotifications = () =>
    new Promise((resolve, reject) => {
      setTimeout(() => resolve(notificationsData), 500)
    })

  getNotificationById = (id) =>
    new Promise((resolve, reject) => {
      setTimeout(() => {
        const notification = notificationsData.find((notification) => notification.id === id)
        resolve(notification)
      }, 500)
    })
}

export default new NotificationsService()
