import classNames from 'classnames'
import { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { parseThousandsGroups } from 'helpers/numbers'
import styles from './styles/analyticsBlock.module.scss'

const OrdersValueAnalytics = ({
  currentStartDate,
  currentFinishDate,
  currentStats,
  prevPeriodStartDate,
  prevPeriodFinishDate,
  prevPeriodStats,
  prevYearStartDate,
  prevYearFinishDate,
  prevYearStats,
}) => {
  const { t } = useTranslation()
  const currentDateFormat =
    currentFinishDate.diff(currentStartDate) <= 43199999 ? 'D MMM HH:mm' : 'D MMM, YYYY'
  const prevPeriodDateFormat =
    prevPeriodFinishDate.diff(prevPeriodStartDate) <= 43199999 ? 'D MMM HH:mm' : 'D MMM, YYYY'
  const prevYearDateFormat =
    prevYearFinishDate.diff(prevYearStartDate) <= 43199999 ? 'D MMM YYYY HH:mm' : 'D MMM, YYYY'

  // Sum total function
  const sumValues = useCallback((stats) => {
    let sum = 0

    if (stats?.length) {
      stats.map((statItem) => {
        sum += statItem.total_avg_order_per_day * parseInt(statItem.orders_count)
        return statItem
      })
    }

    return sum
  }, [])

  // Get percentage value function
  const calcDiffPercentage = useCallback((sum1, sum2) => {
    let percentage = 0

    if (sum2 !== 0) {
      percentage = Math.round((sum1 / sum2 - 1) * 100)
    } else if (sum1 > 0) {
      percentage = 100
    }

    return percentage
  }, [])

  // Generate percentage react node function
  const constructPercentageNode = useCallback((percentage) => {
    if (percentage === 0) {
      return null
    }

    const classes = classNames({
      [`${styles.text}`]: true,
      [`${styles.text_fat}`]: true,
      [`${styles.text_red}`]: percentage < 0,
      [`${styles.text_green}`]: percentage > 0,
    })

    return (
      <span className={classes}>
        {percentage > 0 && '+'}
        {percentage}%
      </span>
    )
  }, [])

  // Save current period total count
  const currentOrdersSum = useMemo(() => sumValues(currentStats), [sumValues, currentStats])

  // Save previous period total count
  const prevPeriodOrdersSum = useMemo(
    () => sumValues(prevPeriodStats),
    [sumValues, prevPeriodStats],
  )

  // Save previous year total count
  const prevYearOrdersSum = useMemo(() => sumValues(prevYearStats), [sumValues, prevYearStats])

  // Save previous period diff percentage value
  const prevPeriodDiffPercentage = useMemo(
    () => calcDiffPercentage(currentOrdersSum, prevPeriodOrdersSum),
    [calcDiffPercentage, currentOrdersSum, prevPeriodOrdersSum],
  )

  // Save previous year diff percentage value
  const prevYearDiffPercentage = useMemo(
    () => calcDiffPercentage(currentOrdersSum, prevYearOrdersSum),
    [calcDiffPercentage, currentOrdersSum, prevYearOrdersSum],
  )

  return (
    <div className={styles.block}>
      <div className={styles.block__inner}>
        {currentStartDate && currentFinishDate && (
          <div className={styles.block__sector}>
            <div className={classNames([styles.block__sectorRow])}>
              <span
                className={classNames([
                  styles.text,
                  styles.text_large,
                  styles.text_gray,
                  styles.text_fat,
                ])}
              >
                {currentStartDate.format(currentDateFormat)} -{' '}
                {currentFinishDate.format(currentDateFormat)}
              </span>
            </div>
            <div className={classNames([styles.block__sectorRow])}>
              <span className={classNames([styles.text, styles.text_larger, styles.text_fat])}>
                {`${parseThousandsGroups(currentOrdersSum)} KD`}
              </span>
            </div>
          </div>
        )}
        {prevPeriodStartDate && prevPeriodFinishDate && (
          <div className={styles.block__sector}>
            <div
              className={classNames([
                styles.block__sectorRow,
                styles.block__sectorRow_spaceBetween,
              ])}
            >
              <span
                className={classNames([
                  styles.text,
                  styles.text_gray,
                  styles.text_upper,
                  styles.text_fat,
                ])}
              >
                {t('dashboard.previous period')}
              </span>
              <span className={classNames([styles.text, styles.text_gray, styles.text_fat])}>
                {prevPeriodStartDate.format(prevPeriodDateFormat)} -{' '}
                {prevPeriodFinishDate.format(prevPeriodDateFormat)}
              </span>
            </div>
            <div className={classNames([styles.block__sectorRow])}>
              <span
                className={classNames([
                  styles.text,
                  styles.text_large,
                  styles.text_fat,
                  styles.text_gray,
                ])}
              >
                {`${parseThousandsGroups(prevPeriodOrdersSum)} KD`}
              </span>
              {constructPercentageNode(prevPeriodDiffPercentage)}
            </div>
          </div>
        )}
        {prevYearStartDate && prevYearFinishDate && (
          <div className={styles.block__sector}>
            <div
              className={classNames([
                styles.block__sectorRow,
                styles.block__sectorRow_spaceBetween,
              ])}
            >
              <span
                className={classNames([
                  styles.text,
                  styles.text_gray,
                  styles.text_upper,
                  styles.text_fat,
                ])}
              >
                {t('dashboard.previous year')}
              </span>
              <span className={classNames([styles.text, styles.text_gray, styles.text_fat])}>
                {prevYearStartDate.format(prevYearDateFormat)} -{' '}
                {prevYearFinishDate.format(prevYearDateFormat)}
              </span>
            </div>
            <div className={classNames([styles.block__sectorRow])}>
              <span
                className={classNames([
                  styles.text,
                  styles.text_large,
                  styles.text_fat,
                  styles.text_gray,
                ])}
              >
                {`${parseThousandsGroups(prevYearOrdersSum)} KD`}
              </span>
              {constructPercentageNode(prevYearDiffPercentage)}
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default OrdersValueAnalytics
