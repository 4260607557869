import {
  GET_PRODUCTS,
  GET_PRODUCT,
  UPDATE_PRODUCT,
  CREATE_PRODUCT,
  DELETE_PRODUCT,
  GET_PRODUCTS_REQUEST_PENDING,
  GET_PRODUCTS_REQUEST_SUCCESS,
  GET_PRODUCTS_REQUEST_ERROR,
  CHANGE_PRODUCTS_REQUEST_PENDING,
  CHANGE_PRODUCTS_REQUEST_SUCCESS,
  CHANGE_PRODUCTS_REQUEST_ERROR,
} from '../types/products'

const initialState = {
  items: [],
  pagination: {
    current: 1,
    pageSize: 20,
  },
  item: {},
  isGetLoading: false,
  isChangeLoading: false,
  errors: [],
}

const products = (state = initialState, action) => {
  switch (action.type) {
    case GET_PRODUCTS:
      return { ...state, ...action.payload }
    case GET_PRODUCT:
      return { ...state, item: action.payload }
    case UPDATE_PRODUCT:
      const itemIndex = state.items.findIndex((item) => item.id === action.payload.id)
      const newItems = [...state.items]
      newItems[itemIndex] = { ...action.payload }
      return { ...state, items: newItems }
    case CREATE_PRODUCT:
      return { ...state, items: [...state.items, action.payload] }
    case DELETE_PRODUCT:
      return { ...state, items: state.items.filter((item) => item.id !== action.payload) }
    case GET_PRODUCTS_REQUEST_PENDING:
      return { ...state, isGetLoading: true }
    case GET_PRODUCTS_REQUEST_SUCCESS:
      return { ...state, errors: [], isGetLoading: false }
    case GET_PRODUCTS_REQUEST_ERROR:
      return { ...state, errors: action.payload, isGetLoading: false }
    case CHANGE_PRODUCTS_REQUEST_PENDING:
      return { ...state, isChangeLoading: true }
    case CHANGE_PRODUCTS_REQUEST_SUCCESS:
      return { ...state, errors: [], isChangeLoading: false }
    case CHANGE_PRODUCTS_REQUEST_ERROR:
      return { ...state, errors: action.payload, isChangeLoading: false }
    default:
      return state
  }
}

export default products
