export const GET_CATEGORIES_REQUEST = 'GET_CATEGORIES_REQUEST'
export const GET_CATEGORIES = 'GET_CATEGORIES'

export const GET_SUP_CATEGORIES_REQUEST = 'GET_SUP_CATEGORIES_REQUEST'

export const GET_CATEGORY_REQUEST = 'GET_CATEGORY_REQUEST'
export const GET_CATEGORY = 'GET_CATEGORY'

export const UPDATE_CATEGORY_REQUEST = 'UPDATE_CATEGORY_REQUEST'
export const UPDATE_CATEGORY = 'UPDATE_CATEGORY'

export const CREATE_CATEGORY_REQUEST = 'CREATE_CATEGORY_REQUEST'
export const CREATE_CATEGORY = 'CREATE_CATEGORY'

export const DELETE_CATEGORY_REQUEST = 'DELETE_CATEGORY_REQUEST'
export const DELETE_CATEGORY = 'DELETE_CATEGORY'

export const GET_CATEGORIES_REQUEST_PENDING = 'GET_CATEGORIES_REQUEST_PENDING'
export const GET_CATEGORIES_REQUEST_SUCCESS = 'GET_CATEGORIES_REQUEST_SUCCESS'
export const GET_CATEGORIES_REQUEST_ERROR = 'GET_CATEGORIES_REQUEST_ERROR'

export const CHANGE_CATEGORIES_REQUEST_PENDING = 'CHANGE_CATEGORIES_REQUEST_PENDING'
export const CHANGE_CATEGORIES_REQUEST_SUCCESS = 'CHANGE_CATEGORIES_REQUEST_SUCCESS'
export const CHANGE_CATEGORIES_REQUEST_ERROR = 'CHANGE_CATEGORIES_REQUEST_ERROR'
