import axios from 'axios'

class UpsellingService {
  getUpsellingProduct = () =>
    axios({
      url: 'upselling/product',
      method: 'GET',
    })
  getUpsellingActiveType = () =>
    axios({
      url: 'upselling/current-active-type',
      method: 'GET',
    })
  getUpsellingSelective = () =>
    axios({
      url: 'upselling/selective',
      method: 'GET',
    })
  getUpsellingRandom = () =>
    axios({
      url: 'upselling/random',
      method: 'GET',
    })
  putUpsellingTypeActivate = (data) =>
    axios({
      url: 'upselling/type/activate',
      method: 'PUT',
      data,
    })
  postUpsellingSelectiveProduct = (data) =>
    axios({
      url: 'upselling/selectiveProduct',
      method: 'POST',
      data,
    })
  postUpsellingRandomProducts = (data) =>
    axios({
      url: 'upselling/randomUpsellingList',
      method: 'POST',
      data,
    })
}
export default new UpsellingService()
