import {
  ADD_UPSELLING_ITEM,
  GET_UPSELLING_ACTIVATE_TYPE,
  GET_UPSELLING_RANDOM,
  GET_UPSELLING_SELECTIVE,
  UPDATE_UPSELLING_RANDOM_LIST,
  REMOVE_UPSELLING_ITEM,
} from '../types/upselling'

const initialState = {
  items: [],
  type: '',
  selective: {},
}

const upselling = (state = initialState, action) => {
  switch (action.type) {
    case GET_UPSELLING_RANDOM:
      return { ...state, items: action.payload }
    case ADD_UPSELLING_ITEM:
      return { ...state, items: [...state.items, action.payload] }
    case REMOVE_UPSELLING_ITEM:
      return { ...state, items: state.items.filter((item, index) => index !== action.payload) }
    case GET_UPSELLING_ACTIVATE_TYPE:
      return { ...state, type: action.payload }
    case UPDATE_UPSELLING_RANDOM_LIST:
      return { ...state, items: action.payload }
    case GET_UPSELLING_SELECTIVE:
      return { ...state, selective: action.payload }
    default:
      return state
  }
}

export default upselling
