import { call, put, all, fork, takeEvery, select } from 'redux-saga/effects'

import { showLoading, hideLoading } from 'react-redux-loading-bar'

import ProductsService from '../middlewares/products'

import { notify } from 'reapop'
import i18n from 'i18next'

import {
  GET_PRODUCTS_REQUEST,
  GET_PRODUCTS,
  GET_PRODUCT_REQUEST,
  GET_PRODUCT,
  UPDATE_PRODUCT_REQUEST,
  UPDATE_PRODUCT,
  GET_PRODUCTS_REQUEST_PENDING,
  GET_PRODUCTS_REQUEST_SUCCESS,
  GET_PRODUCTS_REQUEST_ERROR,
  CHANGE_PRODUCTS_REQUEST_PENDING,
  CHANGE_PRODUCTS_REQUEST_SUCCESS,
  CHANGE_PRODUCTS_REQUEST_ERROR,
  GET_SUP_PRODUCTS_REQUEST,
} from '../types/products'

function* getProductsWatcher() {
  yield takeEvery(GET_PRODUCTS_REQUEST, getProductsWorker)
}

function* getSupProductsWatcher() {
  yield takeEvery(GET_SUP_PRODUCTS_REQUEST, getSupProductsWorker)
}

function* getProductWatcher() {
  yield takeEvery(GET_PRODUCT_REQUEST, getProductWorker)
}

function* updateProductWatcher() {
  yield takeEvery(UPDATE_PRODUCT_REQUEST, updateProductWorker)
}

function* getProductsWorker({ payload: { page, size } }) {
  try {
    yield put(showLoading())
    yield put({ type: GET_PRODUCTS_REQUEST_PENDING })
    const response = yield call(ProductsService.getProducts, page, size)
    const { result, total } = response.data.data
    yield put({
      type: GET_PRODUCTS,
      payload: {
        items: result,
        pagination: {
          current: page,
          pageSize: size,
          total: total,
        },
      },
    })
    yield put({ type: GET_PRODUCTS_REQUEST_SUCCESS })
    yield put(hideLoading())
  } catch (error) {
    yield put({
      type: GET_PRODUCTS_REQUEST_ERROR,
      payload: error.response.data.messages,
    })
    yield put(hideLoading())
  }
}

function* getSupProductsWorker() {
  try {
    yield put(showLoading())
    yield put({ type: GET_PRODUCTS_REQUEST_PENDING })
    yield call(ProductsService.getSupProducts)
    const { current, pageSize } = yield select((state) => state.products.pagination)
    yield put({
      type: GET_PRODUCTS_REQUEST,
      payload: {
        page: current,
        size: pageSize,
      },
    })
    yield put({ type: GET_PRODUCTS_REQUEST_SUCCESS })
    yield put(hideLoading())
  } catch (error) {
    yield put({
      type: GET_PRODUCTS_REQUEST_ERROR,
      payload: error.response.data.messages,
    })
    yield put(hideLoading())
  }
}

function* getProductWorker({ payload: { id } }) {
  try {
    yield put(showLoading())
    yield put({ type: GET_PRODUCTS_REQUEST_PENDING })
    const response = yield call(ProductsService.getProductById, id)
    const product = response.data.data
    yield put({ type: GET_PRODUCT, payload: product })
    yield put({ type: GET_PRODUCTS_REQUEST_SUCCESS })
    yield put(hideLoading())
  } catch (error) {
    yield put({
      type: GET_PRODUCTS_REQUEST_ERROR,
      payload: error.response.data.messages,
    })
    yield put(hideLoading())
  }
}

function* updateProductWorker({ payload: { id, data, navigate } }) {
  try {
    yield put(showLoading())
    yield put({ type: CHANGE_PRODUCTS_REQUEST_PENDING })
    const response = yield call(ProductsService.updateProduct, id, data)
    const product = response.data.data
    yield put({ type: UPDATE_PRODUCT, payload: product })
    yield put({ type: CHANGE_PRODUCTS_REQUEST_SUCCESS })
    yield put(
      notify({
        title: i18n.t('notifs.updated'),
        status: 'success',
        dismissAfter: 4000,
        allowHTML: true,
      }),
    )
    navigate('/main/products')
    yield put(hideLoading())
  } catch (error) {
    yield put({
      type: CHANGE_PRODUCTS_REQUEST_ERROR,
      payload: error.response.data.messages,
    })
    yield put(hideLoading())
  }
}

export default function* products() {
  yield all([
    fork(getProductsWatcher),
    fork(getProductWatcher),
    fork(updateProductWatcher),
    fork(getSupProductsWatcher),
  ])
}
