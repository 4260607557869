import { call, put, all, fork, takeEvery } from 'redux-saga/effects'

import { showLoading, hideLoading } from 'react-redux-loading-bar'

import CustomersService from '../middlewares/customers'

import {
  GET_CUSTOMERS_REQUEST,
  GET_CUSTOMERS,
  GET_CUSTOMER_REQUEST,
  GET_CUSTOMER,
  GET_CUSTOMERS_REQUEST_PENDING,
  GET_CUSTOMERS_REQUEST_SUCCESS,
  GET_CUSTOMERS_REQUEST_ERROR,
} from '../types/customers'

function* getCustomersWatcher() {
  yield takeEvery(GET_CUSTOMERS_REQUEST, getCustomersWorker)
}

function* getCustomerWatcher() {
  yield takeEvery(GET_CUSTOMER_REQUEST, getCustomerWorker)
}

function* getCustomersWorker({ payload: { currentPage, pageSize, sortType, sortOrder, search } }) {
  try {
    yield put(showLoading())
    yield put({ type: GET_CUSTOMERS_REQUEST_PENDING })
    const response = yield call(
      CustomersService.getCustomers,
      currentPage,
      pageSize,
      sortType,
      sortOrder,
      search,
    )
    const { result, total } = response.data.data
    yield put({
      type: GET_CUSTOMERS,
      payload: {
        items: result,
        pagination: {
          current: currentPage,
          pageSize: pageSize,
          total: total,
        },
      },
    })
    yield put({ type: GET_CUSTOMERS_REQUEST_SUCCESS })
    yield put(hideLoading())
  } catch (error) {
    yield put({ type: GET_CUSTOMERS_REQUEST_ERROR, payload: error.response.data.messages })
    yield put(hideLoading())
  }
}

function* getCustomerWorker({ payload: { id } }) {
  try {
    yield put(showLoading())
    yield put({ type: GET_CUSTOMERS_REQUEST_PENDING })
    const response = yield call(CustomersService.getCustomerById, id)
    const customer = response.data.data
    yield put({ type: GET_CUSTOMER, payload: customer })
    yield put({ type: GET_CUSTOMERS_REQUEST_SUCCESS })
    yield put(hideLoading())
  } catch (error) {
    yield put({ type: GET_CUSTOMERS_REQUEST_ERROR, payload: error.response.data.messages })
    yield put(hideLoading())
  }
}

export default function* customers() {
  yield all([fork(getCustomersWatcher), fork(getCustomerWatcher)])
}
