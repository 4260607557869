import Icon from './Icon'
import { ICONS } from '../utils/constants'

const PlayButton = ({ playHandler }) => {
  return (
    <button className="playButton" onClick={playHandler}>
      <Icon icon={ICONS.PLAY} />
    </button>
  )
}

export default PlayButton
