import {
  SIGN_IN_SUCCESS,
  SIGN_IN_ERROR,
  SIGN_UP_SUCCESS,
  SIGN_UP_ERROR,
  SIGN_OUT_SUCCESS,
  SIGN_OUT_ERROR,
} from '../types/auth'

const initialState = {
  isAuthenticated: false,
  signInErrors: [],
  signUpErrors: [],
  signOutErrors: [],
}

const auth = (state = initialState, action) => {
  switch (action.type) {
    case SIGN_IN_SUCCESS:
      return {
        ...state,
        isAuthenticated: true,
        signInErrors: [],
      }
    case SIGN_IN_ERROR:
      return {
        ...state,
        signInErrors: action.payload,
      }
    case SIGN_UP_SUCCESS:
      return {
        ...state,
        isAuthenticated: true,
        signUpErrors: [],
      }
    case SIGN_UP_ERROR:
      return {
        ...state,
        signUpErrors: action.payload,
      }
    case SIGN_OUT_SUCCESS:
      return {
        ...state,
        isAuthenticated: false,
        signOutErrors: [],
      }
    case SIGN_OUT_ERROR:
      return {
        ...state,
        signOutErrors: action.payload,
      }
    default:
      return state
  }
}

export default auth
