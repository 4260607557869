import axios from 'axios'

export const getCampaignList = async (params) => {
  const response = await axios({
    method: 'GET',
    url: 'campaign/list',
    params: {
      page: params.page,
      take: params.size,
      asc: params.sortType && params.sortOrder === 'ascend' ? params.sortType : null,
      desc: params.sortType && params.sortOrder === 'descend' ? params.sortType : null,
    },
  })

  return response.data.data
}

export const fetchCampaign = async (id) => {
  const response = await axios.get(`campaign/${id}`)

  return response.data.data
}

export const createCampaign = async (data) => {
  const response = await axios.post('campaign', data)

  return response.data.data
}

export const updateCampaign = async (data) => {
  const response = await axios.put('campaign', data)

  return response.data.data
}

export const deleteCampaign = async (campaignId) => {
  const response = await axios.delete(`campaign/${campaignId}`)

  return response.data.data
}
