import { useCallback, useEffect, useMemo, useState } from 'react'
import { yupResolver } from '@hookform/resolvers/yup'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { FormProvider, useForm } from 'react-hook-form'
import { notify } from 'reapop'
import { useNavigate, useParams } from 'react-router-dom'
import { Button, Col, Row } from 'antd'

import useClient from 'hooks/useClient'
import HookedField from 'components/HookedField'
import Page from 'components/Page'
import AvatarUploader from 'components/UserImageUploader'
import PreloaderBlock from 'components/PreloaderBlock/PreloaderBlock'
import {
  createAdminUser,
  getAdminUser,
  requestResetAdminUserPassword,
  updateAdminUser,
  updateCurrentAdminUser,
} from 'api/admin-users-managment'
import { deleteFile, updateFile } from 'api/files'
import schema from './schema'
import { ADMIN_ROLE_ID } from '../../utils/constants'
import { getRolesList } from '../../api/roles'

const AdminUser = () => {
  const { t } = useTranslation()
  const queryClient = useQueryClient()
  const navigate = useNavigate()
  const { id: userId } = useParams()
  const isEditMode = useMemo(() => !!userId, [userId])
  const [userRolesOptions, setUserRolesOptions] = useState([])
  const [isFormLoading, setIsFormLoading] = useState(false)
  const dispatch = useDispatch()
  const { client, isClientCan, isSuperAdmin } = useClient()

  const isOwnProfile = useMemo(() => {
    return Boolean(isEditMode && client.data && client.data?.id === Number(userId))
  }, [client.data, userId, isEditMode])

  const { data: rolesList, isSuccess: isRolesFetched } = useQuery({
    queryKey: ['roles-list'],
    queryFn: getRolesList,
  })

  const { data: loadedAdminUser } = useQuery({
    queryKey: ['admin-users-list', userId],
    queryFn: () => getAdminUser(userId),
    enabled: Boolean(client.data) && isEditMode && !isOwnProfile,
  })

  const currentUser = useMemo(() => {
    if (isEditMode) {
      if (isOwnProfile && client.data) {
        return client.data
      }

      if (!isOwnProfile && loadedAdminUser) {
        return loadedAdminUser
      }
    }

    return undefined
  }, [isEditMode, client.data, isOwnProfile, loadedAdminUser])

  const submitErrorHandler = useCallback(
    (err) => {
      const errorCode = err.response?.data?.messages[0].message

      dispatch(
        notify({
          title: t(`adminTeamManagement.users.errors.${errorCode}`),
          status: 'error',
          dismissAfter: 4000,
        }),
      )

      setIsFormLoading(false)
    },
    [dispatch, t],
  )

  const createAdminUserMutation = useMutation(createAdminUser, {
    onError: (err) => submitErrorHandler(err),
  })

  const updateAdminUserMutation = useMutation(updateAdminUser, {
    onError: (err) => submitErrorHandler(err),
  })

  const updateCurrentAdminUserMutation = useMutation(updateCurrentAdminUser, {
    onError: (err) => submitErrorHandler(err),
  })

  const resetAdminUserPasswordMutation = useMutation(requestResetAdminUserPassword, {
    onSuccess: () => {
      setIsFormLoading(false)
      dispatch(
        notify({
          title: t('adminTeamManagement.users.success.emailWithInstructionsSent'),
          status: 'success',
          dismissAfter: 4000,
        }),
      )
    },
    onError: (err) => submitErrorHandler(err),
  })

  const updateAvatarMutation = useMutation(updateFile, {
    onError: (err) => submitErrorHandler(err),
  })

  const deleteAvatarMutation = useMutation(deleteFile, {
    onError: (err) => submitErrorHandler(err),
  })

  const hasPermission = isClientCan('CREATE_ADMIN_USERS')

  const updateAvatar = async (user, image) => {
    const uploadedFile = await updateAvatarMutation.mutateAsync({
      id: user.avatar.id,
      file: image,
    })

    user.avatar.src = process.env.REACT_APP_API_BASE_URL.replace('/api/v1/', `/${uploadedFile.key}`)
  }

  const deleteAvatar = async (user) => {
    await deleteAvatarMutation.mutateAsync({
      id: user.avatar.id,
      givenEntityId: user.id,
    })

    user.avatar.src = null
  }

  const defaultFormValues = useMemo(() => {
    return {
      id: userId ? Number(userId) : null,
      name: currentUser ? currentUser.name : '',
      email: currentUser ? currentUser.email : '',
      roleId: currentUser ? currentUser.role.id : null,
      userName: currentUser ? currentUser.userName : '',
      password: '',
      confirmPassword: '',
      image: currentUser?.avatar?.src ? currentUser.avatar.src : null,
    }
  }, [userId, currentUser])

  const methods = useForm({
    mode: 'onSubmit',
    shouldFocusError: true,
    defaultValues: defaultFormValues,
    resolver: yupResolver(schema),
  })

  // Fields permissions
  const canEditNameField = useMemo(() => {
    return isOwnProfile || hasPermission || isSuperAdmin
  }, [isOwnProfile, hasPermission, isSuperAdmin])

  const canEditEmailField = useMemo(() => {
    return !userId && hasPermission
  }, [userId, hasPermission])

  const canEditPasswordField = useMemo(() => {
    return (!userId && hasPermission) || isOwnProfile
  }, [userId, hasPermission, isOwnProfile])

  const canResetPassword = isClientCan('UPDATE_ADMIN_USERS')

  const canEditRoleField = useMemo(() => {
    if (!!currentUser) {
      return currentUser?.roleId !== 1 && !isOwnProfile && isSuperAdmin
    } else {
      return hasPermission || isSuperAdmin
    }
  }, [currentUser, hasPermission, isOwnProfile, isSuperAdmin])

  const canEditAvatarField = useMemo(() => {
    return isOwnProfile || hasPermission || isSuperAdmin
  }, [isOwnProfile, hasPermission, isSuperAdmin])

  const canSaveForm = useMemo(() => {
    return (
      canEditNameField ||
      canEditEmailField ||
      canEditPasswordField ||
      canEditRoleField ||
      canEditAvatarField
    )
  }, [
    canEditNameField,
    canEditEmailField,
    canEditPasswordField,
    canEditRoleField,
    canEditAvatarField,
  ])

  // Handlers
  const submitHandler = useCallback(
    async (formData) => {
      if (isFormLoading) return false
      setIsFormLoading(true)

      const isExistingUser = Boolean(formData.id)
      const { image } = formData

      // Prepare upload data
      const preparedData = {
        roleId: formData.roleId && canEditRoleField ? Number(formData.roleId) : undefined,
        password: formData.password && canEditPasswordField ? formData.password : undefined,
        email: formData.email && canEditEmailField ? formData.email : undefined,
        name: 'Some application name',
        userName: formData.userName.trim(),
      }

      if (!isExistingUser) {
        const newUser = await createAdminUserMutation.mutateAsync(preparedData)

        queryClient.setQueryData(['admin-users-list', userId], (prevData) => {
          if (!prevData) return undefined

          return {
            ...prevData,
            avatar: newUser.avatar,
            role: newUser.role,
            roleId: newUser.roleId,
            name: newUser.name,
            userName: newUser.userName,
            updatedAt: newUser.updatedAt,
          }
        })

        if (image && typeof image === 'object') {
          await updateAvatar(newUser, image)
        }

        if (newUser?.avatar?.src && image === null) {
          await deleteAvatar(newUser)
        }
      }

      if (isOwnProfile) {
        const updatedCurrentAdmin = await updateCurrentAdminUserMutation.mutateAsync(preparedData)

        queryClient.setQueryData(['current-admin-user'], (prevData) => {
          if (!prevData) return undefined

          return {
            ...prevData,
            avatar: updatedCurrentAdmin.avatar,
            roleId: updatedCurrentAdmin.roleId,
            name: updatedCurrentAdmin.name,
            userName: updatedCurrentAdmin.userName,
            updatedAt: updatedCurrentAdmin.updatedAt,
          }
        })

        if (image && typeof image === 'object') {
          await updateAvatar(updatedCurrentAdmin, image)
        }

        if (updatedCurrentAdmin?.avatar?.src && image === null) {
          await deleteAvatar(updatedCurrentAdmin)
        }
      }

      if (isExistingUser && !isOwnProfile) {
        const updatedAdminUser = await updateAdminUserMutation.mutateAsync({
          id: formData.id,
          data: preparedData,
        })

        if (image && typeof image === 'object') {
          await updateAvatar(updatedAdminUser, image)
        }

        if (updatedAdminUser?.avatar?.src && image === null) {
          await deleteAvatar(updatedAdminUser)
        }
      }

      // Last steps
      dispatch(
        notify({ title: t('notifs.profile updated'), status: 'success', dismissAfter: 2000 }),
      )

      setIsFormLoading(false)
      navigate('/main/users')
    },
    [
      createAdminUserMutation,
      updateAdminUserMutation,
      updateCurrentAdminUserMutation,
      updateAvatarMutation,
      deleteAvatarMutation,
      currentUser,
      isFormLoading,
      canEditPasswordField,
      canEditRoleField,
      canEditEmailField,
      isOwnProfile,
      userId,
      dispatch,
      navigate,
      queryClient,
      t,
      updateAvatar,
      deleteAvatar,
    ],
  )

  // Effects

  useEffect(() => {
    if (!isRolesFetched) return

    setUserRolesOptions(
      rolesList
        .filter((role) => role.id !== ADMIN_ROLE_ID)
        .map(({ roleName, id }) => ({ label: roleName, value: id })),
    )
  }, [rolesList, isRolesFetched])

  useEffect(() => {
    methods.reset(defaultFormValues)
  }, [defaultFormValues, methods])

  const handleOnPasswordResetClick = async () => {
    setIsFormLoading(true)
    await resetAdminUserPasswordMutation.mutateAsync(userId)
  }

  // Templates
  if (!client.isLoaded || (isEditMode && !currentUser) || !isRolesFetched) {
    return <PreloaderBlock minHeight={'60vw'} />
  }

  return (
    <Page pageTitle={t('adminUsersManagement.title')}>
      <FormProvider {...methods}>
        <form className="form" autoComplete="off" onSubmit={methods.handleSubmit(submitHandler)}>
          <Row gutter={[24, 24]} style={{ marginBottom: 20 }}>
            <Col span={24}>
              <h3>
                {userId ? t('adminUsersManagement.formName') : t('adminUsersManagement.createUser')}
              </h3>
            </Col>
          </Row>

          <Row gutter={[24, 24]}>
            <Col span={24} lg={{ span: 12 }}>
              <HookedField
                label={t('fields.name')}
                name="userName"
                placeholder={t('fields.name')}
                fieldType="textField"
                type="text"
                disabled={!canEditNameField}
                isRequired={true}
              />
            </Col>
          </Row>

          <Row gutter={[24, 24]}>
            <Col span={24} lg={{ span: 12 }}>
              <HookedField
                label={t('fields.email')}
                name="email"
                placeholder={t('fields.email')}
                isRequired={true}
                disabled={!canEditEmailField}
                fieldType="textField"
                type="text"
              />
            </Col>
          </Row>

          <Row gutter={[24, 24]}>
            <Col span={24} lg={{ span: 12 }}>
              <HookedField
                label={t('fields.role')}
                name="roleId"
                placeholder={t('fields.role')}
                isRequired={true}
                disabled={!canEditRoleField}
                fieldType="select"
                type="select"
                options={userRolesOptions}
              />
            </Col>
          </Row>

          <Row gutter={[24, 24]} style={{ marginBottom: 20 }}>
            <Col span={24}>
              <h3>{t('adminUsersManagement.changePassword.title')}</h3>
            </Col>
          </Row>

          <Row gutter={[24, 24]}>
            <Col span={24} lg={{ span: 12 }}>
              <HookedField
                label={t('fields.password')}
                name="password"
                placeholder={t('fields.password')}
                isRequired={true}
                disabled={!canEditPasswordField}
                fieldType="textField"
                type="password"
              />
            </Col>
          </Row>
          <Row gutter={[24, 24]}>
            <Col span={24} lg={{ span: 12 }}>
              <HookedField
                label={t('fields.confirm password')}
                name="confirmPassword"
                placeholder={t('fields.confirm password')}
                isRequired={true}
                disabled={!canEditPasswordField}
                fieldType="textField"
                type="password"
              />
            </Col>
          </Row>

          {canResetPassword && (
            <Row gutter={[24, 24]}>
              <Col span={24} lg={{ span: 12 }}>
                <Button
                  className="btn btn--normal btn--primary"
                  type="primary"
                  size="large"
                  loading={isFormLoading}
                  onClick={handleOnPasswordResetClick}
                >
                  Reset Password
                </Button>
              </Col>
            </Row>
          )}

          <Row gutter={[24, 24]} style={{ marginBottom: 20, marginTop: 20 }}>
            <Col span={24}>
              <h3>{t('adminUsersManagement.addImg')}</h3>
            </Col>
          </Row>

          <Row gutter={[24, 24]}>
            <Col span={24}>
              <AvatarUploader
                methods={methods}
                formInputName="image"
                disabled={!canEditAvatarField}
              />
            </Col>
          </Row>

          <Row gutter={[24, 24]} style={{ marginTop: 25 }}>
            <Col span={24}>
              <Button
                className="btn btn--normal btn--primary"
                type="primary"
                size="large"
                htmlType="submit"
                style={{ width: '100%', maxWidth: 250 }}
                disabled={!canSaveForm}
                loading={isFormLoading}
              >
                {userId
                  ? t('adminUsersManagement.updateUser')
                  : t('adminUsersManagement.createUser')}
              </Button>
            </Col>
          </Row>
        </form>
      </FormProvider>
    </Page>
  )
}

export default AdminUser
