import {
  GET_MOSQUES,
  GET_MOSQUES_REQUEST_ERROR,
  GET_MOSQUES_REQUEST_PENDING,
  GET_MOSQUES_REQUEST_SUCCESS,
} from '../types/mosques'

const initialState = {
  items: [],
  pagination: {
    current: 1,
    pageSize: 20,
  },
  item: {},
  isGetLoading: false,
  isChangeLoading: false,
  errors: [],
}

const mosques = (state = initialState, action) => {
  switch (action.type) {
    case GET_MOSQUES:
      return { ...state, ...action.payload }
    case GET_MOSQUES_REQUEST_PENDING:
      return { ...state, isGetLoading: true }
    case GET_MOSQUES_REQUEST_SUCCESS:
      return { ...state, errors: [], isGetLoading: false }
    case GET_MOSQUES_REQUEST_ERROR:
      return { ...state, errors: action.payload, isGetLoading: false }
    default:
      return state
  }
}

export default mosques
