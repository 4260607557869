import moment from 'moment'
import { useEffect, useMemo, useState } from 'react'
import { v4 as uuidv4 } from 'uuid'
import { useTranslation } from 'react-i18next'
import { Controller, useFormContext } from 'react-hook-form'
import InputMask from 'react-input-mask'
import { ICONS, DATE_FORMAT, DATE_TIME_FORMAT } from 'utils/constants'
import { Input, Checkbox, InputNumber, DatePicker, Select, TimePicker, Switch } from 'antd'
import ErrorList from 'components/ErrorList'
import Icon from 'components/Icon'

const { Password, TextArea } = Input
const { RangePicker: TimeRangePicker } = TimePicker
const { RangePicker } = DatePicker

const HookedField = ({
  className,
  name,
  input,
  defaultValue,
  fieldType,
  label,
  type,
  disabled,
  placeholder,
  text,
  error,
  options,
  disabledDate,
  mode,
  allowClear,
  prefixIcon,
  suffixIcon,
  isRequired,
  ...rest
}) => {
  const { t } = useTranslation()
  const [hasErrors, setHasErrors] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')

  const id = uuidv4()

  const { control, formState } = useFormContext()

  // Errors condition check
  useEffect(() => {
    let hasErrors = false
    let errorMessage = ''

    // Array type on name
    if (name.includes('.')) {
      const nameParts = name.split('.')
      let targetErrorMessage = formState.errors

      for (let i = 0; i < nameParts.length; i++) {
        if (targetErrorMessage) {
          targetErrorMessage = targetErrorMessage[nameParts[i]]
        }
      }

      if (targetErrorMessage?.message) {
        hasErrors = true
        errorMessage = targetErrorMessage.message
      }
    } else if (formState.errors[name]?.message) {
      // Simple type on name
      hasErrors = true
      errorMessage = formState?.errors[name]?.message || ''
    }

    setHasErrors(hasErrors)
    setErrorMessage(errorMessage)
  }, [formState, name])

  const classes = [
    'hooked-field',
    'hooked-field--' + fieldType,
    className,
    hasErrors ? 'hooked-field--withError' : '',
  ]

  const renderInput = (onChange, value) => {
    switch (type) {
      case 'password':
        return (
          <Password
            className={className}
            placeholder={placeholder}
            disabled={disabled}
            value={value}
            onChange={onChange}
            id={id}
            {...rest}
          />
        )
      case 'checkbox':
        return (
          <Checkbox
            className={className}
            onChange={(e) => onChange(e.target.checked)}
            checked={value}
            disabled={disabled}
            id={id}
            {...rest}
          >
            {placeholder}
          </Checkbox>
        )
      case 'toggle':
        return (
          <label className="toogle">
            <input
              className="toogle-input"
              type="checkbox"
              disabled={disabled}
              name={name}
              checked={value}
              defaultValue={defaultValue}
              onChange={(e) => onChange(e.target.checked)}
            />
            <span className="toogle-box" />
          </label>
        )
      case 'switch':
        return <Switch className={className} checked={value} onChange={onChange} {...rest} />
      case 'textarea':
        return (
          <TextArea
            className={className}
            placeholder={placeholder}
            disabled={disabled}
            value={value}
            onChange={onChange}
            id={id}
            autoSize={{ minRows: 4, maxRows: 4 }}
            {...rest}
          />
        )
      case 'number':
        return (
          <InputNumber
            className={className}
            type="number"
            placeholder={placeholder}
            disabled={disabled}
            value={value}
            onChange={onChange}
            id={id}
            {...rest}
          />
        )
      case 'price':
        return (
          <InputNumber
            className={className}
            placeholder={placeholder}
            disabled={disabled}
            value={value}
            onChange={onChange}
            formatter={(value) => `₣ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
            parser={(value) => value.replace(/₣\s?|(,*)/g, '')}
            id={id}
            defaultValue={defaultValue}
            {...rest}
          />
        )
      case 'select':
        return (
          <Select
            mode={mode}
            allowClear={allowClear}
            className={className}
            showArrow={true}
            placeholder={placeholder}
            disabled={disabled}
            defaultValue={defaultValue}
            onChange={(option) => {
              onChange(option)
              rest?.onChangeHandler?.(option)
            }}
            id={id}
            value={value}
            options={options}
            {...rest}
          />
        )
      case 'datePicker':
        return (
          <DatePicker
            clearIcon={<Icon size={20} icon={ICONS.CLOSE} />}
            defaultValue={defaultValue && moment(defaultValue, moment.ISO_8601)}
            value={value && moment(value, moment.ISO_8601)}
            format={DATE_FORMAT}
            onChange={onChange}
            disabledDate={disabledDate}
            disabled={disabled}
            {...rest}
          />
        )
      case 'dateRange':
        return (
          <RangePicker
            clearIcon={<Icon size={20} icon={ICONS.CLOSE} />}
            allowClear={allowClear}
            defaultValue={
              defaultValue &&
              defaultValue.length && [
                moment(defaultValue[0], moment.ISO_8601),
                moment(defaultValue[1], moment.ISO_8601),
              ]
            }
            value={value}
            format={DATE_FORMAT}
            onChange={onChange}
            disabledDate={disabledDate}
            {...rest}
          />
        )
      case 'timePicker':
        return (
          <TimePicker
            clearIcon={<Icon size={20} icon={ICONS.CLOSE} />}
            defaultValue={defaultValue && moment(defaultValue, moment.ISO_8601)}
            onChange={onChange}
            {...rest}
          />
        )
      case 'timeRange':
        return (
          <TimeRangePicker
            clearIcon={<Icon size={20} icon={ICONS.CLOSE} />}
            defaultValue={
              defaultValue &&
              defaultValue.length && [
                moment(defaultValue[0], moment.ISO_8601),
                moment(defaultValue[1], moment.ISO_8601),
              ]
            }
            onChange={onChange}
            {...rest}
          />
        )
      case 'dateTimeField':
        return (
          <DatePicker
            clearIcon={<Icon size={20} icon={ICONS.CLOSE} />}
            defaultValue={defaultValue && moment(defaultValue, moment.ISO_8601)}
            value={value && moment(value, moment.ISO_8601)}
            format={DATE_TIME_FORMAT}
            onChange={onChange}
            disabledDate={disabledDate}
            disabled={disabled}
            placeholder={placeholder}
            showTime
            {...rest}
          />
        )
      case 'tel':
        return (
          <InputMask
            mask="+999 999999999"
            maskChar={null}
            className={className}
            type="tel"
            placeholder={placeholder}
            disabled={disabled}
            value={value}
            onChange={onChange}
            id={id}
            {...rest}
          />
        )
      case 'hidden':
        return (
          <Input
            className={className}
            type="hidden"
            placeholder={placeholder}
            disabled={disabled}
            value={value}
            onChange={(e) => {
              onChange(e)
              rest?.onChangeHandler?.(e)
            }}
            id={id}
            {...rest}
          />
        )
      default:
        return (
          <Input
            className={className}
            type="text"
            placeholder={placeholder}
            disabled={disabled}
            value={value}
            onChange={(e) => {
              onChange(e)
              rest?.onChangeHandler?.(e)
            }}
            id={id}
            {...rest}
          />
        )
    }
  }

  const renderController = () => (
    <Controller
      control={control}
      name={name}
      defaultValue={defaultValue}
      render={({ field: { onChange, value } }) => renderInput(onChange, value)}
      type={type}
    />
  )

  const hookedInput = renderController()

  const hookedError = useMemo(() => {
    return hasErrors ? <ErrorList errors={[errorMessage]} /> : null
  }, [hasErrors, errorMessage])

  const renderTextField = () => {
    const classes = [
      'field',
      suffixIcon ? 'field--withSuffixIcon' : '',
      prefixIcon ? 'field--withPrefixIcon' : '',
    ]

    return (
      <div className={classes.join(' ')}>
        <div className="field-header">
          {label && <label htmlFor={id}>{label}</label>}
          {isRequired && <span className="field-caption">{t('fields.required')}</span>}
        </div>
        <div className="field-wrapper">
          {prefixIcon && <div className="field-prefixIcon">{prefixIcon}</div>}
          {hookedInput}
          {suffixIcon && <div className="field-suffixIcon">{suffixIcon}</div>}
          <div className="field-border"></div>
          {hookedError}
        </div>
      </div>
    )
  }

  const renderNumberField = () => (
    <div className="field">
      <div className="field-header">
        {label && <label htmlFor={id}>{label}</label>}
        {isRequired && <span className="field-caption">{t('fields.required')}</span>}
      </div>
      <div className="field-wrapper">
        {hookedInput}
        <div className="field-border"></div>
        {hookedError}
      </div>
    </div>
  )

  const renderPasswordField = () => (
    <div className="password-field">
      <div className="field-header">
        {label && <label htmlFor={id}>{label}</label>}
        {isRequired && <span className="field-caption">{t('fields.required')}</span>}
      </div>
      <div className="field-wrapper">
        {hookedInput}
        <div className="field-border"></div>
        {hookedError}
      </div>
    </div>
  )

  const renderCheckboxField = () => (
    <>
      <label className="customCheckbox">
        {hookedInput}
        {text}
      </label>
      {hookedError}
    </>
  )

  const renderDateField = () => (
    <div className="picker">
      <div className="field-header">
        {label && <label htmlFor={id}>{label}</label>}
        {isRequired && <span className="field-caption">{t('fields.required')}</span>}
      </div>
      <div className="field-wrapper">
        {hookedInput}
        {hookedError}
      </div>
    </div>
  )

  const renderDateRangeField = () => (
    <div className="picker">
      <div className="field-header">
        {label && <label htmlFor={id}>{label}</label>}
        {isRequired && <span className="field-caption">{t('fields.required')}</span>}
      </div>
      <div className="field-wrapper">
        {hookedInput}
        {hookedError}
      </div>
    </div>
  )

  const renderTimeField = () => (
    <div className="picker">
      <div className="field-header">
        {label && <label htmlFor={id}>{label}</label>}
        {isRequired && <span className="field-caption">{t('fields.required')}</span>}
      </div>
      <div className="field-wrapper">
        {hookedInput}
        {hookedError}
      </div>
    </div>
  )

  const renderTimeRangeField = () => (
    <div className="picker">
      <div className="field-header">
        {label && <label htmlFor={id}>{label}</label>}
        {isRequired && <span className="field-caption">{t('fields.required')}</span>}
      </div>
      <div className="field-wrapper">
        {hookedInput}
        {hookedError}
      </div>
    </div>
  )

  const renderDateTimeField = () => (
    <div className="field field--dateTimePicker">
      <div className="field-header">
        {label && <label htmlFor={id}>{label}</label>}
        {isRequired && <span className="field-caption">{t('fields.required')}</span>}
      </div>
      <div className="field-wrapper">
        {hookedInput}
        {hookedError}
      </div>
    </div>
  )

  const renderSelectField = () => (
    <div className="field field--select">
      <div className="field-header">
        {label && <label htmlFor={id}>{label}</label>}
        {isRequired && <span className="field-caption">{t('fields.required')}</span>}
      </div>
      <div className="field-wrapper">
        {hookedInput}
        <div className="field-border"></div>
        {hookedError}
      </div>
    </div>
  )

  const renderHiddenField = () => {
    const classes = [
      'field',
      'field--hidden',
      suffixIcon ? 'field--withSuffixIcon' : '',
      prefixIcon ? 'field--withPrefixIcon' : '',
    ]
    return (
      <div className={classes.join(' ')}>
        <div className="field-header">
          {label && <label htmlFor={id}>{label}</label>}
          {isRequired && <span className="field-caption">{t('fields.required')}</span>}
        </div>
        <div className="field-wrapper">
          {prefixIcon && <div className="field-prefixIcon">{prefixIcon}</div>}
          {hookedInput}
          {suffixIcon && <div className="field-suffixIcon">{suffixIcon}</div>}
          <div className="field-border"></div>
          {hookedError}
        </div>
      </div>
    )
  }

  const renderCustomFieldField = () => {
    return (
      <>
        {hookedInput}
        {hookedError}
      </>
    )
  }

  const renderHookedField = () => {
    switch (fieldType) {
      case 'textField':
        return renderTextField()
      case 'passwordField':
        return renderPasswordField()
      case 'checkboxField':
        return renderCheckboxField()
      case 'numberField':
        return renderNumberField()
      case 'customField':
        return renderCustomFieldField()
      case 'dateField':
        return renderDateField()
      case 'dateRange':
        return renderDateRangeField()
      case 'timeField':
        return renderTimeField()
      case 'timeRangeField':
        return renderTimeRangeField()
      case 'dateTimeField':
        return renderDateTimeField()
      case 'selectField':
        return renderSelectField()
      case 'hiddenField':
        return renderHiddenField()
      default:
        return renderTextField()
    }
  }

  return <div className={classes.join(' ')}>{renderHookedField()}</div>
}

export default HookedField
