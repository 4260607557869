import moment from 'moment'
import { useCallback, useState } from 'react'
import { useMutation } from '@tanstack/react-query'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Col, Modal, Row, DatePicker, Checkbox } from 'antd'
import Icon from 'components/Icon'
import Button from 'components/Button'
import PreloaderBlock from 'components/PreloaderBlock/PreloaderBlock'
import { DATE_FORMAT, ICONS } from 'utils/constants'
import OrdersService from 'redux/middlewares/orders'
import { notify } from 'reapop'

const { RangePicker } = DatePicker

const now = moment()
const todayEnd = moment().endOf('day')
const monthStart = moment().startOf('month')

const ExportOrdersModal = ({ isVisible, handleCancel = null, fileDownloadedHandler = null }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const [exportDates, setExportDates] = useState([monthStart, now])
  const [addRemovedOrders, setAddRemovedOrders] = useState(true)

  const startExportMutation = useMutation(OrdersService.exportOrders, {
    onSuccess: (response) => {
      handleExportResponse(response)
      fileDownloadedHandler && fileDownloadedHandler()
    },
    onError: () => {
      dispatch(notify({ title: t('notifs.error title'), status: 'error', dismissAfter: 4000 }))
    },
  })

  const handleExportResponse = useCallback(
    (exportData) => {
      // Push the file to client downloads
      let data = new Blob([exportData.data], { type: exportData.data.type })
      let objectURL = window.URL.createObjectURL(data)
      let tempLink = document.createElement('a')
      tempLink.href = objectURL
      tempLink.setAttribute(
        'download',
        `orders_data_from_${exportDates[0].format(DATE_FORMAT)}_to_${exportDates[1].format(
          DATE_FORMAT,
        )}.xls`,
      )
      tempLink.click()
    },
    [exportDates],
  )

  const submitHandler = useCallback(() => {
    startExportMutation.mutate({
      startDate: exportDates[0].toISOString(),
      finishDate: exportDates[1].toISOString(),
      addRemovedOrders: addRemovedOrders,
    })
  }, [startExportMutation, exportDates, addRemovedOrders])

  return (
    <Modal
      open={isVisible}
      title={'Select dates range'}
      width={350}
      onCancel={handleCancel}
      footer={false}
    >
      <>
        {startExportMutation.isLoading && <PreloaderBlock asOverlay={true} />}
        <Row gutter={[15, 20]}>
          <Col span={24}>
            <RangePicker
              clearIcon={<Icon size={20} icon={ICONS.CLOSE} />}
              format={DATE_FORMAT}
              value={exportDates}
              onChange={(dates) => {
                setExportDates([dates[0].startOf('day'), dates[1].endOf('day')])
              }}
              allowClear={false}
              disabledDate={(current) => current > todayEnd}
            />
          </Col>
          <Col span={24}>
            <Checkbox
              name={'addRemovedOrders'}
              checked={addRemovedOrders}
              onChange={(event) => setAddRemovedOrders(event.target.checked)}
            >
              Add removed orders
            </Checkbox>
          </Col>
          <Col span={24} style={{ display: 'flex', justifyContent: 'center' }}>
            <Button disabled={!exportDates.length} onClick={submitHandler}>
              Export
            </Button>
          </Col>
        </Row>
      </>
    </Modal>
  )
}

export default ExportOrdersModal
