import {
  GET_ORDERS,
  GET_ORDER,
  UPDATE_ORDER,
  CREATE_ORDER,
  DELETE_ORDER,
  GET_ORDERS_REQUEST_PENDING,
  GET_ORDERS_REQUEST_SUCCESS,
  GET_ORDERS_REQUEST_ERROR,
  CHANGE_ORDERS_REQUEST_PENDING,
  CHANGE_ORDERS_REQUEST_SUCCESS,
  CHANGE_ORDERS_REQUEST_ERROR,
  CHANGE_PAGINATION,
  CHANGE_ORDERS_SEARCH,
  CHANGE_ORDERS_SORT_TYPE,
  CHANGE_ORDERS_SORT_ORDER,
  CHANGE_ORDER_IS_SAP_SYNCED_STATUS,
} from '../types/orders'

const initialState = {
  items: [],
  pagination: {
    current: 1,
    pageSize: 20,
  },
  search: '',
  sortType: 'CREATED_DATE',
  sortOrder: 'DESC',
  item: {},
  isGetLoading: false,
  isChangeLoading: false,
  errors: [],
}

const propertiesKeyMap = {
  orderNumber: 'ORDER_NUMBER',
  customerName: 'CUSTOMER_NAME',
  mobile: 'MOBILE',
  address: 'ADDRESS',
  deliveryStatus: 'DELIVERY_STATUS',
  paymentStatus: 'PAYMENT_STATUS',
  paymentMethod: 'PAYMENT_METHOD',
  subTotalAmount: 'SUB_TOTAL_AMOUNT',
  totalAmount: 'TOTAL_AMOUNT',
  createdDate: 'CREATED_DATE',
}

const adaptSortType = (antTableType) => {
  return propertiesKeyMap[antTableType] ? propertiesKeyMap[antTableType] : antTableType
}

const adaptSortOrder = (antTableOrder) => {
  switch (antTableOrder) {
    case 'ascend':
      return 'ASC'
    case 'descend':
      return 'DESC'
    default:
      return antTableOrder
  }
}

const orders = (state = initialState, action) => {
  switch (action.type) {
    case GET_ORDERS:
      return { ...state, ...action.payload }
    case GET_ORDER:
      return { ...state, item: action.payload }
    case CHANGE_PAGINATION:
      return { ...state, pagination: action.payload }
    case CHANGE_ORDERS_SEARCH:
      return { ...state, search: action.payload }
    case CHANGE_ORDERS_SORT_TYPE:
      return { ...state, sortType: adaptSortType(action.payload) }
    case CHANGE_ORDERS_SORT_ORDER:
      return { ...state, sortOrder: adaptSortOrder(action.payload) }
    case GET_ORDERS_REQUEST_PENDING:
      return { ...state, isGetLoading: true }
    case GET_ORDERS_REQUEST_SUCCESS:
      return { ...state, errors: [], isGetLoading: false }
    case GET_ORDERS_REQUEST_ERROR:
      return { ...state, errors: action.payload, isGetLoading: false }
    case CHANGE_ORDER_IS_SAP_SYNCED_STATUS:
      return {
        ...state,
        items: state.items.map((order) => {
          return {
            ...order,
            isSapSynced:
              order.id === action.payload.orderId ? action.payload.isSapSynced : order.isSapSynced,
          }
        }),
        item: {
          ...state.item,
          isSapSynced:
            state.item.id === action.payload.orderId
              ? action.payload.isSapSynced
              : state.item.isSapSynced,
        },
      }
    default:
      return state
  }
}

export default orders
