import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import Page from 'components/Page'
import { getRatingsMessage, updateRatingsMessage } from 'redux/actions/rating'
import RatingMessageForm from 'components/forms/RatingMessageForm'

const RatingView = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  useEffect(() => {
    dispatch(getRatingsMessage())
  }, [dispatch])

  const { item: message, isGetLoading, isChangeLoading } = useSelector((state) => state.rating)

  const submitHandler = ({
    messId: id,
    title,
    subtitle,
    arabianTitle,
    arabianSubtitle,
    isActive,
  }) => {
    dispatch(
      updateRatingsMessage({
        data: {
          id,
          title,
          subtitle,
          arabianTitle: arabianTitle || title,
          arabianSubtitle: arabianSubtitle || subtitle,
          isActive,
        },
      }),
    )
  }

  return (
    <Page
      pageTitle={t('ratings.rating message')}
      isGetLoading={isGetLoading}
      isChangeLoading={isChangeLoading}
      // deleteHandler={() => deleteHandler(id)}
    >
      <RatingMessageForm
        defaultValues={message}
        loading={isChangeLoading}
        // products={products}
        submitHandler={(data) => submitHandler(data)}
      />
    </Page>
  )
}

export default RatingView
