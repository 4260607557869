import moment from 'moment'
import * as yup from 'yup'
import { notify } from 'reapop'
import { useCallback, useMemo, useEffect } from 'react'
import { yupResolver } from '@hookform/resolvers/yup/dist/yup'
import { useForm, FormProvider } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import { useMutation } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'
import { Col, Row } from 'antd'
import Button from 'components/Button'
import HookedField from 'components/HookedField'
import BannersService from 'redux/middlewares/banners'
import { DATE_TIME_FORMAT } from 'utils/constants'

const BannerForm = ({ banner, submitCallback }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const defaultFormValues = useMemo(() => {
    return {
      title: banner.product.title,
      validFrom: banner.validFrom ? banner.validFrom : null,
      validTo: banner.validTo ? banner.validTo : null,
      oldPrice: banner.oldPrice ? banner.oldPrice : null,
    }
  }, [banner])

  // Setup hooked form
  const methods = useForm({
    mode: 'onSubmit',
    shouldFocusError: true,
    defaultValues: defaultFormValues,
    resolver: yupResolver(
      yup.object().shape({
        title: yup.string(),
        validFrom: yup.date().required('This field is required').nullable(),
        validTo: yup.date().when(['validFrom'], (validFrom) => {
          if (validFrom) {
            return yup
              .date()
              .min(
                moment(validFrom).add(1, 'minute'),
                'Valid to date must be later than ' +
                  moment(validFrom).add(1, 'minute').format(DATE_TIME_FORMAT),
              )
              .required('This field is required')
              .nullable()
          }

          return yup.date().required('This field is required').nullable()
        }),
        oldPrice: yup.number().min(1).notRequired().nullable(),
      }),
    ),
  })

  const updateBannerMutation = useMutation(BannersService.updateBanner, {
    onSuccess: (response) => {
      dispatch(notify({ title: t('notifs.updated'), status: 'success', dismissAfter: 4000 }))
      submitCallback && submitCallback(response.data.data)
    },
    onError: () => {
      dispatch(notify({ title: t('notifs.error title'), status: 'error', dismissAfter: 4000 }))
    },
  })

  // Form submit handler
  const submitHandler = useCallback(
    (formData) => {
      updateBannerMutation.mutate({
        bannerId: banner.id,
        data: {
          validFrom: formData.validFrom ? formData.validFrom.toISOString() : null,
          validTo: formData.validTo ? formData.validTo.toISOString() : null,
          oldPrice: formData.oldPrice ? formData.oldPrice : null,
        },
      })
    },
    [banner, updateBannerMutation],
  )

  // Reset form values if role property was changed
  useEffect(() => {
    methods.reset(defaultFormValues)
  }, [banner, defaultFormValues, methods])

  return (
    <div>
      <FormProvider {...methods}>
        <form className="form" autoComplete="off" onSubmit={methods.handleSubmit(submitHandler)}>
          <Row gutter={[15, 10]}>
            <Col span={24}>
              <HookedField
                label={t('fields.name')}
                name="title"
                placeholder={t('fields.name')}
                fieldType="textField"
                type="text"
                disabled={true}
              />
            </Col>
            <Col span={12}>
              <HookedField
                label={t('fields.valid from')}
                name="validFrom"
                placeholder={t('fields.set valid from')}
                disabledDate={(current) => current && current < moment().startOf('day')}
                isRequired={true}
                fieldType="dateTimeField"
                type="dateTimeField"
              />
            </Col>
            <Col span={12}>
              <HookedField
                label={t('fields.valid to')}
                name="validTo"
                placeholder={t('fields.set valid to')}
                disabledDate={(current) => current && current < moment().startOf('day')}
                isRequired={true}
                fieldType="dateTimeField"
                type="dateTimeField"
              />
            </Col>
            <Col span={24}>
              <HookedField
                label={t('fields.old price')}
                placeholder={t('fields.set old price')}
                name="oldPrice"
                min={1}
                fieldType="numberField"
                type="number"
              />
            </Col>
            <Col span={24} style={{ textAlign: 'center' }}>
              <Button
                className="save-btn"
                type="primary"
                size="large"
                htmlType="submit"
                disabled={updateBannerMutation.isLoading}
              >
                {t('app.save')}
              </Button>
            </Col>
          </Row>
        </form>
      </FormProvider>
    </div>
  )
}

export default BannerForm
