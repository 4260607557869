import moment from 'moment'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { useState, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import useClient from 'hooks/useClient'
import PageTable from 'components/PageTable'
import { Popconfirm } from 'antd'
import { deletePromocode, getPromocodes } from 'api/promocodes'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import SortableColumnTitle from 'components/SortableColumnTitle'
import Button from 'components/Button'
import Icon from 'components/Icon'
import {
  DATE_TIME_FORMAT,
  ICONS,
  PERMISSIONS,
  PROMOCODE_STATUSES,
  PROMOCODE_TYPES,
} from 'utils/constants'
import { notify } from 'reapop'
import axios from 'axios'
import './styles.scss'

const Promocodes = () => {
  const { t } = useTranslation()
  const { isClientCan } = useClient()
  const queryClient = useQueryClient()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [pageSize, setPageSize] = useState(20)
  const [currentPage, setCurrentPage] = useState(1)
  const [search, setSearch] = useState('')
  const [sortType, setSortType] = useState('PROMO_CODE_NAME')
  const [sortOrder, setSortOrder] = useState('DESC')
  const [filterByStatus, setFilterByStatus] = useState(null)

  const isClientCanCreatePromoCodes = isClientCan(PERMISSIONS.CREATE_PROMO_CODES)
  const isClientCanDeletePromoCodes = isClientCan(PERMISSIONS.DELETE_PROMO_CODES)
  const isClientCanUpdatePromoCodes = isClientCan(PERMISSIONS.UPDATE_PROMO_CODES)

  const { isLoading, isFetching, data } = useQuery(
    ['promocodesTable', pageSize, currentPage, sortType, sortOrder, search, filterByStatus],
    async () =>
      await getPromocodes(pageSize, currentPage, sortType, sortOrder, search, filterByStatus),
  )

  const deletePromocodeMutation = useMutation(deletePromocode, {
    onSuccess: () => {
      queryClient.invalidateQueries(['promocodesTable'])
      dispatch(notify({ title: t('notifs.deleted'), status: 'success', dismissAfter: 4000 }))
    },
    onError: (error) =>
      axios.isAxiosError(error) &&
      dispatch(notify({ title: error.message, status: 'error', dismissAfter: 4000 })),
  })

  const propertiesKeyMap = {
    pc_col_title: 'TITLE',
    pc_col_promoCodeName: 'PROMO_CODE_NAME',
    pc_col_startDate: 'START_DATE',
    pc_col_finishDate: 'FINISH_DATE',
    pc_col_isActive: 'STATUS',
  }

  const adaptSortOrder = (antTableOrder) => {
    if (antTableOrder === 'ascend') return 'ASC'
    if (antTableOrder === 'descend') return 'DESC'
    return null
  }

  const columns = useMemo(() => {
    const promoCodesColumns = []
    promoCodesColumns.push({
      title: '№',
      dataIndex: 'idxNumber',
      key: 'idxNumber',
      align: 'center',
      width: '30px',
      render: (text, record, index) => index + 1,
    })
    promoCodesColumns.push({
      title: t('promocodes.field id column'),
      dataIndex: 'id',
      key: 'id',
      align: 'center',
    })
    promoCodesColumns.push({
      title: (props) => (
        <SortableColumnTitle
          {...props}
          title={t('promocodes.field title column')}
          id="pc_col_title"
        />
      ),
      dataIndex: 'title',
      key: 'pc_col_title',
      sorter: true,
    })
    promoCodesColumns.push({
      title: (props) => (
        <SortableColumnTitle
          {...props}
          title={t('promocodes.field promoCodeName column')}
          id="pc_col_promoCodeName"
        />
      ),
      dataIndex: 'promoCodeName',
      key: 'pc_col_promoCodeName',
      sorter: true,
      defaultSortOrder: 'descend',
    })
    promoCodesColumns.push({
      title: t('promocodes.field discountType column'),
      dataIndex: 'discountType',
      key: 'discountType',
      render: (discountType, record) => {
        const cellHTML =
          record.discountQuantity +
          (discountType === PROMOCODE_TYPES.MONEY.KEY && '&nbsp;') +
          PROMOCODE_TYPES[discountType].LABEL
        return <span dangerouslySetInnerHTML={{ __html: cellHTML }}></span>
      },
    })
    promoCodesColumns.push({
      title: t('promocodes.field minOrderValue column'),
      dataIndex: 'minOrderValue',
      key: 'minOrderValue',
      render: (minOrderValue) => {
        const cellHTML = minOrderValue + '&nbsp;' + PROMOCODE_TYPES.MONEY.LABEL
        return <span dangerouslySetInnerHTML={{ __html: cellHTML }}></span>
      },
    })
    promoCodesColumns.push({
      title: t('promocodes.field fullUsageCount column'),
      dataIndex: 'fullUsageCount',
      key: 'fullUsageCount',
      render: (fullUsageCount, record) =>
        (!isNaN(parseInt(record?.currentUsageCount)) ? record.currentUsageCount : '?') +
        '/' +
        fullUsageCount,
    })
    promoCodesColumns.push({
      title: t('promocodes.field productId column'),
      dataIndex: 'productPromoCode',
      key: 'productPromoCode',
      render: (productPromoCode) => {
        const cellHTML =
          Array.isArray(productPromoCode) && productPromoCode?.length
            ? productPromoCode.length + '&nbsp;' + t('fields.products')
            : t('products.all products')
        return <span dangerouslySetInnerHTML={{ __html: cellHTML }}></span>
      },
    })
    promoCodesColumns.push({
      title: (props) => (
        <SortableColumnTitle
          {...props}
          title={t('promocodes.field status column')}
          id="pc_col_isActive"
        />
      ),
      dataIndex: 'isActive',
      key: 'pc_col_isActive',
      render: (isActive, record) => {
        if (!isActive) {
          return <span>🔴 {t('promocodes.status inactive')}</span>
        } else {
          const now = moment()
          const startDate = moment(record.startDate)
          const finishDate = moment(record.finishDate)

          if (now.diff(startDate) < 0) {
            return <span>🟡 {t('promocodes.status awaiting')}</span>
          } else if (now.diff(finishDate) < 0) {
            return <span>🟢 {t('promocodes.status active')}</span>
          } else {
            return <span>🟡 {t('promocodes.status expired')}</span>
          }
        }
      },
      filters: [
        { text: PROMOCODE_STATUSES.IN_PROGRESS, value: 'IN_PROGRESS' },
        { text: PROMOCODE_STATUSES.INACTIVE, value: 'INACTIVE' },
        { text: PROMOCODE_STATUSES.EXPIRED, value: 'EXPIRED' },
        { text: PROMOCODE_STATUSES.AWAITING, value: 'AWAITING' },
      ],
      filterMultiple: false,
      sorter: true,
    })
    promoCodesColumns.push({
      title: (props) => (
        <SortableColumnTitle
          {...props}
          title={t('promocodes.field startDate column')}
          id="pc_col_startDate"
        />
      ),
      dataIndex: 'startDate',
      key: 'pc_col_startDate',
      render: (startDate) => moment(startDate).format(DATE_TIME_FORMAT),
      sorter: true,
    })
    promoCodesColumns.push({
      title: (props) => (
        <SortableColumnTitle
          {...props}
          title={t('promocodes.field finishDate column')}
          id="pc_col_finishDate"
        />
      ),
      dataIndex: 'finishDate',
      key: 'finishDate',
      render: (finishDate) => moment(finishDate).format(DATE_TIME_FORMAT),
      sorter: true,
    })
    if (isClientCanDeletePromoCodes) {
      promoCodesColumns.push({
        title: t('fields.actions'),
        dataIndex: 'actions',
        key: 'actions',
        render: (_, record) => {
          if (record.currentUsageCount !== 0) {
            return <></>
          }

          return (
            <div className={'deletePromocodeIconWrap'}>
              <Popconfirm
                title={'Are you sure?'}
                onConfirm={(e) => {
                  e.stopPropagation()
                  deletePromocodeMutation.mutate(record.id)
                }}
                onCancel={(e) => e.stopPropagation()}
                okText="Delete"
                okType="danger"
                cancelText="No"
              >
                <Icon
                  className={'deletePromocodeIcon'}
                  icon={ICONS.TRASH}
                  size={20}
                  onClick={(e) => e.stopPropagation()}
                />
              </Popconfirm>
            </div>
          )
        },
      })
    }
    return promoCodesColumns
  }, [isClientCanDeletePromoCodes, deletePromocodeMutation, t])

  const tableChangeHandler = (pagination, filter, sorter) => {
    setPageSize(pagination.pageSize)
    setCurrentPage(pageSize === pagination.pageSize ? pagination.current : 1)
    setSortType(propertiesKeyMap[sorter.columnKey] || null)
    setSortOrder(adaptSortOrder(sorter.order))
    setFilterByStatus(filter?.pc_col_isActive ? filter.pc_col_isActive[0] : null)
  }

  return (
    <PageTable
      pageTitle={t('promocodes.title')}
      action={
        isClientCanCreatePromoCodes ? (
          <Button type="primary" onClick={() => navigate(`/main/promocodes/add`)}>
            <Icon icon={ICONS.PLUS} className="btn-icon-suffix" />
            <span className="btn-text">{t('promocodes.new promocode')}</span>
          </Button>
        ) : null
      }
      baseData={data?.result || []}
      onRow={
        isClientCanUpdatePromoCodes
          ? (record) => ({
              onClick: () => navigate(`/main/promocodes/view/${record.id}`),
            })
          : null
      }
      columns={columns}
      loading={isLoading || isFetching}
      pagination={{
        pageSize,
        current: currentPage,
        total: data?.total,
        showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
        showSizeChanger: data?.total >= 10 || true,
      }}
      onChange={tableChangeHandler}
      searchHandler={(value) => setSearch(value.trim())}
      sortDirections={['ascend', 'descend', 'ascend']}
    />
  )
}

export default Promocodes
