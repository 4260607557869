import moment from 'moment'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import SortableColumnTitle from 'components/SortableColumnTitle'
import PageTable from 'components/PageTable'
import Button from 'components/Button'
import Icon from 'components/Icon'
import { DATE_TIME_FORMAT, ICONS, PERMISSIONS } from 'utils/constants'
import { useState } from 'react'
import { useQuery } from '@tanstack/react-query'
import RatingService from 'redux/middlewares/rating'
import useClient from 'hooks/useClient'

const Rating = () => {
  const { t } = useTranslation()
  const { isClientCan } = useClient()
  const navigate = useNavigate()
  const [pageSize, setPageSize] = useState(20)
  const [currentPage, setCurrentPage] = useState(1)
  const [sortType, setSortType] = useState(null)
  const [sortOrder, setSortOrder] = useState(null)

  const isClientCanUpdateRating = isClientCan(PERMISSIONS.UPDATE_RATING)

  const { isLoading, isFetching, data } = useQuery(
    ['ratingTable', pageSize, currentPage, sortType, sortOrder],
    async () => {
      const response = await RatingService.getRatings(pageSize, currentPage, sortType, sortOrder)
      return response.data.data
    },
  )

  const tableChangeHandler = (pagination, filter, sorter) => {
    setPageSize(pagination.pageSize)
    setCurrentPage(pagination.current)
    setSortType(propertiesKeyMap[sorter.columnKey] || null)
    setSortOrder(adaptSortOrder(sorter.order))
  }

  const propertiesKeyMap = {
    rating_col_fullName: 'FULL_NAME',
    rating_col_email: 'EMAIL',
    rating_col_updatedAt: 'DATE',
  }

  const adaptSortOrder = (antTableOrder) => {
    if (antTableOrder === 'ascend') return 'ASC'
    if (antTableOrder === 'descend') return 'DESC'
    return null
  }

  const columns = [
    {
      title: '№',
      dataIndex: 'id',
      render: (text, record, index) => index + 1,
      align: 'center',
      key: 'id',
      width: 53,
    },
    {
      title: t('ratings.rating message'),
      dataIndex: 'messageTitle',
      key: 'messageTitle',
      width: 208,
    },
    {
      title: t('ratings.rate'),
      dataIndex: 'rating',
      key: 'rating',
      width: 85,
    },
    {
      title: (props) => (
        <SortableColumnTitle {...props} title={t('fields.name en')} id="rating_col_fullName" />
      ),
      dataIndex: ['user', 'fullName'],
      key: 'rating_col_fullName',
      width: 186,
      sorter: true,
    },
    {
      title: (props) => (
        <SortableColumnTitle {...props} title={t('fields.email')} id="rating_col_email" />
      ),
      dataIndex: ['user', 'email'],
      key: 'rating_col_email',
      width: 304,
      sorter: true,
    },
    {
      title: (props) => (
        <SortableColumnTitle {...props} title={t('fields.date')} id="rating_col_updatedAt" />
      ),
      dataIndex: 'updatedAt',
      key: 'rating_col_updatedAt',
      width: 231,
      render: (updatedAt) => updatedAt && moment(updatedAt).format(DATE_TIME_FORMAT),
      sorter: true,
    },
  ]

  return (
    <PageTable
      pageTitle={t('ratings.title')}
      action={
        isClientCanUpdateRating ? (
          <Button type="primary" onClick={() => navigate(`/main/rating/massage`)}>
            <Icon icon={ICONS.PLUS} className="btn-icon-suffix" />
            <span className="btn-text">{t('ratings.view rating message')}</span>
          </Button>
        ) : null
      }
      baseData={data?.result || []}
      columns={columns}
      loading={isLoading || isFetching}
      pagination={{
        pageSize,
        current: currentPage,
        total: data?.total,
        showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
        showSizeChanger: data?.total >= 10 || true,
      }}
      onChange={tableChangeHandler}
    />
  )
}

export default Rating
