import { useEffect } from 'react'
import useClient from 'hooks/useClient'
import { useTranslation } from 'react-i18next'
import { FormProvider, useForm } from 'react-hook-form'
import HookedField from 'components/HookedField'
import Button from 'components/Button'
import { PERMISSIONS } from 'utils/constants'

const CouponForm = ({ defaultValues = {}, submitHandler, loading, products }) => {
  const { t } = useTranslation()
  const { isClientCan } = useClient()

  const methods = useForm({
    mode: 'onSubmit',
    shouldFocusError: true,
  })
  const isClientCanUpdateCoupons = isClientCan(PERMISSIONS.UPDATE_COUPONS)

  useEffect(() => {
    if (defaultValues?.product?.id) methods.setValue('productId', defaultValues.product.id)
  }, [defaultValues, products])

  return (
    <div className="productForm">
      <FormProvider {...methods}>
        <form className="form" autoComplete="off" onSubmit={methods.handleSubmit(submitHandler)}>
          <HookedField
            label="Product"
            name="productId"
            defaultValue={defaultValues?.product?.id || null}
            placeholder={t('fields.pack name en')}
            fieldType="selectField"
            type="select"
            options={products}
            isRequired={true}
            disabled={!!defaultValues.product || !isClientCanUpdateCoupons}
          />
          <div className="form-grid">
            <Button
              className="save-btn"
              size="large"
              type="primary"
              htmlType="submit"
              disabled={loading || !methods.formState.isDirty}
            >
              {t('app.save')}
            </Button>
          </div>
        </form>
      </FormProvider>
    </div>
  )
}

export default CouponForm
