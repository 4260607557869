import useClient from '../hooks/useClient'
import { ICONS } from '../utils/constants'
import Icon from '../components/Icon'
import LogoHorizontal from '../assets/images/logo-horizontal.svg'
import { Link } from 'react-router-dom'

const Header = () => {
  const { client } = useClient()

  return (
    <header className="header">
      <div className="wrap">
        <div className="logo">
          <img className="logo-img" src={LogoHorizontal} alt="ABRAAJ WATER" />
        </div>
        {client.data ? (
          <Link className="member" to={`/main/edit-user/${client.data.id}`}>
            {client.data.avatar?.src ? (
              <div className={'imageWrap'}>
                <img className={'image'} src={client.data.avatar?.src} alt={'user'} />
              </div>
            ) : (
              <Icon className="menuLink-icon" icon={ICONS.USER} size={24} />
            )}
            <span>{client.data.userName}</span>
          </Link>
        ) : null}
      </div>
    </header>
  )
}

export default Header
