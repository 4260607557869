import axios from 'axios'

export const getFlyers = async ({ take, page }) => {
  const hardcodeGetParams =
    'select=id&select=oldPrice&select=validFrom&select=validTo&select=isVisible'
  const response = await axios.get(`/fliers/admin/list?${hardcodeGetParams}`, {
    params: {
      take: take ? take : undefined,
      page: page ? page : undefined,
    },
  })

  return response.data.data
}

export const createFlyer = async ({ productId, oldPrice, validFrom, validTo, isVisible }) => {
  const response = await axios.post('/fliers/admin', {
    productId,
    oldPrice,
    validFrom,
    validTo,
    isVisible,
  })

  return response.data.data
}

export const updateFlyer = async ({
  id,
  productId,
  oldPrice,
  validFrom,
  validTo,
  isVisible,
  index,
}) => {
  const response = await axios.put(`/fliers/admin/${id}`, {
    productId,
    oldPrice,
    validFrom,
    validTo,
    isVisible,
    index,
  })

  return response.data.data
}

export const deleteFlyer = async (id) => {
  const response = await axios.delete(`/fliers/admin/${id}`)
  return response.data.data
}
