export const GET_CUSTOMERS_REQUEST = 'GET_CUSTOMERS_REQUEST'
export const GET_CUSTOMERS = 'GET_CUSTOMERS'

export const GET_CUSTOMER_REQUEST = 'GET_CUSTOMER_REQUEST'
export const GET_CUSTOMER = 'GET_CUSTOMER'

export const GET_CUSTOMERS_REQUEST_PENDING = 'GET_CUSTOMERS_REQUEST_PENDING'
export const GET_CUSTOMERS_REQUEST_SUCCESS = 'GET_CUSTOMERS_REQUEST_SUCCESS'
export const GET_CUSTOMERS_REQUEST_ERROR = 'GET_CUSTOMERS_REQUEST_ERROR'

export const UPDATE_CUSTOMER_REQUEST = 'UPDATE_CUSTOMER_REQUEST'
export const UPDATE_CUSTOMER = 'UPDATE_CUSTOMER'

export const CREATE_CUSTOMER_REQUEST = 'CREATE_CUSTOMER_REQUEST'
export const CREATE_CUSTOMER = 'CREATE_CUSTOMER'

export const DELETE_CUSTOMER_REQUEST = 'DELETE_CUSTOMER_REQUEST'
export const DELETE_CUSTOMER = 'DELETE_CUSTOMER'

export const CHANGE_CUSTOMERS_REQUEST_PENDING = 'CHANGE_CUSTOMERS_REQUEST_PENDING'
export const CHANGE_CUSTOMERS_REQUEST_SUCCESS = 'CHANGE_CUSTOMERS_REQUEST_SUCCESS'
export const CHANGE_CUSTOMERS_REQUEST_ERROR = 'CHANGE_CUSTOMERS_REQUEST_ERROR'
