import moment from 'moment'
import { notify } from 'reapop'
import { useEffect, useMemo, useState } from 'react'
import { Button as AntButton, DatePicker, Input, InputNumber, Popconfirm } from 'antd'
import { default as CustomButton } from 'components/Button'
import { useTranslation } from 'react-i18next'
import { useMutation } from '@tanstack/react-query'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import useClient from 'hooks/useClient'
import {
  DATE_FORMAT,
  ICONS,
  ORDER_STATUSES,
  PAYMENT_METHODS,
  PAYMENT_STATUSES,
  PERMISSIONS,
} from 'utils/constants'
import {
  getOrders,
  setOrdersSearch,
  setOrdersSortOrder,
  setOrdersSortType,
  setPagination,
  setOrderIsSapSyncedStatus,
} from 'redux/actions/orders'
import SortableColumnTitle from 'components/SortableColumnTitle'
import PageTable from 'components/PageTable'
import { isObjectsEqual, roundPrice } from 'utils/functions'
import ExportOrdersModal from 'components/ExportOrdersModal'
import Icon from 'components/Icon'
import OrdersService from 'redux/middlewares/orders'

const { RangePicker } = DatePicker

const Orders = () => {
  const { t } = useTranslation()
  const { isClientCan } = useClient()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [isExportModalVisible, setIsExportModalVisible] = useState(false)
  const [isNeedLoadData, setIsNeedLoadData] = useState(false)
  const [tableFilter, setTableFilter] = useState({
    paymentMethod: null,
    subTotalAmount: null,
    createdDate: null,
  })
  const [createdDatesFilter, setCreatedDatesFilter] = useState(null)
  const [subTotalAmountMinFilter, setSubTotalAmountMinFilter] = useState(null)
  const [subTotalAmountMaxFilter, setSubTotalAmountMaxFilter] = useState(null)
  const [sendingInSapOrderId, setSendingInSapOrderId] = useState(null)
  const { items, pagination, search, sortType, sortOrder, isGetLoading } = useSelector(
    (state) => state.orders,
  )

  const isClientCanSendOrders = isClientCan(PERMISSIONS.SEND_ORDERS)

  const refreshOrders = () => {
    dispatch(
      getOrders({
        page: pagination.current,
        size: pagination.pageSize,
        search,
        filters: tableFilter,
        sortType,
        sortOrder,
      }),
    )

    setIsNeedLoadData(false)
  }

  const sendOrderToSapMutation = useMutation(OrdersService.sendOrderToSap, {
    onSuccess: (mutationData) => {
      if (mutationData?.data?.data?.result) {
        if (mutationData.data.data.result === 'Success') {
          dispatch(
            notify({ title: t('notifs.order sent to SAP'), status: 'success', dismissAfter: 4000 }),
          )
        } else {
          dispatch(
            notify({ title: mutationData.data.data.result, status: 'warning', dismissAfter: 4000 }),
          )
        }
      }
    },
    onError: () => {
      dispatch(
        notify({
          title: t('notifs.error sending order to SAP'),
          status: 'error',
          dismissAfter: 4000,
        }),
      )
    },
  })

  const isHasNotSyncedOrders = useMemo(() => {
    let isHasNotSyncedOrders = false

    items.forEach((order) => {
      if (order.isSapSynced === false) {
        isHasNotSyncedOrders = true
      }
    })

    return isHasNotSyncedOrders
  }, [items])

  useEffect(() => {
    if (pagination.current !== 1) dispatch(setPagination({ ...pagination, current: 1 }))
    setIsNeedLoadData(true)
  }, [tableFilter, search])

  useEffect(() => {
    setIsNeedLoadData(true)
  }, [tableFilter, search, sortType, sortOrder, pagination.current, pagination.pageSize])

  useEffect(() => {
    isNeedLoadData && refreshOrders()
  }, [isNeedLoadData])

  const preparedSubTotalAmountFilter = useMemo(() => {
    const isMinValid = !isNaN(parseInt(subTotalAmountMinFilter))
    const isMaxValid = !isNaN(parseInt(subTotalAmountMaxFilter))

    if (!isMinValid && !isMaxValid) {
      return null
    }

    const formattedData = []
    formattedData.push(isMinValid ? parseInt(subTotalAmountMinFilter) : null)
    formattedData.push(isMaxValid ? parseInt(subTotalAmountMaxFilter) : null)

    return formattedData
  }, [subTotalAmountMinFilter, subTotalAmountMaxFilter])

  const columns = [
    {
      title: '№',
      dataIndex: 'id',
      key: 'id',
      width: '35px',
      align: 'center',
    },
    {
      title: (props) => (
        <SortableColumnTitle {...props} title={t('fields.order number')} id="orderNumber" />
      ),
      dataIndex: 'orderNumber',
      key: 'orderNumber',
      sorter: true,
    },
    {
      title: (props) => (
        <SortableColumnTitle {...props} title={t('fields.customer name')} id="customerName" />
      ),
      dataIndex: ['user', 'fullName'],
      key: 'customerName',
      sorter: true,
    },
    {
      title: (props) => <SortableColumnTitle {...props} title={t('fields.mobile')} id="mobile" />,
      dataIndex: ['user', 'phoneNumber'],
      key: 'mobile',
      sorter: true,
    },
    {
      title: (props) => <SortableColumnTitle {...props} title={t('fields.address')} id="address" />,
      dataIndex: ['deliveryAddress', 'addressName'],
      key: 'address',
      sorter: true,
    },
    {
      title: (props) => (
        <SortableColumnTitle {...props} title={t('fields.delivery status')} id="deliveryStatus" />
      ),
      dataIndex: 'status',
      key: 'deliveryStatus',
      sorter: true,
      render: (status) => status && ORDER_STATUSES[status],
    },
    {
      title: (props) => (
        <SortableColumnTitle {...props} title={t('fields.payment status')} id="paymentStatus" />
      ),
      dataIndex: 'paymentStatus',
      key: 'paymentStatus',
      sorter: true,
      render: (paymentStatus) => paymentStatus && PAYMENT_STATUSES[paymentStatus],
    },
    {
      title: (props) => (
        <SortableColumnTitle {...props} title={t('fields.payment method')} id="paymentMethod" />
      ),
      dataIndex: 'paymentMethod',
      key: 'paymentMethod',
      sorter: true,
      filters: [
        { text: PAYMENT_METHODS.CASH, value: 'CASH' },
        { text: PAYMENT_METHODS.KNET, value: 'KNET' },
        { text: PAYMENT_METHODS.COUPON, value: 'COUPON' },
        { text: PAYMENT_METHODS.APPLE_PAY, value: 'APPLE_PAY' },
        { text: PAYMENT_METHODS.CREDIT_CARD, value: 'CREDIT_CARD' },
      ],
      render: (paymentMethod) => paymentMethod && PAYMENT_METHODS[paymentMethod],
    },
    {
      title: (props) => (
        <SortableColumnTitle {...props} title={t('fields.total amount')} id="subTotalAmount" />
      ),
      dataIndex: 'subTotalAmount',
      key: 'subTotalAmount',
      sorter: true,
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <>
          <div className={'container'}>
            <Input.Group compact>
              <InputNumber
                min={0}
                placeholder="min"
                value={subTotalAmountMinFilter}
                onChange={(value) => setSubTotalAmountMinFilter(value)}
              />
              <InputNumber
                className="site-input-right"
                min={0}
                formatter={(value) => `${value}`.replace(/\D/g, '')}
                placeholder="max"
                value={subTotalAmountMaxFilter}
                onChange={(value) => setSubTotalAmountMaxFilter(value)}
              />
            </Input.Group>
          </div>
          <div className={'ant-table-filter-dropdown-btns'}>
            <AntButton
              type={'link'}
              size={'small'}
              onClick={() => {
                setSubTotalAmountMinFilter(null)
                setSubTotalAmountMaxFilter(null)
              }}
            >
              Reset
            </AntButton>
            <AntButton
              type={'primary'}
              size={'small'}
              onClick={() => {
                setSelectedKeys(preparedSubTotalAmountFilter)
                confirm({ closeDropdown: true })
              }}
            >
              OK
            </AntButton>
          </div>
        </>
      ),
      render: (subTotalAmount) => roundPrice(subTotalAmount),
    },
    {
      title: (props) => (
        <SortableColumnTitle {...props} title={t('fields.created date')} id="createdDate" />
      ),
      dataIndex: 'createdAt',
      key: 'createdDate',
      sorter: true,
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <>
          <div className={'container'}>
            <RangePicker
              clearIcon={<Icon size={20} icon={ICONS.CLOSE} />}
              format={DATE_FORMAT}
              value={createdDatesFilter}
              onChange={(dates) => {
                setCreatedDatesFilter([dates[0].startOf('day'), dates[1].endOf('day')])
              }}
            />
          </div>
          <div className={'ant-table-filter-dropdown-btns'}>
            <AntButton
              type={'link'}
              size={'small'}
              disabled={!createdDatesFilter}
              onClick={() => setCreatedDatesFilter(null)}
            >
              Reset
            </AntButton>
            <AntButton
              type={'primary'}
              size={'small'}
              onClick={() => {
                if (createdDatesFilter !== null) {
                  setSelectedKeys([
                    createdDatesFilter[0].toISOString(),
                    createdDatesFilter[1].toISOString(),
                  ])
                } else {
                  setSelectedKeys(null)
                }
                confirm({ closeDropdown: true })
              }}
            >
              OK
            </AntButton>
          </div>
        </>
      ),
      render: (date) => date && moment(date).format(DATE_FORMAT),
      defaultSortOrder: 'descend',
    },
  ]

  if (isHasNotSyncedOrders && isClientCanSendOrders) {
    columns.push({
      title: t('fields.actions'),
      dataIndex: 'actions',
      key: 'actions',
      width: 70,
      render: (_, record) => {
        return (
          !record.isSapSynced && (
            <div onClick={(e) => e.stopPropagation()}>
              <Popconfirm
                title={t('notifs.are you sure question')}
                onConfirm={() => {
                  setSendingInSapOrderId(record.id)

                  sendOrderToSapMutation.mutateAsync(record.orderNumber).then((mutationData) => {
                    if (mutationData?.data?.data?.result === 'Success') {
                      dispatch(
                        setOrderIsSapSyncedStatus({
                          orderId: record.id,
                          isSapSynced: true,
                        }),
                      )
                    }

                    setSendingInSapOrderId(null)
                  })
                }}
                okText={t('app.send')}
                okType="primary"
                cancelText={t('app.no')}
                disabled={sendOrderToSapMutation.isLoading}
              >
                <AntButton
                  type="danger"
                  loading={sendOrderToSapMutation.isLoading && sendingInSapOrderId === record.id}
                  size={'small'}
                >
                  {t('orders.send to sap')}
                </AntButton>
              </Popconfirm>
            </div>
          )
        )
      },
    })
  }

  const tableChangeHandler = (tablePagination, filter, sorter) => {
    setTableFilter((prevFilter) => (isObjectsEqual(prevFilter, filter) ? prevFilter : filter))
    dispatch(setOrdersSortType(sorter.columnKey))
    dispatch(setOrdersSortOrder(sorter.order))
    dispatch(setPagination(tablePagination))
  }

  return (
    <>
      <PageTable
        pageTitle={t('orders.title')}
        baseData={items}
        columns={columns}
        action={
          <>
            <CustomButton type="primary" onClick={() => setIsExportModalVisible(true)}>
              <Icon icon={ICONS.UPLOAD} size={24} color={'#ffffff'} />
              <span className="btn-text">{t('app.export')}</span>
            </CustomButton>
          </>
        }
        onRow={(record) => {
          return {
            onClick: () => {
              navigate(`/main/orders/view/${record.id}`)
            },
          }
        }}
        loading={isGetLoading}
        pagination={{
          ...pagination,
          showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
          showSizeChanger: pagination.total >= 10,
        }}
        onChange={tableChangeHandler}
        sortDirections={['ascend', 'descend', 'ascend']}
        searchHandler={(search) => dispatch(setOrdersSearch(search))}
      />
      <ExportOrdersModal
        isVisible={isExportModalVisible}
        handleCancel={() => setIsExportModalVisible(false)}
        fileDownloadedHandler={() => setIsExportModalVisible(false)}
      />
    </>
  )
}

export default Orders
