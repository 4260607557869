import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useMutation } from '@tanstack/react-query'
import { useNavigate } from 'react-router-dom'
import { FormProvider, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { Col, Row } from 'antd'
import { notify } from 'reapop'
import moment from 'moment'

import { campaignSchema } from '../../../utils/schemas'
import { createCampaign, updateCampaign } from '../../../api/campaigns'

import HookedField from '../../HookedField'
import Button from '../../Button'

import Icon from '../../Icon'
import { ICONS, PERMISSIONS } from '../../../utils/constants'

import styles from './CampaignForm.module.scss'
import useClient from '../../../hooks/useClient'

const CampaignForm = ({ defaultValues }) => {
  const { client, isClientCan } = useClient()
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const isClientCanUpdateCampaigns = isClientCan(PERMISSIONS.UPDATE_CAMPAIGN)

  const createCampaignMutation = useMutation(createCampaign, {
    onSuccess: () => {
      navigate('/main/campaigns')
      dispatch(notify({ title: t('notifs.success'), status: 'success', dismissAfter: 4000 }))
    },
    onError: (error) => {
      if (error?.response?.data?.messages?.length) {
        const errorMessage = error?.response?.data?.messages[0].message
        dispatch(
          notify({
            title: t(`campaigns.error.${errorMessage}`),
            status: 'error',
            dismissAfter: 4000,
          }),
        )
      } else {
        dispatch(
          notify({
            title: t('notifs.error title'),
            status: 'error',
            dismissAfter: 4000,
          }),
        )
      }
    },
  })

  const updateCampaignMutation = useMutation(updateCampaign, {
    onSuccess: () => {
      navigate('/main/campaigns')
      dispatch(
        notify({
          title: t('campaigns.messages.updated'),
          status: 'success',
          dismissAfter: 2000,
        }),
      )
    },
    onError: (error) => {
      if (error?.response?.data?.messages?.length) {
        const errorMessage = error?.response?.data?.messages[0].message
        dispatch(
          notify({
            title: t(`campaigns.error.${errorMessage}`),
            status: 'error',
            dismissAfter: 4000,
          }),
        )
      } else {
        dispatch(
          notify({
            title: t('campaigns.messages.updateFailed'),
            status: 'error',
            dismissAfter: 4000,
          }),
        )
      }
    },
  })

  const methods = useForm({
    mode: 'onSubmit',
    shouldFocusError: true,
    resolver: yupResolver(campaignSchema),
  })

  const submitHandler = useCallback(
    async (formData) => {
      if (!client.data.id) return

      const campaignMutation = defaultValues ? updateCampaignMutation : createCampaignMutation

      await campaignMutation.mutateAsync(formData)
    },
    [createCampaignMutation, updateCampaignMutation, client, defaultValues],
  )

  return (
    <div className={styles.campaignForm}>
      <FormProvider {...methods}>
        <form className="form" autoComplete="off" onSubmit={methods.handleSubmit(submitHandler)}>
          <div className="form-title">{t('campaigns.form.title')}</div>

          <HookedField
            name="campaignCreatorId"
            defaultValue={defaultValues ? defaultValues.campaignCreatorId : client?.data?.id}
            fieldType="hiddenField"
            type="hidden"
          />

          {defaultValues && (
            <HookedField
              name="id"
              defaultValue={defaultValues.id}
              fieldType="hiddenField"
              type="hidden"
            />
          )}

          <Row gutter={[20, 5]}>
            <Col span={24} lg={{ span: 12 }}>
              <HookedField
                disabled={!isClientCanUpdateCampaigns}
                fieldType="textField"
                isRequired
                label={t('campaigns.form.campaignName')}
                name="campaignName"
                placeholder={t('campaigns.form.campaignNamePlaceholder')}
                type="text"
                defaultValue={defaultValues?.campaignName || ''}
              />
            </Col>
            <Col span={24} lg={{ span: 12 }}>
              <HookedField
                disabled={Boolean(defaultValues)}
                fieldType="textField"
                isRequired
                label={t('campaigns.form.bonusAmount')}
                name="bonusAmount"
                placeholder={t('campaigns.form.bonusAmountPlaceholder')}
                type="text"
                defaultValue={defaultValues?.bonusAmount}
              />
            </Col>
            <Col span={24} lg={{ span: 12 }}>
              <HookedField
                disabled={!isClientCanUpdateCampaigns}
                disabledDate={(current) => current && current < moment().startOf('day')}
                fieldType="dateTimeField"
                isRequired
                label={t('campaigns.form.startDate')}
                name="startDate"
                placeholder={t('campaigns.form.startDatePlaceholder')}
                type="dateTimeField"
                defaultValue={defaultValues?.startDate}
              />
            </Col>
            <Col span={24} lg={{ span: 12 }}>
              <HookedField
                disabled={!isClientCanUpdateCampaigns}
                disabledDate={(current) => current && current < moment().startOf('day')}
                fieldType="dateTimeField"
                isRequired
                label={t('campaigns.form.finishDate')}
                name="finishDate"
                placeholder={t('campaigns.form.finishDatePlaceholder')}
                type="dateTimeField"
                defaultValue={defaultValues?.finishDate}
              />
            </Col>
            <Col span={24} lg={{ span: 12 }}></Col>
            <Col span={24} lg={{ span: 12 }}>
              <div className="form-visibility">
                <Icon className="form-visibility-icon" icon={ICONS.EYE} size={24} />
                <span className="form-visibility-text">{t('campaigns.form.isActive')}</span>
                <HookedField
                  disabled={!isClientCanUpdateCampaigns}
                  className="form-visibility-switch"
                  name="isActive"
                  fieldType="customField"
                  type="switch"
                  defaultValue={defaultValues?.isActive}
                />
              </div>
            </Col>
          </Row>
          <Row gutter={[20, 5]} className={styles.actions}>
            <Col span={24} lg={{ span: 12 }}>
              <Button
                className={styles.saveBtn}
                disabled={!methods.formState.isDirty}
                size="large"
                type="primary"
                htmlType="submit"
              >
                {t('app.save')}
              </Button>
            </Col>
          </Row>
        </form>
      </FormProvider>
    </div>
  )
}

export default CampaignForm
