export const GET_USERS_REQUEST = 'GET_USERS_REQUEST'
export const GET_USERS = 'GET_USERS'

export const UPDATE_USER_REQUEST = 'UPDATE_USER_REQUEST'
export const UPDATE_USER = 'UPDATE_USER'

export const CREATE_USER_REQUEST = 'CREATE_USER_REQUEST'
export const CREATE_USER = 'CREATE_USER'

export const DELETE_USER_REQUEST = 'DELETE_USER_REQUEST'
export const DELETE_USER = 'DELETE_USER'

export const USERS_REQUEST_PENDING = 'USERS_REQUEST_PENDING'
export const USERS_REQUEST_SUCCESS = 'USERS_REQUEST_SUCCESS'
export const USERS_REQUEST_ERROR = 'USERS_REQUEST_ERROR'
