import {
  GET_PROMOTIONS_REQUEST,
  GET_PROMOTION_REQUEST,
  UPDATE_PROMOTION_REQUEST,
  CREATE_PROMOTION_REQUEST,
  DELETE_PROMOTION_REQUEST,
  GET_SUP_PROMOTIONS_REQUEST,
} from '../types/promotions'

export function getPromotions(payload) {
  return {
    type: GET_PROMOTIONS_REQUEST,
    payload,
  }
}

export function getSupPromotions(payload) {
  return {
    type: GET_SUP_PROMOTIONS_REQUEST,
    payload,
  }
}

export function getPromotion(payload) {
  return {
    type: GET_PROMOTION_REQUEST,
    payload,
  }
}

export function updatePromotion(payload) {
  return {
    type: UPDATE_PROMOTION_REQUEST,
    payload,
  }
}

export function createPromotion(payload) {
  return {
    type: CREATE_PROMOTION_REQUEST,
    payload,
  }
}

export function deletePromotion(payload) {
  return {
    type: DELETE_PROMOTION_REQUEST,
    payload,
  }
}
