export const GET_ORDERS_REQUEST = 'GET_ORDERS_REQUEST'
export const GET_ORDERS = 'GET_ORDERS'

export const GET_ORDER_REQUEST = 'GET_ORDER_REQUEST'
export const GET_ORDER = 'GET_ORDER'

export const GET_ORDERS_REQUEST_PENDING = 'GET_ORDERS_REQUEST_PENDING'
export const GET_ORDERS_REQUEST_SUCCESS = 'GET_ORDERS_REQUEST_SUCCESS'
export const GET_ORDERS_REQUEST_ERROR = 'GET_ORDERS_REQUEST_ERROR'

export const UPDATE_ORDER_REQUEST = 'UPDATE_ORDER_REQUEST'
export const UPDATE_ORDER = 'UPDATE_ORDER'

export const CREATE_ORDER_REQUEST = 'CREATE_ORDER_REQUEST'
export const CREATE_ORDER = 'CREATE_ORDER'

export const DELETE_ORDER_REQUEST = 'DELETE_ORDER_REQUEST'
export const DELETE_ORDER = 'DELETE_ORDER'

export const CHANGE_ORDERS_REQUEST_PENDING = 'CHANGE_ORDERS_REQUEST_PENDING'
export const CHANGE_ORDERS_REQUEST_SUCCESS = 'CHANGE_ORDERS_REQUEST_SUCCESS'
export const CHANGE_ORDERS_REQUEST_ERROR = 'CHANGE_ORDERS_REQUEST_ERROR'

export const CHANGE_PAGINATION = 'CHANGE_PAGINATION'
export const CHANGE_ORDERS_SEARCH = 'CHANGE_ORDERS_SEARCH'
export const CHANGE_ORDERS_SORT_TYPE = 'CHANGE_ORDERS_SORT_TYPE'
export const CHANGE_ORDERS_SORT_ORDER = 'CHANGE_ORDERS_SORT_ORDER'
export const CHANGE_ORDER_IS_SAP_SYNCED_STATUS = 'CHANGE_ORDER_IS_SAP_SYNCED_STATUS'
