import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { createNotification } from '../../../redux/actions/notifications'
import { useNavigate } from 'react-router-dom'
import Page from '../../../components/Page'
import NotificationForm from '../../../components/forms/NotificationForm'

const NotificationsAdd = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { t } = useTranslation()

  const { isChangeLoading } = useSelector((state) => state.notifications)

  const submitHandler = (data) => {
    dispatch(
      createNotification({
        data,
        navigate,
      }),
    )
  }

  return (
    <Page pageTitle={t('notifications.new notification')} withCancelBtn={true}>
      <NotificationForm submitHandler={submitHandler} loading={isChangeLoading} />
    </Page>
  )
}

export default NotificationsAdd
