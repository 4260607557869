import { useEffect, useMemo } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useQuery, useMutation } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'
import { notify } from 'reapop'
import { useNavigate, useParams } from 'react-router-dom'
import { Popconfirm, Button as AntButton } from 'antd'
import { getOrder, setOrder, setOrderIsSapSyncedStatus } from 'redux/actions/orders'
import Page from 'components/Page'
import OrderForm from 'components/forms/OrderForm'
import OrdersService from 'redux/middlewares/orders'
import { getPromocodeById } from 'api/promocodes'

const OrdersView = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { id } = useParams()
  const navigate = useNavigate()
  const { item: order, isGetLoading, isChangeLoading } = useSelector((state) => state.orders)

  const { data: orderPromocode, isFetching: isOrderPromocodeFetching } = useQuery(
    [`getPromocode-${id}`],
    async () => await getPromocodeById(order.promoCodeId),
    {
      enabled: !!order.promoCodeId && order.promoCodeId !== 0,
    },
  )

  const sendOrderToSapMutation = useMutation(OrdersService.sendOrderToSap, {
    onSuccess: (mutationData) => {
      if (mutationData?.data?.data?.result) {
        if (mutationData.data.data.result === 'Success') {
          dispatch(
            notify({ title: t('notifs.order sent to SAP'), status: 'success', dismissAfter: 4000 }),
          )
        } else {
          dispatch(
            notify({ title: mutationData.data.data.result, status: 'warning', dismissAfter: 4000 }),
          )
        }
      }
    },
    onError: () => {
      dispatch(
        notify({
          title: t('notifs.error sending order to SAP'),
          status: 'error',
          dismissAfter: 4000,
        }),
      )
    },
  })

  useEffect(() => {
    dispatch(
      getOrder({
        id,
        navigate,
      }),
    )
    return () => {
      // clear redux on unmount component
      dispatch(setOrder({}))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const sendOrderToSapButton = useMemo(() => {
    return (
      order?.id &&
      !order.isSapSynced && (
        <Popconfirm
          title={t('notifs.are you sure question')}
          onConfirm={() => {
            sendOrderToSapMutation.mutateAsync(order.orderNumber).then((mutationData) => {
              if (mutationData?.data?.data?.result === 'Success') {
                dispatch(
                  setOrderIsSapSyncedStatus({
                    orderId: order.id,
                    isSapSynced: true,
                  }),
                )
              }
            })
          }}
          okText={t('app.send')}
          okType="primary"
          cancelText={t('app.no')}
          disabled={sendOrderToSapMutation.isLoading}
        >
          <AntButton type="danger" loading={sendOrderToSapMutation.isLoading} size={'large'}>
            {t('orders.send to sap')}
          </AntButton>
        </Popconfirm>
      )
    )
  }, [order, sendOrderToSapMutation, t])

  return (
    <>
      <Page
        pageTitle={`Order № ${order.orderNumber}`}
        isGetLoading={isGetLoading || isOrderPromocodeFetching}
        isChangeLoading={isChangeLoading}
        customActions={[sendOrderToSapButton]}
      >
        <OrderForm
          defaultValues={order}
          orderPromocode={orderPromocode}
          loading={isChangeLoading || isOrderPromocodeFetching}
        />
      </Page>
    </>
  )
}

export default OrdersView
