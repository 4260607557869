import { useTranslation } from 'react-i18next'

import Page from '../../../components/Page'
import CampaignForm from '../../../components/forms/CampaignForm/CampaignForm'

const CampaignsAdd = () => {
  const { t } = useTranslation()

  return (
    <Page pageTitle={t('campaigns.add.title')}>
      <CampaignForm />
    </Page>
  )
}

export default CampaignsAdd
