import JsPDF from 'jspdf'
import html2canvas from 'html2canvas'
import { useEffect, useState, useRef } from 'react'
import { Col, Row, Space } from 'antd'
import Button from 'components/Button'
import Icon from 'components/Icon'
import { ICONS } from 'utils/constants'
import { renderToString } from 'react-dom/server'
import { cloneNode } from 'utils/functions'

const DashboardToPDFButton = ({ onGenerateStart, onGenerateFinish }) => {
  const maxPDFWidth = 1540
  const maxPDFHeight = 945
  const paddingX = 15
  const paddingY = 10

  const iframeRef = useRef()
  const [iframeDoc, setIframeDoc] = useState(null)

  // Get iframe Document model
  useEffect(() => {
    if (iframeRef.current) {
      setIframeDoc(iframeRef.current.contentDocument || iframeRef.current.contentWindow.document)
    }
  }, [iframeRef])

  // Save global styles in iframe
  useEffect(() => {
    if (iframeDoc) {
      iframeDoc.head.innerHTML = document.head.innerHTML
    }
  }, [iframeDoc])

  const BasePageTemplate = ({ children }) => {
    return (
      <section className={'dashboard'}>
        <Space size={20} direction={'vertical'} style={{ width: '100%' }}>
          <Row gutter={[20, 15]}>{children}</Row>
        </Space>
      </section>
    )
  }

  const addPage1 = async (doc) => {
    const SalesSummaryNode = document.querySelector('[data-print-target="SalesSummaryChart"]')
    const NewCustomersNode = document.querySelector('[data-print-target="NewCustomersInfoCard"]')
    const CompletedOrdersNode = document.querySelector(
      '[data-print-target="CompletedOrdersInfoCard"]',
    )
    const TotalNumberOfPromocodeUseNode = document.querySelector(
      '[data-print-target="TotalNumberOfPromocodeUseInfoCard"]',
    )
    const RevenueNode = document.querySelector('[data-print-target="RevenueInfoCard"]')
    const OrdersConversionNode = document.querySelector(
      '[data-print-target="OrdersConversionInfoCard"]',
    )
    const MoreOneOrdersNode = document.querySelector('[data-print-target="MoreOneOrdersChart"]')
    const OrdersAverageRevenueNode = document.querySelector(
      '[data-print-target="OrdersAverageRevenueChart"]',
    )

    if (
      SalesSummaryNode ||
      NewCustomersNode ||
      CompletedOrdersNode ||
      TotalNumberOfPromocodeUseNode ||
      RevenueNode ||
      OrdersConversionNode ||
      MoreOneOrdersNode ||
      OrdersAverageRevenueNode
    ) {
      iframeDoc.body.innerHTML = renderToString(
        <BasePageTemplate>
          {SalesSummaryNode && (
            <Col span={24} dangerouslySetInnerHTML={{ __html: SalesSummaryNode.outerHTML }}></Col>
          )}
          {NewCustomersNode && (
            <Col span={6} dangerouslySetInnerHTML={{ __html: NewCustomersNode.outerHTML }}></Col>
          )}
          {CompletedOrdersNode && (
            <Col span={6} dangerouslySetInnerHTML={{ __html: CompletedOrdersNode.outerHTML }}></Col>
          )}
          {TotalNumberOfPromocodeUseNode && (
            <Col
              span={6}
              dangerouslySetInnerHTML={{ __html: TotalNumberOfPromocodeUseNode.outerHTML }}
            ></Col>
          )}
          {RevenueNode && (
            <Col span={6} dangerouslySetInnerHTML={{ __html: RevenueNode.outerHTML }}></Col>
          )}
          {OrdersConversionNode && (
            <Col
              span={24}
              dangerouslySetInnerHTML={{ __html: OrdersConversionNode.outerHTML }}
            ></Col>
          )}
          {MoreOneOrdersNode && (
            <Col span={12} dangerouslySetInnerHTML={{ __html: MoreOneOrdersNode.outerHTML }}></Col>
          )}
          {OrdersAverageRevenueNode && (
            <Col
              span={12}
              dangerouslySetInnerHTML={{ __html: OrdersAverageRevenueNode.outerHTML }}
            ></Col>
          )}
        </BasePageTemplate>,
      )

      const canvas = await html2canvas(iframeDoc.body, {})
      const imgBase64Data = canvas.toDataURL('image/png')

      doc.addImage(
        imgBase64Data,
        'PNG',
        paddingX,
        paddingY,
        maxPDFWidth - paddingX * 2,
        maxPDFHeight - paddingY * 2,
      )

      return doc
    }

    return doc
  }

  const addPage2 = async (doc) => {
    const OrdersChartNode = document.querySelector('[data-print-target="OrdersChart"]')
    const OrdersValuesChartNode = document.querySelector('[data-print-target="OrdersValuesChart"]')

    if (OrdersChartNode || OrdersValuesChartNode) {
      doc.addPage()
      iframeDoc.body.innerHTML = ''
      const iframeContentEl = document.createElement('div')

      iframeContentEl.innerHTML = renderToString(
        <BasePageTemplate>
          {OrdersChartNode && <Col span={24} id={'OrdersChartNode__Placement'}></Col>}
          {OrdersValuesChartNode && <Col span={24} id={'OrdersValuesChartNode__Placement'}></Col>}
        </BasePageTemplate>,
      )

      if (OrdersChartNode) {
        iframeContentEl
          .querySelector('#OrdersChartNode__Placement')
          .append(cloneNode(OrdersChartNode))
      }

      if (OrdersValuesChartNode) {
        iframeContentEl
          .querySelector('#OrdersValuesChartNode__Placement')
          .append(cloneNode(OrdersValuesChartNode))
      }

      iframeDoc.body.append(iframeContentEl)

      const canvas = await html2canvas(iframeDoc.body, {})
      const imgBase64Data = canvas.toDataURL('image/png')

      doc.addImage(
        imgBase64Data,
        'PNG',
        paddingX,
        paddingY,
        maxPDFWidth - paddingX * 2,
        maxPDFHeight - paddingY * 2,
      )

      return doc
    }

    return doc
  }

  const addPage3 = async (doc) => {
    const OrdersAverageNode = document.querySelector('[data-print-target="OrdersAverageChart"]')
    const KNetVsCashNode = document.querySelector('[data-print-target="KNetVsCashChart"]')

    if (OrdersAverageNode || KNetVsCashNode) {
      doc.addPage()
      iframeDoc.body.innerHTML = ''
      const iframeContentEl = document.createElement('div')

      iframeContentEl.innerHTML = renderToString(
        <BasePageTemplate>
          {OrdersAverageNode && <Col span={24} id={'OrdersAverageNode__Placement'}></Col>}
          {KNetVsCashNode && <Col span={24} id={'KNetVsCashNode__Placement'}></Col>}
        </BasePageTemplate>,
      )

      if (OrdersAverageNode) {
        iframeContentEl
          .querySelector('#OrdersAverageNode__Placement')
          .append(cloneNode(OrdersAverageNode))
      }

      if (KNetVsCashNode) {
        iframeContentEl
          .querySelector('#KNetVsCashNode__Placement')
          .append(cloneNode(KNetVsCashNode))
      }

      iframeDoc.body.append(iframeContentEl)

      const canvas = await html2canvas(iframeDoc.body, {})
      const imgBase64Data = canvas.toDataURL('image/png')

      doc.addImage(
        imgBase64Data,
        'PNG',
        paddingX,
        paddingY,
        maxPDFWidth - paddingX * 2,
        maxPDFHeight - paddingY * 2,
      )

      return doc
    }

    return doc
  }

  const addPage4 = async (doc) => {
    const OrdersRepeatNode = document.querySelector('[data-print-target="OrdersRepeatChart"]')
    const PopularProductsNode = document.querySelector('[data-print-target="PopularProductsChart"]')

    if (OrdersRepeatNode || PopularProductsNode) {
      doc.addPage()
      iframeDoc.body.innerHTML = ''
      const iframeContentEl = document.createElement('div')

      iframeContentEl.innerHTML = renderToString(
        <BasePageTemplate>
          {OrdersRepeatNode && <Col span={24} id={'OrdersRepeatNode__Placement'}></Col>}
          {PopularProductsNode && <Col span={24} id={'PopularProductsNode__Placement'}></Col>}
        </BasePageTemplate>,
      )

      if (OrdersRepeatNode) {
        iframeContentEl
          .querySelector('#OrdersRepeatNode__Placement')
          .append(cloneNode(OrdersRepeatNode))
      }

      if (PopularProductsNode) {
        iframeContentEl
          .querySelector('#PopularProductsNode__Placement')
          .append(cloneNode(PopularProductsNode))
      }

      iframeDoc.body.append(iframeContentEl)

      const canvas = await html2canvas(iframeDoc.body, {})
      const imgBase64Data = canvas.toDataURL('image/png')

      doc.addImage(
        imgBase64Data,
        'PNG',
        paddingX,
        paddingY,
        maxPDFWidth - paddingX * 2,
        maxPDFHeight - paddingY * 2,
      )

      return doc
    }

    return doc
  }

  const addPage5 = async (doc) => {
    const OrdersPerAreaNode = document.querySelector('[data-print-target="OrdersPerAreaChart"]')
    const OrdersValuesPerAreaNode = document.querySelector(
      '[data-print-target="OrdersValuesPerAreaChart"]',
    )
    const OrdersAveragePerAreaNode = document.querySelector(
      '[data-print-target="OrdersAveragePerAreaChart"]',
    )

    if (OrdersPerAreaNode || OrdersValuesPerAreaNode || OrdersAveragePerAreaNode) {
      doc.addPage()
      iframeDoc.body.innerHTML = ''
      const iframeContentEl = document.createElement('div')

      iframeContentEl.innerHTML = renderToString(
        <BasePageTemplate>
          {OrdersPerAreaNode && <Col span={12} id={'OrdersPerAreaNode__Placement'}></Col>}
          {OrdersValuesPerAreaNode && (
            <Col span={12} id={'OrdersValuesPerAreaNode__Placement'}></Col>
          )}
          {OrdersAveragePerAreaNode && (
            <Col span={24} id={'OrdersAveragePerAreaNode__Placement'}></Col>
          )}
        </BasePageTemplate>,
      )

      if (OrdersPerAreaNode) {
        iframeContentEl
          .querySelector('#OrdersPerAreaNode__Placement')
          .append(cloneNode(OrdersPerAreaNode))
      }

      if (OrdersValuesPerAreaNode) {
        iframeContentEl
          .querySelector('#OrdersValuesPerAreaNode__Placement')
          .append(cloneNode(OrdersValuesPerAreaNode))
      }

      if (OrdersAveragePerAreaNode) {
        iframeContentEl
          .querySelector('#OrdersAveragePerAreaNode__Placement')
          .append(cloneNode(OrdersAveragePerAreaNode))
      }

      iframeDoc.body.append(iframeContentEl)

      const canvas = await html2canvas(iframeDoc.body, {})
      const imgBase64Data = canvas.toDataURL('image/png')

      doc.addImage(
        imgBase64Data,
        'PNG',
        paddingX,
        paddingY,
        maxPDFWidth - paddingX * 2,
        maxPDFHeight - paddingY * 2,
      )

      return doc
    }

    return doc
  }

  const addPage6 = async (doc) => {
    const PromoCodesCountNode = document.querySelector('[data-print-target="PromoCodesCountChart"]')
    const PromoCodesRevenueNode = document.querySelector(
      '[data-print-target="PromoCodesRevenueChart"]',
    )

    if (PromoCodesCountNode || PromoCodesRevenueNode) {
      doc.addPage()
      iframeDoc.body.innerHTML = ''
      const iframeContentEl = document.createElement('div')

      iframeContentEl.innerHTML = renderToString(
        <BasePageTemplate>
          {PromoCodesCountNode && <Col span={24} id={'PromoCodesCountNode__Placement'}></Col>}
          {PromoCodesRevenueNode && <Col span={24} id={'PromoCodesRevenueNode__Placement'}></Col>}
        </BasePageTemplate>,
      )

      if (PromoCodesCountNode) {
        iframeContentEl
          .querySelector('#PromoCodesCountNode__Placement')
          .append(cloneNode(PromoCodesCountNode))
      }

      if (PromoCodesRevenueNode) {
        iframeContentEl
          .querySelector('#PromoCodesRevenueNode__Placement')
          .append(cloneNode(PromoCodesRevenueNode))
      }

      iframeDoc.body.append(iframeContentEl)

      const canvas = await html2canvas(iframeDoc.body, {})
      const imgBase64Data = canvas.toDataURL('image/png')

      doc.addImage(
        imgBase64Data,
        'PNG',
        paddingX,
        paddingY,
        maxPDFWidth - paddingX * 2,
        maxPDFHeight - paddingY * 2,
      )

      return doc
    }

    return doc
  }

  const addPage7 = async (doc) => {
    const TopAreasNode = document.querySelector('[data-print-target="TopAreas"]')
    const TopSKUNode = document.querySelector('[data-print-target="TopSKU"]')
    const TopPromotionsNode = document.querySelector('[data-print-target="TopPromotions"]')
    const TopOffersNode = document.querySelector('[data-print-target="TopOffers"]')

    if (TopAreasNode || TopSKUNode || TopPromotionsNode || TopOffersNode) {
      doc.addPage()
      iframeDoc.body.innerHTML = ''
      const iframeContentEl = document.createElement('div')

      iframeContentEl.innerHTML = renderToString(
        <BasePageTemplate>
          {TopAreasNode && <Col span={12} id={'TopAreasNode__Placement'}></Col>}
          {TopSKUNode && <Col span={12} id={'TopSKUNode__Placement'}></Col>}
          {TopPromotionsNode && <Col span={12} id={'TopPromotionsNode__Placement'}></Col>}
          {TopOffersNode && <Col span={12} id={'TopOffersNode__Placement'}></Col>}
        </BasePageTemplate>,
      )

      if (TopAreasNode) {
        iframeContentEl.querySelector('#TopAreasNode__Placement').append(cloneNode(TopAreasNode))
      }

      if (TopSKUNode) {
        iframeContentEl.querySelector('#TopSKUNode__Placement').append(cloneNode(TopSKUNode))
      }

      if (TopPromotionsNode) {
        iframeContentEl
          .querySelector('#TopPromotionsNode__Placement')
          .append(cloneNode(TopPromotionsNode))
      }

      if (TopOffersNode) {
        iframeContentEl.querySelector('#TopOffersNode__Placement').append(cloneNode(TopOffersNode))
      }

      iframeDoc.body.append(iframeContentEl)

      const canvas = await html2canvas(iframeDoc.body, {})
      const imgBase64Data = canvas.toDataURL('image/png')

      doc.addImage(
        imgBase64Data,
        'PNG',
        paddingX,
        paddingY,
        maxPDFWidth - paddingX * 2,
        maxPDFHeight - paddingY * 2,
      )

      return doc
    }

    return doc
  }

  const addPages = async (doc) => {
    doc = await addPage1(doc)
    doc = await addPage2(doc)
    doc = await addPage3(doc)
    doc = await addPage4(doc)
    doc = await addPage5(doc)
    doc = await addPage6(doc)
    doc = await addPage7(doc)
    return doc
  }

  const getPDF = async () => {
    // Handle callback function
    if (onGenerateStart && typeof onGenerateStart === 'function') {
      onGenerateStart()
    }

    // Add images to PDF flow
    let doc = new JsPDF({
      orientation: 'landscape',
      unit: 'px',
      format: [maxPDFWidth, maxPDFHeight],
    })

    doc = await addPages(doc)

    // Download PDF flow
    let blob = doc.output('blob')

    let PDFObjectURL = window.URL.createObjectURL(blob)
    let PDFTempLink = document.createElement('a')
    PDFTempLink.href = PDFObjectURL
    PDFTempLink.setAttribute('download', `dashboard.pdf`)
    PDFTempLink.click()

    if (onGenerateFinish && typeof onGenerateFinish === 'function') {
      onGenerateFinish(blob)
    }
  }

  return (
    <>
      <Button disabled={false} type="simple" size="small" onClick={getPDF}>
        <Icon icon={ICONS.UPLOAD} color={'black'} size={24} />
        <span>PDF</span>
      </Button>
      <iframe
        title={'pdf-convert-iframe'}
        ref={iframeRef}
        width={maxPDFWidth}
        height={maxPDFHeight}
        style={{
          width: maxPDFWidth - paddingX * 2,
          minWidth: maxPDFWidth - paddingX * 2,
          height: maxPDFHeight - paddingY * 2,
          minHeight: maxPDFHeight - paddingY * 2,
          visibility: 'hidden',
          position: 'absolute',
          transform: 'translateX(-10000px)',
        }}
      />
    </>
  )
}

export default DashboardToPDFButton
