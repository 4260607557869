import { Modal } from 'antd'
import EditWalletModalForm from '../../../forms/EditWalletModalForm/EditWalletModalForm'

const EditWalletModal = ({ isVisible, onClose, onSubmit, walletAmount, userId }) => {
  return (
    <Modal
      open={isVisible}
      onCancel={onClose}
      title={'Edit wallet'}
      footer={null}
      centered={true}
      destroyOnClose={true}
      width={550}
      closable={true}
    >
      <EditWalletModalForm
        onClose={onClose}
        onSubmit={onSubmit}
        walletAmount={walletAmount}
        userId={userId}
      />
    </Modal>
  )
}

export default EditWalletModal
