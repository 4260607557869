import React from 'react'

import Header from './Header'
import Sidebar from './Sidebar'

const MainLayout = ({ children }) => {
  return (
    <div className="mainLayout">
      <Header />
      <Sidebar />
      <main className="main">
        <div className="container">{children}</div>
      </main>
    </div>
  )
}

export default MainLayout
