import {
  GET_USERS_NOTIFICATIONS,
  GET_USERS_NOTIFICATION,
  UPDATE_USERS_NOTIFICATION,
  CREATE_USERS_NOTIFICATION,
  DELETE_USERS_NOTIFICATION,
  GET_USERS_NOTIFICATIONS_REQUEST_PENDING,
  GET_USERS_NOTIFICATIONS_REQUEST_SUCCESS,
  GET_USERS_NOTIFICATIONS_REQUEST_ERROR,
  CHANGE_USERS_NOTIFICATIONS_REQUEST_PENDING,
  CHANGE_USERS_NOTIFICATIONS_REQUEST_SUCCESS,
  CHANGE_USERS_NOTIFICATIONS_REQUEST_ERROR,
  SEND_USERS_NOTIFICATION,
  GET_NOTIFICATION_PRODUCTS,
  GET_NOTIFICATION_PRODUCTS_REQUEST_PENDING,
  GET_NOTIFICATION_PRODUCTS_REQUEST_SUCCESS,
  GET_NOTIFICATION_PRODUCTS_REQUEST_ERROR,
  GET_NOTIFICATION_AREAS,
  GET_NOTIFICATION_AREAS_PENDING,
  GET_NOTIFICATION_AREAS_SUCCESS,
  GET_NOTIFICATION_AREAS_ERROR,
} from '../types/usersNotifications'

const initialState = {
  items: [],
  pagination: {
    current: 1,
    pageSize: 20,
  },
  item: {},
  isGetLoading: false,
  isChangeLoading: false,
  errors: [],
  products: [],
  isProductsLoading: false,
}

const usersNotifications = (state = initialState, action) => {
  switch (action.type) {
    case GET_USERS_NOTIFICATIONS:
      return { ...state, ...action.payload }
    case GET_USERS_NOTIFICATION:
      return { ...state, item: action.payload }
    case UPDATE_USERS_NOTIFICATION:
      const itemIndex = state.items.findIndex((item) => item.id === action.payload.id)
      const newItems = [...state.items]
      newItems[itemIndex] = { ...action.payload }
      return { ...state, items: newItems }
    case CREATE_USERS_NOTIFICATION:
      return { ...state, items: [...state.items, action.payload] }
    case SEND_USERS_NOTIFICATION:
      return {
        ...state,
        items: state.items.map((oneItem) =>
          action.payload.id === oneItem.id ? action.payload : oneItem,
        ),
      }
    case DELETE_USERS_NOTIFICATION:
      return { ...state, items: state.items.filter((item) => item.id !== action.payload.id) }
    case GET_USERS_NOTIFICATIONS_REQUEST_PENDING:
      return { ...state, isGetLoading: true }
    case GET_USERS_NOTIFICATIONS_REQUEST_SUCCESS:
      return { ...state, errors: [], isGetLoading: false }
    case GET_USERS_NOTIFICATIONS_REQUEST_ERROR:
      return { ...state, errors: action.payload, isGetLoading: false }
    case CHANGE_USERS_NOTIFICATIONS_REQUEST_PENDING:
      return { ...state, isChangeLoading: true }
    case CHANGE_USERS_NOTIFICATIONS_REQUEST_SUCCESS:
      return { ...state, errors: [], isChangeLoading: false }
    case CHANGE_USERS_NOTIFICATIONS_REQUEST_ERROR:
      return { ...state, errors: action.payload, isChangeLoading: false }
    case GET_NOTIFICATION_PRODUCTS:
      return { ...state, products: action.payload }
    case GET_NOTIFICATION_PRODUCTS_REQUEST_PENDING:
      return { ...state, isProductsLoading: true }
    case GET_NOTIFICATION_PRODUCTS_REQUEST_SUCCESS:
      return { ...state, isProductsLoading: false }
    case GET_NOTIFICATION_PRODUCTS_REQUEST_ERROR:
      return { ...state, isProductsLoading: false }
    case GET_NOTIFICATION_AREAS:
      return { ...state, areas: action.payload }
    case GET_NOTIFICATION_AREAS_PENDING:
      return { ...state, isAreasLoading: true }
    case GET_NOTIFICATION_AREAS_SUCCESS:
      return { ...state, isAreasLoading: false }
    case GET_NOTIFICATION_AREAS_ERROR:
      return { ...state, isAreasLoading: false }
    default:
      return state
  }
}

export default usersNotifications
