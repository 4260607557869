import {
  GET_PAYMENT_REQUEST_ERROR,
  GET_PAYMENT_REQUEST_PENDING,
  GET_PAYMENT_REQUEST_SUCCESS,
} from '../types/payment'

const initialState = {
  isSuccessPayment: false,
  isLoading: false,
  order: null,
  payment: null,
}

const payment = (state = initialState, action) => {
  switch (action.type) {
    case GET_PAYMENT_REQUEST_PENDING:
      return { ...state, isLoading: true }
    case GET_PAYMENT_REQUEST_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isSuccessPayment: action.payload.isSuccess,
        order: action.payload.order,
        payment: action.payload.payment,
      }
    case GET_PAYMENT_REQUEST_ERROR:
      return { ...state, isLoading: false }
    default:
      return state
  }
}

export default payment
