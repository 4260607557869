import { useDispatch } from 'react-redux'
import { useNavigate, useLocation } from 'react-router-dom'
import { Button, Menu } from 'antd'
import { useTranslation } from 'react-i18next'
import { ICONS, MODAL_TYPES, PERMISSIONS } from '../utils/constants'
import { getFromLocalStorage, setToLocalStorage } from '../utils/functions'
import classNames from 'classnames'
import Icon from '../components/Icon'
import { showModal } from '../redux/actions/modal'
import { signOut } from '../redux/actions/auth'
import { useState } from 'react'
import useClient from 'hooks/useClient'

const IS_MENU_COLLAPSED = 'isMenuCollapsed'

const Sidebar = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { isClientCan } = useClient()
  const [collapsed, setCollapsed] = useState(getFromLocalStorage(IS_MENU_COLLAPSED) === 'true')
  const location = useLocation()
  const isClientCanReadDashboards = isClientCan(PERMISSIONS.READ_DASHBOARD)
  const isClientCanReadCustomers = isClientCan(PERMISSIONS.READ_USERS)
  const isCLientCanReadRoles = isClientCan(PERMISSIONS.READ_ROLES)
  const isClientCanReadFliers = isClientCan(PERMISSIONS.READ_FLIERS)
  const isClientCanReadRating = isClientCan(PERMISSIONS.READ_RATING)
  const isClientCanReadOrders = isClientCan(PERMISSIONS.READ_ORDERS)
  const isClientCanReadOffers = isClientCan(PERMISSIONS.READ_OFFERS)
  const isClientCanReadCoupons = isClientCan(PERMISSIONS.READ_COUPONS)
  const isClientCanReadMosques = isClientCan(PERMISSIONS.READ_MOSQUES)
  const isClientCanReadProducts = isClientCan(PERMISSIONS.READ_PRODUCTS)
  const isClientCanReadUpselling = isClientCan(PERMISSIONS.READ_UPSELLING)
  const isClientCanReadPromotions = isClientCan(PERMISSIONS.READ_PROMOTIONS)
  const isClientCanReadPromoCodes = isClientCan(PERMISSIONS.READ_PROMO_CODES)
  const isClientCanReadNotifications = isClientCan(PERMISSIONS.READ_NOTIFICATIONS)
  const isClientCanReadProductCategoriess = isClientCan(PERMISSIONS.READ_PRODUCT_CATEGORIES)
  const isClientCanReadAdminUsers = isClientCan(PERMISSIONS.READ_ADMIN_USERS)
  const isClientCanReadCampaigns = isClientCan(PERMISSIONS.READ_CAMPAIGN)

  const toggleCollapsed = () => {
    setCollapsed(!collapsed)
    setToLocalStorage(IS_MENU_COLLAPSED, !collapsed ? 'true' : 'false')
  }

  const logOutHandler = () => {
    dispatch(
      showModal({
        type: MODAL_TYPES.CONFIRM,
        question: 'Are you sure want to log out?',
        acceptHandler: () => {
          dispatch(signOut({ navigate }))
        },
      }),
    )
  }

  const toggleMenuClasses = classNames({
    sidebar__section: true,
    'sidebar__section_toggle-menu': true,
    'sidebar__section_toggle-menu_collapsed': collapsed,
  })

  const mainMenuClasses = classNames({
    sidebar__section: true,
    'sidebar__section_main-menu': true,
    'sidebar__section_main-menu_collapsed': collapsed,
  })

  const bottomMenuClasses = classNames({
    sidebar__section: true,
    'sidebar__section_bottom-menu': true,
    'sidebar__section_bottom-menu_collapsed': collapsed,
  })

  // Map routes with menu items key for highlight selected menu items and opened menu dropdowns
  const routesKeysMap = {
    '/main': 'customers', // One key "customers" for few routes
    '/main/customers': 'customers', // One key "customers" for few routes
    '/main/dashboard': 'dashboard',
    '/main/products': 'submenu-product-catalogue_products',
    '/main/categories': 'submenu-product-catalogue_categories',
    '/main/promotions': 'submenu-marketing_promotions',
    '/main/banners': 'submenu-marketing_banners',
    '/main/flyers': 'submenu-marketing_flyers',
    '/main/orders': 'orders',
    '/main/upselling': 'submenu-marketing_upselling',
    '/main/users-notifications': 'user-notifications',
    '/main/coupons': 'submenu-marketing_coupons',
    '/main/promocodes': 'submenu-marketing_promocodes',
    '/main/rating': 'submenu-marketing_rating',
    '/main/mosques': 'mosques',
    '/main/users': 'team-management_users',
    '/main/roles': 'team-management_roles',
    '/main/campaigns': 'submenu-marketing_campaigns',
  }

  const mainMenuItems = [
    isClientCanReadDashboards && {
      label: t('sidebar.dashboard'),
      key: 'dashboard',
      icon: <Icon icon={ICONS.PRODUCT} size={24} />,
      onClick: () => navigate('/main/dashboard'),
    },
    isClientCanReadCustomers && {
      label: t('sidebar.customers'),
      key: 'customers',
      icon: <Icon icon={ICONS.USER} size={24} />,
      onClick: () => navigate('/main'),
    },
    isClientCanReadOrders && {
      label: t('sidebar.orders'),
      key: 'orders',
      icon: <Icon icon={ICONS.ORDERS} size={24} />,
      onClick: () => navigate('/main/orders'),
    },
    (isClientCanReadAdminUsers || isCLientCanReadRoles) && {
      label: t('sidebar.userManagement'),
      key: 'team-management',
      icon: <Icon icon={ICONS.USERS} size={24} />,
      children: [
        isClientCanReadAdminUsers && {
          label: t('sidebar.users'),
          key: 'team-management_users',
          onClick: () => navigate('/main/users'),
        },
        isCLientCanReadRoles && {
          label: t('sidebar.roles'),
          key: 'team-management_roles',
          onClick: () => navigate('/main/roles'),
        },
        {
          label: t('sidebar.logs'),
          key: 'team-management_logs',
          onClick: () => navigate('/main/logs'),
        },
      ],
    },
    (isClientCanReadProducts || isClientCanReadProductCategoriess) && {
      label: t('sidebar.product catalogue'),
      key: 'submenu-product-catalogue',
      icon: <Icon icon={ICONS.PRODUCT} size={24} />,
      children: [
        isClientCanReadProducts && {
          label: t('sidebar.products'),
          key: 'submenu-product-catalogue_products',
          icon: <Icon icon={ICONS.BANNERS} size={24} />,
          onClick: () => navigate('/main/products'),
        },
        isClientCanReadProductCategoriess && {
          label: t('sidebar.categories'),
          key: 'submenu-product-catalogue_categories',
          icon: <Icon icon={ICONS.BANNERS} size={24} />,
          onClick: () => navigate('/main/categories'),
        },
      ],
    },
    (isClientCanReadCoupons ||
      isClientCanReadOffers ||
      isClientCanReadFliers ||
      isClientCanReadPromotions ||
      isClientCanReadPromoCodes ||
      isClientCanReadRating ||
      isClientCanReadUpselling) && {
      label: t('sidebar.marketing'),
      key: 'submenu-marketing',
      icon: <Icon icon={ICONS.SETTINGS} color={'#200E32'} size={24} />,
      children: [
        isClientCanReadCoupons && {
          label: t('sidebar.coupons'),
          key: 'submenu-marketing_coupons',
          icon: <Icon icon={ICONS.COUPON} color={'#200E32'} size={24} />,
          onClick: () => navigate('/main/coupons'),
        },
        isClientCanReadOffers && {
          label: t('sidebar.banners'),
          key: 'submenu-marketing_banners',
          icon: <Icon icon={ICONS.BANNERS} size={24} />,
          onClick: () => navigate('/main/banners'),
        },
        isClientCanReadFliers && {
          label: t('sidebar.flyers'),
          key: 'submenu-marketing_flyers',
          icon: <Icon icon={ICONS.BANNERS} size={24} />,
          onClick: () => navigate('/main/flyers'),
        },
        isClientCanReadPromotions && {
          label: t('sidebar.promotions'),
          key: 'submenu-marketing_promotions',
          icon: <Icon icon={ICONS.STAR_EMPTY} color={'#200E32'} size={24} />,
          onClick: () => navigate('/main/promotions'),
        },
        isClientCanReadPromoCodes && {
          label: t('sidebar.promocodes'),
          key: 'submenu-marketing_promocodes',
          icon: <Icon icon={ICONS.PROMOTIONS} size={24} />,
          onClick: () => navigate('/main/promocodes'),
        },
        isClientCanReadRating && {
          label: t('sidebar.rating'),
          key: 'submenu-marketing_rating',
          icon: <Icon icon={ICONS.RATING} color={'#200E32'} size={24} />,
          onClick: () => navigate('/main/rating'),
        },
        isClientCanReadUpselling && {
          label: t('sidebar.upselling'),
          key: 'submenu-marketing_upselling',
          icon: <Icon icon={ICONS.USERS} size={24} />,
          onClick: () => navigate('/main/upselling'),
        },
        isClientCanReadCampaigns && {
          label: t('sidebar.campaigns'),
          key: 'submenu-marketing_campaigns',
          icon: <Icon icon={ICONS.USERS} size={24} />,
          onClick: () => navigate('/main/campaigns'),
        },
        {
          label: t('sidebar.cashback'),
          key: 'submenu-marketing_cashback',
          icon: <Icon icon={ICONS.USERS} size={24} />,
          onClick: () => navigate('/main/cashback'),
        },
      ],
    },
    isClientCanReadNotifications && {
      label: t('sidebar.user notifications'),
      key: 'user-notifications',
      icon: <Icon icon={ICONS.NOTIFICATIONS} size={24} />,
      onClick: () => navigate('/main/users-notifications'),
    },
    isClientCanReadMosques && {
      label: t('sidebar.mosques'),
      key: 'mosques',
      icon: <Icon icon={ICONS.MOSQUE} color={'#200E32'} size={24} />,
      onClick: () => navigate('/main/mosques'),
    },
  ]

  const bottomMenuItems = [
    {
      label: t('app.log out'),
      key: 'log-out',
      icon: <Icon icon={ICONS.EXIT} size={24} />,
      danger: true,
      onClick: logOutHandler,
    },
  ]

  // Search selected and opened menu elements using current route and routesKeysMap
  const getMenuActiveKeys = (menu) => {
    let selected = []
    let opened = []
    let currentSubMenuKey = null

    // Find selected menu keys by route
    for (let route in routesKeysMap) {
      if (route === location.pathname) {
        selected.push(routesKeysMap[route])
      }
    }

    // Find opened submenu keys if menu is not collapsed
    if (!collapsed) {
      menu.forEach((menuItem) => {
        if (menuItem.children) {
          currentSubMenuKey = menuItem.key
          menuItem.children.forEach((childMenuItem) => {
            if (selected.indexOf(childMenuItem.key) !== -1) {
              opened.push(currentSubMenuKey)
            }
          })
          currentSubMenuKey = null
        }
      })
    }

    return { selected, opened }
  }

  return (
    <aside className="sidebar">
      <div className="sidebar__inner">
        <div className={toggleMenuClasses}>
          <Button
            onClick={toggleCollapsed}
            type="text"
            shape="circle"
            size={'large'}
            icon={<Icon icon={ICONS.MENU_OUTLINED} size={24} />}
          />
        </div>
        <div className={mainMenuClasses}>
          <Menu
            mode="inline"
            inlineCollapsed={collapsed}
            items={mainMenuItems}
            defaultSelectedKeys={getMenuActiveKeys(mainMenuItems).selected}
            defaultOpenKeys={getMenuActiveKeys(mainMenuItems).opened}
          />
        </div>
        <div className={bottomMenuClasses}>
          <Menu mode="inline" inlineCollapsed={collapsed} items={bottomMenuItems} />
        </div>
      </div>
    </aside>
  )
}

export default Sidebar
