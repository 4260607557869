import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { CASHBACK_RULE_LABEL, DATE_TIME_FORMAT, ICONS } from '../../../utils/constants'

import PageTable from '../../../components/PageTable'
import Button from '../../../components/Button'
import Icon from '../../../components/Icon'
import { useQuery } from '@tanstack/react-query'
import { getCashbackList } from '../../../api/cashback'
import { useMemo, useState } from 'react'
import SortableColumnTitle from '../../../components/SortableColumnTitle'
import moment from 'moment/moment'

const Cashback = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [currentPage, setCurrentPage] = useState(1)
  const [pageSize, setPageSize] = useState(20)
  const [sortType, setSortType] = useState('startDate')
  const [sortOrder, setSortOrder] = useState('descend')

  const {
    data: cashbackList,
    refetch: refetchCashbackList,
    isFetching,
  } = useQuery(
    ['cashback-list', currentPage, pageSize, sortType, sortOrder],
    async () =>
      await getCashbackList({
        currentPage,
        pageSize,
        sortType,
        sortOrder,
      }),
  )

  const tableChangeHandler = (pagination, filter, sorter) => {
    setCurrentPage(pageSize === pagination.pageSize ? pagination.current : 1)
    setPageSize(pagination.pageSize)
    setSortType(sorter.columnKey || null)
    setSortOrder(sorter.order)
  }

  const columns = useMemo(() => {
    return [
      {
        title: '№',
        dataIndex: 'id',
        key: 'id',
        align: 'center',
        width: '30px',
        render: (_, record, index) => index + 1,
      },
      {
        title: t('cashback.table.name'),
        dataIndex: 'name',
        key: 'name',
        align: 'left',
        width: 250,
      },
      {
        title: t('cashback.table.cashbackAmount'),
        dataIndex: 'cashbackAmount',
        key: 'cashbackAmount',
        align: 'right',
      },
      {
        title: t('cashback.table.rule'),
        dataIndex: 'rule',
        key: 'rule',
        align: 'left',
        render: (rule) => CASHBACK_RULE_LABEL[rule],
      },
      {
        title: (props) => (
          <SortableColumnTitle {...props} title={t('cashback.table.startDate')} id="startDate" />
        ),
        dataIndex: 'startDate',
        key: 'startDate',
        render: (startDate) => (startDate ? moment(startDate).format(DATE_TIME_FORMAT) : '-'),
        sorter: true,
      },
      {
        title: (props) => (
          <SortableColumnTitle {...props} title={t('cashback.table.endDate')} id="endDate" />
        ),
        dataIndex: 'endDate',
        key: 'endDate',
        render: (endDate) => (endDate ? moment(endDate).format(DATE_TIME_FORMAT) : '-'),
        sorter: true,
      },
      {
        title: t('cashback.table.status'),
        dataIndex: 'isActive',
        key: 'isActive',
        align: 'left',
        render: (isActive) =>
          isActive ? t('cashback.table.active') : t('cashback.table.inactive'),
      },
    ]
  }, [t])

  return (
    <PageTable
      action={
        <Button type="primary" onClick={() => navigate(`/main/cashback/add`)}>
          <Icon icon={ICONS.PLUS} className="btn-icon-suffix" />
          <span className="btn-text">{t('cashback.newCashback')}</span>
        </Button>
      }
      baseData={cashbackList?.result || []}
      columns={columns}
      loading={isFetching}
      onRow={(record) => ({
        onClick: () => navigate(`/main/cashback/${record.id}`),
      })}
      onChange={tableChangeHandler}
      pageTitle={t('cashback.title')}
    />
  )
}

export default Cashback
