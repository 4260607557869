import { call, put, all, fork, takeEvery } from 'redux-saga/effects'

import { showLoading, hideLoading } from 'react-redux-loading-bar'

import RatingService from '../middlewares/rating'

import {
  GET_RATING,
  GET_RATING_MESSAGE,
  GET_RATING_MESSAGE_REQUEST,
  GET_RATING_MESSAGE_REQUEST_ERROR,
  GET_RATING_MESSAGE_REQUEST_PENDING,
  GET_RATING_MESSAGE_REQUEST_SUCCESS,
  GET_RATING_REQUEST,
  GET_RATING_REQUEST_ERROR,
  GET_RATING_REQUEST_PENDING,
  GET_RATING_REQUEST_SUCCESS,
  UPDATE_RATING_MESSAGE,
  UPDATE_RATING_MESSAGE_REQUEST,
  UPDATE_RATING_MESSAGE_REQUEST_ERROR,
  UPDATE_RATING_MESSAGE_REQUEST_PENDING,
  UPDATE_RATING_MESSAGE_REQUEST_SUCCESS,
} from '../types/rating'

import { notify } from 'reapop'
import i18n from 'i18next'

function* getRatingsWatcher() {
  yield takeEvery(GET_RATING_REQUEST, getRatingWorker)
}

function* getRatingMessageWatcher() {
  yield takeEvery(GET_RATING_MESSAGE_REQUEST, getRatingMessageWorker)
}
function* updateRatingMessageWatcher() {
  yield takeEvery(UPDATE_RATING_MESSAGE_REQUEST, updateRatingMessageWorker)
}
function* getRatingWorker({ payload: { page, size, date } }) {
  try {
    yield put(showLoading())
    yield put({ type: GET_RATING_REQUEST_PENDING })
    const response = yield call(RatingService.getRatings, page, size, date)
    const { result, total } = response.data.data
    yield put({
      type: GET_RATING,
      payload: {
        items: result,
        pagination: {
          current: page,
          pageSize: size,
          total: total,
        },
      },
    })

    yield put({ type: GET_RATING_REQUEST_SUCCESS })
    yield put(hideLoading())
  } catch (error) {
    yield put({ type: GET_RATING_REQUEST_ERROR, payload: error.response.data.messages })
    yield put(hideLoading())
  }
}

function* getRatingMessageWorker() {
  try {
    yield put(showLoading())
    yield put({ type: GET_RATING_MESSAGE_REQUEST_PENDING })
    const response = yield call(RatingService.getRatingMassage)
    const message = response.data.data
    yield put({ type: GET_RATING_MESSAGE, payload: message })
    yield put({ type: GET_RATING_MESSAGE_REQUEST_SUCCESS })
    yield put(hideLoading())
  } catch (error) {
    yield put({ type: GET_RATING_MESSAGE_REQUEST_ERROR, payload: error.response.data.messages })
    yield put(hideLoading())
  }
}
function* updateRatingMessageWorker({ payload: { data } }) {
  try {
    yield put(showLoading())
    yield put({ type: UPDATE_RATING_MESSAGE_REQUEST_PENDING })
    const apiMethod = data.id
      ? RatingService.updateRatingMassage
      : RatingService.createRatingMassage
    delete data.id // Important to not provide id, accordingly API conditions
    const response = yield call(apiMethod, data)
    const message = response.data.data
    yield put({ type: UPDATE_RATING_MESSAGE, payload: message })
    yield put({ type: UPDATE_RATING_MESSAGE_REQUEST_SUCCESS })
    yield put(
      notify({
        title: i18n.t('notifs.updated'),
        status: 'success',
        dismissAfter: 4000,
        allowHTML: true,
      }),
    )
    yield put(hideLoading())
  } catch (error) {
    yield put({ type: UPDATE_RATING_MESSAGE_REQUEST_ERROR, payload: error.response.data.messages })
    yield put(hideLoading())
  }
}
export default function* ratings() {
  yield all([
    fork(getRatingsWatcher),
    fork(getRatingMessageWatcher),
    fork(updateRatingMessageWatcher),
  ])
}
