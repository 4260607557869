import React from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { hideModal } from '../../redux/actions/modal'

import { ICONS, MODAL_TYPES } from '../../utils/constants'

import Icon from '../Icon'
import ConfirmModal from './modals/ConfirmModal/ConfirmModal'
import AlertModal from './modals/AlertModal/AlertModal'
import FormModal from './modals/FormModal/FormModal'

const Modal = () => {
  const dispatch = useDispatch()

  const isOpened = useSelector((state) => state.modal.isOpened)
  const data = useSelector((state) => state.modal.dataArray[state.modal.dataArray.length - 1]) || {}

  const closeModal = () => {
    dispatch(hideModal())
  }

  const renderContent = () => {
    switch (data.type) {
      case MODAL_TYPES.CONFIRM:
        return (
          <ConfirmModal
            question={data.question}
            acceptHandler={data.acceptHandler}
            declineHandler={data.declineHandler || closeModal}
          />
        )
      case MODAL_TYPES.ALERT:
        return <AlertModal title={data.title} closeHandler={data.closeHandler || closeModal} />
      case MODAL_TYPES.FORM:
        return (
          <FormModal
            notificationId={data.notificationId}
            submitHandler={data.submitHandler}
            declineHandler={data.declineHandler || closeModal}
          />
        )
      default:
        return null
    }
  }

  return isOpened ? (
    <div className="modal">
      {isOpened && <div className="modal-overlay" onClick={data.onClose || closeModal} />}

      <div className="modal-window">
        <button className="modal-close" onClick={data.onClose || closeModal}>
          <Icon icon={ICONS.CLOSE} size={24} />
        </button>
        {data && <div className="modal-content">{renderContent()}</div>}
      </div>
    </div>
  ) : null
}

export default Modal
