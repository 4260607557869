import { createContext, useState } from 'react'

const defaultState = {
  client: {
    data: undefined,
    isLoaded: false,
    isAuthorized: false,
  },
  setClient: () => {},
}

const ClientContext = createContext(defaultState)

export const ClientProvider = ({ children }) => {
  const [client, setClient] = useState(defaultState.client)

  return <ClientContext.Provider value={{ client, setClient }}>{children}</ClientContext.Provider>
}

export default ClientContext
