import React from 'react'
import { useTranslation } from 'react-i18next'

import Page from '../../../components/Page'

const OrdersAdd = () => {
  const { t } = useTranslation()

  return <Page pageTitle={t('orders.new order')} withCancelBtn={true}></Page>
}

export default OrdersAdd
