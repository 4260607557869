import { useTranslation } from 'react-i18next'

import { FormProvider, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'

import { customersSchema } from '../../utils/schemas'

import HookedField from '../../components/HookedField'
import useClient from '../../hooks/useClient'
import { PERMISSIONS } from '../../utils/constants'

const CustomersForm = ({ defaultValues = {}, submitHandler, handleWalletAmountEdit }) => {
  const { t } = useTranslation()
  const { isClientCan } = useClient()

  const isClientCanUpdateWallet = isClientCan(PERMISSIONS.UPDATE_WALLET)

  const methods = useForm({
    mode: 'onSubmit',
    shouldFocusError: true,
    resolver: yupResolver(customersSchema),
  })

  return (
    <div className="customer-form">
      <FormProvider {...methods}>
        <form className="form" autoComplete="off" onSubmit={methods.handleSubmit(submitHandler)}>
          <fieldset>
            <div className="form-title">{t('customers.customer info')}</div>
            <div className="form-grid" style={{ alignItems: 'center' }}>
              {defaultValues.users_id && (
                <HookedField
                  label={t('fields.id')}
                  name="id"
                  disabled={true}
                  defaultValue={defaultValues.users_id}
                  placeholder="id"
                  fieldType="textField"
                  type="text"
                />
              )}
              <HookedField
                label={t('fields.name en')}
                name="nameEn"
                defaultValue={defaultValues?.users_fullName || ''}
                placeholder={t('fields.name en')}
                fieldType="textField"
                type="text"
                disabled={true}
              />
              <HookedField
                label={t('fields.mobile')}
                name="mobile"
                defaultValue={defaultValues?.users_phoneNumber || ''}
                placeholder={t('fields.mobile')}
                fieldType="textField"
                type="tel"
                disabled={true}
              />
              <HookedField
                label={t('fields.email')}
                name="email"
                defaultValue={defaultValues?.users_email || ''}
                placeholder={t('fields.email')}
                fieldType="textField"
                type="text"
                disabled={true}
              />
              <HookedField
                label={t('fields.total orders')}
                name="totalOrders"
                defaultValue={defaultValues?.totalorders || 0}
                placeholder={t('fields.total orders')}
                fieldType="numberField"
                type="number"
                disabled={true}
              />
              <HookedField
                label={t('fields.last order')}
                name="lastOrder"
                defaultValue={defaultValues?.lastorder || defaultValues?.order_createdAt || ''}
                placeholder={t('fields.last order')}
                fieldType="dateField"
                type="datePicker"
                disabled={true}
              />
              <HookedField
                label={t('fields.wallet')}
                name="lastOrder"
                defaultValue={''}
                placeholder={t('wallet.currency', {
                  amount: defaultValues?.walletBalance,
                })}
                fieldType="numberField"
                type="number"
                disabled={true}
              />
              {isClientCanUpdateWallet && (
                <div>
                  <button
                    type="button"
                    className="btn btn--normal btn--primary"
                    onClick={handleWalletAmountEdit}
                  >
                    Edit wallet
                  </button>
                </div>
              )}
            </div>
          </fieldset>
        </form>
      </FormProvider>
    </div>
  )
}

export default CustomersForm
