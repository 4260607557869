import classNames from 'classnames'

const Button = ({
  children,
  onClick,
  className,
  size = 'normal',
  type = 'primary',
  htmlType = 'button',
  disabled = false,
}) => {
  const classes = classNames({
    btn: true,
    [className]: !!className,
    [`btn--${size}`]: true,
    [`btn--${type}`]: true,
  })

  return (
    <button className={classes} onClick={onClick} type={htmlType} disabled={disabled}>
      {children}
    </button>
  )
}

export default Button
