import { GET_RATING, GET_RATING_MESSAGE, UPDATE_RATING_MESSAGE } from '../types/rating'

const initialState = {
  items: [],
  pagination: {
    current: 1,
    pageSize: 20,
  },
  date: false,
  item: {},
  isGetLoading: false,
  isChangeLoading: false,
  errors: [],
}

const rating = (state = initialState, action) => {
  switch (action.type) {
    case GET_RATING:
      return { ...state, ...action.payload }
    case GET_RATING_MESSAGE:
      return { ...state, item: action.payload }
    case UPDATE_RATING_MESSAGE:
      return { ...state, item: action.payload }
    default:
      return state
  }
}

export default rating
